// import React, { useEffect, useState } from 'react';

// import { connect } from 'react-redux';

// import Container from '@material-ui/core/Container';
// import { Grid, makeStyles } from '@material-ui/core';

// import FirmarSection from '../FirmarSection';
// import { getListDocumentPending } from '../../../redux/actions/ConsultSignedDocumentsAction';
// import PendingDocsView from '../../../components/PendingDocsView';

// const useStyles = makeStyles(theme => ({
// 	pending: {
// 		position: 'absolute',
// 		zIndex: 500,
// 		top: '80px',
// 		width: '57.5%',
// 		maxWidth: '965px',
// 		'@media(max-width:1279px)': {
// 			width: '51%',
// 			maxWidth: '655px',
// 		},
// 		'@media(max-width:1235px)': {
// 			width: '75%',
// 			maxWidth: '930px',
// 		},
// 		'@media(max-width:1200px)': {
// 			top: '70px',
// 		},
// 		'@media(max-width:991px)': {
// 			top: '0',
// 			position: 'relative',
// 			marginBottom: '10px',
// 		},
// 	},
// }));

// const HomeCF = props => {
// 	const {
// 		rowsPending,
// 		getListDocumentPending,
// 	} = props;
// 	const [loading, setLoading] = useState(true);
// 	const classes = useStyles();


// 	useEffect(() => {
// 		(async () => {
// 			await Promise.all([
// 				getListDocumentPending(),
// 			]);
// 			setLoading(false);
// 		})();
// 	}, []);
// 	return (
// 		<>
// 			<Container component='main' maxWidth='lg'>
// 				<h1 className={'title-background'}>Firmar documentos</h1>
// 			</Container>
// 			<Container component='main'>
// 				<Grid
// 					container
// 					spacing={0}
// 					direction='row'
// 					justify='flex-start'
// 					alignItems='flex-start'
// 					className='zone-upload-container'
// 					maxWidth='md'
// 				>
// 					{rowsPending.length>0&&
// 						<Grid item xs={12} sm={12} md={8} lg={9} className={classes.pending}>
// 						<PendingDocsView cantidad={rowsPending.length}/>
// 					</Grid>
// 					}
// 					<Grid item xs={12} sm={12} md={12} lg={12}>
// 						<FirmarSection isDashboard={false} />
// 					</Grid>
// 				</Grid>
// 			</Container>
// 		</>
// 	);
// };

// const mapStateToProps = state => ({
// 	rowsPending: state.consultSignedDocument.pendingSign,
// });

// const mapDispatchToProps = {
// 	getListDocumentPending,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(HomeCF);

import React from 'react';

import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

import FirmarSection from '../FirmarSection';

export const HomeCF = () => {
	return (
		<>
			<Container component='main' maxWidth='lg'>
				<h1 className={'title-background'}>Firmar documentos</h1>
			</Container>
			<Container component='main'>
				<Grid
					container
					spacing={0}
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
					maxWidth='md'
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FirmarSection isDashboard={false} />
					</Grid>
				</Grid>
			</Container>
		</>
	);
};
