import React, { useState } from 'react';
import TableValidationResponsive from './TableValidationResponsive';
import imgDownloadIcon from '../../assets/img/descargarIcon.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import icoLeft from '../../assets/img/iconLeft.png';
import icoCerrar from '../../assets/img/icono-cerrar.svg';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Paper, Tooltip, Typography, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	IconButton,
	Modal,
} from '@material-ui/core';
import { downloadPDF } from '../../helpers/FilesFunction.js';
 
const useStyles = makeStyles((theme) => ({
	modal: {
    	display: 'flex',
    	alignItems: 'center',
    	justifyContent: 'center',
  	},
	iconButtonDownload: {
		borderRadius: '5px',
		backgroundImage: `url(${imgDownloadIcon}) `,
		backgroundPosition: 'center',
		width: '28px !important',
		height: '28px !important',		
	},
	iconEye: {
		float: 'right',
		marginRight: '29px',
		cursor: 'pointer',
		color: '#E55200'
	},
	titleTable: {
		font: 'normal normal bold 14px/16px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
	iconClose: {
		justifyContent: 'flex-end',
		marginTop: '-5px',
		marginRight: '-5px',
		float: 'right'
	},
	sub: {
		display: 'inline'
  	},
	sub1: {
		font: 'normal normal bold 14px/13px Muli',
		whiteSpace: 'nowrap',
		letterSpacing: '0px',
		color: '#575756;',
		opacity: '1',
	},
	sub2: {
		font: 'normal normal normal 14px/13px Muli',
		lineHeight:'30px',
		whiteSpace: 'nowrap',
		letterSpacing: '0px',
		color: '#575756',
		opacity: '1',
	},
	btitle: {
		color: '#E55200',
		paddingLeft: '19px',
		padding: '24px',
		marginBottom: -19,
		font: 'normal normal bold 22px/22px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
	title: {
		color: '#E55200',
		padding: '27px',
		marginBottom: -25,
		font: 'normal normal bold 22px/22px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
	naTitle: {
		color: '#E55200',
		marginLeft: '-34px',
		font: 'normal normal 600 14px/19px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
		marginTop: '20px',
	},
	title2: {
		color: '#E55200',
		font: 'normal normal 600 14px/19px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
		marginTop: '20px',
	},
	title1: {
		color: '#E55200',
		font: 'normal normal bold 20px/20px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: '1',
	},
	table: {
		minWidth: 0
	},
	tableContainer: {
		marginTop: 20,
		marginBottom: 15,
	},
	tableHead: {
		backgroundColor: '#FFEFD8',
	},
	gridItem: {
		margin: '10px 5px 20px',
	},
	iconBack: {
		marginTop:'2px',
		cursor: 'pointer'
	},
	msgFail: {
		marginTop: 20,
		fontFamily: 'Verdana',
		fontSize: '100%',
	},
}));

const IconLeft = (props) => {
	return ( <img src={icoLeft} {...props}/>);
}

const IconClose = () => {
	return ( <img src={icoCerrar}/>);
}

const InfoValidation = ({ dataDocument, isPdf, isResponsive, file, handleRegresar }) => {
	const classes = useStyles();
	const [documentoTitular, setDocumentoTitular] = useState(null);
	const [indexRow, setIndex] = useState(null);
	const documentoFirmado = dataDocument?.documentoFirmado;
	const dictAsimilacion = {
		id: 'Id',
		nombreCertificado: 'Nombre certificado',
		clave: 'Clave',
		base64: 'Base64',
		cn: 'Titular',
		email: 'Correo',
		serialNumber: 'Serial',
		organizacion: 'Entidad certificadora',
		pais: 'País',
		alias: 'Alias',
		fechaDesde: 'Certificado válido desde',
		fechaHasta: 'Certificado válido hasta',
		estadoValidacion: 'Estado validación',
		fechaFirmado: 'Fecha firmada',
		numeroDocumento: 'Número documento',
		ciudad: 'Ciudad',
	};

	const handleClickVerContenido = (e) => {
		const re = /(?:\.([^.]+))?$/;
		const extension = re.exec(file.name)[0];
		let nameFile = file.name;
		var regExp = /\(([^)]+)\)/g;
		var matches = file.name.match(regExp);
		if (matches) {
			for (let i = 0; i < matches.length; i++) {
				var str = matches[i];
				nameFile = nameFile.replace('(' + str.substring(1, str.length - 1) + ')', '');
			}
		}
		let posicion = nameFile.indexOf('_FirmaYa ');
		let nomArchivoOrig = "";
		if (posicion != -1) {
			nomArchivoOrig = nameFile.replace(extension, '').replace('_FirmaYa ', '');
		} else {
			nomArchivoOrig = nameFile.replace(extension, '').replace('_FirmaYa', '');
		}
		const extensionwithoutPoint = re.exec(file.name)[1];
		downloadPDF(dataDocument.contenidoOriginal, nomArchivoOrig, extensionwithoutPoint, 'data:image/jpg;base64,');
	};
	
	const nameEstate = (name) => {
		return name ? name.includes('Certificado') ? name.replace('Certificado', 'Cert.') : name : null;
	}

	const cutNameTitle = (name) => {
	let word = window.innerWidth < 991 ? 40 : 70
	return(
		name.length > word ? (
			<Tooltip title={name}>
				<div>{name.substring(0, word).concat('...')}</div>
			</Tooltip>
		) : <div>{name}</div>)
	};

	const cutNameDocument = (name) => {
		if(name != null){
		return(
			name.length > 20 ? (
				<Tooltip title={name}>
					<span>{name.substring(0, 20).concat('...')}</span>
				</Tooltip>
		) : <span>{name}</span>)}
	};

	const cutName = (name) => {
		const re = /(?:\.([^.]+))?$/;
		const extension = re.exec(name)[0];
		var regExp = /\(([^)]+)\)/g;
		var matches = file.name.match(regExp);
		if (matches) {
			for (let i = 0; i < matches.length; i++) {
				var str = matches[i];
				name = name.replace('(' + str.substring(1, str.length - 1) + ')', '');
			}
		}
		let posicion = name.indexOf('_FirmaYa ');
		let nomArchivoOrig = "";
		if (posicion != -1) {
			nomArchivoOrig = name.replace(extension, '').replace('_FirmaYa ', '');
		} else {
			nomArchivoOrig = name.replace(extension, '').replace('_FirmaYa', '');
		}
		return cutNameTitle(nomArchivoOrig);
	}

	const StyledTooltip = withStyles({
		tooltipPlacementTop: {
		  margin: "1px 0",
		},
	})(Tooltip);

	const handleClicTitular = (serial) => {
		setDocumentoTitular(documentoFirmado.find((h) => h === serial));
	};

	return (
		<Paper elevation={0}>
			<Grid container spacing={3} justify="">
				<Grid item>
					<IconLeft color="primary" className={classes.iconBack} aria-label="regresar al inicio" onClick={handleRegresar}/>
				</Grid>
				<Grid item>
					<Typography className={classes.title1} variant="h6" id="tableTitle" component="div">
						Resultado de la verificación
					</Typography>
					<Typography className={!isResponsive ? classes.naTitle : classes.title2} variant="h6" id="tableTitle" component="div">
						{cutNameTitle(file.name)}
					</Typography>
				</Grid>
			</Grid>
			{dataDocument != null &&
				dataDocument.codigoRespuesta == 'RS1' ? (
				<>
					{isResponsive ? <TableContainer className={classes.tableContainer}>
						<Table className={'tableSignedDocuments'} >
							<TableHead className={'headerTableValidate'}>
								<TableRow>
									<TableCell><Typography className={classes.titleTable}>Serial</Typography></TableCell>
									<TableCell><Typography className={classes.titleTable}>Titular</Typography></TableCell>
									<TableCell><Typography className={classes.titleTable}>Resultado validación</Typography></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object(documentoFirmado).map((item, index) => (
									<TableRow hover key={`row_titular_${index}`} onMouseEnter={() => setIndex(index)} onMouseLeave={() => setIndex(null)}>
										<TableCell align="left" font-weight="normal">{cutNameDocument(item.serialNumber)}</TableCell>
										<TableCell align="left">{item.cn}</TableCell>
										<TableCell align="left">
											{item.estadoValidacion}
											<StyledTooltip placement="top" title={'Detalles del certificado'}>
												<VisibilityIcon style={{ visibility: indexRow === index ? 'visible' : 'hidden' }} 
													fontSize="small" className={classes.iconEye} onClick={() => { handleClicTitular(item) }}/>
											</StyledTooltip>
										</TableCell>
									</TableRow>
								))
								}
							</TableBody>
						</Table>
					</TableContainer> : 
					<>
						<TableValidationResponsive handleClicTitular={handleClicTitular} rows={Object(documentoFirmado)}/>
					</>}
					<Modal
						open={documentoTitular}
						disableBackdropClick
						onClose={() => {
							setDocumentoTitular(null)
						}}
						className={classes.modal}
					>
						<Paper className={'paperValidate'}>
							<IconButton
								onClick={() => setDocumentoTitular(false)}
								className={classes.iconClose}
							>
								<IconClose/>
							</IconButton>
							<Typography className={!isResponsive ? classes.btitle : classes.title} id="tableTitle">
								{!isResponsive ? 'Resultado de la firma' : 'Detalles del certificado'}
							</Typography>
							<Paper elevation={0} className={'paperTableValidate'}>
								<TableContainer>
									<Table className={classes.table} size="small" aria-label="a dense table">
										<TableBody>
											{documentoTitular && (
												Object.keys(documentoTitular).map((key) => (
													(key !== 'id' && key !== 'nombreCertificado' && key !== 'clave' && key !== 'base64' && key !== 'alias') &&
													(
													<TableRow key={key}>
														<TableCell style={{padding:'5px', marginLeft: '0px'}}component="th" scope="row">
															<Typography className={isResponsive ? classes.sub1 : classes.sub2} display="inline">
																{dictAsimilacion[key] === undefined ? key : dictAsimilacion[key] + ': '}
															</Typography>
															<Typography className={classes.sub2} display="inline">
																{key.includes('fech') && documentoTitular[key] ? new Intl.DateTimeFormat('es-CO', {
																	month: 'long',
																	day: '2-digit',
																	year: 'numeric',
																}).format(new Date(documentoTitular[key])) : dictAsimilacion[key].length < 20  ? nameEstate(documentoTitular[key]) : cutNameDocument(documentoTitular[key])}
															</Typography>
														</TableCell>
													</TableRow>)
												))
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Paper>
					</Modal>
					<>
						<Grid
							container
							spacing={1}
							className={classes.grid}
							direction="row"
							justify={!isResponsive ? "flex-start" : "flex-end"}
							alignItems="center"
						>
							{!isPdf &&
							<>
							<Grid item className={classes.gridItem}>
								<Typography className={classes.title2} variant="h6" id="tableTitle" component="div">
									{cutName(file.name)}
								</Typography>
							</Grid>
							<Grid item style={{marginTop:!isResponsive ? '-14px' : '20px'}} className={classes.gridItem}>
								<Button
									type="submit"
									startIcon={<IconButton className={classes.iconButtonDownload} />}
									variant="contained"
									color="primary"
									className={'btnValidate'}
									onClick={(e) => { handleClickVerContenido(e); }}
								>
									Descargar
								</Button>
							</Grid>
							</>
							}
						</Grid>
					</>
				</>
			) : (dataDocument != null && dataDocument.codigoRespuesta != 'RS1' &&
				(<div className={classes.msgFail}>No fue posible verificar el archivo. Certificado expirado.</div>)
			)
			}
		</Paper>
	);
};

export default InfoValidation;
