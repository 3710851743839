import React, { useMemo, useState, useEffect } from 'react';
import iconPc from '../assets/img/IconPc.png';
import GoogleDriveExplorer from '../views/ConsultSignedDocument/GetFileGoogleDrive';
import OneDriveExplorer from '../views/ConsultSignedDocument/GetFileOneDrive';
import DropboxExplorer from '../views/ConsultSignedDocument/GetFileDropbox';
import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { kbToMb } from '../helpers/FilesFunction';
import { Menu, MenuItem } from '@material-ui/core';

const ButtonsFiles = props => {
	const {
		accept,
		modeChange,
		multiple,
		files,
		setLoading,
		setFiles,
		maxSize,
		isCades = false,
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [statusModalDropbox, setStatusModalDropbox] = useState(false);
	const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(true);
	const [sizeFiles, setSizeFiles] = useState(false);

	const cutName = name =>
		name.length > 29 ? name.substring(0, 29).concat('...') : name;

	const handleClose = () => {
		setStatusModalCloudSelect(false);
	};
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setStatusModalCloudSelect(true);
	};

	const { enqueueSnackbar } = useSnackbar();

	const onDrop = (dropedFiles, rejectedFiles) => {
		if (rejectedFiles.length) {
			rejectedFiles.map(file => {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				var extension = '.' + file.name.split('.').pop();
				var isAccept = accept.find(element => element == extension);
				if (!isAccept) {
					enqueueSnackbar(
						`El archivo ${cutName(
							file.name
						)} no es permitido, los tipos de archivos permitidos son: ${accept
							.filter(e => e.length < 5)
							.toString()
							.replaceAll(',', ' ')}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					return;
				}
				if (fileSizeMb > maxSizeMb) {
					handleClose();
					enqueueSnackbar(
						`El tamaño del archivo ${cutName(
							file.name
						)} supera el máximo permitido.\nTamaño limite de ${kbToMb(
							maxSize
						)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					return;
				}
			});
		}
		if (dropedFiles.length === 0) return;
		setFiles(dropedFiles);
		enqueueSnackbar('Archivos agregados exitosamente.', {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const openZoneDrop = event => {
		document.getElementById('dFZoneUpload').click();
		handleClose();
	};

	const closeMenuFileSource = () => {
		setStatusModalCloudSelect(false);
	};

	const { getInputProps } = useDropzone({
		accept,
		multiple,
		maxSize,
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	const StyledMenu = withStyles({
		paper: {
			border: '1.25px solid #9D9D9C',
			width: modeChange ? '' : '172px',
		},
	})(props => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		/>
	));

	return (
		<div className='container'>
			{
				<div>
					<input
						{...getInputProps()}
						id='dFZoneUpload'
						onClick={event => {
							event.target.value = null;
						}}
					/>
					<Button
						disableElevation
						className={
							isCades ? 'btnGeneralNewDos' :
							modeChange ? 'btnChange' :
							'btnGeneral'
						  }
						onClick={handleClick}
						endIcon={ <ArrowDropDownIcon className={isCades ? 'orangeIcon' : ''} />}
					>
						{' '}
						{modeChange ? 'Cambiar archivo' : 'Cargar archivo'}
					</Button>
					<StyledMenu
						className={modeChange ? 'menuChange' : 'menuSourceFile'}
						id='simple-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl) && statusModalCloudSelect}
						onClose={handleClose}
					>
						<MenuItem dense={true} onClick={openZoneDrop}>
							<img src={iconPc} width='25px' className='buttonPC' /> Mi
							ordenador
						</MenuItem>
						{/* {!isCades && ( */}
							<>
								<MenuItem dense={true} onClick={closeMenuFileSource}>
									{
										<GoogleDriveExplorer
											disableBackdropClick
											maxSize={maxSize}
											isP7s={!isCades}
											setStatusModalCloudSelect={setStatusModalCloudSelect}
											files={files}
											setFiles={setFiles}
											setLoading={setLoading}
											sizeFiles={sizeFiles}
											isCades={isCades}
										></GoogleDriveExplorer>
									}
								</MenuItem>
								<MenuItem dense={true}>
									<OneDriveExplorer
										setStatusModalCloudSelect={setStatusModalCloudSelect}
										maxSize={maxSize}
										isP7s={!isCades}
										files={files}
										setFiles={setFiles}
										setLoading={setLoading}
										sizeFiles={sizeFiles}
										isCades={isCades}
									></OneDriveExplorer>
								</MenuItem>
								<MenuItem dense={true} onClick={closeMenuFileSource}>
									<DropboxExplorer
										open={statusModalDropbox}
										maxSize={maxSize}
										isP7s={!isCades}
										onClose={() => setStatusModalDropbox(false)}
										setStatusModalDropbox={setStatusModalDropbox}
										setStatusModalCloudSelect={setStatusModalCloudSelect}
										files={files}
										setFiles={setFiles}
										setLoading={setLoading}
										sizeFiles={sizeFiles}
										isCades={isCades}
									></DropboxExplorer>
								</MenuItem>
							</>
						{/* )} */}
					</StyledMenu>
				</div>
			}
		</div>
	);
};

export default ButtonsFiles;
