import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import { StyledCheckbox } from './StyledCheckbox';
import Loading from '../../../components/Loading';
import ModalDeleteFiles from '../../ConsultSignedDocument/ModalDeleteFiles';

import { APP_KEY, MAX_FILE_DOWNLOAD } from '../../../redux/types/ApiConstants';
import PendingToSign from '../../ConsultSignedDocument/PendingToSign';

const useStyles = makeStyles(theme => ({
	resignCont: {
		display: 'flex',
		justifyContent: 'start',
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'space-between',
		},
	},
	nombre: {
		font: 'normal normal normal 14px/19px Mulish;',
		lineHeight: '19px',
		color: '#000000',
		letterSpacing: '0px',
		fontWeight: '400',
		opacity: 1,
	},
	ordenar: {
		font: 'normal normal normal 13px/16px Mulish',
		textTransform: 'inherit',
		float: 'right',
		color: '#706F6F',
		letterSpacing: '0px',
		fontWeight: '500',
		opacity: 1,
		marginTop: '-47px',
		marginRight: '20px',
	},
	fila: {
		cursor: 'pointer',
		'&:hover': {
			'& span': {
				opacity: '1',
			},
		},
	},
	filaFolder: {
		cursor: 'pointer',
		'& span': {
			opacity: '1',
		},
	},
	tabs: {
		margin: '15px 25px 0',
	},
	seleccion: {
		textTransform: 'none',
		fontFamily: 'Mulish',
		fontSize: '16px',
		lineHeight: '28px',
		fontWeight: '400',
		fontStyle: 'normal',
	},
	fecha: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#575756',
		letterSpacing: '0px',
		opacity: 1,
	},
	title: {
		margin: 0,
		height: '100px',
		paddingTop: '20px',
		color: 'white',
	},
	resign: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '14px',
		color: '#E55200',
	},
	origen: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#4F4F4F',
		letterSpacing: '0px',
		opacity: 1,
	},
	paper: {
		padding: '20px',
		marginTop: '-25px',
		borderRadius: '10px',
	},
	table: {
		borderCollapse: 'collapse',
		border: 0,
		padding: 0,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	buttonMargin: {
		margin: '2px',
	},
	image: {
		width: '25px',
		height: '25px',
	},
	visible: {
		visibility: 'visible',
	},
	oculto: {
		visibility: 'hidden',
	},
	titleEncabezado: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '12px',
		color: '#E55200',
		lineHeight: '16px',
	},
	titleEncabezado2: {
		color: '#E55200',
		fontFamily: 'Mulish',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '16px',
		paddingLeft: '18px',
	},
}));

const DateSign = props => {
	const classes = useStyles();
	const { fechaFirma } = props;
	var date = new Date(fechaFirma);
	return (
		<Grid container spacing={2} direction='row'>
			<Grid item xs={10} style={{ marginLeft: '2px' }}>
				<Typography className={classes.fecha}>
					{date.getDate() +
						'/' +
						(date.getMonth() + 1) +
						'/' +
						date.getFullYear()}
					<br></br>
					{date
						.toLocaleString('en-US', {
							hour: 'numeric',
							minute: 'numeric',
							second: 'numeric',
							hour12: true,
						})
						.toLowerCase()}
				</Typography>
			</Grid>
		</Grid>
	);
};
const Resign = ({
	index,
	indexRow,
	isCircuit,
	isFolder = false,
	backToSignOneFile,
	selected,
}) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.resignCont}>
			{!isFolder ? (
				<>
					{window.innerWidth < 1265 ? (
						<>
							{isCircuit && indexRow !== index && (
								<Grid
									item
									xs={6}
									style={{
										display: 'contents',
									}}
								>
									<Button className='btnChange2'>Circuito de firmas</Button>
								</Grid>
							)}
							<>
								{selected.length === 0 && (
									<Grid item xs={12}>
										{indexRow === index && (
											<Grid
												container
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#E55200',
												}}
											>
												<Grid
													item
													xs={2}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Tooltip
														title='Volver a firmar'
														style={{
															width: '18px',
															height: '18px',
														}}
													>
														<EditIcon />
													</Tooltip>
												</Grid>
												<Grid
													item
													xs={10}
													onClick={e => backToSignOneFile(e, indexRow)}
													style={{
														textAlign: 'left',
													}}
												>
													{/* Este es el de hover pero en vista pequeña */}
													<Typography className={classes.resign}>
														Volver a firmar
													</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								)}
							</>
						</>
					) : (
						<>
							<Grid item xs={6}>
								{isCircuit && (
									<Button className='btnChange2'>Circuito de firmas</Button>
								)}
							</Grid>
							{selected.length === 0 && (
								<Grid item xs={6}>
									{indexRow == index && (
										<Grid
											container
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												color: '#E55200',
											}}
										>
											<Grid
												item
												xs={2}
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Tooltip
													title='Volver a firmar'
													style={{
														width: '18px',
														height: '18px',
													}}
												>
													<EditIcon />
												</Tooltip>
											</Grid>
											<Grid
												item
												xs={10}
												onClick={e => backToSignOneFile(e, indexRow)}
											>
												{/* Este es el de hover */}
												<Typography className={classes.resign}>
													Volver a firmar
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							)}
						</>
					)}
				</>
			) : (
				<>
					{selected.length === 0 && (
						<Grid item xs={12}>
							{indexRow === index && (
								<Grid
									container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										color: '#E55200',
									}}
								>
									<Grid
										item
										xs={2}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Tooltip
											title='Volver a firmar'
											style={{
												width: '18px',
												height: '18px',
											}}
										>
											<EditIcon />
										</Tooltip>
									</Grid>
									<Grid
										item
										xs={10}
										onClick={e => {
											backToSignOneFile(e, indexRow);
										}}
									>
										<Typography className={classes.resign}>
											Volver a firmar
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
};

export const PendingDocuments = ({
	setOpenedRowIndex,
	refresh,
}) => {
	const CryptoJS = require('crypto-js');
	const key = APP_KEY;

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const ref = useRef(null);
	const { pendingSign, loading: LoadingDocument } = useSelector(state => state.consultSignedDocument);
    const [orderMenu, setOrderMenu] = useState(null);
	const [idsFolder, setIdsFolder] = useState([]);
	const [indexRow, setIndex] = useState(null);
	const [isAllSelected, setAllSelected] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalDeleteFiles, setModalDeleteFiles] = useState(false);
	const [p7z, setP7z] = useState(0);
	const [rowsFilter, setRowsFilter] = useState(pendingSign);
	const [selected, setSelected] = useState([]);
	const [user, setUser] = useState();


	const valorEncriptado = rowsFilter
		.map(objeto => {
			if (objeto.encriptado !== undefined && objeto.encriptado !== null) {
				return objeto.encriptado;
			}
		})
		.filter(valor => valor !== undefined)[0];

	useEffect(() => {
		if (valorEncriptado) {
			const procesar = valorEncriptado
				.replace(/[-]/g, '+')
				.replace(/[_]/g, '/');
			let decifrado = CryptoJS.AES.decrypt(procesar, key);
			let salida = decifrado.toString(CryptoJS.enc.Utf8);

			function extraerNumeroIf(string) {
				const array = string.split('&');
				const elementoIf = array.find(elemento => elemento.startsWith('if='));
				const numeroIf = elementoIf ? elementoIf.substring(3) : null;
				return numeroIf;
			}
			const string = salida;
			const numeroIf = extraerNumeroIf(string);
			setUser(numeroIf);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valorEncriptado]);

	/**
	 *  *  se encarga de manejar el evento de selección/deselección de todos los documentos de la tabla
	 * @param {*} event
	 * @param {*} ListOrder
	 */
	const handleSelectAllClick = (event, ListOrder) => {
		if (selected.length > 0) {
			setAllSelected(false);
			setSelected([]);
			setIdsFolder([]);
			setOpenedRowIndex(-1);
		} else if (isAllSelected == false || selected.length == 0) {
			const listRows = [];
			const ListsIdsFolder = [];
			const idFolderSelect = [];
			let count = p7z;
			let cont = 0;
			const prueba = ListOrder;
			ListOrder.map((row, index) => {
				if (index < 10) {
					if (row.isFolder) {
						const idsCircuito = row.documentCircuito.map(
							document => document.id
						);
						listRows.push(row);
						ListsIdsFolder.push({
							idFolder: row.id,
							idsCircuit: idsCircuito,
						});
					} else {
						listRows.push(row);
					}
					let nombre = row.nombrereal;
					let extension = nombre.split('.').pop();
					if (extension != 'pdf') {
						count += 1;
					}
				}
			});
			if (event.target !== undefined) {
				if (event.target.checked && selected.length < MAX_FILE_DOWNLOAD) {
					const newSelecteds = [];
					if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
						for (let i = 0; i < prueba.length; i++) {
							if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
								let indexObject = listRows.find(row => row.id === prueba[i].id);
								if (indexObject?.isFolder) {
									const idFolder = ListsIdsFolder.filter(
										obj => obj.idFolder === indexObject.id
									);
									const folder = idFolder.map(obj => obj.idsCircuit);
									folder[0].forEach(id => {
										if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
											newSelecteds.push(id);
										}
									});
									const includes = folder.some(arr =>
										arr.every(value => newSelecteds.includes(value))
									);
									if (includes) {
										newSelecteds.push(prueba[i].id);
										idFolderSelect.push(prueba[i].id);
										setIdsFolder(idFolderSelect);
										cont++;
									} else {
										setOpenedRowIndex(i);
									}
								} else {
									if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
										if (indexObject.id === prueba[i].id) {
											newSelecteds.push(prueba[i].id);
										}
										let nombre = prueba[i].nombrereal;
										let extension = nombre.split('.').pop();
										if (extension != 'pdf') {
											count += 1;
										}
									}
								}
							}
						}
					}
					setP7z(count);
					setSelected(newSelecteds);
				}
			}
			setAllSelected(true);
		} else {
			setAllSelected(false);
			setSelected([]);
		}
	};

	/**
	 *  * Recibe el id de un documento y verifica si es un folder o no, si es un folder, retorna un array con los ids de los documentos que contiene, si no es un folder, retorna el id del documento.
	 * @param {*} id
	 * @param {*} files
	 * @returns
	 */

	const isIdCarpet = (id, files) => {
		let searchFiles = files.filter(file => file.id === id);
		if (searchFiles.length > 0) {
			if (searchFiles[0].documentCircuito) {
				if (idsFolder.includes(id)) {
					setIdsFolder(idsFolder.filter(ob => ob !== id));
				} else {
					setIdsFolder([...idsFolder, id]);
				}
				searchFiles = searchFiles[0].documentCircuito?.filter(
					file => file.idCircuito === id
				);
				let ids = searchFiles.map(ob => ob.id);
				ids.push(id);
				return ids;
			} else {
				return id;
			}
		} else {
			return id;
		}
	};

	/**
	 *
	 * @param {*} id
	 * @param {*} rows
	 * @param {*} selected
	 * @param {*} cont
	 * @returns
	 */
	const checkFolder = (id, rows, selected, cont) => {
		let newSelected = [];
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) {
			const arrayIds = foundMatch.documentCircuito.map(ob => ob.id);
			const result = arrayIds.every(val => selected.includes(val));
			if (result) {
				setIdsFolder([...idsFolder, foundMatch.id]);
				return newSelected.concat(selected, foundMatch.id);
			} else {
				setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));
				return selected;
			}
		}
		return selected;
	};

	/**
	 *  * busca el id de un documento en las filas de la tabla.
	 * @param {*} id
	 * @param {*} rows
	 * @returns
	 */

	const findInRows = (id, rows) => {
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));

		return foundMatch ? foundMatch.id : null;
	};

	/**
	 * * Retorna la cantidad de documentos seleccionados, sin contar los folders que contienen documentos seleccionados.
	 * @param {*} ids
	 * @param {*} selected
	 * @param {*} folderId
	 * @param {*} rows
	 * @returns
	 */
	const skipIdFolders = (ids, selected, folderId, rows) => {
		if (typeof ids === 'number') {
			ids = [ids];
		} else {
			const foundMatch = rows.find(
				row =>
					row.documentCircuito !== null &&
					row.documentCircuito.find(file => ids.includes(file.idCircuito))
			);
			if (foundMatch) ids = ids.filter(ob => ob !== foundMatch.id);
		}
		const contselected = selected.length;
		const contids = ids.length;
		const contfolder = folderId.length;
		const cont = contselected + contids - contfolder;

		return cont;
	};

	/**
	 *  * Maneja el evento de click en un checkbox de la tabla, actualiza el estado correspondiente.
	 * @param {*} event
	 * @param {*} name
	 * @returns
	 */
	const handleClick = async (event, name) => {
		const row = event.target.closest('tr');
		const isrowselected = selected.includes(name);
		let newSelected = [];
		if (event.target.checked === true || (row.id == name && !isrowselected)) {
			const idsSelected = isIdCarpet(name, rowsFilter);
			const idsWithoutFolder = skipIdFolders(
				idsSelected,
				selected,
				idsFolder,
				rowsFilter
			);
			if (idsWithoutFolder <= MAX_FILE_DOWNLOAD) {
				newSelected = newSelected.concat(selected, idsSelected);
				newSelected = checkFolder(idsSelected, rowsFilter, newSelected);
			} else {
				enqueueSnackbar(
					`Máximo puede seleccionar ${MAX_FILE_DOWNLOAD} documentos`,
					{
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				return;
			}
			setSelected(newSelected);
		} else {
			const idsToDelete = isIdCarpet(name, rowsFilter);
			if (typeof idsToDelete === 'number') {
				newSelected = selected.filter(x => x !== idsToDelete);
				const rowFound = findInRows(name, rowsFilter);
				if (rowFound) {
					newSelected = newSelected.filter(x => x !== rowFound);
				}
			} else {
				newSelected = selected.filter(id => !idsToDelete.includes(id));
			}
			setSelected(newSelected);
		}
		let count = 0;
		// ! Arreglar map de selected
		newSelected.map(sel => {
			let file = rowsFilter.find(x => x.id === sel);
			// let nombre = file.nombrereal; // ! Revisar
			let nombre = 'nombre';
			let extension = nombre.split('.').pop();
			if (extension != 'pdf') {
				count += 1;
			}
		});
		setP7z(count);
	};

	/**
	 *  * Retorna el nombre del documento, si es mayor a 30 caracteres, se muestra un tooltip con el nombre completo y el nombre cortado con puntos suspensivos.
	 * @param {*} name
	 * @returns
	 */
	const cutName = name =>
		name.length > 30 ? (
			<Tooltip title={name}>
				<div>{name.substring(0, 30).concat('...')}</div>
			</Tooltip>
		) : (
			<div>{name}</div>
		);

	return (
		<div className={'contentDocumentSignedHome2'}>
							<>
								<PendingToSign
									classes={classes}
									DateSign={DateSign}
									handleClick={handleClick}
									handleSelectAllClick={handleSelectAllClick}
									indexRow={indexRow}
									isFilterIsNull={rowsFilter.length>0?false:true}
									ref={ref}
									rowsFilter={rowsFilter}
									selected={selected}
									setIndex={setIndex}
									StyledCheckbox={StyledCheckbox}
									orderMenu={orderMenu}
									setOrderMenu={setOrderMenu}
									isHome={true}
								/>
							</>
			{loading || LoadingDocument ? <Loading /> : null}
			{modalDeleteFiles && (
				<ModalDeleteFiles
					idsFolder={idsFolder}
					modalDeleteFiles={modalDeleteFiles}
					refresh={refresh}
					rows={rowsFilter}
					selected={selected}
					setLoading={setLoading}
					setModalDeleteFiles={setModalDeleteFiles}
					setOpenedRowIndex={setOpenedRowIndex}
					setSelected={setSelected}
					user={user}
				/>
			)}
		</div>
	);
};
