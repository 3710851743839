import React from 'react';
import correctEncrypt from '../../assets/img/correctEncrypt.png';
import correctDecrypt from '../../assets/img/correctDecrypt.png';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
    titleEncrypt: {
        font: 'normal normal bold 20px/20px Muli',
        margin: '0 0 0 0',
        color: '#E55200',
        fontSize:'20px',
        textAlign:'center',
    },
    titleEncrypt2: {
        font: 'normal normal bold 18px/23px Muli',
        margin: '0 0 0 0',
        color: '#E55200',
        fontSize:'18px',
        textAlign:'center',
    },
    subtitleCorrect: {
        font: 'normal normal normal 16px/24px Muli',
        margin: '0 0 0 0',
        color: '#4F4F4F',
        fontSize:'16px',
        textAlign:'center',
    },
    button: {
        backgroundColor: '#F5AE19',
        marginBottom: '40px',
        marginTop: '30px',
        boxShadow: '0px 3px 0px #00000033',
        textShadow: '0px 2px 0px #00000040',
        width: '130px',
        height: '38px',
        borderRadius: '10px',
        color: '#FFFFFF',
        textShadow: '0px 2px 0px #00000040',
        font: 'normal normal bold 16px/22px Muli',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#F5AE19',
            boxShadow: '0px 3px 0px #00000033'
         },
    },
    button2: {
        backgroundColor: '#F5AE19',
        marginBottom: '30px',
        marginTop: '30px',
        boxShadow: '0px 3px 0px #00000033',
        textShadow: '0px 2px 0px #00000040',
        width: '130px',
        height: '38px',
        borderRadius: '10px',
        color: '#FFFFFF',
        textShadow: '0px 2px 0px #00000040',
        font: 'normal normal bold 16px/22px Muli',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#F5AE19',
            boxShadow: '0px 3px 0px #00000033'
         },
    }
}));

const ModalCorrect = (props) => {
	const { isDecrypt, setStatusModalCorrect } = props;
	const classes = useStyles();

	return (
		<Paper style={{ width:'442px' }} elevation={3} className="paperRecipient">
            <center>
                {isDecrypt ?
                <>
                    <img style={{ paddingTop: '30px' }} src={correctDecrypt} className={classes.logo}/>
                    <h1 style={{ paddingTop: '11px' }} className={classes.titleEncrypt2}>¡Tus archivos han sido descifrados!</h1>
                </>
                :
                <>
                    <img style={{ paddingTop: '40px' }} src={correctEncrypt} className={classes.logo}/>
                    <h1 style={{ paddingTop: '20px' }} className={classes.titleEncrypt}>¡Tus archivos fueron cifrados!</h1>
                </>
                }
                <h1 style={{ paddingTop: '20px' }} className={classes.subtitleCorrect}>Revisa tus descargas, hemos dejado allí <br/> la carpeta con estos archivos</h1>
                <Button
                    hover
			    	disableElevation
			    	className={classes.button2}
			    	onClick={() => setStatusModalCorrect(false)}
			    >
			    	Aceptar
                </Button>
            </center>
		</Paper>
	);
};

export default ModalCorrect;