  import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import imgDropbox from '../../../assets/img/dropbox.png';
import { UploadDocumentToDropBoxCF } from '../../../redux/actions/ConsultSignedDocumentsAction';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import ConfirmDropbox from '../../ConsultSignedDocument/ConfirmDropbox';
 
const DropBoxExplorerStyles = makeStyles((theme) => ({
  buttonDropbox: {
    backgroundImage: `url(${imgDropbox}) `,
    backgroundPosition: 'center',
  }
}));

const Dropbox = (props) => {

  const { selected, setSelected, rows, UploadDocumentToDropBoxCF, iconBlack, handleClick, setStatusDropbox } = props;
  const classes = DropBoxExplorerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const handleClickDropbox = async () => {
    const lista = [];
    let datosDescarga = [];
    selected.forEach((valor) => {
      lista.push(rows.find((n) => n.idCircuito === valor));
    });
    const listSelect = lista.map(el => el.documentos).flat();
    if(iconBlack) setStatusDropbox(false);
    await Promise.all([
      datosDescarga = await UploadDocumentToDropBoxCF(listSelect, enqueueSnackbar),
      datosDescarga.length > 0 && setFiles(datosDescarga) + setOpen(true),
    ]);
  };

  if(handleClick) handleClickDropbox();

  return (
    <div>
      { iconBlack != true &&
      <IconButton
        tooltip="true"
        data-title="Guardar en Dropbox"
        className={classes.buttonDropbox}
        onClick={handleClickDropbox}
      ></IconButton>}
      {open && (
        <ConfirmDropbox
          open={open}
          setOpen={setOpen}
          files={files}
          setFiles={setFiles}
          setSelected={setSelected}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  linkDescarga: state.consultSignedDocument.link,
});

const mapDispatchToProps = {
  UploadDocumentToDropBoxCF: UploadDocumentToDropBoxCF,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropbox);


