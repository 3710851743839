'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();
var _react = require('react');
var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _loadScript = require('load-script');

var _loadScript2 = _interopRequireDefault(_loadScript);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
var SCRIPT_ID = 'dropboxjs';

var scriptLoadingStarted = false;

// read more
// https://www.dropbox.com/developers/chooser

var DropboxSaver = function (_Component) {
  _inherits(DropboxSaver, _Component);

  function DropboxSaver(props) {
    _classCallCheck(this, DropboxSaver);

    var _this = _possibleConstructorReturn(this, (DropboxSaver.__proto__ || Object.getPrototypeOf(DropboxSaver)).call(this, props));

    _this.onChoose = _this.onChoose.bind(_this);
    return _this;
  }

  _createClass(DropboxSaver, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (!this.isDropboxReady() && !scriptLoadingStarted) {
        scriptLoadingStarted = true;
        (0, _loadScript2.default)(DROPBOX_SDK_URL, {
          attrs: {
            id: SCRIPT_ID,
            'data-app-key': this.props.appKey
          }
        });
      }
    }
  }, {
    key: 'isDropboxReady',
    value: function isDropboxReady() {
      return !!window.Dropbox;
    }
  }, {
    key: 'onChoose',
    value: function onChoose() {
      if (!this.isDropboxReady() || this.props.disabled) {
        return null;
      }

      var _props = this.props,
        files = _props.files,
        success = _props.success,
        progress = _props.progress,
        cancel = _props.cancel,
        error = _props.error;

      var options = {
        files: files,
        success: success,
        progress: progress,
        cancel: cancel,
        error: error
      }
      window.Dropbox.save(options);
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { onClick: this.onChoose },
        this.props.children ? this.props.children : _react2.default.createElement(
          'button',
          null,
          'Open dropbox saver'
        )
      );
    }
  }]);

  return DropboxSaver;
}(_react.Component);

DropboxSaver.propTypes = {
  children: _propTypes2.default.node,
  appKey: _propTypes2.default.string.isRequired,files: _propTypes2.default.array.isRequired,
  success: _propTypes2.default.func.isRequired,
  progress: _propTypes2.default.func,
  cancel: _propTypes2.default.func,
  error: _propTypes2.default.func,
  extensions: _propTypes2.default.arrayOf(_propTypes2.default.string),
  disabled: _propTypes2.default.bool,
};
DropboxSaver.defaultProps = {
  cancel: function cancel() { },
  progress: () => { },
  disabled: false
};
exports.default = DropboxSaver;