import { LOADING, CURRENT_PATH } from '../types/HomeTypes';

const INITIAL_STATE = {
  loading: false,
  current_path : false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CURRENT_PATH:
      return{
        ...state,
        current_path: action.payload
      }
    default:
      return state;
  }
};