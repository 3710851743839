  //abrimos una conexión con la base de datos de IndexedDB y creamos un almacén de objetos llamado archivos. 
  //Después, agregamos cada archivo del array al almacén de objetos usando un bucle forEach.
 export const guardarEnIndexedDB = (files, nombreBD, item) => {
    const request = indexedDB.open(nombreBD, 1);
    //Preguntamos si la base de datos existe, si no existe la creamos 
    request.onupgradeneeded = event => {
      const db = event.target.result;
      //creamos un almacen de datos  con createObjectStore
      const objectStore = db.createObjectStore(item, { keyPath: 'id', autoIncrement: true });
    };
    //Escuchamos lo eventos al abrir la base de datos, onsuccess=> Si todo estuvo bien, onerror=>por si falla 
    request.onsuccess = event => {
      const db = event.target.result;
      const transaction = db.transaction([item], 'readwrite');
      const objectStore = transaction.objectStore(item);

      files.forEach(archivo => {
        objectStore.add(archivo);
      });
      console.log(`Los ${item} se han guardado en IndexedDB.`);
    };
  };

  //eliminamos la base de datos
  export const borrarIndexedDB = (nombreBD) => {
    const request = indexedDB.deleteDatabase(nombreBD);
  
    request.onsuccess = event => {
      console.log('La base de datos se ha eliminado correctamente.');
    };
  
    request.onerror = event => {
      console.error('Error al eliminar la base de datos:', event.target.error);
    };
  };

 export  const escribirDB = (archivos, nombreBD, item) => {
    const request = window.indexedDB.open(nombreBD, 1);
  
    request.onerror = event => {
      console.log("Error al abrir la base de datos", event.target.error);
    };
  
    request.onsuccess = event => {
      const db = event.target.result;
      const transaction = db.transaction([item], "readwrite");
      const objectStore = transaction.objectStore(item);
  
      // Verifica si archivos es un array
      if (Array.isArray(archivos)) {
        archivos.forEach(archivo => {
          objectStore.add(archivo);
        });
      } else {
        objectStore.add(archivos);
      }
  
      transaction.oncomplete = () => {
        console.log("Archivo guardado en la base de datos");
      };
  
      transaction.onerror = event => {
        console.log("Error al guardar el archivo en la base de datos", event.target.error);
      };
    };
  
    request.onupgradeneeded = event => {
      const db = event.target.result;
      const objectStore = db.createObjectStore(item, { keyPath: "id", autoIncrement: true });
      objectStore.createIndex("nombre", "nombre", { unique: false });
      objectStore.createIndex("tipo", "tipo", { unique: false });
    };
  };
  