import { makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import check from "../../../../assets/img/checks.png";
import {
  modalFinished,
  modalMissingSignatures,
} from "../../../../redux/actions/CircuitoFirmasAction";
import { LANDING_PAGE_FIRMAYA } from "../../../../redux/types/ApiConstants";

const useStyle = makeStyles({
	modal: {
		margin: 0,
		padding: '30px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '5.72px',
		width: '436px',
		height: '376px',
		'&:focus': {
			outline: 'none',
		},
		'@media (max-width: 600px)': {
			width: '90%',
		},
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: '20px 0 0 0',
		lineHeight: '30px',
	},
	parrafo: {
		fontSize: '16px',
		width: '475px',
		fontFamily: 'Mulish',
		fontWeight: '400',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		marginTop: '40px',
	},
	parrafo: {
		fontSize: '16px',
		textAlign: 'center',
		margin: '24px 0 0 0',
	},
	btnCerrar: {
		color: '#E55200',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		border: '1px solid #E55200',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
	},
});

const ModalFinishedSigning = ({irHome=false}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const modal = true;
  // const url = "https://firmaya.co/"
  const url = LANDING_PAGE_FIRMAYA;
  const his = useHistory();

  const closeModal = () => {
    dispatch(modalFinished(false));
    if (irHome) {
      localStorage.removeItem("URL_Circuito");
      his.push("/home");
    } else {
      localStorage.removeItem("URL_Circuito");
      window.open(url, "_self");
    }
  };

  return (
    <Modal
      open={modal}
      // onClose={closeModal}
      disableBackdropClick
    >
      <div className={classes.modal}>
        <img src={check} />
        <h4 className={classes.title}>¡Has terminado de firmar!</h4>
        <p className={classes.parrafo}>
          Recibirás una copia por correo electrónico una vez hayan firmado todos
        </p>
        <div className={classes.flex}>
          <button onClick={() => closeModal()} className={classes.continue}>
            Entendido
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFinishedSigning;
