import { SIGN_OK_FIRMA_AVANZADA, SET_SELECT_IDTSA, LOADING, SET_LIST_HANDWRITTE } from '../types/SignCunstomDocumentTypes';
import { TOKEN_CONNECTION } from '../types/SignTypes';

const INITIAL_STATE = {
  signOkFirmaAvanzada: false,
  loading: false,
  idTSA: [],
  LISTHANDWRITTEIMG: [],
  tokenConnection :false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_OK_FIRMA_AVANZADA:
      return {
        ...state,
        signOkFirmaAvanzada: action.payload,
      };
    case SET_SELECT_IDTSA:
      return {
        ...state,
        idTSA: action.payload,
      };
    case SET_LIST_HANDWRITTE:
        return {
          ...state,
          idTSA: action.payload,
        };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      case TOKEN_CONNECTION:
        return {
          ...state,
          tokenConnection: action.payload,
        };
    default:
      return state;
  }
};