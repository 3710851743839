import { makeStyles, Modal } from "@material-ui/core";
import { truncateSync } from "fs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ups from "../../../../assets/img/ups.png";
import { modalUps } from "../../../../redux/actions/CircuitoFirmasAction";
import { LANDING_PAGE_FIRMAYA } from "../../../../redux/types/ApiConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyle = makeStyles({
  modal: {
    margin: 0,
    padding: "40px",
    position: "absolute",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 3px 0px #00000033 !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "442px",
    height: "402px",
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    color: "#E55200",
    fontSize: "24px",
    fontFamily: "Mulish",
    fontWeight: "700",
    margin: "20px 0 0 0",
    lineHeight: "30px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "40px",
  },
  parrafo: {
    fontSize: "16px",
    textAlign: "center",
    margin: "24px 0 0 0",
  },
  continue: {
    backgroundColor: "#E55200",
    color: "white",
    padding: "8px 24px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "800",
    fontFamily: "Mulish",
    border: "1px solid #E55200",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#E55200",
    },
  },
});

const ModalUps = ({
	correoCreador,
	normal = true,
	mensaje = 'Error interno de la aplicación. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte',
	redireccionar,
  userDigitalWare=false
}) => {
	const classes = useStyle();
	const [modal, setModal] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const url = LANDING_PAGE_FIRMAYA;

	const closeModal = () => {
		if (!normal) {
			setModal(false);
			dispatch(modalUps(false));
			if (redireccionar) {
				if(userDigitalWare){
          history.push('/home/CircuitoFirmas');
        }else{
          history.push('/home');
        }
			}
		} else {
			setModal(false);
			dispatch(modalUps(false));
			return window.open(url, '_self');
		}
	};

	return (
		<Modal open={modal} onClose={closeModal} disableBackdropClick>
			<div className={classes.modal}>
				<img src={ups} />
				<h4 className={classes.title}>¡Ups!</h4>
				{normal && !mensaje ? (
					<p className={classes.parrafo}>
						Ha ocurrido un problema al firmar tu(s) documento(s). Para
						solucionarlo debes comunicarte con <b>{correoCreador}</b>
					</p>
				) : (
					<p className={classes.parrafo}>{mensaje}</p>
				)}
				<div className={classes.flex}>
					<button className={classes.continue} onClick={closeModal}>
						Aceptar
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalUps;
