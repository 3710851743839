import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import dropboxIcon from "../assets/img/DropboxIconHover.png";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import tableMenuIcon from "../assets/img/tableMenu.png";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom';
import {
  getBase64OfSelectedFile,
  getListSignedDocument,
} from "../redux/actions/ConsultSignedDocumentsAction";
import GoogleDriveExplorer from "../views/ConsultSignedDocument/ExplorerGoogleDrive";
import DropboxExplorer from "../views/ConsultSignedDocument/ExplorerDropBox";
import OneDriveExplorer from "../views/ConsultSignedDocument/ExplorerOneDriveModal";
import EnhancedTableToolbar from "../views/ConsultSignedDocument/EnhancedTableToolbar";
import clsx from "clsx";
import ModalFirmaAvanzada from "../views/Firmar/FirmaAvanzada/ModalFirmaAvanzada";
import { MAX_FILE_DOWNLOAD } from "../redux/types/ApiConstants";
import Loading from "./Loading";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import iconOneDrive from "../assets/img/OnedriveIconHover.png";
import iconSort from "../assets/img/iconSort.png";

import ModalGetFIleCloud from "../views/ConsultSignedDocument/ModalGetFIleCloud";
import { VerticalAlignTop } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "5px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 2px rgba(0, 0, 0, .5)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.5)",
      borderRadius: "5px",
      outline: "1px solid slategrey",
    },
  },
  fontType: {
    font: "normal normal normal 12px/12px Muli",
    fontWeight: 600,
    color: "#706F6F",
    paddingTop: "1px",
    textAlign: "center",
  },
  type: {
    position: "relative",
    marginTop: "3px",
    height: "15px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #706F6F",
    borderRadius: "3px",
    width: "29px",
  },
  buttons: {
    background: "#F5AE19 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    textAlign: "center",
    textTransform: "none",
    font: "normal normal bold 14px/18px Muli",
    left: "38%",
    letterSpacing: "0px",
    color: "#FFFFFF",
    textShadow: "0px 2px 0px #00000040",
    width: "95px",
    height: "32px",
    marginTop: "22px",
    marginBottom: "20px",
  },
  titleBackground: {
    height: "90px",
    paddingTop: "20px",
    width: "100%",
  },
  paper: {
    marginTop: "-90px",
    height: "80%",
    width: "100%",
  },
  nameDocument: {
    font: "Muli",
    color: "#4F4F4F",
    letterSpacing: "0px",
    fontSize: "16px",
    fontWeight: "Bold",
  },
  table: {
    height: "80%",
    paddingBottom: "20%",
    width: "96%",
    overflow: "scroll",
    position: "fixed",
    backgroundColor: "white",
  },
  documentInfo: {
    font: "normal normal normal 16px/16px Muli",
    letterSpacing: "0px",
    lineHeight: "22px",
    color: "#706F6F",
  },
  itemOrder: {
    font: "normal normal normal 16px/16px Muli",
    letterSpacing: "0px",
    color: "#4F4F4F",
    paddingLeft: "20px",
    paddingTop: "20px",
  },
  itemFont: {
    font: "normal normal bold 16px/16px Muli",
    fontWeight: "bolder",
    letterSpacing: "0px",
    color: "#4F4F4F",
    paddingLeft: "10px",
  },
  googleDrive: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  menuGoogleDrive: {
    position: "relative",
    overflow: "hidden",
    height: "40px",
    width: "100%",
    marginTop: "11px",
  },
  save: {
    font: "normal normal bold 16px/16px Muli",
    fontWeight: "bold",
    marginTop: "8px",
    letterSpacing: "0px",
    marginLeft: "8%",
    color: "#E55200",
  },
}));

const useStylesCheckOrange = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 2,
    width: 18,
    height: 18,
    boxShadow: "inset 0 0 0 1px rgba(157, 157, 156,1.0)",
    backgroundColor: "white",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    backgroundColor: "white",
    backgroundImage:
      "linear-gradient(10deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(229, 82, 0)'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
  },
});

function StyledCheckbox(props) {
  const classes = useStylesCheckOrange();
  return (
    <Checkbox
      className={classes.root}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

function CertifiedType(props) {
  const classes = useStyles();
  const { title } = props;
  var matches = title.match(/\b(\w)/g);
  var acronym =
    matches != null ? "C" + matches.join("").toUpperCase().slice(0, 2) : "";
  return (
    <Paper elevation={0} className={classes.type}>
      <Typography className={classes.fontType}>{acronym}</Typography>
    </Paper>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const cutName = (name) =>
  name.length > 30 ? (
    <Tooltip title={name}>
      <div>{name.substring(0, 30).concat("...")}</div>
    </Tooltip>
  ) : (
    <div>{name}</div>
  );

const SignedDocumentsResponsive = (props) => {
  const classes = useStyles();
  const { rows, getListDocument, isDashboard } = props;
  const [order, setOrder] = useState("desc");
  const [statusModalDropbox, setStatusDropbox] = useState(false);
  const [orderBy, setOrderBy] = useState("fechaFirma");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isDashboard ? 5 : 15);
  const [rowsFilter, setRowsFilter] = useState(rows);
  const [statusModalAvanzado, setStatusModalAvanzado] = useState(false);
  const [p7z, setP7z] = useState(0);
  const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(false);
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isFilterIsNull, setIsFilterIsNull] = useState(false);
  const [optionsMenu, setOptionsMenu] = useState(false);
  const [sortMenu, setSortMenu] = useState(false);
  const [statusModalGoogleDrive, setStatusModalGoogleDrive] = useState(false);
  const [statusModalOneDrive, setStatusModalOneDrive] = useState(false);
  const [tokenOneDrive, setTokenOneDrive] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getListDocument();
  }, []);

  useEffect(() => {
    setRowsFilter(rows);
  }, [rows.length]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, document) => {
		history.push(`/circuito/${document.encriptado}`);
	};

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDate = (string) => {
    var date = new Date(string);
    if (date != null)
      return (
        date.getDate() +
        "/" +
        date.toLocaleString("es-es", { month: "short" }) +
        "/" +
        date.getFullYear()
      );
  };

  const excludeColumns = [
    "tipoFirma",
    "ruta",
    "repositorio",
    "peso",
    "origenFirma",
    "nombrerepositorio",
    "id",
    "consecutivo",
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangeBuscar = (event) => {
    const lowercasedValue = event.target.value.toLowerCase().trim();
    if (lowercasedValue.length > 3 || lowercasedValue === "") {
      if (lowercasedValue === "") {
        setIsFilterIsNull(false);
        setRowsFilter(rows);
      } else {
        const filteredData = rows.filter((item) => {
          return Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : key == "fechaFirma"
              ? getDate(item[key]).includes(lowercasedValue)
              : item[key].toLowerCase().includes(lowercasedValue)
          );
        });
        if (filteredData.length < 1) {
          filteredData.push({ nombrereal: "No existe el documento" });
          setIsFilterIsNull(true);
        } else setIsFilterIsNull(false);
        setRowsFilter(filteredData);
      }
    }
  };

  const onClose = (type) => {
    refresh(type);
    setStatusModalAvanzado(false);
  };

  const refresh = (reInitSelect) => {
    if (reInitSelect) {
      setSelected([]);
      setPage(0);
      ref.current.scrollTo(0, 0);
      getListDocument();
    }
  };

  if (loading) return <Loading />;

  const format = (date, peso) => {
    var date = new Date(date);
    if (peso == undefined) peso = 0;
    else if (peso < 1024) peso = peso + "B";
    else peso = Math.round(peso / 1024) + "KB";
    return (
      date.getDate() +
      "/" +
      date.toLocaleString("es-es", { month: "short" }) +
      "/" +
      date.getFullYear() +
      " - " +
      date
        .toLocaleString("en-us", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
        .toLowerCase() +
      " - " +
      peso
    );
  };

  const sort = (orderby, order) => {
    setOrderBy(orderby);
    setOrder(order);
    setSortMenu(null);
  };

  const formatearFecha = (fechaModificacion) => {
    const fecha = new Date(fechaModificacion);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Se suma 1 porque los meses van de 0 a 11
    const anio = fecha.getFullYear();
  
    return `${dia}/${mes}/${anio}`;
  }
  return (
    <>
      <Grid container spacing={0} direction="row">
        <div className={classes.titleBackground} />
        <Paper elevation={0} className={classes.paper}>
          <div className={isDashboard ? "contentDocumentSigned" : ""}>
            <div className={isDashboard ? "" : "gridDocuments"}>
              <Typography
                className={
                  isDashboard ? "titleDocumentsHome" : "titleDocuments"
                }
              >
                {!isDashboard
                  ? "Mis documentos"
                  : "Documentos pendientes por firmar"}
              </Typography>
              {!isDashboard && (
                <>
                  <Grid container direction="row" justify="space-around">
                    <Grid item xs={10}>
                      <TextField
                        className={"fieldSearchResponsive"}
                        style={{ marginLeft: "0%" }}
                        id="txtBuscar"
                        onChange={handleChangeBuscar}
                      ></TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        id="sort-menu"
                        aria-haspopup="true"
                        onClick={(e) => setSortMenu(e.currentTarget)}
                        aria-controls="sort-menu"
                        color="inherit"
                      >
                        <img src={iconSort} width="27px" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}
              <EnhancedTableToolbar
                numSelected={selected.length}
                selected={selected}
                // handleSelectAllClick={(e) => handleSelectAllClick(e)}
                setSelected={setSelected}
                isP7={p7z}
                refresh={refresh}
                responsiveMode={true}
              />
              <Divider />
            </div>
            <div ref={ref} className={!isDashboard ? "tableResponsive" : ""}>
              <TableContainer>
                <Table
                  className={"tableSignedDocuments"}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                  cellSpacing="0px"
                >
                  <TableBody>
                    {stableSort(rowsFilter, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={
                              isFilterIsNull
                                ? null
                                : (event) => handleClick(event, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            style={{ cursor: "pointer" }}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            {/* <TableCell
                              style={{
                                paddingTop: "11px",
                                verticalAlign: "top",
                              }}
                              padding="checkbox"
                            >
                              {isFilterIsNull ? null : (
                                <StyledCheckbox
                                  checked={isItemSelected}
                                  className={"checkButtonDocumentSigned"}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              )}
                            </TableCell> */}
                            <TableCell
                              align="left"
                              component="th"
                              style={{
                                paddingTop: "18px",
                                paddingBottom: "18px",
                              }}
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {isFilterIsNull ? (
                                <Typography>{row.nombrereal}</Typography>
                              ) : (
                                <>
                                  <Typography className={classes.nameDocument}>
                                    {cutName(row.nombrereal)}
                                  </Typography>
                                  {/* <Typography className={classes.documentInfo}>
                                    <OrderCertificate
                                      certified={row.descripcionOrigenFirma}
                                    />
                                  </Typography> */}
                                  <Typography className={classes.documentInfo}>
                                    Creador del circuito: {row.creador}
                                  </Typography>
                                  <Typography className={classes.documentInfo}>
                                    Fecha creacion: {formatearFecha(row.fechaCreacion)}
                                  </Typography>
                                  <Typography className={classes.documentInfo}>
                                  Fecha Recepcion:{formatearFecha(row.fechaRecepcion)}
                                  </Typography>
                                </>
                              )}
                            </TableCell>
                            {/* <TableCell
                              align="left"
                              style={{ cursor: "pointer", paddingLeft: "10px" }}
                            >
                              {isFilterIsNull ? null : (
                                <img
                                  src={tableMenuIcon}
                                  onClick={(e) =>
                                    setOptionsMenu(e.currentTarget)
                                  }
                                  alt="opciones"
                                  align="left"
                                />
                              )}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Menu
                open={Boolean(optionsMenu)}
                anchorEl={optionsMenu}
                onClose={() => setOptionsMenu(null)}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                getContentAnchorEl={null}
                PaperProps={{
                  style: {
                    width: "228px",
                    height: "180px",
                    marginLeft: "-11px",
                  },
                }}
              >
                <Typography className={classes.save}>Guardar en</Typography>
                <MenuItem
                  className={classes.menuGoogleDrive}
                  dense={true}
                  onClick={() => setOptionsMenu(null)}
                >
                  <div className={classes.googleDrive}>
                    <GoogleDriveExplorer
                      open={statusModalGoogleDrive}
                      disableBackdropClick
                      onClose={() => setStatusModalGoogleDrive(false)}
                      setStatusModalGoogleDrive={setStatusModalGoogleDrive}
                      selected={selected}
                      rows1={rows}
                      setSelected={setSelected}
                      iconBlack={true}
                    />
                  </div>
                </MenuItem>
                <MenuItem
                  dense={true}
                  style={{ height: "40px" }}
                  onClick={() => {
                    setStatusModalOneDrive(true);
                    setOptionsMenu(null);
                  }}
                >
                  <img src={iconOneDrive} width="25px" />
                  <Typography
                    className={classes.itemFont}
                    style={{ marginLeft: "2%" }}
                  >
                    OneDrive
                  </Typography>
                </MenuItem>
                <MenuItem
                  dense={true}
                  style={{ marginLeft: "2%", height: "40px" }}
                  onClick={() => {
                    setStatusDropbox(true);
                    setOptionsMenu(null);
                  }}
                >
                  <img src={dropboxIcon} />
                  <Typography
                    className={classes.itemFont}
                    style={{ marginLeft: "4%" }}
                  >
                    Dropbox
                  </Typography>
                </MenuItem>
              </Menu>
              <Menu
                keepMounted
                open={Boolean(sortMenu)}
                anchorEl={sortMenu}
                onClose={() => setSortMenu(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                getContentAnchorEl={null}
                PaperProps={{ style: { width: "228px", height: "215px" } }}
              >
                <Typography className={classes.save}>Ordenar por</Typography>
                <MenuItem
                  dense={true}
                  className={classes.itemOrder}
                  onClick={() => sort("nombrereal", "asc")}
                >
                  A - Z
                </MenuItem>
                <MenuItem
                  dense={true}
                  className={classes.itemOrder}
                  onClick={() => sort("nombrereal", "desc")}
                >
                  Z - A
                </MenuItem>
                <MenuItem
                  dense={true}
                  className={classes.itemOrder}
                  onClick={() => sort("fechaFirma", "desc")}
                >
                  Más recientes
                </MenuItem>
                <MenuItem
                  dense={true}
                  className={classes.itemOrder}
                  onClick={() => sort("fechaFirma", "asc")}
                >
                  Más antiguos
                </MenuItem>
              </Menu>
               <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  labelRowsPerPage="Filas por página"
                  component="div"
                  count={rowsFilter.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              {/* {!isDashboard ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  labelRowsPerPage="Filas por página"
                  component="div"
                  count={rowsFilter.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ) : (
                // <Button
                //   component={Link}
                //   to="/home/ConsultSignedDocument"
                //   variant="contained"
                //   color="primary"
                //   className={classes.buttons}
                // >
                //   Ver Más
                // </Button>
              )} */}
            </div>
          </div>
        </Paper>
      </Grid>
      <ModalGetFIleCloud
        isOpen={statusModalCloudSelect}
        onClose={() => setStatusModalCloudSelect(false)}
        setStatusModalCloudSelect={setStatusModalCloudSelect}
        statusModalCloudSelect={statusModalCloudSelect}
      />
      <DropboxExplorer
        selected={selected}
        setSelected={setSelected}
        rows={rows}
        iconBlack={true}
        handleClick={statusModalDropbox}
        setStatusDropbox={setStatusDropbox}
      />
      <Modal
        open={statusModalOneDrive}
        disableBackdropClick
        onClose={() => setStatusModalOneDrive(0)}
        className={classes.modalpopUpshare}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OneDriveExplorer
          tokenOneDrive={tokenOneDrive}
          setTokenOneDrive={setTokenOneDrive}
          setStatusModalOneDrive={setStatusModalOneDrive}
          selected={selected}
          rows1={rows}
          setSelected={setSelected}
        />
      </Modal>
      {statusModalAvanzado && (
        <ModalFirmaAvanzada
          isOpen={statusModalAvanzado}
          onClose={onClose}
          files={[]}
          filesSize={0}
          style={"style"}
          rows={rowsFilter}
          selected={selected}
          setFiles={() => {}}
          refresh={refresh}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  rows: state.consultSignedDocument.pendingSign,
  signOkFirmaAvanzada: state.consultSignedDocument.signOkFirmaAvanzada,
});

const mapDispatchToProps = {
  obtenerBase64: getBase64OfSelectedFile,
  getListDocument: getListSignedDocument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignedDocumentsResponsive);
