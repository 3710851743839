import { Button, makeStyles, Modal, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { dataQr, firm_positions, modalConfirm, stepCircuit } from '../../../../redux/actions/CircuitoFirmasAction';
import { guardarEnIndexedDB } from '../../../../redux/actions/indexedDB';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		backgroundColor: 'white',
		boxShadow: '0px 3px 0px #00000033 !important',
		padding: '15px 30px 38px 34px',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px !important',
		maxWidth: '530px',
		fontSize: '16px',
		'&:focus': {
			outline: 'none',
		},
		'@media(max-width:599px)': {
			width: '90%',
		},
	},
	color: {
		color: '#E55200',
	},
	title: {
		color: '#E55200',
		textAlign: 'center',
		fontSize: '24px',
	},
	pCenter: {
		textAlign: 'center',
		margin: '10px 0 25px',
		padding: '5px 0',
	},
	flx: {
		display: 'flex',
		gap: '25px',
		alignItems: 'center',
		justifyContent: 'center',
		'@media(max-width:700px)': {
			width: '90%',
			flexDirection: 'column',
			marginLeft: '5%',
		},
	},
	btnBack: {
		color: '#E55200',
		textTransform: 'none',
		border: '1px solid #E55200',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
		'@media(max-width:600px)': {
			padding: '8px 24px',
			fontSize: '1rem !important',
			width: '90%',
		},
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#db4f04',
		},
		'@media(max-width:600px)': {
			padding: '8px 24px',
			fontSize: '1rem !important',
			width: '90%',
		},
	},
}));

const ModalConfirm = ({ pendingList, firmantes, files}) => {

    const classes = useStyles();
    const [modal, setModal] = useState(true);
    const dispatch = useDispatch();

    const closeModal = () => {
        setModal(false)
        dispatch(modalConfirm(false))
    }

    const continuar = async () => {
        dispatch(firm_positions(firmantes))
        dispatch(dataQr(files))
        dispatch(stepCircuit(3));
        closeModal()
    }

    const truncarNombreCompleto = (pendingList) => {
			if (pendingList.length > 35) {
				return pendingList.slice(0, 32) + '...';
			}
			return pendingList;
		};

    return (
			<Modal
				open={modal}
				onClose={closeModal}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper className={classes.modal}>
					<h2 className={classes.title}>¡Ten en cuenta!</h2>
					<p>
						No has incluido el cuadro de firma para:{' '}
						{pendingList.map(nombre => (
							<span className={classes.color} title={nombre}>
								{truncarNombreCompleto(nombre)},{' '}
							</span>
						))}
					</p>
					<p style={{ padding: '5px 0' }}>
						Ellos podrán incluir su firma en cualquier lugar.
					</p>
					<p className={classes.pCenter}>
						<b>¿Deseas continuar?</b>
					</p>
					<div className={classes.flx}>
						<Button
							variant='outlined'
							className={classes.btnBack}
							onClick={() => closeModal()}
						>
							Regresar
						</Button>
						<Button className={classes.continue} onClick={() => continuar()}>
							Si
						</Button>
					</div>
				</Paper>
			</Modal>
		);
}

export default ModalConfirm