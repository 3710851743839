import { capitalizeWords } from '../formatText';

export const createDataPending = data => {
	return data.map(
		({
			idCircuito,
			carpeta,
			usuarioCreador,
			fechaCreacion,
			fechaVencimiento,
			estadoCircuito,
			documentos,
			encriptado, 
			fechaModificacion,
			firmado,
			firmadoUsuario,
			firmasRealizadas,
			firmasTotal,
			radicado,
			vence,
			tipo
		}) => ({
			id: idCircuito,
			nombrereal: carpeta.trim() === '' ? documentos[0].nombre : carpeta,
			creador: capitalizeWords(usuarioCreador),
			fechaCreacion,
			fechaRecepcion: fechaModificacion,
			isCircuit: Boolean(estadoCircuito),
			isFolder: carpeta.trim() || null,
			documentos,
			fechaModificacion,
			firmado,
			firmadoUsuario,
			firmasRealizadas,
			firmasTotal,
			radicado,
			vence,
			encriptado,
			tipo
		})
	);
};
