/*
	 Autor:          Juan Sebastian Morales Carreño
			  Jose david ochoa
	 Nombre:         CanvasDragResize
	 Fecha:          2020-04-30
	 Descripcion:    CanvasDragResize - Agrega a un canvas ya RENDERIZADO!! 
						  las acciones de mover y redimensionar
*/

const CanvasDragResize = (props) => {
	var _canvasOriginal = document.createElement("canvas");
	var _canvas = document.createElement("canvas");
	var _canvas_id = "";
	var _canvas_color = props.colorFirmante ? props.colorFirmante : "";
	props.isReSign ? _canvas_id = props.idReSign : _canvas_id = "cvSign_" + new Date().getTime();
	var _container = document.createElement("div");
	_container.classList.add('container-sign');
	_container.style.backgroundColor = _canvas_color;
	_container.id = 'container-' + _canvas_id;
	if (props.onclick) {
		let pos = _canvas_id.split("_")[2]
		_container.addEventListener("click", props.onclick);
	}
	document.getElementById(props.idPdfContainer).appendChild(_container);

	_container.appendChild(_canvas);
	if (props.isReSign) {
		_container.style.top = props.posY + 'px';
		_container.style.left = props.posX + 'px';

	} else {
		_container.style.top = document.getElementById(props.idPdfContainer).scrollTop + 5 + 'px';
		_container.style.left = document.getElementById(props.idPdfContainer).scrollLeft + 5 + 'px';

	}
	createCorners(_container, _canvas_id);
	createTopCorners(_container, _canvas_id);

	_canvas.id = _canvas_id
	_canvas.style.position = 'absolute';

	var _ctx = _canvas.getContext("2d");
	var _ctxOriginal = _canvasOriginal.getContext("2d");
	var _img = new Image();
	var _id = _canvas_id

	var _posX = 50//= window.event.clientX;//= 0
	var _posY = 100// window.event.clientY;// = 0

	var _imgWidth
	var _imgHeight

	var _marginCorner = 6
	var _percent = 0.3

	var _type = props.typeImg

	_img.src = props.imgRoute
	_img.onload = () => {
		_imgWidth = props.isReSign ? props.reSignWidth : _img.width
		_imgHeight = props.isReSign ? props.reSignHeigth : _img.height
		_posY = props.posY;
		_posX = props.posX;
		_ctx = _updateCanvas(_canvas, _ctx, _img, 0, 0, _imgWidth, _imgHeight, _marginCorner, _imgWidth, _imgHeight)
		if (props.endRender) {
			props.endRender(_canvas_id);
			props.setPredefinedSource(false);
		}

	}

	const _updateCanvas = (canvas, context, img, x, y, imgWidth, imgHeight, margin, newWidth, newHeight) => {
		if (props.predefinedSource == true) {
			_container.style.width = ((imgWidth) * _percent) + "px";
			_container.style.height = ((imgHeight) * _percent) + "px";
		} else {
			_container.style.width = imgWidth + "px";
			_container.style.height = imgHeight + "px";
		}

		canvas.setAttribute("width", imgWidth + "px");
		canvas.setAttribute("height", imgHeight + "px");
		_canvasOriginal.setAttribute("width", imgWidth + "px");
		_canvasOriginal.setAttribute("height", imgHeight + "px");

		context.drawImage(img, -6, -6, img.width + 12, img.height + 12, 0, 0, newWidth, newHeight);
		_ctxOriginal.drawImage(img, -6, -6, img.width + 12, img.height + 12, 0, 0, newWidth, newHeight);

		canvas.style.top = y + 'px';
		canvas.style.left = x + 'px';

		_canvasOriginal.style.top = y + 'px';
		_canvasOriginal.style.left = x + 'px';
		if (props.isFieldempty && props.assignField) {
			props.assignField({ id: _id, px: _posX, py: _posY, pw: _imgWidth, ph: _imgHeight });
		}
	}

	function createCorners(_container, id) {
		if (props.deleteSign) {
			var buttonClose = document.createElement("Button");
			buttonClose.setAttribute('id', 'btn-' + id);
			_container.appendChild(buttonClose);
			buttonClose.addEventListener("click", (e) => {


				props.deleteSign(e, id)
			});
			var bottonRight = document.createElement("div");
			bottonRight.classList.add('resizer', 'se');
			_container.appendChild(bottonRight);
			moveResizable(_container.id, id);
			moveResizableTouch(_container.id, id);
		}
	}

	function createTopCorners(_container, id) {
		if (props.replaceSign) {
			var buttonClose = document.createElement("Button")
			buttonClose.setAttribute('id', 'btn-' + id)
			buttonClose.style.top = "-8px"
			buttonClose.style.left = "-8px"
			_container.appendChild(buttonClose)
			buttonClose.addEventListener("click", props.replaceSign)
		}
	}

	function moveResizable(div, id) {

		var mousePosition;
		var offset = [0, 0];
		var isDown = false;
		const el = document.getElementById(div);
		let isResizing = false;
		el.addEventListener("mousedown", mousedown);
		function mousedown(e) {
			isDown = true;
			offset = [
				_container.offsetLeft - e.clientX,
				_container.offsetTop - e.clientY
			];
			window.addEventListener("mousemove", mousemove);
			window.addEventListener("mouseup", mouseup);
			function mousemove(e) {
				if (!isResizing) {
					e.preventDefault();
					if (isDown) {
						mousePosition = {
							x: e.clientX,
							y: e.clientY
						};
						const contenedor = document.getElementById('tttttcontainer')
						if (contenedor) {
							if (!(mousePosition.x + offset[0] + el.scrollWidth > document.getElementById('tttttcontainer').scrollWidth - 2 || mousePosition.x + offset[0] < 1)) {
								let psLeft = (mousePosition.x + offset[0]);
								if (props.minLeft && props.minLeft > 0) {
									if (psLeft < props.minLeft) {
										psLeft = props.minLeft
									}
								}
								if (props.maxLeft && props.maxLeft > 0) {
									let tamPerX = _container.style.width.split("px")
									let maxPerX = props.maxLeft - Number(tamPerX[0]) + 45
									if (psLeft > maxPerX) {
										psLeft = maxPerX
									}
								}
								_container.style.left = psLeft + 'px';
							}
							if (!(mousePosition.y + offset[1] + el.clientHeight > document.getElementById('tttttcontainer').scrollHeight - 20 || mousePosition.y + offset[1] < 1)) {
								let psTop = (mousePosition.y + offset[1]);
								if (props.minTop && props.minTop > 0) {
									if (psTop < props.minTop) {
										psTop = props.minTop
									}
								}
								/*if (props.maxTop && props.maxTop > 0) {
									let tamPerY = _container.style.height.split("px")
									let maxPerY = props.maxTop - Number(tamPerY[0]) + 16
									if (psTop > maxPerY) {
										psTop = maxPerY
									}
								}*/
								_container.style.top = psTop + 'px';
							}
						}

					}
				}
			}
			function mouseup() {

				var targetElement = e.target;

				if (!targetElement.id.startsWith('btn-cvSign_')) {
					let signCont = document.getElementById(_container.id);
					let _imgBase64 = _canvasOriginal.toDataURL().replace("data:application/pdf;base64,", "");
					_posX = Number((signCont.style.left).replace('px', ''));
					_posY = Number((signCont.style.top).replace('px', ''));
					_imgWidth = Number((signCont.style.width).replace('px', ''));
					_imgHeight = Number((signCont.style.height).replace('px', ''));
					_id = id;
					if (props.onMouseUp) {
						props.onMouseUp({
							_posX,
							_posY,
							_imgWidth,
							_imgHeight,
							_imgBase64,
							_id,
							_type
						});
					}
					if (props.isFieldempty && props.assignField) {
						props.assignField({ id: _id, px: _posX, py: _posY, pw: _imgWidth, ph: _imgHeight });
					}
				}
				window.removeEventListener("mousemove", mousemove);
				window.removeEventListener("mouseup", mouseup);
				isDown = false;
			}
		}
		const resizers = document.getElementById(_container.id).querySelectorAll(' .resizer');
		let currentResizer;
		for (let resizer of resizers) {
			resizer.addEventListener("mousedown", mousedown);
			function mousedown(e) {
				currentResizer = e.target;
				isResizing = true;
				let prevX = e.clientX;
				let prevY = e.clientY;
				window.addEventListener("mousemove", mousemove);
				window.addEventListener("mouseup", mouseup);
				let cX = el.getBoundingClientRect().x;
				function mousemove(e) {
					const rect = el.getBoundingClientRect();
					offset = [
						_container.offsetLeft - e.clientX,
						_container.offsetTop - e.clientY
					];
					if (currentResizer.classList.contains("se")) {
						if (e.clientY > rect.y && e.clientX > rect.x &&
							((e.clientX + offset[0] + el.scrollWidth < document.getElementById('tttttcontainer').scrollWidth - 4) || e.clientX < prevX)
							&&
							((e.clientY + offset[1] + el.scrollHeight < document.getElementById('tttttcontainer').scrollHeight - 4) || e.clientY < prevY)) {
							let moveX = prevX - e.clientX;
							let porcentaje = (moveX * 100) / rect.width;
							let newHeight = (porcentaje * rect.height) / 100;
							el.style.width = rect.width - moveX + "px";
							el.style.height = rect.height - newHeight + "px";
						}
					}
					prevX = e.clientX;
					prevY = e.clientY;
				}
				function mouseup(e) {
					window.removeEventListener("mousemove", mousemove);
					window.removeEventListener("mouseup", mouseup);
					isResizing = false;
				}
			}
		}
	}

	function moveResizableTouch(div, id) {
		var mousePosition;
		var offset = [0, 0];
		var isDown = false;
		const el = document.getElementById(div);
		let isResizing = false;
		el.addEventListener("touchstart", touchstart);
		function touchstart(e) {
			let touch = e.touches[0];
			isDown = true;
			offset = [
				_container.offsetLeft - touch.pageX,
				_container.offsetTop - touch.pageY
			];
			window.addEventListener("touchmove", touchmove);
			window.addEventListener("touchend", touchend);
			function touchmove(e) {
				let touchE = e.touches[0];
				if (!isResizing) {
					if (isDown) {
						mousePosition = {
							x: touchE.pageX,
							y: touchE.pageY
						};
						if (!(mousePosition.x + offset[0] + el.scrollWidth > document.getElementById('tttttcontainer').scrollWidth - 1.5 || mousePosition.x + offset[0] < 1)) {
							_container.style.left = (mousePosition.x + offset[0]) + 'px';
						}
						if (!(mousePosition.y + offset[1] + el.clientHeight > document.getElementById('tttttcontainer').scrollHeight - 20 || mousePosition.y + offset[1] < 1)) {
							_container.style.top = (mousePosition.y + offset[1]) + 'px';
						}
					}
				}
			}
			function touchend() {
				let signCont = document.getElementById(_container.id);
				let _imgBase64 = _canvasOriginal.toDataURL().replace("data:application/pdf;base64,", "");
				_posX = Number((signCont.style.left).replace('px', ''));
				_posY = Number((signCont.style.top).replace('px', ''));
				_imgWidth = Number((signCont.style.width).replace('px', ''));
				_imgHeight = Number((signCont.style.height).replace('px', ''));
				_id = id;
				if (props.onMouseUp) {
					props.onMouseUp({
						_posX,
						_posY,
						_imgWidth,
						_imgHeight,
						_imgBase64,
						_id,
						_type
					});
				}
				window.removeEventListener("touchmove", touchmove);
				window.removeEventListener("touchend", touchend);
				isDown = false;
			}
		}
		const resizers = document.getElementById(_container.id).querySelectorAll(' .resizer');
		let currentResizer;
		for (let resizer of resizers) {
			resizer.addEventListener("touchstart", touchstart);
			function touchstart(e) {
				let touch = e.touches[0];
				currentResizer = touch.target;
				isResizing = true;
				let prevX = touch.pageX;
				let prevY = touch.pageY;
				window.addEventListener("touchmove", touchmove);
				window.addEventListener("touchend", touchend);
				let cX = el.getBoundingClientRect().x;
				function touchmove(e) {
					let touchC = e.touches[0];
					offset = [
						_container.offsetLeft - touchC.pageX,
						_container.offsetTop - touchC.pageY
					];
					const rect = el.getBoundingClientRect();
					if (currentResizer.classList.contains("se")) {
						if (((touchC.pageX + offset[0] + el.scrollWidth < document.getElementById('tttttcontainer').scrollWidth - 4) || touchC.pageX < prevX)
							&&
							((touchC.pageY + offset[1] + el.scrollHeight < document.getElementById('tttttcontainer').scrollHeight - 4) || touchC.pageY < prevY)) {
							let moveX = prevX - touchC.pageX;
							let porcentaje = (moveX * 100) / rect.width;
							let newHeight = (porcentaje * rect.height) / 100;
							el.style.width = rect.width - moveX + "px";
							el.style.height = rect.height - newHeight + "px";
						}
					}
					prevX = touchC.pageX;
					prevY = touchC.pageY;
				}
				function touchend() {
					window.removeEventListener("touchmove", touchmove);
					window.removeEventListener("touchend", touchend);
					isResizing = false;
				}
			}
		}
	}

	var x = document.createElement("CANVAS");
	var ctx = x.getContext("2d");
	ctx.fillStyle = "#FF0000";
	ctx.fillRect(20, 20, 150, 100);
	return null;

}
export default CanvasDragResize;