import axios from 'axios';
import { API_HOSTNAME } from '../types/ApiConstants';

export const consultarDocumento = async (props) => {
  document.getElementById("dvPreloader").style.display = "inline";
  return await axios.get(`${API_HOSTNAME}/api/SendEmail/getInfoOtp`, {
    headers: {
      otp: props.code,      
    },
  }).then(res => {    
    document.getElementById("dvPreloader").style.display = "none";
    document.getElementById("contentText").style.display = "none";
    return res.data;
  }).catch(error => {
    alert("Documento no fue encontrado")
    console.log(error)
    document.getElementById("dvPreloader").style.display = "none";
    return false;
  });
};
 
export default consultarDocumento;