import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import Checkbox from '@material-ui/core/Checkbox';

const useStylesCheckOrange = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'black',
		},
	},
	icon: {
		borderRadius: 0,
		width: 16,
		height: 16,
		opacity: '0',
		boxShadow: 'inset 0 0 0 2px rgba(157, 157, 156,1.0)',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
	},
	checkedIcon: {
		opacity: '1',
		backgroundColor: 'white',
		backgroundImage:
			'linear-gradient(10deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(229, 82, 0)'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: 'white',
		},
	},
});

export const StyledCheckbox = props => {
	const classes = useStylesCheckOrange();
	return (
		<Checkbox
			className={classes.root}
			disableRipple
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			inputProps={{ 'aria-label': 'decorative checkbox' }}
			{...props}
		/>
	);
};
