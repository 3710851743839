import { makeStyles, Modal } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ups from '../../../../assets/img/ups.png'
import { helpHttp } from '../../../../helpers/helpHttp';
import { deleteAction, modalDelete, noAction } from '../../../../redux/actions/CircuitoFirmasAction';
import { API_HOSTNAME_AWS } from '../../../../redux/types/ApiConstants';


const useStyle = makeStyles({
    modal: {
        margin: 0,
        padding: "40px",
        position: "absolute",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: '0px 3px 0px #00000033 !important',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: '10px',
        width: "434px",
        height: "336px",
        "&:focus": {
            outline: "none"
        }
    },
    title: {
        color: '#E55200',
        fontSize: "24px",
        fontFamily: "Mulish",
        fontWeight: "700",
        margin: "20px 0 0 0",
        lineHeight: "30px"
    },
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginTop: "40px",
    },
    parrafo: {
        fontSize: "16px",
        textAlign: "center",
        margin: "24px 0 0 0"
    },
    continue: {
        backgroundColor: "#E55200",
        color: "white",
        padding: "8px 24px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontWeight: "800",
        fontFamily: "Mulish",
        border: "1px solid #E55200",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#E55200",
        },
    },
    btnCerrar: {
        color: "#E55200",
        padding: "8px 24px",
        fontSize: "16px",
        cursor: "pointer",
        border: "1px solid #E55200",
        borderRadius: "8px",
        backgroundColor: "transparent",
        fontFamily: "Mulish",
        fontWeight: "700",
        margin: 0,
    },
})

const ModalDelete = (props) => {

    const {  setSelected, setLoading } = props;
    const dispatch = useDispatch()
    const states = useSelector(state => state)
    const URL = API_HOSTNAME_AWS + "webCircuit/api/UpdateCircuit";
    const itemDelete = states.CircuitoFirmasReducer.deleteClickDer
    let api = helpHttp()
    const { enqueueSnackbar } = useSnackbar();
    const jwt = localStorage.getItem("JWT");
    const classes = useStyle();
    const [modal, setModal] = useState(true);
    
    const closeModal = () => {
        setModal(false)
        dispatch(modalDelete(false))
    }

    const deleteCF = async (idCircuito) => {
        setLoading(true);
        let raw = ({
            "circuito": idCircuito,
            "estadoCircuito": "5",
            "firmante": "",
            "estadoFirmante": "",
            "correoFirmante": ""
        });
        let options = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${jwt}`
            },
            body: raw,
            redirect: 'follow'
        } 
            await api.post(URL, options)
                .then((res) => {
                    if (!res.err) {
                        dispatch(deleteAction(idCircuito));
                        enqueueSnackbar('Circuito eliminado exitosamente.', {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        });
                    } else {
                        dispatch(noAction())
                        enqueueSnackbar('No fue posible eliminar el circuito', {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        });
                    }
                });

                setLoading(false)
    }
    const btnDeleteCF = () => {
        itemDelete.forEach(el => deleteCF(el))
        setSelected([])
        closeModal()
    }

    const cancel = () => {
        closeModal()
    }


    return (
        <Modal
            open={modal}
            onClose={closeModal}
             disableBackdropClick
        >
            <div className={classes.modal}>
                <img src={ups} />
                <h4 className={classes.title}>Eliminar circuito</h4>
                <p className={classes.parrafo}>¿Estas seguro de eliminar este circuito de firmas de manera permanente?</p>
                <div className={classes.flex}>
                    <button onClick={() => btnDeleteCF()} className={classes.btnCerrar}>Si</button>
                    <button onClick={() => cancel()} className={classes.continue}>No</button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalDelete