import axios from 'axios';
import {
	API_HOSTNAME,
	API_HOSTNAME_AWS,
	API_LICENCIAMIENTO,
	API_HOSTNAME_SIGNS,
} from '../types/ApiConstants';
import {
	LOAD_LIST_SIGNED_DOCUMENT,
	LIST_DOCUMENTS_DOWNLOAD,
	SET_LOADING,
	SET_LIST_CERTIFICATES,
	SET_PLAN_LIST,
	DOCUMENTS_SIGN,
	PENDING_SIGN,
	PENDING_OBSERVE,
} from '../types/ConsultSignedDocumentsTypes';
import { downloadPDF } from '../../helpers/FilesFunction.js';
import { saveAs } from 'file-saver';
import { sendMultipartFileGoogleDrive } from '../actions/CloudStorageAction';
import { createDataDocuments } from '../../helpers/ConsultSignedDocuments/createDataDocuments';
import { createDataPending } from '../../helpers/ConsultSignedDocuments/createDataPending';

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('JWT');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

export const getListNSignedDocument = () => async dispatch => {
	try {
		dispatch({ type: SET_LOADING, payload: true });
		const response = await axios.get(
			`${API_HOSTNAME}/api/NDocumentos?cantidadDocumentos=5`
		);
		const { data } = response;
		if (response.status === 200) {
			dispatch({
				type: LOAD_LIST_SIGNED_DOCUMENT,
				payload: data,
			});
			dispatch({ type: SET_LOADING, payload: false });
		}
	} catch (error) {
		dispatch({ type: SET_LOADING, payload: false });
		console.log('Error: ', error);
	}
};

export const getListSignedDocument = () => async dispatch => {
	try {
		dispatch({ type: SET_LOADING, payload: true });
		const response = await axios.get(`${API_HOSTNAME}/api/Documento`);
		const { data } = response;
		var data_filter = data.filter(
			element => !element.ruta.includes('Apli_FirmaYa_circuito')
		);
		if (response.status === 200) {
			dispatch({
				type: LOAD_LIST_SIGNED_DOCUMENT,
				payload: data_filter,
			});
			dispatch({ type: SET_LOADING, payload: false });
		}
	} catch (error) {
		dispatch({ type: SET_LOADING, payload: false });
		console.log('Error: ', error);
	}
};

export const getListDocumentPending = () => async dispatch => {
	try {
		dispatch({ type: SET_LOADING, payload: true });
		const response = await axios.post(
			`${API_HOSTNAME_AWS}webCircuit/api/documentos`,
			{ pendientes: true }
		);
		const { data } = response;
		if (response.status === 200) {		
		const sinObservador = data.pendientes.filter(item=>item.observador == 0)
			const pendings = createDataPending(sinObservador);
			dispatch({
				type: PENDING_SIGN,
				payload: pendings,
			});
		}
	} catch (error) {
		dispatch({ type: SET_LOADING, payload: false });
		console.log('Error: ', error);
	} finally {
		dispatch({ type: SET_LOADING, payload: false });
	}
};

export const getListSignedDocumentCF =
	(email, value, firstTime, pendingsLength) => async dispatch => {
		try {
			dispatch({ type: SET_LOADING, payload: true });
			const response = await axios.post(
				`${API_HOSTNAME_AWS}webCircuit/api/documentos`,
				{ pendientes: !!value }
			);
			const { data } = response;
			// var data_filter = data.filter(element => !element.ruta.includes('Apli_FirmaYa_circuito'))
			if (response.status === 200) {
				if (!value) {
					const documents = createDataDocuments(
						[...data.documentos, ...data.circuitoParticipa],
						email
					);
					dispatch({
						type: DOCUMENTS_SIGN,
						payload: documents,
					});
					pendingsLength();
				} else {
					const sinObservador = data.pendientes.filter(item=>item.observador == 0)
					const pendings = createDataPending(sinObservador);
					dispatch({
						type: PENDING_SIGN,
						payload: pendings,
					});
				}
				firstTime && dispatch({ type: SET_LOADING, payload: false });
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('Error: ', error);
		}finally {
			dispatch({ type: SET_LOADING, payload: false });
		}
	};

	export const getListDocumentPendingObserve = () => async dispatch => {
		try {
			dispatch({ type: SET_LOADING, payload: true });
			const response = await axios.post(
				`${API_HOSTNAME_AWS}webCircuit/api/documentos`,
				{ pendientes: false, observador: true }
			);
			const { data } = response;
			if (response.status === 200) {
				const sinObservador = data.pendientes.filter(
					item => item.observador == 1
				);
				const pendings = createDataPending(sinObservador);
				dispatch({
					type: PENDING_OBSERVE,
					payload: pendings,
				});
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('Error: ', error);
		} finally {
			dispatch({ type: SET_LOADING, payload: false });
		}
	};

export const getListSignedDocumentCFHome = (email, value) => async dispatch => {
	try {
		// dispatch({ type: SET_LOADING, payload: true });
		const response = await axios.post(
			`${API_HOSTNAME_AWS}webCircuit/api/documentos`,
			{ pendientes: !!value }
		);
		const { data } = response;
		if (response.status === 200) {
			const documents = createDataDocuments(
				[...data.documentos, ...data.circuitoParticipa],
				email
			);
			dispatch({
				type: DOCUMENTS_SIGN,
				payload: documents,
			});
		}
	} catch (error) {
		// dispatch({ type: SET_LOADING, payload: false });
		console.log('Error: ', error);
	} 
	// finally {
	// 	dispatch({ type: SET_LOADING, payload: false });
	// }
};

const nameValidate = (names, name) => {
	let result = false;
	for (let i = 0; i < names.length; i++) {
		if (names[i] === name) result = true;
	}
	return result;
};

export const getBase64OfSelectedFile = files => async dispatch => {
	try {
		const listBase64 = [];
		var JSZip = require('jszip');
		var zip = new JSZip();
		var fileNames = new Array();
		if (files.length == 1) {
			files.forEach(async element => {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager`,
					{
						headers: {
							Location: element.ruta,
							Name: element.nombrerepositorio,
							Repository: element.repositorio,
						},
					}
				);
				const { data } = response;
				var nombreArchivo = removeExtension(element.nombrereal);
				var nombreExtencion =
					nombreArchivo.toLowerCase().indexOf('_firmaya') != -1
						? nombreArchivo + '.' + data.extensionArchivo
						: nombreArchivo + '_FirmaYa.' + data.extensionArchivo;
				downloadPDF(
					data.archivoBase64,
					nombreExtencion,
					data.extensionArchivo,
					'data:application/pdf;base64,'
				);
				listBase64.push(data);
			});
		} else {
			let index = 1;
			files.forEach(element => {
				axios
					.get(`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager`, {
						headers: {
							Location: element.ruta,
							Name: element.nombrerepositorio,
							Repository: element.repositorio,
						},
					})
					.then(response => {
						const { data } = response;
						var nombreArchivo = removeExtension(element.nombrereal);
						var nombreExtencion =
							nombreArchivo.toLowerCase().indexOf('_firmaya') != -1
								? nombreArchivo + '.' + data.extensionArchivo
								: nombreArchivo + '_FirmaYa.' + data.extensionArchivo;
						var nombreValidado = '';
						while (nameValidate(fileNames, nombreExtencion)) {
							nombreValidado = nombreExtencion.replace(
								'_FirmaYa',
								'_FirmaYa(' + index + ')'
							);
							nombreExtencion = nombreValidado;
							index++;
						}
						nombreValidado = nombreExtencion;
						zip.file(nombreValidado, data.archivoBase64, { base64: true });
						fileNames.push(nombreValidado);
						listBase64.push(data);
						if (listBase64.length == files.length) {
							zip.generateAsync({ type: 'blob' }).then(function (content) {
								saveAs(content, 'mis_documentos_Firmaya.zip');
							});
						}
					});
			});
		}
		dispatch({
			type: LIST_DOCUMENTS_DOWNLOAD,
			payload: listBase64,
		});
	} catch (error) {
		console.log('Error: ', error);
	}
};

export const getBase64OfSelectedFileArr = files => async dispatch => {
	try {
		const listBase64 = [];
		files.forEach(async element => {
			const response = await axios.get(
				`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager`,
				{
					headers: {
						Location: element.ruta,
						Name: element.nombrerepositorio,
						Repository: element.repositorio,
					},
				}
			);

			const { data } = response;
			data.nombreArchivo = element.nombrereal;
			var nombreArchivo = removeExtension(element.nombrereal);
			listBase64.push(data);
		});
		dispatch({
			type: LIST_DOCUMENTS_DOWNLOAD,
			payload: listBase64,
		});
	} catch (error) {
		console.log('Error: ', error);
	}
};

export const UploadDocumentToDrive =
	(files, oauthToken, folderID, enqueueSnackbar) => async dispatch => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			for (let i = 0; i < files.length; i++) {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
					{
						headers: {
							key: files[i].ruta + '/' + files[i].nombrerepositorio,
							pRepository: files[i].repositorio,
						},
					}
				);
				const { data } = response;
				if (data.httpStatus == 201) {
					let blob = await getDocumentS3(data.url, files[i].nombrereal);
					var reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						sendMultipartFileGoogleDrive(
							oauthToken,
							folderID,
							files[i].nombrereal,
							base64,
							enqueueSnackbar
						);
					};
				} else {
					enqueueSnackbar(`No fue posible obtener el documento desde S3`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			}
		} catch (error) {
			enqueueSnackbar(`Error al cargar el archivo en Google Drive`, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		dispatch({ type: SET_LOADING, payload: false });
	};

export const UploadDocumentToDriveCF =
	(files, oauthToken, folderID, enqueueSnackbar) => async dispatch => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			for (let i = 0; i < files.length; i++) {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
					{
						headers: {
							key: files[i].ruta + '/' + files[i].nombrerepositorio,
							pRepository: files[i].repositorio,
						},
					}
				);
				const { data } = response;
				if (data.httpStatus == 201) {
					let blob = await getDocumentS3(data.url, files[i].nombre);
					var reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						sendMultipartFileGoogleDrive(
							oauthToken,
							folderID,
							files[i].nombre,
							base64,
							enqueueSnackbar
						);
					};
				} else {
					enqueueSnackbar(`No fue posible obtener el documento desde S3`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			}
		} catch (error) {
			enqueueSnackbar(`Error al cargar el archivo en Google Drive`, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		dispatch({ type: SET_LOADING, payload: false });
	};

export const UploadDocumentToOneDrive =
	(files, oneDriveAPI, accesToken, parentFolder, enqueueSnackbar) =>
	async dispatch => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			for (let i = 0; i < files.length; i++) {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
					{
						headers: {
							key: files[i].ruta + '/' + files[i].nombrerepositorio,
							pRepository: files[i].repositorio,
						},
					}
				);
				const { data } = response;
				if (data.httpStatus == 201) {
					let blob = await getDocumentS3(data.url, files[i].nombrereal);
					let fileSize = blob.size;
					var reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						var encodedStr = '',
							encodeChars = [
								'(',
								')',
								'~',
								"'",
								'!',
								'+',
								'$',
								'@',
								'&',
								'-',
								'=',
								'#',
								'[',
								']',
								'{',
								'}',
							];
						var name = files[i].nombrereal;
						for (var j = 0, len = name.length; j < len; j++) {
							if (encodeChars.indexOf(name[j]) >= 0) {
								var hex = parseInt(name.charCodeAt(j)).toString(16);
								encodedStr += '%' + hex;
							} else {
								encodedStr += name[j];
							}
						}
						const Readable = require('stream').Readable;
						const imgBuffer = Buffer.from(base64, 'base64');
						const s = new Readable({
							read(size) {
								this.push(imgBuffer);
								this.push(null);
							},
						});
						oneDriveAPI.items
							.uploadSession({
								accessToken: accesToken,
								filename: encodeURI(encodedStr),
								fileSize: fileSize,
								parentId: parentFolder,
								readableStream: s,
							})
							.then(response => {
								enqueueSnackbar(`Archivo exportado con exito `, {
									variant: 'success',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								});
							});
					};
				} else {
					enqueueSnackbar(`No fue posible obtener el documento desde S3`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			}
			dispatch({ type: SET_LOADING, payload: false });
			return true;
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			enqueueSnackbar(`Error al cargar el archivo en Google Drive`, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return false;
		}
	};

export const UploadDocumentToOneDriveCF =
	(files, oneDriveAPI, accesToken, parentFolder, enqueueSnackbar) =>
	async dispatch => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			for (let i = 0; i < files.length; i++) {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
					{
						headers: {
							key: files[i].ruta + '/' + files[i].nombrerepositorio,
							pRepository: files[i].repositorio,
						},
					}
				);
				const { data } = response;
				if (data.httpStatus == 201) {
					let blob = await getDocumentS3(data.url, files[i].nombre);
					let fileSize = blob.size;
					var reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = function () {
						var dataUrl = reader.result;
						var base64 = dataUrl.split(',')[1];
						var encodedStr = '',
							encodeChars = [
								'(',
								')',
								'~',
								"'",
								'!',
								'+',
								'$',
								'@',
								'&',
								'-',
								'=',
								'#',
								'[',
								']',
								'{',
								'}',
							];
						var name = files[i].nombre;
						for (var j = 0, len = name.length; j < len; j++) {
							if (encodeChars.indexOf(name[j]) >= 0) {
								var hex = parseInt(name.charCodeAt(j)).toString(16);
								encodedStr += '%' + hex;
							} else {
								encodedStr += name[j];
							}
						}
						const Readable = require('stream').Readable;
						const imgBuffer = Buffer.from(base64, 'base64');
						const s = new Readable({
							read(size) {
								this.push(imgBuffer);
								this.push(null);
							},
						});
						oneDriveAPI.items
							.uploadSession({
								accessToken: accesToken,
								filename: encodeURI(encodedStr),
								fileSize: fileSize,
								parentId: parentFolder,
								readableStream: s,
							})
							.then(response => {
								enqueueSnackbar(`Archivo exportado con exito `, {
									variant: 'success',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								});
							});
					};
				} else {
					enqueueSnackbar(`No fue posible obtener el documento desde S3`, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			}
			dispatch({ type: SET_LOADING, payload: false });
			return true;
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			enqueueSnackbar(`Error al cargar el archivo en Google Drive`, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return false;
		}
	};

export const UploadDocumentToDropBox = (files, enqueueSnackbar) => async () => {
	const listDocuments = [];
	try {
		for (let i = 0; i < files.length; i++) {
			const response = await axios.get(
				`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
				{
					headers: {
						key: files[i].ruta + '/' + files[i].nombrerepositorio,
						pRepository: files[i].repositorio,
					},
				}
			);
			const { data } = response;
			var nombreArchivo = removeExtension(files[i].nombrereal);
			var nombreExtencion =
				nombreArchivo.toLowerCase().indexOf('_FirmaYa') != -1
					? nombreArchivo + '.' + getFileExtension(files[i].nombrereal)
					: nombreArchivo + '_FirmaYa.' + getFileExtension(files[i].nombrereal);
			listDocuments.push({
				url: data.url,
				filename: nombreExtencion,
			});
		}
	} catch (error) {
		console.log('Error: ', error);
		enqueueSnackbar('Hubo un error al cargar los docuemntos', {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	}
	return listDocuments;
};

export const UploadDocumentToDropBoxCF =
	(files, enqueueSnackbar) => async () => {
		const listDocuments = [];
		try {
			for (let i = 0; i < files.length; i++) {
				const response = await axios.get(
					`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
					{
						headers: {
							key: files[i].ruta + '/' + files[i].nombrerepositorio,
							pRepository: files[i].repositorio,
						},
					}
				);
				const { data } = response;
				var nombreArchivo = removeExtension(files[i].nombre);
				var nombreExtencion =
					nombreArchivo.toLowerCase().indexOf('_FirmaYa') != -1
						? nombreArchivo + '.' + getFileExtension(files[i].nombre)
						: nombreArchivo + '_FirmaYa.' + getFileExtension(files[i].nombre);
				listDocuments.push({
					url: data.url,
					filename: nombreExtencion,
				});
			}
		} catch (error) {
			console.log('Error: ', error);
			enqueueSnackbar('Hubo un error al cargar los docuemntos', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		return listDocuments;
	};

export const getClientCertificates = () => async dispatch => {
	try {
		const response = await axios.get(
			`${API_HOSTNAME}/api/Certificado/certificadosUsuario`
		);
		if (response.status === 200) {
			dispatch({
				type: SET_LIST_CERTIFICATES,
				payload: response.data.certificados,
			});
		}
		return 0;
	} catch (error) {
		return 1;
	}
};

export const getPlan = enqueueSnackbar => async dispatch => {
	try {
		var dToken = decodeToken(localStorage.JWT);
		const response = await axios.post(
			`${API_LICENCIAMIENTO}/license/api/License/GetDetailLicenseCustomerMyPlan`,
			{
				DocumentType: dToken.customer_type,
				DocumentNumber: dToken.customer_value,
				ServiceName: 'firmaya',
			}
		);
		if (response.status == 200) {
			response.data.result.records.slice(0, 1).map(row => {
				var fecha = new Date(row.expirationDate).toLocaleDateString();
				var dataPlan = {
					paquete: row.packageName,
					date: fecha,
				};
				dispatch({ type: SET_PLAN_LIST, payload: dataPlan });
			});
		} else {
			enqueueSnackbar('Hubo un error al consultar los datos de Mi plan', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	} catch (error) {
		if (error.message.includes('Request failed with status code 422')) {
			enqueueSnackbar('Error de licencia, usuario sin empresa licenciada', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		} else {
			enqueueSnackbar('Hubo un error al conectarse con el servidor', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	}
};
export const downloadFromS3 = (files, enqueueSnackbar) => async () => {
	try {
		const listBase64 = [];
		var JSZip = require('jszip');
		var zip = new JSZip();
		var fileNames = new Array();
		if (files.length == 1) {
			await Promise.all(
				files.map(async file => {
					const response = await axios.get(
						`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
						{
							headers: {
								key: file.ruta + '/' + file.nombrerepositorio,
								pRepository: file.repositorio,
							},
						}
					);
					if (response.data.codigoRespuesta == 'RS1') {
						let url = response.data.url;
						toDataUrl(url, function (myBase64) {
							var namefile = removeExtension(file.nombrereal);
							var extension = file.nombrereal.split('.').pop();
							var extensionName =
								namefile.toLowerCase().indexOf('_firmaya') != -1
									? namefile + '.' + extension
									: namefile + '_FirmaYa.' + extension;
							downloadPDF(myBase64.split(',').pop(), extensionName, extension);
						});
					} else {
						enqueueSnackbar(response.data.descripcionRespuesta, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				})
			);
		} else {
			let index = 1;
			await Promise.all(
				files.map(async file => {
					const response = await axios.get(
						`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
						{
							headers: {
								key: file.ruta + '/' + file.nombrerepositorio,
								pRepository: file.repositorio,
							},
						}
					);
					if (response.data.codigoRespuesta == 'RS1') {
						let url = response.data.url;
						toDataUrl(url, function (myBase64) {
							var namefile = removeExtension(file.nombrereal);
							var extension = file.nombrereal.split('.').pop();
							var extensionName =
								namefile.toLowerCase().indexOf('_firmaya') != -1
									? namefile + '.' + extension
									: namefile + '_FirmaYa.' + extension;
							var nombreValidado = '';
							while (nameValidate(fileNames, extensionName)) {
								nombreValidado = extensionName.replace(
									'_FirmaYa',
									'_FirmaYa(' + index + ')'
								);
								extensionName = nombreValidado;
								index++;
							}
							nombreValidado = extensionName;
							zip.file(nombreValidado, myBase64.split(',').pop(), {
								base64: true,
							});
							listBase64.push({
								base64: myBase64.split(',').pop(),
								extensionName: extensionName,
							});
							fileNames.push(nombreValidado);
							if (listBase64.length == files.length) {
								zip.generateAsync({ type: 'blob' }).then(function (content) {
									saveAs(content, 'mis_documentos_Firmaya.zip');
								});
							}
						});
					} else {
						enqueueSnackbar(response.data.descripcionRespuesta, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				})
			);
		}
	} catch (error) {
		console.log('Error: ', error);
		enqueueSnackbar('No es posible descargar el documento', {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	}
};

export const downloadFromS3CF = (files, enqueueSnackbar) => async () => {
	try {
		const listBase64 = [];
		var JSZip = require('jszip');
		var zip = new JSZip();
		var fileNames = new Array();
		if (files.length == 1) {
			await Promise.all(
				files.map(async file => {
					const response = await axios.get(
						`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
						{
							headers: {
								key: file.ruta + '/' + file.nombrerepositorio,
								pRepository: file.repositorio,
							},
						}
					);
					if (response.data.codigoRespuesta == 'RS1') {
						let url = response.data.url;
						toDataUrl(url, function (myBase64) {
							var namefile = removeExtension(file.nombre);
							var extension = file.nombre.split('.').pop();
							var extensionName =
								namefile.toLowerCase().indexOf('_firmaya') != -1
									? namefile + '.' + extension
									: namefile + '_FirmaYa.' + extension;
							downloadPDF(myBase64.split(',').pop(), extensionName, extension);
						});
					} else {
						enqueueSnackbar(response.data.descripcionRespuesta, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				})
			);
		} else {
			let index = 1;
			await Promise.all(
				files.map(async file => {
					const response = await axios.get(
						`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3get`,
						{
							headers: {
								key: file.ruta + '/' + file.nombrerepositorio,
								pRepository: file.repositorio,
							},
						}
					);
					if (response.data.codigoRespuesta == 'RS1') {
						let url = response.data.url;
						toDataUrl(url, function (myBase64) {
							var namefile = removeExtension(file.nombre);
							var extension = file.nombre.split('.').pop();
							var extensionName =
								namefile.toLowerCase().indexOf('_firmaya') != -1
									? namefile + '.' + extension
									: namefile + '_FirmaYa.' + extension;
							var nombreValidado = '';
							while (nameValidate(fileNames, extensionName)) {
								nombreValidado = extensionName.replace(
									'_FirmaYa',
									'_FirmaYa(' + index + ')'
								);
								extensionName = nombreValidado;
								index++;
							}
							nombreValidado = extensionName;
							zip.file(nombreValidado, myBase64.split(',').pop(), {
								base64: true,
							});
							listBase64.push({
								base64: myBase64.split(',').pop(),
								extensionName: extensionName,
							});
							fileNames.push(nombreValidado);
							if (listBase64.length == files.length) {
								zip.generateAsync({ type: 'blob' }).then(function (content) {
									saveAs(content, 'mis_documentos_Firmaya.zip');
								});
							}
						});
					} else {
						enqueueSnackbar(response.data.descripcionRespuesta, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				})
			);
		}
	} catch (error) {
		console.log('Error: ', error);
		enqueueSnackbar('No es posible descargar el documento', {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	}
};

export const deleteFile = (files, enqueueSnackbar) => async () => {
	try {
		const response = await axios.post(
			`${API_HOSTNAME_SIGNS}api/sign/deleteDocument`,
			files
		);
		if (response.data.codigoRespuesta == 'RS1') {
			enqueueSnackbar(response.data.descripcionRespuesta, {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		} else {
			enqueueSnackbar(response.data.descripcionRespuesta, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	} catch (error) {
		enqueueSnackbar('No fue posible eliminar el archivo', {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		console.log('Error: ', error);
	}
};

function decodeToken(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

function getFileExtension(filename) {
	var ext = /^.+\.([^.]+)$/.exec(filename);
	return ext == null ? '' : ext[1];
}

function removeExtension(filename) {
	const lastDotPosition = filename.lastIndexOf('.');
	if (lastDotPosition === -1) return filename;
	return filename.substr(0, lastDotPosition);
}

function toDataUrl(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

function getDocumentS3(url) {
	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			if (xhr.status != 200) {
				reject(xhr.response);
			} else {
				resolve(xhr.response);
			}
		};
		xhr.send();
	});
}
