import { LOAD_INFO_VALIDATE_FILE, SET_LOADING, SET_ALERT_SNACK } from '../types/ValidateDocumentType';

const INITIAL_STATE = {
  dataDocument: null,
  loading: false,
  snackInfo: {
    status: false,
    message: null,
    severity: 'success',
    title: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_INFO_VALIDATE_FILE:
      return {
        ...state,
        dataDocument: action.payload,
      };
    default:
      return state;
  }
};