import { makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ups from "../../../../assets/img/ups.png";
import { modalMissingSignatures } from "../../../../redux/actions/CircuitoFirmasAction";

const useStyle = makeStyles({
  modal: {
    margin: 0,
    padding: "40px",
    position: "absolute",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 3px 0px #00000033 !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "299px",
    height: "300px",
    "&:focus": {
      outline: "none",
    },
  },
  parrafo: {
    fontSize: "16px",
    textAlign: "center",
    margin: "24px 0 0 0",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "40px",
  },
  continue: {
    backgroundColor: "#E55200",
    color: "white",
    padding: "8px 24px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "800",
    fontFamily: "Mulish",
    border: "1px solid #E55200",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#E55200",
    },
  },
});

const ModalMissingSignatures = ({ faltantes }) => {
  const dispatch = useDispatch();
  // const states = useSelector(state => state.CircuitoFirmasReducer)

  const classes = useStyle();
  const [modal, setModal] = useState(true);
  const closeModal = () => {
    dispatch(modalMissingSignatures(false));
  };

  return (
    <Modal open={modal} onClose={closeModal} disableBackdropClick>
      <div className={classes.modal}>
        <img src={ups} />
        <p className={classes.parrafo}>
          Te hacen falta incluir <b>{faltantes} firmas</b> para poder finalizar
        </p>
        <div className={classes.flex}>
          <button className={classes.continue} onClick={() => closeModal()}>
            Entendido
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMissingSignatures;
