import { Button, makeStyles } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import Icon from '../assets/img/iconDocsPending.svg'


const useStyles = makeStyles(theme => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: '#FFEFD8',
		border: '1px solid #F5AE19',
		borderRadius: '10px',
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '10px',
		marginLeft: '40px',
		'@media(max-width:1279px)': {
			marginLeft: '20px',
		},
	},
	btnIr: {
		textTransform: 'none',
		marginRight: '20px',
		color: '#BF5000',
		textDecoration: 'underline',
		'@media(max-width:1279px)': {
			marginRight: '10px',
		},
	},
	text: {
		fontFamily: 'Mulish',
		fontWeight: '400',
	},
}));

const PendingDocsView = ({cantidad}) => {
  const classes = useStyles();
  return (
		<section className={classes.mainContainer}>
			<div className={classes.box}>
				<img src={Icon} />
				<p className={classes.text}>
					Tiene {cantidad} documentos pendientes por firmar.
				</p>
			</div>
			<Button
				component={Link}
				to='/home/ConsultSignedDocument'
				className={classes.btnIr}
			>
				clic aqui para ir a "Mis documentos"
			</Button>
		</section>
	);
}

export default PendingDocsView