import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';


const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <Fragment>
        <Component {...props} {...rest} />
      </Fragment>
    )} />

  );
}

export default PublicRoute;
