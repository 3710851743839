import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading";
import {
	modalConfirmReject,
	modalReject,
	modalSignatureReject,
	modalUps,
} from '../../../../redux/actions/CircuitoFirmasAction';
import { API_HOSTNAME_AWS } from '../../../../redux/types/ApiConstants';

const useStyle = makeStyles({
	modal: {
		margin: 0,
		padding: '40px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '5.72px',
		width: '530px',
		'&:focus': {
			outline: 'none',
		},
		'@media (max-width: 600px)': {
			width: '90%',
			height: "auto"
		},
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
	},
	parrafo: {
		fontSize: '16px',
		
	},
	btnCerrar: {
		color: '#E55200',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		border: '1px solid #E55200',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
	},
	continueDisable: {
		backgroundColor: '#fadccc',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #fadccc',
		borderRadius: '8px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		marginTop: '40px',
	},
	formControl: {
		width: '100%',
		minWidth: 120,
		marginBottom: '16px',
		border: '1px solid gray',
		borderRadius: '8px',
	},
	selectEmpty: {},
	motivos: {
		width: '100%',
		height: '130px',
		border: '1px solid gray',
		padding: '8px',
		borderRadius: '8px',
		fontFamily: 'mulish',
		fontSize: '16px',
		resize: 'none',
		'&:focus': {
			outline: 'none',
		},
	},
});

const ModalConfirmReject = props => {
	const classes = useStyle();
	const { idCircuito, idFirmante, TokenClient = false, setErrorS3,setRedireccionar } = props;
	const dispatch = useDispatch();
	const states = useSelector(state => state.CircuitoFirmasReducer);
	const [motivoText, setMotivoText] = useState('');
	const [motivo, setMotivo] = useState('');
	const token = !TokenClient ? states.tokenGenerico : TokenClient;
	const [loading, setLoading] = useState(false);
	const closeModal = () => {
		dispatch(modalConfirmReject(false));
	};

	const notificarCircuitoRechazado = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			Circuito: idCircuito,
			firmante: idFirmante,
			notificacion: 2,
			motivo: motivoText,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/notify',
				requestOptions
			);
			const result_1 = await response.text();
		} catch (error) {
			return console.error('error', error);
		}
	};

	const rechazar = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: idCircuito,
			estadoCircuito: '',
			firmante: idFirmante,
			estadoFirmante: 4,
			correoFirmante: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const result_1 = await response.json();

			if (
				result_1.responseText ===
				'El firmante ya realizó la firma y no se puede modificar'
			) {
				setErrorS3('El firmante ya realizó la firma y no se puede modificar');
        setRedireccionar(true)
				dispatch(modalUps(true));
        dispatch(modalReject(false)) 
			} else {
				await notificarCircuitoRechazado();
				dispatch(modalSignatureReject(true));
				dispatch(modalConfirmReject(false));
        setLoading(false);
        setRedireccionar(true);
			}
			
		} catch (error) {
			return console.error('error', error);
		}
	};

	const confirmarReject = async () => {
		if (motivoText.trim().length < 3) {
			return
		}else {
			setLoading(true);
			await rechazar();
		}
	};

	const handleChange = event => {
		setMotivo(event.target.value);
	};

	useEffect(() => {
		switch (motivo) {
			case 1:
				setMotivoText('El contenido es incorrecto');
				break;
			case 2:
				setMotivoText('Debe ser firmado por otra persona');
				break;
			case 3:
				setMotivoText('No estoy de acuerdo con el contenido');
				break;
			case 4:
				setMotivoText('Documento firmado anteriormente');
				break;
			case 5:
				setMotivoText('');
				break;
			default:
				break;
		}
	}, [motivo]);

	if (loading) return <Loading />;

	return (
		<Modal
			open={true}
			// onClose={closeModal}
			disableBackdropClick
		>
			<div className={classes.modal}>
				<h4 className={classes.title}>¡Vas a rechazar!</h4>
				<p className={classes.parrafo}>
					Por favor selecciona el motivo de tu rechazo:{' '}
				</p>
				<FormControl className={classes.formControl} required>
					<InputLabel style={{ padding: '0 13px ' }} id='labelMotivo'>
						Motivo del rechazo
					</InputLabel>
					<Select
						labelId='labelMotivo'
						id='motivo'
						value={motivo}
						onChange={handleChange}
						label='motivo'
					>
						<MenuItem value={1}>El contenido es incorrecto</MenuItem>
						<MenuItem value={2}>Debe ser firmado por otra persona</MenuItem>
						<MenuItem value={3}>No estoy de acuerdo con el contenido</MenuItem>
						<MenuItem value={4}>Documento firmado anteriormente</MenuItem>
						<MenuItem value={5}>Otro</MenuItem>
					</Select>
				</FormControl>
				{motivo === 5 && (
					<textarea
						type='text'
						placeholder='Escribe el motivo'
						className={classes.motivos}
						value={motivoText}
						onChange={e => setMotivoText(e.target.value)}
						required
					/>
				)}
				<div className={classes.flex}>
					<button onClick={() => closeModal()} className={classes.btnCerrar}>
						Cerrar
					</button>
					{motivo ? (
						<button
							onClick={() => confirmarReject()}
							className={classes.continue}
						>
							Rechazar
						</button>
					) : (
						<button className={classes.continueDisable}>Rechazar</button>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ModalConfirmReject;
