import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Paper, Button, Box, Grid, makeStyles } from "@material-ui/core";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import Sign from "./Sign";
import CanvasDragResize from "./CanvasDragResize";
import Iconhandwritten from "../../../assets/img/Iconomanuscrita.png";
import IconStamp from "../../../assets/img/iconoestampa.png";
import IconCheck from "../../../assets/img/check.png";
import IconMarcaAgua from "../../../assets/img/ico_marca_agua.png";
import IconQR from "../../../assets/img/ico_qr.png";
import { ReactComponent as IcoFirmantes } from "../../../assets/img/icoFirmantesT.svg";
import {
  SignCustomWithoutdocument,
  SignCustomWithoutdocumentS3,
  signOkFirmaAvanzada,
  firmaAvanzadaToken,
} from "../../../redux/actions/SignCunstomDocumentAction";
import { kbToMb } from "../../../helpers/FilesFunction";
import {
  APP_KEY,
  MAX_MEGAS_FILE_SIZE,
  MAX_MEGAS_FILES_SIZE,
  URL_FIRMAYA_ASISTENTE_TOKEN,
} from "../../../redux/types/ApiConstants";
import { useSnackbar } from "notistack";
import ModalQR from "./ModalQR";
import ModalFirmantes from "./Signer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import imgLoader from "../../../assets/img/loader.gif";
import { PDFDocument } from "pdf-lib";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch } from "react-redux";
import { POP } from "../../../redux/types/SignTypes";
import SignDocuments from "./CredentialsSign";
import "./Pdf.css";
import "./Sign.css";
import addButton from "../../../assets/img/add-button.png";
import { getListSignature } from "../../../helpers/SignatureUtil";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { encriptar } from "../../../redux/actions/Encriptar";

const runTextScript = (text) => {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.innerHTML = text;
  document.body.appendChild(s);
  document.body.removeChild(s);
};

const Pdf = (props) => {
  const {
    homeReducer,
    isUniqueElectronic,
    style,
    filesSize,
    firmaAvanzadaToken,
    history,
    onClose,
    pop,
    setOpenedRowIndex,
  } = props;
  const [signerIcoX, setSignerIcoX] = useState(null);
  const [signerIcoY, setSignerIcoY] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const idPdfContainer = "tttttcontainer";
  const idThumbnailsContainer = "idThumbnailsContainer";
  const [active, setActive] = useState(null);
  const [verManuscrita, setVerManuscrita] = useState(false);
  const [verManuscritaDisable, setVerManuscritaDisable] = useState(false);
  const [statusModalSign, setStatusModalSign] = useState(false);
  const [statusModaltoken, setStatusModaltoken] = useState(false);
  const [imgSign, setImgSign] = useState(null);
  const [scale, setScale] = useState(1);
  const [showComplete] = useState(true);
  const [docs, setDocs] = useState(props.files);
  const [currentDoc, setCurrentDoc] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDigitalSign, setDigitalSign] = useState(false);
  const dispatch = useDispatch();
  const [tieneFirmaDigital, setTieneFirmaDigital] = useState(false);
  const [isStamp, setStamp] = useState(false);
  const [isMarcaAgua, setMarcaAgua] = useState(false);
  const [isQR, setQR] = useState(false);
  const [verQR, setVerQR] = useState(false);
  const [sendData, setSendData] = useState({
    signerClientId: null,
    firmaelectronica: null,
    key: null,
    documents: [],
  });
  const [imageSignature, setImageSignature] = useState([]);
  const [configQR, setConfigQR] = useState({
    title: "",
    url: false,
    tPaginas: false,
  });
  const [statusModalAlert, setStatusModalAlert] = useState(false);
  const [predefinedSource, setPredefinedSource] = useState(false);
  const [signPredefined, setSignPredefined] = useState(0);
  const [addDataCert, setAddDataCert] = useState(0);
  const [firmas, setFirmas] = useState([]);
  const [numerodocumentos, setNumeroDocumentos] = useState(0);
  const [modalFirmantes, setModalFirmantes] = useState(false);
  const manuscrita = "manuscrita";
  const digital = "digital";
  const estampa = "estampa";
  const marcaAgua = "marcaagua";
  const qr = "qr";
  const [typeImg, setTypeImg] = useState(manuscrita);
  const [myRef] = useState(React.createRef());
  const id = modalFirmantes ? "simple-popover" : undefined;
  const [listSignatures, setListSignatures] = useState([]);
  const [digitalImg, setDigitalImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [s3Limit] = useState(4);
  const [isReSign, setReSign] = useState(false);

  const openZoneDrop = () => {
    document.getElementById("Upload").click();
  };

  window.addEventListener(
    "resize",
    function (event) {
      if (this.window.innerWidth > 1290) {
        setSignerIcoX(this.window.innerWidth - 720);
        setSignerIcoY(this.window.innerHeight / 5);
      }
    },
    true
  );

  const handlerChange = async () => {
    let archivo = document.getElementById("Upload").files;
    if (archivo[0] != null) {
      await getPageNumber(archivo);
      var fileSizeMb = Math.round(archivo[0].size / 1024);
      var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1024);
      archivo[0].sizeBytes = archivo[0].size;
      var extension = "." + archivo[0].name.split(".").pop();
      if (!extension.toLowerCase().includes(".pdf")) {
        enqueueSnackbar(
          `El archivo ${archivo[0].name} no es permitido, los tipos de archivos permitidos son: .pdf`,
          {
            style: { whiteSpace: "pre-line" },
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else if (docs.length == 10) {
        enqueueSnackbar(
          `La cantidad de archivos supera el máximo permitido de 10 documentos.`,
          {
            style: { whiteSpace: "pre-line" },
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        if (fileSizeMb > maxSizeMb) {
          enqueueSnackbar(
            `El tamaño del archivo ${
              archivo[0].name
            } supera el máximo permitido.\nTamaño limite de ${kbToMb(
              MAX_MEGAS_FILE_SIZE * 1000000
            )}`,
            {
              style: { whiteSpace: "pre-line" },
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        } else {
          let dropedSize = 0;
          docs.map((file) => {
            var sizekiloByte = Math.round(file.size / 1024);
            dropedSize += sizekiloByte;
          });
          let maxFilesSizeMb = Math.round(
            (MAX_MEGAS_FILES_SIZE * 1000000) / 1000
          );
          dropedSize += fileSizeMb;
          if (dropedSize > maxFilesSizeMb) {
            enqueueSnackbar(
              `El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(
                MAX_MEGAS_FILES_SIZE * 1000000
              )}`,
              {
                style: { whiteSpace: "pre-line" },
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          } else {
            var results = docs.filter(function (doc) {
              return doc.name == archivo[0].name;
            });
            var existe = results.length > 0 ? results[0] : null;
            if (existe != null) {
              enqueueSnackbar(
                `El archivo ${archivo[0].name} se encuentra actualmente agregado`,
                {
                  style: { whiteSpace: "pre-line" },
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            } else {
              var filesList = [];
              props.files.map((file) => {
                filesList.push(file);
              });
              var newdoc = docs;
              newdoc.push(archivo[0]);
              filesList.push(archivo[0]);
              props.setFiles(filesList);
              setDocs(newdoc);
              document.getElementById("Upload").files = null;
              updateDocuments();
              aplicarFirma();
            }
          }
        }
      }
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  async function getPageNumber(pFiles) {
    var validFiles = [];
    for await (const pFile of pFiles) {
      if (pFile.numPages === undefined) {
        if ("." + pFile.name.split(".").pop() == ".pdf") {
          try {
            const arrayBuffer = await readFile(pFile);
            const pdf = await PDFDocument.load(arrayBuffer);
            pFile.numPages = pdf.getPages().length;
            validFiles.push(pFile);
          } catch (error) {
            enqueueSnackbar(
              `El archivo ${pFile.name} está protegido y no es posible hacerle cambios`,
              {
                style: { whiteSpace: "pre-line" },
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
          }
        } else {
          pFile.numPages = 0;
          validFiles.push(pFile);
        }
      }
    }
  }

  const updateDocuments = () => {
    let count = 0;
    let documentos = [];
    let newDocuments = [];
    let documentosCargados = 0;
    docs.map((doc) => {
      if (count <= sendData.documents.length - 1) {
        documentos.unshift(sendData.documents[count]);
        documentosCargados++;
      } else {
        let typeSignarutes = [];
        if (isStamp == true) {
          typeSignarutes.push({
            signatureType: estampa,
            signPredefined: 0,
            addDataCert: 0,
          });
        }
        if (isMarcaAgua == true) {
          typeSignarutes.push({
            signatureType: marcaAgua,
            signPredefined: 0,
            addDataCert: 0,
          });
        }
        const reader = new FileReader();
        reader.numDoc = count;
        reader.filename = doc.name;
        reader.readAsDataURL(doc);
        reader.onload = (event) => {
          var document = new Object();
          document.uuid = event.target.numDoc;
          document.fileName = event.target.filename;
          document.base64 = event.target.result.replace(
            "data:" + doc.type + ";base64,",
            ""
          );
          document.listSigns = getListSignature(document.base64);
          document.typeSignature = typeSignarutes;
          documentosCargados++;
          documentos.push(document);
          if (numerodocumentos < docs.length) {
            if (documentosCargados > numerodocumentos) {
              documentos.sort((doc1, doc2) =>
                doc1.uuid > doc2.uuid ? 1 : doc1.uuid < doc2.uuid ? -1 : 0
              );
              newDocuments.push(document);
              let newSenData = { ...sendData };
              newSenData.documents = documentos;
              setSendData(newSenData);
              runTextScript(
                "loadDocuments(" +
                  scale +
                  ",'" +
                  JSON.stringify(newDocuments) +
                  "'," +
                  docs.length +
                  ",'" +
                  JSON.stringify(documentos) +
                  "', true)"
              );
            }
            setNumeroDocumentos(docs.length);
          }
        };
      }
      count++;
    });
  };

  useEffect(() => {
    let count = 0;
    let documentos = [];
    let documentosCargados = 0;
    setFirmas([]);
    runTextScript(
      "initPdfComponent('" +
        idPdfContainer +
        "','" +
        idThumbnailsContainer +
        "')"
    );
    docs.map((doc) => {
      const reader = new FileReader();
      reader.numDoc = count;
      reader.filename = doc.name;
      reader.readAsDataURL(doc);
      reader.onload = (event) => {
        var document = new Object();
        document.uuid = event.target.numDoc;
        document.fileName = event.target.filename;
        document.base64 = event.target.result.replace(
          "data:" + doc.type + ";base64,",
          ""
        );
        document.typeSignature = [];
        document.listSigns = getListSignature(document.base64);
        documentos.push(document);
        documentosCargados++;

        if (documentosCargados >= docs.length) {
          documentos.sort((doc1, doc2) =>
            doc1.uuid > doc2.uuid ? 1 : doc1.uuid < doc2.uuid ? -1 : 0
          );
          let newSenData = { ...sendData };
          newSenData.documents = documentos;
          if (!isReSign) setSendData(newSenData);
          runTextScript(
            "loadDocuments(" + scale + ",'" + JSON.stringify(documentos) + "')"
          );
        }
        setNumeroDocumentos(documentosCargados);
      };
      count++;
    });
    setLoading(false);
  }, [scale]);

  const refreshDoc = (e) => {
    let docAct = null;
    let listMini = document.getElementsByClassName("pdf-canvas-mini");
    if (listMini) {
      docAct = Array.from(listMini)
        .find((mini) => mini.classList.contains("active-sheet"))
        .getAttribute("data-documento");
    }
    if (e && e.target && e.target.getAttribute("documento")) {
      docAct = e.target.getAttribute("documento");
    }
    if (docAct != null) {
      setVerManuscritaDisable(false);
      setDigitalSign(false);
      setQR(false);
      let doc = sendData.documents[docAct];
      if (doc.listSigns !== null && doc.listSigns.length > 0) {
        for (var j = 0; j < doc.typeSignature.length; j++) {
          switch (doc.typeSignature[j].signatureType) {
            case manuscrita:
              setVerManuscritaDisable(true);
              setDigitalSign(true);
              break;
            case qr:
              setQR(true);
              break;
          }
        }
      }
      if (doc.typeSignature.length > 0) {
        for (var i = 0; i < doc.typeSignature.length; i++) {
          switch (doc.typeSignature[i].signatureType) {
            case digital:
              setDigitalSign(true);
              break;
            case qr:
              setQR(true);
              break;
          }
        }
      }
    }
  };

  const aplicarFirma = (state, type) => {
    setReSign(false);
    let newSenData = { ...sendData };
    let cantidad = 0;
    if (type == marcaAgua && state === true) {
      newSenData.documents.map((doc) => {
        cantidad += doc.listSigns.length;
      });
    }
    if (cantidad == 0) {
      if (type == manuscrita) {
        if (verManuscritaDisable) {
          return;
        }
        let docAct = null;
        let listMini = document.getElementsByClassName("pdf-canvas-mini");
        if (listMini) {
          docAct = Array.from(listMini)
            .find((mini) => mini.classList.contains("active-sheet"))
            .getAttribute("data-documento");
        }
        setTypeImg(manuscrita);
        let doc = sendData.documents[docAct];
        if (doc.uuid == docAct) {
          if (doc.listSigns !== null && doc.listSigns.length > 0) {
            setDigitalSign(true);
            setTieneFirmaDigital(true);
          } else {
            setTieneFirmaDigital(false);
          }
        }
        setVerManuscrita(state);
        return;
      }
      let newFirmas = [];
      let firma;
      let oFirma = {
        signatureType: type,
        signPredefined: 0,
        addDataCert: 0,
      };
      let existe = false;
      if (state) {
        for (var a in newSenData.documents) {
          existe = false;
          for (var b in newSenData.documents[a].typeSignature) {
            firma = newSenData.documents[a].typeSignature[b];
            if (firma.signatureType == type) {
              existe = true;
            }
          }
          if (!existe) {
            newSenData.documents[a].typeSignature.push(oFirma);
          }
        }
      } else {
        for (var a in newSenData.documents) {
          newFirmas = [];
          for (var b in newSenData.documents[a].typeSignature) {
            firma = newSenData.documents[a].typeSignature[b];
            if (firma.signatureType == type) {
              continue;
            }
            newFirmas.push(firma);
          }
          newSenData.documents[a].typeSignature = newFirmas;
        }
      }
      setSendData(newSenData);
      switch (type) {
        case manuscrita:
          setVerManuscrita(state);
          break;
        case estampa:
          setStamp(state);
          break;
        case marcaAgua:
          setMarcaAgua(state);
          break;
      }
    } else {
      if (type == marcaAgua) {
        enqueueSnackbar(
          "Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar marca de agua",
          {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
    }
  };

  const handleClick = (event) => {
    let docAct = null;
    setSignerIcoY(event.clientY + 20);
    setSignerIcoX(event.pageX - 467);
    let listMini = document.getElementsByClassName("pdf-canvas-mini");
    if (listMini) {
      docAct = Array.from(listMini)
        .find((mini) => mini.classList.contains("active-sheet"))
        .getAttribute("data-documento");
    }
    setActive(docAct);
    let newSenData = { ...sendData };
    for (let i in newSenData.documents) {
      let doc = newSenData.documents[i];
      if (doc.uuid == docAct) {
        setListSignatures(doc.listSigns);
        break;
      }
    }
    setModalFirmantes(true);
  };

  const aplicarQR = () => {
    setReSign(false);
    let cantidad = 0;
    let newSenData = { ...sendData };
    let docAct = null;
    let listMini = document.getElementsByClassName("pdf-canvas-mini");
    if (listMini) {
      docAct = Array.from(listMini)
        .find((mini) => mini.classList.contains("active-sheet"))
        .getAttribute("data-documento");
    }
    newSenData.documents.map((doc) => {
      if (doc.uuid == docAct) {
        cantidad += doc.listSigns.length;
      }
    });
    if (cantidad == 0) {
      let existe = false;
      for (let i in newSenData.documents) {
        let doc = newSenData.documents[i];
        if (doc.uuid == docAct) {
          let tSignature = doc.typeSignature;
          for (let t in tSignature) {
            if (tSignature[t].signatureType === qr) {
              existe = true;
            }
          }
        }
      }
      if (existe === false) {
        setTypeImg(qr);
        setVerQR(true);
        setQR(true);
      } else {
        alert("Solo se permite un QR por documento");
      }
    } else {
      enqueueSnackbar(
        "Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar Qr",
        {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
  };

  const setSignImage = (imgB64, predefined) => {
    if (predefined) {
      setPredefinedSource(true);
    }
    let docAct = null;
    let listMini = document.getElementsByClassName("pdf-canvas-mini");
    if (listMini) {
      docAct = Array.from(listMini)
        .find((mini) => mini.classList.contains("active-sheet"))
        .getAttribute("data-documento");
    }
    let doc = sendData.documents[docAct];
    if (doc.listSigns !== null && doc.listSigns.length > 0) {
      setVerManuscritaDisable(true);
    }
    setImageSignature([
      {
        reSignWidth: 0,
        reSignHeigth: 0,
        idReSign: "",
        posX: 0,
        posY: 0,
        imgRoute: imgB64,
        typeImg: typeImg,
      },
    ]);
    setVerManuscrita(false);
    setImgSign(imgB64);
  };

  const reSignImage = (newScale, mode) => {
    let newImageSignature = [];
    setScale(newScale);
    setReSign(true);

   
    sendData.documents.map((documents) => {
      documents.typeSignature.map((sign) => {
        if (sign.signatureType == manuscrita || sign.signatureType == qr) {
          let posX;
          let posY;
          let x = parseInt(
            document
              .getElementById("container-" + sign.id)
              .style.left.replace("px", "")
          );
          let y = parseInt(
            document
              .getElementById("container-" + sign.id)
              .style.top.replace("px", "")
          );
          let width = parseInt(
            document
              .getElementById("container-" + sign.id)
              .style.width.replace("px", "")
          );
          let height = parseInt(
            document
              .getElementById("container-" + sign.id)
              .style.height.replace("px", "")
          );
          let heigthInit = sign.posXY.pageHeight / scale;
          let widthInit = sign.posXY.pageWidth / scale;
          var offSetLeftInit = (widthInit * 1.2 - widthInit) / 2 / 2;

          if (
            document.getElementsByClassName("pdf-canvas")[0].offsetLeft -
              offSetLeftInit >=
              0 &&
            mode
          ) {
            posX =
              ((((x - offSetLeftInit) * 100) / sign.posXY.pageWidth) *
                (widthInit * (scale + 0.2))) /
                100 -
              offSetLeftInit;
            posY =
              (((y * 100) / sign.posXY.pageHeight) *
                (heigthInit * (scale + 0.2))) /
              100;
          } else if (
            document.getElementsByClassName("pdf-canvas")[0].offsetLeft -
              offSetLeftInit >=
              0 &&
            !mode
          ) {
            posX =
              ((((x + offSetLeftInit * 2) * 100) / sign.posXY.pageWidth) *
                (widthInit * (scale - 0.2))) /
                100 +
              offSetLeftInit * 2;
            posY =
              (((y * 100) / sign.posXY.pageHeight) *
                Math.round(heigthInit * (scale - 0.2))) /
              100;
          } else if (mode) {
            posX =
              (((x * 100) / sign.posXY.pageWidth) *
                (widthInit * (scale + 0.2))) /
              100;
            posY =
              (((y * 100) / sign.posXY.pageHeight) *
                (heigthInit * (scale + 0.2))) /
              100;
          } else {
            posX =
              (((x * 100) / sign.posXY.pageWidth) *
                (widthInit * (scale - 0.2))) /
              100;
            posY =
              (((y * 100) / sign.posXY.pageHeight) *
                (heigthInit * (scale - 0.2))) /
              100;
          }
          sign.posXY.width = mode
            ? (width / scale) * (scale + 0.2)
            : (width / scale) * (scale - 0.2);
          sign.posXY.height = mode
            ? (height / scale) * (scale + 0.2)
            : (height / scale) * (scale - 0.2);
          sign.posXY.x = mode ? posX / scale + 0.2 : posX / scale - 0.2;

          newImageSignature.push({
            reSignWidth: mode
              ? (width / scale) * (scale + 0.2)
              : (width / scale) * (scale - 0.2),
            reSignHeigth: mode
              ? (height / scale) * (scale + 0.2)
              : (height / scale) * (scale - 0.2),
            idReSign: sign.id,
            posX: posX,
            posY: posY,
            imgRoute: "data:image/png;base64," + sign.base64Image,
            typeImg: sign.signatureType,
          });
        }
      });
    });
    setImageSignature(newImageSignature);
    setImgSign(imageSignature[0].imgRoute);
  };

  const assistant = () => {
    setStatusModalSign(false);
    setStatusModaltoken(true);
  };
  const terminaFirma = (redirect) => {
    runTextScript("hideLoadingPDF()");
    if (redirect) {
      if (onClose != undefined) onClose(true);
      dispatch({ type: POP, payload: sendData.documents.length });
      history.push("/home/ConsultSignedDocument");
    }
  };

  const signApply = (signerClientId, key, codeId, isElectronic) => {
    if (signerClientId == "Token" && key == "Token") {
      sendData.key = null;
      sendData.signerClientId = null;
      sendData.documents.map((data) => {
        data.typeSignature.map((signature, index) => {
          signature.id = index;
        });
      });
      runTextScript("showLoadingPDF()");
      setStatusModalSign(false);
      firmaAvanzadaToken(sendData, enqueueSnackbar, terminaFirma, assistant);
    } else {
      sendData.signerClientId = signerClientId;
      sendData.key = encriptar(key, APP_KEY);
      sendData.firmaelectronica = isElectronic ? true : null;
      sendData.codeId = isElectronic ? codeId : null;
      sendData.documents.map((data) => {
        data.typeSignature.map((signature, index) => {
          signature.id = index;
          if (
            signature.signatureType == "manuscrita" ||
            signature.signatureType == "qr"
          ) {
            if (window.innerWidth <= 1253) {
              signature.posXY.pageHeight =
                document.getElementsByClassName("pdf-canvas")[0].scrollHeight;
              signature.posXY.pageWidth =
                document.getElementsByClassName("pdf-canvas")[0].scrollWidth;
            }
          }
        });
      });
      runTextScript("showLoadingPDF()");
      setStatusModalSign(false);
      if (filesSize > s3Limit * 1024 || (isElectronic && style)) {
        SignCustomWithoutdocumentS3(
          sendData,
          enqueueSnackbar,
          setStatusModalAlert,
          terminaFirma
        );
      } else {
        SignCustomWithoutdocument(
          sendData,
          enqueueSnackbar,
          setStatusModalAlert,
          terminaFirma
        );
      }
    }
  };

  const firmarDocumentos = async () => {
    await sendData.documents.map((document) => {
      if (document.typeSignature.length < 1) aplicarFirma(true, digital);
    });
    setOpenedRowIndex(-1)
    setStatusModalSign(true);
  };

  const onMouseUp = (oImg) => {
    let offX;
    let offY;
    let uuid;
    let centerImgX = oImg._posX + oImg._imgWidth / 2;
    let centerImgY = oImg._posY + oImg._imgHeight / 2;
    let pdfPages = document.getElementsByClassName("pdf-canvas");
    let oPage = {
      top: null,
      left: null,
      width: null,
      height: null,
    };
    let oFirma = {
      id: oImg._id,
      signatureType: oImg._type,
      signPredefined: 0,
      addDataCert: 0,
      base64Image: null,
      tpag: configQR.tPaginas,
      url: configQR.url,
      title: configQR.title,
      posXY: {
        pageNumber: null,
        x: null,
        y: null,
        width: null,
        height: null,
        pageWidth: null,
        pageHeight: null,
      },
    };
    for (let a = 0; a < pdfPages.length; a++) {
      oPage.top = pdfPages[a].offsetTop;
      oPage.left = pdfPages[a].offsetLeft;
      if (window.innerWidth < 1253) {
        oPage.pageWidth = pdfPages[a].scrollWidth;
        oPage.pageHeight = pdfPages[a].scrollHeight;
      } else {
        oPage.pageWidth = pdfPages[a].width;
        oPage.pageHeight = pdfPages[a].height;
      }
      if (
        centerImgY > oPage.top &&
        centerImgY < oPage.top + oPage.pageHeight &&
        centerImgX > oPage.left &&
        centerImgX < oPage.left + oPage.pageWidth
      ) {
        offX = pdfPages[a].offsetLeft;
        offY = pdfPages[a].offsetTop;

        uuid = pdfPages[a].getAttribute("data-doc");
        oFirma.idimg = oImg._id;
        oFirma.base64Image =
          oImg._type === qr
            ? "iVBORw0KGgoAAAANSUhEUgAAAIQAAACEAQMAAABrihHkAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyElEQVRIie2Uyw3DMAxDtQH335IbsKKdBO2lRcQeIxiC/A6M9UvVYz9MEu2PYEzak6DIFc+JYzTbMCb1ByJCMfFBqT5yv09c8GUfvbhPbND5ujmxqD/A6qoFRHCGLv6V6YisidISVUZQ6OOEKyD9stZtR4AB6YIBHigPZ0B8bc2WPjOdka0rgVdPR6Tv3P8RVUK2evmJYEC8IHDVmZE1kNouIl5c35UT/7B7fY/U58Tt49q7gLjwlM61mxJXvmMn+taL++SxL/YC+rrZ93htqY0AAAAASUVORK5CYII="
            : oImg._imgBase64.replace("data:image/png;base64,", "");
        oFirma.signPredefined = signPredefined;
        oFirma.addDataCert = addDataCert;
        oFirma.posXY.pageNumber = pdfPages[a].getAttribute("data-pag");
        oFirma.posXY.pageHeight = oPage.pageHeight;
        oFirma.posXY.pageWidth = oPage.pageWidth;
        oFirma.posXY.x = oImg._posX - offX;
        oFirma.posXY.y = oImg._posY - offY;
        oFirma.posXY.width = oImg._imgWidth;
        oFirma.posXY.height = oImg._imgHeight;
        setCurrentPage(pdfPages[a].getAttribute("data-pag"));
        break;
      }
    }
    let newSenData = { ...sendData };
    let newFirmas = [];
    let firma;
    let existe = false;
    for (var a in newSenData.documents) {
      if (newSenData.documents[a].uuid != uuid) {
        continue;
      }
      for (var b in newSenData.documents[a].typeSignature) {
        firma = newSenData.documents[a].typeSignature[b];
        if (firma.idimg == oFirma.idimg) {
          firma = oFirma;
          existe = true;
        }
        newFirmas.push(firma);
      }
      if (!existe) {
        newFirmas.push(oFirma);
      }
      newSenData.documents[a].typeSignature = newFirmas;
      setSendData(newSenData);
      break;
    }
    refreshDoc();
  };

  function deleteSign(e, idDelete) {
    let id;
    idDelete ? (id = idDelete) : (id = e.target.id.replace("btn-", ""));
    let newSendData = [];
    let listDocs = [];
    newSendData.signerClientId = sendData.signerClientId;
    newSendData.key = encriptar(sendData.key, APP_KEY);
    document.getElementById("container-" + id).remove();
    sendData.documents.map((document) => {
      let listSignatures = [];
      document.typeSignature.map((type) => {
        if (type.idimg) {
          if (type.idimg != id) {
            listSignatures.push(type);
          }
        } else {
          listSignatures.push(type);
        }
      });
      document.typeSignature = listSignatures;
      listDocs.push(document);
    });
    newSendData.documents = listDocs;
    setSendData(newSendData);
    setVerManuscritaDisable(false);
    refreshDoc();
  }
  const handleClickToken = () => {
    window.open(URL_FIRMAYA_ASISTENTE_TOKEN);
  };
  const setStateFirmas = () => {
    let hasSign = false;
    sendData.documents.map((document) => {
      document.typeSignature.map((type) => {
        if (
          type.addDataCert == 1 ||
          type.signPredefined == 1 ||
          type.signatureType == "digital"
        ) {
          hasSign = true;
        }
      });
    });
    if (imgSign == null && hasSign == false) {
      setDigitalSign(false);
    }
    setVerManuscrita(false);
  };

  const useStyles = makeStyles((theme) => ({
    btnFirmante: {
      width: "30px",
      backgroundImage: `url(${IcoFirmantes})`,
      textDecoration: "none",
      color: "black",
      border: "2px solid #106ebe",
      "&:hover": {
        border: "1px solid #E55200",
      },
    },
    titleSign: {},
    btnBack: {
      height: "24px",
      width: "auto",
      color: "#E55200",
    },
  }));

  const classes = useStyles();
  return (
    <Paper id="modal-pdf" elevation={3} className={"pdf-paper"} fixed={true}>
      <Grid container className={"pdf-container"}>
        <Grid item className={"mini-container"}>
          <Grid className="pdf-miniaturas">
            <div style={{ display: "none" }}>
              <input
                accept="application/pdf"
                multiple=""
                type="file"
                id="Upload"
                onChangeCapture={handlerChange}
              />
            </div>
            <Grid
              container
              direction="row"
              justify="center"
              className="addDocumentToSign"
            >
              <IconButton
                edge="start"
                aria-label="upload file"
                onClick={openZoneDrop}
              >
                <img src={addButton} />
              </IconButton>
            </Grid>
            <Grid
              id={idThumbnailsContainer}
              className="pdf-miniaturas-firmas"
              onClick={refreshDoc}
            ></Grid>
          </Grid>
        </Grid>
        <Grid item className={"botonera-container"}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className="bar-sign"
          >
            <Grid item style={{ paddingLeft: "11px", width: "35px" }}>
              <IconButton onClick={() => onClose(false)}>
                <ArrowBackIosIcon className={classes.btnBack} />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <h3 className="title-modal">Firma avanzada</h3>
            </Grid>
          </Grid>
          <Grid container style={{ height: "100%" }}>
            <Grid item className="pdf-botonera-firmas">
              <BotoneraFirmas
                manuscrita={manuscrita}
                estampa={estampa}
                aplicarFirma={aplicarFirma}
                isDigitalSign={isDigitalSign}
                isStamp={isStamp}
                verManuscrita={verManuscrita}
                verManuscritaDisable={verManuscritaDisable}
                marcaAgua={marcaAgua}
                isMarcaAgua={isMarcaAgua}
                aplicarQR={aplicarQR}
                setQR={setQR}
                isQR={isQR}
                isUniqueElectronic={isUniqueElectronic}
                qr={qr}
                active={active}
                dataTSA={homeReducer.dataTsa}
                setVerManuscrita={setVerManuscrita}
                documents={{ ...sendData.documents }}
                sendData={sendData}
                setDigitalSign={setDigitalSign}
              />
            </Grid>
            <Grid className={"pdf-botonera-siguiente"}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className="pdf-btn"
                  onClick={() => {
                    firmarDocumentos();
                  }}
                >
                  Firmar
                </Button>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                >
                  <IconButton
                    className={"btn-close-sign-popup"}
                    aria-label="Cerrar"
                    onClick={() => onClose(false)}
                  >
                    <HighlightOffIcon color="primary" fontSize="large" />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              className={"editar-container"}
            >
              <Grid item xs={11}>
                <BotoneraEditar
                  scale={scale}
                  setScale={setScale}
                  firmas={firmas}
                  reSignImage={reSignImage}
                  sendData={sendData}
                  deleteSign={deleteSign}
                  setReSign={setReSign}
                  imageSignature={imageSignature}
                />
              </Grid>
              <Grid item xs={0}>
                <div
                  style={{ display: "none", padding: "8px", float: "right" }}
                  id="dvCurrentPageNumber"
                >
                  Página: {currentPage}
                </div>
              </Grid>
              <Grid item xs={1} data-title="Lista de Firmas">
                <div className="box">
                  <Button
                    onClick={handleClick}
                    endIcon={<IcoFirmantes />}
                  ></Button>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} className="view-container">
              {
                <div
                  id={idPdfContainer}
                  className="drag-container"
                  style={{ height: "100%" }}
                >
                  {imgSign
                    ? imageSignature.map((signature) => (
                        <CanvasDragResize
                          idPdfContainer={idPdfContainer}
                          currentDoc={currentDoc}
                          setCurrentDoc={setCurrentDoc}
                          imgRoute={signature.imgRoute}
                          endRender={async () => {
                            setImgSign(null);
                            setReSign(false);
                          }}
                          onMouseUp={onMouseUp}
                          typeImg={signature.typeImg}
                          deleteSign={deleteSign}
                          isReSign={isReSign}
                          posX={signature.posX}
                          posY={signature.posY}
                          reSignWidth={signature.reSignWidth}
                          reSignHeigth={signature.reSignHeigth}
                          idReSign={signature.idReSign}
                          predefinedSource={predefinedSource}
                          setPredefinedSource={setPredefinedSource}
                        />
                      ))
                    : null}
                </div>
              }
            </Grid>
          </Grid>
        </Grid>
        <IconButton
          className="pdf-btn-float"
          onClick={() => {
            firmarDocumentos();
          }}
        >
          Siguiente
        </IconButton>
        <div className="box-signer">
          <IconButton
            onClick={handleClick}
            endIcon={<IcoFirmantes />}
          ></IconButton>
        </div>
      </Grid>
      {loading == true && (
        <div className="show-loading">
          <img src={imgLoader} />
        </div>
      )}
      <Modal
        open={modalFirmantes}
        onClose={() => setModalFirmantes(false)}
        style={{ top: signerIcoY, left: signerIcoX }}
        className="modal-signer"
        BackdropProps={{
          style: window.innerWidth > 1250 && { backgroundColor: "transparent" },
        }}
      >
        <ModalFirmantes
          signatures={listSignatures}
          onClose={() => setModalFirmantes(false)}
        />
      </Modal>
      <Modal
        open={verManuscrita}
        onClose={() => {
          setStateFirmas(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-sign"
        disableBackdropClick
      >
        <Sign
          setSignImage={setSignImage}
          setDigitalSign={setDigitalSign}
          setSignPredefined={setSignPredefined}
          setAddDataCert={setAddDataCert}
          aplicarFirma={aplicarFirma}
          digital={digital}
          setTypeImg={setTypeImg}
          manuscrita={manuscrita}
          signPredefined={signPredefined}
          showComplete={showComplete}
          setDigitalImg={setDigitalImg}
          tieneFirmaDigital={tieneFirmaDigital}
          sendData={sendData}
          setStateFirmas={setStateFirmas}
        />
      </Modal>

      <Modal
        open={statusModalSign}
        onClose={() => {
          setStatusModalSign(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-sign"
        disableBackdropClick
      >
        <div>
          <SignDocuments
            setStatusModalSign={setStatusModalSign}
            terminar={(signerClientId, key, codeId, isElectronic) => {
              signApply(signerClientId, key, codeId, isElectronic);
            }}
            filesSize={filesSize}
          />
        </div>
      </Modal>
      <Modal
        open={statusModaltoken}
        onClose={() => {
          setStatusModaltoken(false);
        }}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          id="paperElectro2"
          elevation={3}
          className="sign-paper-source-signature"
        >
          <Grid
            container
            direction="row"
            className={classes.grid}
            justify="space-between"
          >
            <IconButton
              edge="start"
              id="forwardCode"
              onClick={() => {
                setStatusModaltoken(false);
                setStatusModalSign(true);
              }}
              className={"btn-forward-sign-modal"}
            ></IconButton>
            <IconButton
              edge="start"
              aria-label="closeModal"
              onClick={() => {
                setStatusModaltoken(false);
              }}
              className={"btn-close-sign-modal"}
            ></IconButton>
          </Grid>
          <div>
            <form>
              <FormControl
                autoComplete="off"
                noValidate
                className={classes.form}
              >
                <Typography
                  className={classes.textModalElectro2}
                  style={{ margin: "28px 52px 0px " }}
                >
                  El asistente de firma no se está ejecutando. Ejecútelo e
                  intente de nuevo.
                  <br></br>
                  <br></br>
                  Si no cuenta con el asistente lo puede obtener en el botón
                  descargar.
                </Typography>
                <Grid
                  style={{ margin: "29px 0px 0px 0px" }}
                  container
                  justify="center"
                >
                  <Button
                    className={"btnGeneral18"}
                    onClick={() => {
                      setStatusModaltoken(false);
                      signApply("Token", "Token", undefined, undefined);
                    }}
                    style={{ width: "154px", height: "41px", margin: "10px" }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    className={"btnGeneral18"}
                    onClick={handleClickToken}
                    style={{ width: "154px", height: "41px" }}
                  >
                    Descargar
                  </Button>
                </Grid>
              </FormControl>
            </form>
          </div>
        </Paper>
      </Modal>
      <Modal
        open={verQR}
        disableBackdropClick
        onClose={() => {
          setVerQR(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-sign"
      >
        <ModalQR
          setQR={setQR}
          setVerQR={setVerQR}
          setSignImage={setSignImage}
          setTypeImg={setTypeImg}
          setConfigQR={setConfigQR}
          qr={qr}
          aplicarQR={aplicarQR}
        />
      </Modal>
    </Paper>
  );
};

const BotoneraFirmas = (props) => {
  let manuscritaImg = Iconhandwritten;
  let manuscritaClass = "pdf-botonera-btn";

  let estampaImg = IconStamp;
  let estampaClass = "pdf-botonera-btn";

  let marcaAguaImg = IconMarcaAgua;
  let marcaAguaClass = "pdf-botonera-btn";

  let QRImg = IconQR;
  let QRClass = "pdf-botonera-btn";

  if (props.verManuscrita) {
    manuscritaImg = IconCheck;
    manuscritaClass = "pdf-botonera-btn-active";
  }

  if (props.verManuscritaDisable) {
    manuscritaImg = IconCheck;
    manuscritaClass = "pdf-botonera-btn-active";
  }

  if (props.isStamp) {
    estampaImg = IconCheck;
    estampaClass = "pdf-botonera-btn-active";
  }

  if (props.isMarcaAgua) {
    marcaAguaImg = IconCheck;
    marcaAguaClass = "pdf-botonera-btn-active";
  }

  let docIsQR = false;
  for (let i in props.documents) {
    let doc = props.documents[i];
    if (doc.uuid == props.active) {
      let tSignature = doc.typeSignature;
      for (let t in tSignature) {
        if (tSignature[t].signatureType === props.qr) {
          docIsQR = true;
        }
      }
    }
  }

  if (props.isQR) {
    QRImg = IconCheck;
    QRClass = "pdf-botonera-btn-active";
  }

  const clickManuscrita = () => {
    props.aplicarFirma(true, props.manuscrita);
  };

  const clickEstampa = () => {
    if (props.isStamp) props.aplicarFirma(false, props.estampa);
    else {
      props.aplicarFirma(true, props.estampa);
    }
  };

  const clickMarcaAgua = () => {
    props.aplicarFirma(!props.isMarcaAgua, props.marcaAgua);
  };

  const handleclickQR = () => {
    props.aplicarQR();
  };

  return (
    <>
      <span className={"subtitleOptions"}>
        Selecciona las opciones de firma que quieras incluir al documento
      </span>
      <Box className={"box-botonera"}>
        <div className={manuscritaClass} onClick={clickManuscrita}>
          <Grid
            container
            className={"gridBotonera"}
            spacing={0}
            justify="center"
            alignItems="center"
          >
            <Grid item={5}>
              <img src={manuscritaImg} height="24" fontSize="large" />
            </Grid>
            <Grid item={7}>Firma manuscrita</Grid>
          </Grid>
        </div>
        <div
          className={estampaClass}
          style={
            props.isUniqueElectronic
              ? { pointerEvents: "none", opacity: 0.4 }
              : null
          }
          onClick={clickEstampa}
        >
          <Grid
            container
            className={"gridBotonera"}
            spacing={0}
            justify="center"
            alignItems="center"
          >
            <Grid item={5}>
              <img src={estampaImg} height="24" fontSize="large" />
            </Grid>
            <Grid item={7}>Estampa</Grid>
          </Grid>
        </div>
        <div className={marcaAguaClass} onClick={clickMarcaAgua}>
          <Grid
            container
            className={"gridBotonera"}
            spacing={0}
            justify="center"
            alignItems="center"
          >
            <Grid item={5}>
              <img src={marcaAguaImg} height="24" fontSize="large" />
            </Grid>
            <Grid item={7}>Marca de agua</Grid>
          </Grid>
        </div>

        <div className={QRClass} onClick={handleclickQR}>
          <Grid
            container
            className={"gridBotonera"}
            spacing={0}
            justify="center"
            alignItems="center"
          >
            <Grid item={5}>
              <img src={QRImg} height="24" fontSize="large" />
            </Grid>
            <Grid item={7}>
              <span class="nameQR">QR</span>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

const BotoneraEditar = (props) => {
  const reSign = async (scale, mode) => {
    if (props.imageSignature.length > 0) props.reSignImage(scale, mode);
    else {
      props.setScale(scale);
      props.setReSign(true);
    }
  };

  return (
    <Box display="flex" justifyContent="flex-start">
      <ZoomIn
        fontSize="large"
        htmlColor="#9D9D9C"
        className="zoom-button"
        style={{ marginLeft: "-2px" }}
        onClick={() => {
          let newScale = 0;
          if (props.scale + 0.2 > 4) return;
          else newScale = Math.round((props.scale + 0.2) * 100) / 100;
          reSign(newScale, true);
        }}
      ></ZoomIn>
      <ZoomOut
        fontSize="large"
        htmlColor="#9D9D9C"
        className="zoom-button"
        style={{ marginLeft: "5px" }}
        onClick={() => {
          let newScale = 0;
          if (props.scale - 0.2 < 0.4) return;
          else newScale = Math.round((props.scale - 0.2) * 100) / 100;
          reSign(newScale, false);
        }}
      ></ZoomOut>
      <div style={{ padding: "8px", float: "right" }}>
        Zoom: {(props.scale * 100) | 0} %
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    homeReducer: state.home,
    isUniqueElectronic: state.sign.isUniqueElectronic,
  };
};

const mapDispatchToProps = {
  signOkFirmaAvanzada,
  firmaAvanzadaToken: firmaAvanzadaToken,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pdf));
