import axios from 'axios';
import { IS_UNIQUE_ELECTRONIC } from '../types/SignTypes';
import { useHistory } from 'react-router-dom';
import { API_HOSTNAME, APP_KEY} from '../types/ApiConstants';
import { LOADING, SET_USER_INFO } from '../types/AuthTypes';
import { getInfoToken, validateEstampa } from '../../helpers/authUtils';
import { encriptarForUrl } from './Encriptar';


export const getPlainToken = (token, circuito) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const history = useHistory();
    const data = { token };
    const response = await axios.post(`${API_HOSTNAME}/api/Auth/GetPlainInfo`, data);
    if (response.status === 200) {
      localStorage.setItem('APP_THEME', 'orange');
      localStorage.setItem('JWT', response.data);
      localStorage.setItem('JWTD', token);
      const userInfo = getInfoToken();
      if (validateEstampa()) dispatch({ type: IS_UNIQUE_ELECTRONIC, payload: false });
      else dispatch({ type: IS_UNIQUE_ELECTRONIC, payload: true });
      dispatch({ type: SET_USER_INFO, payload: userInfo });
      dispatch({ type: LOADING, payload: false });
   
      if (!userInfo.userDigitalWare) {
        history.push('/home');
      } 
      else if (userInfo.userDigitalWare && circuito) {
        const cadenaEncriptada = encriptarForUrl(`id=${circuito}&estadoC=2`, APP_KEY);
        history.push(`/home/DetalleCircuito/${cadenaEncriptada}`);
      } 
      else {
        history.push('/home/CircuitoFirmas');
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const setInfoToken = () => {
  const userInfo = getInfoToken();
  return ({ type: SET_USER_INFO, payload: userInfo });
};
