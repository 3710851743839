import React, { Component } from 'react';

import './core';

import { consultarDocumento } from '../../redux/actions/consultardocumento';

import IconoRefrescar from '../../assets/img/btnRefreshCaptcha.png';

import { Grid } from '@material-ui/core';

class Captcha extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mainCaptcha: 'mainCaptcha',

			txtCaptcha: 'txtCaptcha',

			captcha: 'captcha',

			texto: '',

			hiddenCaptcha: false,

			file: props.file,

			setInfo: props.setInfo,

			filename: props.fileName,
		};
	}

	componentDidMount() {
		this.genCaptcha();
	}

	genCaptcha = () => {
		var alpha = new Array(
			'A',
			'B',
			'C',
			'D',
			'E',
			'F',
			'G',
			'H',
			'J',
			'K',
			'L',
			'M',
			'N',
			'O',
			'P',
			'Q',
			'R',
			'S',
			'T',
			'U',
			'V',
			'W',
			'X',
			'Y',
			'Z',
			'a',
			'b',
			'c',
			'd',
			'e',
			'f',
			'g',
			'h',
			'i',
			'j',
			'k',
			'm',
			'n',
			'o',
			'p',
			'q',
			'r',
			's',
			't',
			'u',
			'v',
			'w',
			'x',
			'y',
			'z'
		);

		var i;

		var a = '';

		for (i = 0; i < 5; i++) {
			a = a + alpha[Math.floor(Math.random() * alpha.length)] + ' ';
		}

		var code = a.substring(0, a.length - 1);

		document.getElementById('mainCaptcha').value = code;

		this.state.texto = code;

		this.CreaIMG();
	};

	ValidCaptcha = async () => {
		var string1 = document
			.getElementById('mainCaptcha')
			.value.split(' ')
			.join('');

		var string2 = document
			.getElementById('txtCaptcha')
			.value.split(' ')
			.join('');

		if (string2 != '') {
			if (string1 == string2) {
				this.setState(state => {
					return {
						hiddenCaptcha: true,
					};
				});

				var info = await consultarDocumento(this.props);

				this.props.setInfo(info.usuarios);
			} else {
				alert('Texto invalido, intenta nuevamente');

				document.getElementById('txtCaptcha').value = '';

				this.genCaptcha('mainCaptcha');

				return false;
			}
		} else alert('Por favor ingrese el texto en la imagen');
	};

	CreaIMG = () => {
		var texto = this.state.texto;

		var ctxCanvas = document.getElementById('captcha').getContext('2d');

		var fontSize = '40px';

		var fontFamily = 'Arial';

		var width = 220;

		var height = 100;

		//tamaño

		ctxCanvas.canvas.width = width;

		ctxCanvas.canvas.height = height;

		//color de fondo

		ctxCanvas.fillStyle = 'WHITE'; // "whitesmoke";

		ctxCanvas.fillRect(0, 0, width, height);

		//puntos de distorsion

		ctxCanvas.setLineDash([7, 10]);

		ctxCanvas.strokeStyle = 'white';

		ctxCanvas.lineDashOffset = 100;

		ctxCanvas.beginPath();

		var line;

		for (var i = 0; i < width; i++) {
			line = i * 5;

			ctxCanvas.moveTo(line, 0);

			ctxCanvas.lineTo(0, line);
		}

		ctxCanvas.stroke();

		//formato texto

		ctxCanvas.direction = 'ltr';

		ctxCanvas.font = fontSize + ' ' + fontFamily;

		//texto posicion

		var x = width / 9;

		var y = (height / 3) * 2;

		//color del borde del texto

		ctxCanvas.strokeStyle = 'yellow';

		ctxCanvas.strokeText(texto, x, y);

		//color del texto

		ctxCanvas.fillStyle = 'red';

		ctxCanvas.fillText(texto, x, y);
	};

	render() {
		return (
			<div id='dvCaptcha' hidden={this.state.hiddenCaptcha}>
				<Grid container direction='column'>
					<Grid item xs={12}>
						<input type='hidden' id='mainCaptcha' name='mainCaptcha' />

						<canvas id='captcha' name='captcha'>
							{' '}
						</canvas>

						<Grid container spacing={1} className='captchaMin' direction='row'>
							<Grid item xs={2}>
								<img
									src={IconoRefrescar}
									height='36'
									onClick={this.genCaptcha}
									title='Refrescar'
								></img>
							</Grid>

							<Grid item xs={6}>
								<input
									type='text'
									id='txtCaptcha'
									name='txtCaptcha'
									className='form-control'
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid xs={8}>
						<div className=''>
							<div className=''>
								<center>
									<input
										id='Button1'
										type='button'
										className='btnClass form-control'
										value='Ver documento'
										onClick={this.ValidCaptcha}
									/>
								</center>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default Captcha;
