import { API_DROPBOX_TOKEN, API_DROPBOX_URL_POST } from '../types/ApiConstants';
import axios from 'axios';

export const sendMultipartFileGoogleDrive = (token, folderId, name, filebase64, enqueueSnackbar) => {
    if (name.toLowerCase().endsWith('.pdf')) {
        var file = b64toBlob(filebase64, 'application/pdf');//new Blob([filebase64]);
        var metadata = {
            'name': name,
            'mimeType': 'application/pdf',
            'parents': [folderId], // Folder ID at Google Drive
        };
    } else {
        var file = b64toBlob(filebase64, 'application/json');//new Blob([filebase64]);
        var metadata = {
            'name': name,
            'mimeType': 'application/json',
            'parents': [folderId], // Folder ID at Google Drive
        };
    }
 
    var form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    var xhr = new XMLHttpRequest();
    xhr.open('post', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id');
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.responseType = 'json';
    xhr.onload = () => {
        if (xhr.response !== '') {
            enqueueSnackbar('Archivo exportado con exito ', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
        }
        else {
            enqueueSnackbar('Archivo no exportado', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
        }

    };
    xhr.send(form);
}

export const sendMultipartFileDropBox = (name, filebase64, enqueueSnackbar) => {
    var file = b64toBlob(filebase64, 'application/pdf');
    var metadata = {
        'name': name,
        'mimeType': 'application/pdf',
    };
    var form = new FormData();
    form.append('metadata', new Blob([metadata], { type: 'application/octet-stream' }));
    form.append('file', file);

    let path = '/' + name;
    let request = new XMLHttpRequest();
    request.open("POST", API_DROPBOX_URL_POST, true);
    request.setRequestHeader("Authorization", "Bearer " + API_DROPBOX_TOKEN);
    request.setRequestHeader("Dropbox-API-Arg", '{"path":"' + path + '","mode":{".tag":"overwrite"},"autorename":true,"mute":false}');
    request.setRequestHeader("Content-type", "application/octet-stream");
    request.onload = () => {
        if (request.status == 200) {
            enqueueSnackbar('Archivo exportado con exito ', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
        }
        else {
            enqueueSnackbar('Archivo no exportado', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
        }
    }
    request.send(form);
}

export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}


export const uploadToExternalService = async (filebase64, name) => {
    try {
        let res = await axios({
            url: 'https://api-content.dropbox.com/1/files_put/auto/' + name,
            method: 'put',
            // timeout: 8000,
            headers: {
                Authorization: 'Bearer ' + API_DROPBOX_TOKEN,
                'Content-Type': 'application/pdf'
            },
            body: filebase64
        })
        if (res.status == 200) {
            console.log(res.status)
        }
        if (res.status == 400) {
            console.log(res)
        }
        return res.data
    }
    catch (err) {
        console.error(err);
    }
}

export const uplodaDocuement = async () => {
    try {
        console.log(' upload Data ')
    }
    catch (err) {
        console.error(err);
    }
}


export default sendMultipartFileGoogleDrive;