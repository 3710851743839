import axios from 'axios';
import {
  LOAD_INFO_VALIDATE_FILE,
  SET_LOADING,
} from '../types/ValidateDocumentType';
import { API_HOSTNAME_AWS } from '../types/ApiConstants';
import { toBase64 } from '../../helpers/FilesFunction';
 

const adaptResponse = (newResponse) => {
  const { estado, mensaje, listaFirmas, contenidoOriginal } = newResponse;

  const documentoFirmado = listaFirmas.map((firma) => ({
    id: firma.id,
    nombreCertificado: firma.id, 
    clave: null, 
    base64: null, 
    cn: firma.firmante,
    email: firma.correo,
    serialNumber: firma.serial,
    organizacion: firma.certificadora,
    pais: firma.pais,
    alias: null, 
    fechaDesde: firma.fechaDesde, 
    fechaHasta: firma.fechaHasta, 
    estadoValidacion: firma.estado ? 'Certificado verificado correctamente' : 'Certificado no verificado', 
    fechaFirmado: new Date(firma.fechaFirmado).getTime(), 
    numeroDocumento: firma.numeroDocumento,
    ciudad: firma.ciudad,
  }));

  return {
    mensaje,
    documentoFirmado,
    contenidoOriginal:contenidoOriginal? contenidoOriginal : null, 
    codigoRespuesta: estado === 0 ? 'RS1' : 'RS0', 
    httpStatus: estado === 0 ? 200 : 500, 
  };
};


export const getInfoValidateAction = (file, enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  try {
    dispatch({
      type: LOAD_INFO_VALIDATE_FILE,
      payload: null,
    });
    const base64Documento = (await toBase64(file)).split(',').pop();
    const typeDoc =file.path.split('.').pop();
    const infoValidate = {
      "tipoArchivo": typeDoc,
      "archivo":base64Documento,
    };
    // const infoValidate = {
    //   base64Documento,
    // };
    const response = await axios.post(`https://qa-core.firmaya.co/documentvalidate/api/validate/validaciondocumento`, infoValidate);
    const { data } = response;
    const adaptedData = adaptResponse(data);
    dispatch({
      type: LOAD_INFO_VALIDATE_FILE,
      payload: adaptedData
      // payload:data
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        enqueueSnackbar(error.response.data.mensaje, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else if (error.response.status === 500) {
        enqueueSnackbar('Error', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    else {
      enqueueSnackbar('Error consultando Api de validación', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
    console.error(error);
    dispatch({
      type: LOAD_INFO_VALIDATE_FILE,
      payload: null,
    });
  }
  dispatch({ type: SET_LOADING, payload: false });
};
