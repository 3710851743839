import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useForm, Controller } from 'react-hook-form';
import {
	modalFinished,
	modalUps,
} from '../../../../redux/actions/CircuitoFirmasAction';
import {
	getCerificatesByUser,
	firmarAction,
	firmarS3ActionCircuit,
	firmarToken,
	getUrldocumentsToken,
	mailOtpSendClient,
	generateCertificadoAction,
} from '../../../../redux/actions/SignAction';
import Loading from '../../../../components/Loading';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
	cambiarEstadoFirmante2,
	notificar2,
	notificarCreador2,
} from '../../../../redux/actions/CircuitActions';
import {
	API_HOSTNAME_AWS,
	API_HOSTNAME_SIGNS,
	APP_KEY,
	MAX_NUMBER_ATTEMPS_ELECTRONIC,
	PASSWORD_DIGITAL,
	URL_FIRMAYA_ASISTENTE_TOKEN,
	USUARIO_DIGITAL,
} from '../../../../redux/types/ApiConstants';
import Modal from '@material-ui/core/Modal';
import { LOADING, TOKEN_CONNECTION } from '../../../../redux/types/SignTypes';
import { Rules } from '../../../../helpers/RHFRules';
import { encriptar } from '../../../../redux/actions/Encriptar';
import axios from 'axios';
import { certificateName } from '../../../../helpers/helpNames';
const token = localStorage.getItem('JWT');

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	resize: {
		paddingLeft: '4%',
		fontSize: '15px',
		letterSpacing: '0px',
		color: 'black',
		opacity: '1',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	flagButton: {},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '5px 0 5px',
	},
	form: {
		width: '100%',
	},
	formElements: {
		marginBottom: '10px',
	},
	dialogAction: {
		justifyContent: 'center',
		marginBottom: '5px',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	textModalElectro: {
		font: 'normal normal 100 16px/24px Muli',
		padding: '16px 10px 0px',
		color: '#4F4F4F',
	},
	textModalElectro2: {
		font: 'normal normal 100 16px/24px Muli',
		padding: '28px 10px 0px',
		color: '#4F4F4F',
	},
	validateCheck: {
		font: 'normal normal 600 16px/20px Muli',
		margin: '35px 48px 14px',
	},
	validateCode: {
		font: 'normal normal 600 16px/20px Muli',
		margin: '37px 70px 14px',
	},
	validateLink: {
		font: 'normal normal 600 16px/20px Muli',
		color: '#E55200',
		margin: '30px 90px 22px',
		textDecoration: 'underline',
		textTransform: 'inherit',
		cursor: 'pointer',
	},
	input: {
		height: 5,
	},
	cssLabel: {
		font: 'normal normal normal 15px/22px Muli',
		color: '#707070 !important',
	},
}));

const TextFieldVerify = withStyles({
	root: {
		'& input:valid + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& input:valid:focus + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& label.Mui-focused': {
			left: '-45px !important',
			marginTop: '-2px',
			font: 'normal normal normal 14px/22px Muli',
			color: '#707070',
		},
		'& label': {
			textAlign: 'center !important',
			width: '90%',
			marginTop: '-9px',
			font: 'normal normal normal 18px/22px Muli',
			color: '#B2B2B2',
		},
		opacity: 0.7,
	},
})(TextField);

const TextFieldVerifyStyle = withStyles({
	root: {
		'& input:valid + fieldset': {
			boxShadow: '0px 3px 0px #00000033',
			border: '0.8387856483459473px solid #706F6F',
			borderRadius: '10px',
			padding: '5px !important',
		},
		'& input:valid:focus + fieldset': {
			boxShadow: '0px 3px 0px #00000033',
			border: '0.8387856483459473px solid #706F6F',
			borderRadius: '10px',
			padding: '5px !important',
		},
		color: '#B2B2B2 !important',
		font: 'normal normal 600 18px/22px Muli',
	},
})(TextField);

const ModalFirmaCliente = props => {
	const {
		isOpen,
		onClose,
		info,
		getCertificates,
		services,
		setServices,
		certificates,
		firmar,
		firmarS3,
		history,
		filesSize,
		style,
		rows,
		selected,
		getUrldocuments,
		loadingAction,
		firmarToken,
		getUrldocumentsToken,
		isP7,
		mailOtpSendClient,
		generateCertificate,
		setStatusModal,
		firmante,
		circuito,
		statusModaltoken,
		setStatusModaltoken,
		irHome = false,
		ultimaPosicion = false,
		dataSignatory,
		sendData,
		signApply,
		setErrorsS3,
		setRedireccionar,
		lineal,
		vencimiento,
	} = props;

	const certificadoOptions = [
		{ id: '2', label: 'Centralizado' },
		{ id: '3', label: 'Token' },
		{ id: '4', label: 'Firma electrónica' },
	];
	const spanishTranslations = {
		Albania: 'Albania',
		Andorra: 'Andorra',
		Austria: 'Austria',
		Belarus: 'Bielorrusia',
		Belgium: 'Bélgica',
		'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
		Bulgaria: 'Bulgaria',
		Croatia: 'Croacia',
		Cyprus: 'Chipre',
		'Czech Republic': 'República Checa',
		Denmark: 'Dinamarca',
		Estonia: 'Estonia',
		Finland: 'Finlandia',
		France: 'Francia',
		Germany: 'Alemania',
		Greece: 'Grecia',
		Hungary: 'Hungría',
		Iceland: 'Islandia',
		Ireland: 'Irlanda',
		Italy: 'Italia',
		Latvia: 'Letonia',
		Liechtenstein: 'Liechtenstein',
		Lithuania: 'Lituania',
		Luxembourg: 'Luxemburgo',
		Malta: 'Malta',
		Moldova: 'Moldavia',
		Monaco: 'Mónaco',
		Montenegro: 'Montenegro',
		Netherlands: 'Países Bajos',
		'North Macedonia': 'Macedonia del Norte',
		Norway: 'Noruega',
		Poland: 'Polonia',
		Portugal: 'Portugal',
		Romania: 'Rumanía',
		Russia: 'Rusia',
		'San Marino': 'San Marino',
		Serbia: 'Serbia',
		Slovakia: 'Eslovaquia',
		Slovenia: 'Eslovenia',
		Spain: 'España',
		Sweden: 'Suecia',
		Switzerland: 'Suiza',
		Ukraine: 'Ucrania',
		'United Kingdom': 'Reino Unido',
		'Vatican City': 'Ciudad del Vaticano',
		Argentina: 'Argentina',
		Bolivia: 'Bolivia',
		Brazil: 'Brasil',
		Canada: 'Canadá',
		Chile: 'Chile',
		Colombia: 'Colombia',
		'Costa Rica': 'Costa Rica',
		Cuba: 'Cuba',
		'Dominican Republic': 'República Dominicana',
		Ecuador: 'Ecuador',
		'El Salvador': 'El Salvador',
		Guatemala: 'Guatemala',
		Honduras: 'Honduras',
		Mexico: 'México',
		Nicaragua: 'Nicaragua',
		Panama: 'Panamá',
		Paraguay: 'Paraguay',
		Peru: 'Perú',
		'Puerto Rico': 'Puerto Rico',
		Uruguay: 'Uruguay',
		'United States': 'Estados Unidos',
		Venezuela: 'Venezuela',
		'Antigua and Barbuda': 'Antigua y Barbuda',
		'British Virgin Islands': 'Islas Vírgenes Británicas',
		Greenland: 'Groenlandia',
		'Caribbean Netherlands': 'Caribe Neerlandés',
		'Cayman Islands': 'Islas Caimán',
		'Falkland Islands': 'Islas Malvinas',
		'Faroe Islands': 'Islas Feroe',
		'French Guiana': 'Guayana Francesa',
		Grenada: 'Granada',
		Martinique: 'Martinica',
		'Saint Barthélemy': 'San Bartolomé',
		'Saint Kitts and Nevis': 'San Cristóbal y Nieves',
		'Saint Lucia': 'Santa Lucia',
		'Saint Martin': 'San Martin',
		'Saint Pierre and Miquelon': 'San Pedro y Miquelón',
		'Saint Vincent and the Grenadines': 'San Vicente y las Granadinas',
		'Sint Maarten': 'San Martín',
		'Turks and Caicos Islands': 'Islas Turcas y Caicos',
		'U.S. Virgin Islands': 'Islas Vírgenes de los Estados Unidos',
		Turkey: 'Turquía',
		'Trinidad and Tobago': 'Trinidad y Tobago',
	};

	const classes = useStyles();

	const [s3Limit] = useState(4);
	const [loading, setLoading] = useState(true);
	const [enableButton, setEnableButton] = useState(true);
	const [sendElecSign, setSendElecSign] = useState(0);
	const [value, setValue] = useState('');
	const [outMail, setOutMail] = useState('');
	const [codeMail, setCodeMail] = useState('');
	const [attemps, setAttemps] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	let tokenValidate = false;
	const [errorsS3Circuito, setErrorsS3Circuito] = useState();
	let lastSigner;
	const [sendToOption, setSendToOption] = useState('email');
	// For the new select input (email or mobile)
	const [mobileNumber, setMobileNumber] = useState(''); // Nuevo estado para el número de móvil
	const [email, setEmail] = useState('');

	const [mobileNumberError, setMobileNumberError] = useState('');

	const [isElectronicSignatureSelected, setIsElectronicSignatureSelected] =
		useState(false); // To track if electronic signature is selected

	useEffect(() => {
		const fetchData = async () => {
			let result = await getCertificates(
				enqueueSnackbar,
				onExit,
				onNoCertificates,
				onFree, 
				setServices
			);
			if (result) {
				setLoading(false);
			}
		};
		fetchData();
	}, []);
	useEffect(() => {
		if (!isElectronicSignatureSelected) {
			setMobileNumber('');
			setSendToOption('email');
		}
	}, [isElectronicSignatureSelected]);
	useEffect(() => {
		if (sendToOption !== 'mobile' || sendToOption !== 'WhatsApp') {
			setMobileNumber('');
		}
	}, [sendToOption]);

	const { handleSubmit, errors, control } = useForm();
	const handleMobileNumberChange = (value, countryData) => {
		// Quita el signo + al inicio si lo hay
		const valorSinPlus = value.replace(/^\+/, '');

		// Obtiene el diaCode (indicativo) del objeto countryData
		const indicativo = countryData.dialCode;

		// Calcula el número sin el indicativo
		const numeroSinIndicativo = valorSinPlus.substring(indicativo.length);

		// Formatea el número con el guion después del indicativo
		const valorFormateado = `${indicativo}-${numeroSinIndicativo}`;

		// Establece el número de teléfono formateado
		setMobileNumber(valorFormateado);

		if (!/^\d{10,13}$/.test(valorSinPlus)) {
			setMobileNumberError(
				sendToOption === 'mobile'
					? 'El número debe contener entre 10 y 13 caracteres numéricos.'
					: 'El número WhatsApp debe contener entre 10 y 13 caracteres numéricos.'
			);
		} else {
			setMobileNumberError(''); // Limpiar el error si el formato es correcto
		}
	};

	const onSubmit = async data => {
		if (vencimiento.vence && new Date() > new Date(vencimiento.fechaVence)) {
			setErrorsS3('No se puede firmar porque el Circuito ha expirado');
			await vencerCircuito();
			setRedireccionar(true);
			dispatch(modalUps(true));
			onClose();
		}

		if (isElectronicSignatureSelected && sendToOption === 'email') {
			await handleEmailVerification();

			return;
		}

		if (isElectronicSignatureSelected && sendToOption === 'mobile') {
			await handlemobileVerification();

			return;
		}

		if (isElectronicSignatureSelected && sendToOption === 'WhatsApp') {
			await handlemobileVerification();

			return;
		}

		if (value == 3 || tokenValidate) {
			if (style) {
				const signInfo = {
					ltv: true,
					estampa: info.estampa,
				};
				let selectFiles = [];
				selected.map(sel => {
					let file = rows.find(x => x.id === sel);
					selectFiles.push(file);
				});

				getUrldocumentsToken(
					selectFiles,
					signInfo,
					enqueueSnackbar,
					history,
					onExit
				);
			} else {
				await signApply('Token', 'Token', false, false);
			}
		} else {
			if (style) {
				const signInfo = {
					idClienteFirmante: data.certificate,
					clave: data.password,
					ltv: info.ltv,
					estampa: info.estampa,
				};
				let selectFiles = [];
				selected.map(sel => {
					let file = rows.find(x => x.id === sel);
					selectFiles.push(file);
				});
				getUrldocuments(
					signInfo,
					enqueueSnackbar,
					onExit,
					history,
					dataSignatory
				);
			} else {
				const firmarS3Info = {
					ltv: info.ltv,
					estampa: info.estampa,
					documentos: info.files,

					certificateId: data.certificate,
					password: data.password,
				};
				SignCustomWithoutdocumentS3CF(
					enqueueSnackbar,
					terminaFirma,
					firmarS3Info,
					false
				);
			}
		}
	};

	const onExit = () => {
		onClose(true);
	};
	const onFree = async () => {
		setSendElecSign(1);
		setLoading(true);
		await handleEmailVerification();

		handleChange(4);
	};

	const onNoCertificates = () => {
		handleChange(4);
	};

	const dispatch = useDispatch();
	const tokenRefresh = () => {
		dispatch({ type: TOKEN_CONNECTION, payload: false });
		tokenValidate = true;
		onSubmit();
		tokenValidate = false;
	};
	const cerrarModal = () => {
		setStatusModaltoken(false);
	};
	const terminaFirma = redirect => {
		runTextScript('hideLoadingPDF()');
		if (redirect) {
			onClose();
		}
	};
	function nuevoArray(array) {
		const { nombreReal, nombre, location } = array;
		return {
			nombreReal: nombreReal,
			ruta: location,
			nombre: nombre,
		};
	}
	const newArray = dataSignatory.map(nuevoArray);

	function mergeArrays(array, dataSignatoryN) {
		const mergedArray = [];

		array.documents.forEach((obj, index) => {
			const newObj = dataSignatoryN.find(o => o.nombre === obj.nombre);
			if (newObj) {
				const { fileName, ...rest } = obj;
				mergedArray.push({
					uuid: index,
					nombreReal: fileName,
					...newObj,
					...rest,
				});
			}
		});
		return mergedArray;
	}

	let sendDatas = mergeArrays(sendData, newArray);
	sendDatas.forEach(doc => {
		doc.typeSignature.forEach((typeSig, index) => {
			typeSig.id = index;
		});
	});

	function _base64ToArrayBuffer(base64) {
		var binary_string = window.atob(base64);
		var len = binary_string.length;
		var bytes = new Uint8Array(len);
		for (var i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}
	// function putDocumentsS3(url, data) {
	// 	return new Promise(function (resolve, reject) {
	// 		let xhr = new XMLHttpRequest();
	// 		xhr.open('PUT', url, true);
	// 		xhr.onload = function () {
	// 			if (xhr.status != 200) {
	// 				reject(xhr.status);
	// 			} else {
	// 				resolve(xhr.status);
	// 			}
	// 		};
	// 		xhr.send(data);
	// 	});
	// }
	const runTextScript = text => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.innerHTML = text;
		document.body.appendChild(s);
		document.body.removeChild(s);
	};

	const S3Circuito = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		let filesPut = [];
		await Promise.all(
			dataSignatory.map(f => {
				filesPut.push({ nombreReal: f.nombreReal, nombre: f.nombre });
			})
		);
		let raw = JSON.stringify({
			application: 'FirmaYaWeb',
			repository: '1',
			ltv: false,
			estampa: false,
			firmaelectronica: false,
			documentos: filesPut,
			ruta: dataSignatory[0].location,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		// setLoading(true);
		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3Circuito`,
				requestOptions
			);
			const result_1 = await response.json();
			if (!response.ok) {
				setErrorsS3Circuito(result_1.descripcionRespuesta);
				// setLoading(false);
				throw new Error(result_1.httpStatus);
			}
			return result_1;
		} catch (error) {
			if (error >= 400 && error < 500) {
				if (error == 401) {
					// + error.response.data.descripcionRespuesta
					enqueueSnackbar('No tiene disponibilidad para firmar', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					enqueueSnackbar(errorsS3Circuito, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			} else {
				enqueueSnackbar(
					'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						style: { whiteSpace: 'pre-line' },
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			}
			terminaFirma(false);
			return false;
		}
	};

	const saveDocsDigital = async ( estructura, TokenCF ) => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + TokenCF);
			myHeaders.append('Content-Type', 'application/json');
	
			const raw = JSON.stringify(estructura);
	
			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			const response = await fetch(API_HOSTNAME_AWS + 'expdoc', requestOptions);
			const result = await response.json();
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const advancedSignRequest = async toSend => {
		const TokenCF = await localStorage.getItem('JWT');
		let myHeaders = new Headers();
		//const lineal = false
		myHeaders.append('Authorization', 'Bearer ' + TokenCF);
		myHeaders.append('Content-Type', 'application/json');

		var requestOption = {
			method: 'POST',
			headers: myHeaders,
			body: toSend,
			redirect: 'follow',
		};
		let errorAdvancedS3;
		try {
			const response = await fetch(
				`${API_HOSTNAME_SIGNS}api/sign/advancedsignatures3`,
				requestOption
			);
			const responsePut = await response.json();
			errorAdvancedS3 = responsePut;

			if (responsePut.httpStatus == 200) {
				const {codeResponse: codigoRespuesta} = responsePut
				if (codigoRespuesta == 'RS1') {
					enqueueSnackbar('Firma completa', {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					await cambiarEstadoFirmante2(circuito, firmante, 2, TokenCF);
					const result_1 = await estadoCircuito();
					lastSigner = result_1.inFirmantes.every(
						firmante => firmante.estado === 2 || firmante.estado === 5
					);
					if (result_1.circuito.documentoURL && lastSigner) {
						const estructura = {
							radicado: result_1.circuito.radicado,
							circuitCode: result_1.circuito.id,
							urlDocument: result_1.documentos.map(doc=>doc.urlGet),
							user: USUARIO_DIGITAL,
							password: PASSWORD_DIGITAL,
							urlSave: result_1.circuito.urlGuardar,
						};
						saveDocsDigital(estructura, TokenCF)
					}
					
					if (lineal) {
						if (!lastSigner) {
							await notificar2(TokenCF, circuito, 0);
						}
					}

					await notificarCreador2(lastSigner, circuito, firmante, TokenCF);
					setLoading(false);
					dispatch({ type: LOADING, payload: false });
					dispatch(modalFinished(true));
					terminaFirma(true);
					onClose();
					return;
				} else {
					responsePut.documentos.forEach(element => {
						enqueueSnackbar(element.mensaje, {
							variant: 'warning',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					});
					// setLoading(false);
					dispatch({ type: LOADING, payload: false });
					terminaFirma(false);
					return;
				}
			} else {
				if (responsePut.codigoRespuesta == 'RS7') {
					setErrorsS3('No tiene firmas disponibles, por favor aumente su plan');
					throw Error(responsePut.httpStatus);
				} else if (responsePut.codigoRespuesta == 'RS6') {
					setErrorsS3('Clave incorrecta');
					throw Error(responsePut.httpStatus);
				} else if (responsePut.codigoRespuesta === 'E2') {
					setTimeout(() => {
						advancedSignRequest(toSend);
					}, 3000);
				} else {
					setErrorsS3(responsePut.descripcionRespuesta);
					enqueueSnackbar(responsePut.descripcionRespuesta, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					setLoading(false);
					dispatch({ type: LOADING, payload: false });
				}
			}
		} catch (error) {
			if (error == 'Error: 401') {
				setLoading(false);
				dispatch({ type: LOADING, payload: false });
				dispatch(modalUps(true));
				onClose();
			} else if (error == 'Error: 400') {
				setLoading(false);
				dispatch({ type: LOADING, payload: false });
				dispatch(modalUps(true));
				onClose();
			} else {
				dispatch(modalUps(true));
				onClose();
				setLoading(false);
				dispatch({ type: LOADING, payload: false });
			}
		}
	};

	const estadoCircuito = async () => {
		const TokenCF = await localStorage.getItem('JWT');
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + TokenCF);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: circuito,
			firmante: firmante,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/Signatory',
				requestOptions
			);
			const result_1 = await response.json();
			return result_1;
		} catch (error) {
			return error;
		}
	};

	const SignCustomWithoutdocumentS3CF = async (
		enqueueSnackbar,
		terminaFirma,
		datosCerti,
		firmaelectronica
	) => {
		dispatch({ type: LOADING, payload: true });

		const result_1 = await estadoCircuito();
		if (result_1.circuito.estado == 4) {
			setRedireccionar(true);
			setErrorsS3('No se puede firmar porque el Circuito ha sido rechazado');
			dispatch(modalUps(true));
			setLoading(false);
			dispatch({ type: LOADING, payload: false });
			onClose();
		} else if (result_1.circuito.estado == 5) {
			setRedireccionar(true);
			setErrorsS3('No se puede firmar porque el Circuito ha sido eliminado');
			dispatch(modalUps(true));
			setLoading(false);
			onClose();
		} else if (result_1.circuito.estado == 3) {
			setRedireccionar(true);
			setErrorsS3('No se puede firmar porque el Circuito ha expirado');
			dispatch(modalUps(true));
			setLoading(false);
			onClose();
		} else {
			const response = await S3Circuito();
			if (response?.httpStatus === 200) {
				const listFiles = [];
				const listSign = [];

				await Promise.all(
					sendDatas.map(async f => {
						listFiles.push({
							uuid: f.uuid,
							nombreReal: f.nombreReal,
							nombre: f.nombre,
							base64: f.base64,
						});
					})
				);

				await Promise.all(
					response.documentos.map(async file => {
						var bytes = null;
						listFiles.map(f => {
							if (file.nombreReal == f.nombreReal) {
								bytes = _base64ToArrayBuffer(f.base64);
							}
						});
						if (bytes != null) {
							await Promise.all(
								sendDatas.map(async f => {
									if (file.nombreReal == f.nombreReal) {
										listSign.push({
											uuid: f.uuid,
											fileName: file.nombre,
											realFileName: file.nombreReal,
											urlGet: file.urlGet,
											urlPut: file.urlPut,
											typeSignature: f.typeSignature,
											location: file.location,
											listSigns: f.listSigns,
										});
									}
								})
							);
							// putDocumentS3 resuelve o rechaza una promesa
							// let putDocument = await putDocumentsS3(file.urlPut, bytes);
							// if (putDocument == 200) {

							// } else {
							// 	enqueueSnackbar(
							// 		'Error al cargar el archivo ' + file.nombreReal + ' en S3',
							// 		{
							// 			variant: 'error',
							// 			anchorOrigin: {
							// 				vertical: 'top',
							// 				horizontal: 'right',
							// 			},
							// 		}
							// 	);
							// }
						}
					})
				);
				const toSend = JSON.stringify({
					ltv: datosCerti.ltv,
					estampa: datosCerti.estampa,
					signerClientId: datosCerti.certificateId,
					key: encriptar(datosCerti.password, APP_KEY),
					firmaelectronica: firmaelectronica,
					codeId: datosCerti.codeId,
					documents: listSign,
					idCircuit: circuito,
				});
				advancedSignRequest(toSend);
			} else {
				setLoading(false);

				dispatch({ type: LOADING, payload: false });
			}
		}
	};

	const vencerCircuito = async () => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');

			let raw = JSON.stringify({
				circuito: circuito,
				estadoCircuito: '3',
				firmante: firmante,
				estadoFirmante: '',
				correoFirmante: '',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			const respCambioEstado = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const { result } = await respCambioEstado.json();
			return result;
		} catch (error) {
			console.error('Ocurrio un error', error);
			return null;
		}
	};

	const handleSend = async () => {
		if (value == 4 && codeMail !== '' && !mobileNumber) {
			setEnableButton(true);
			if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
				enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				return;
			}
			let firmaS3 = false;
			if (filesSize > s3Limit * 1024) {
				firmaS3 = true;
			}
			setLoading(true);
			let response = await generateCertificate(enqueueSnackbar, codeMail);

			if (response) {
				if ((response.status = 200)) {
					let datosCerti = response.data.result;

					SignCustomWithoutdocumentS3CF(
						enqueueSnackbar,
						terminaFirma,
						datosCerti,
						true
					);
					setStatusModal(false);
					return response;
				}
			}
			setAttemps(attemps + 1);
		}
		if (value == 4 && codeMail !== '' && mobileNumber) {
			setEnableButton(true);
			if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
				enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				return;
			}
			let firmaS3 = false;
			if (filesSize > s3Limit * 1024) {
				firmaS3 = true;
			}
			setLoading(true);
			let response = await generateCertificate(
				enqueueSnackbar,
				codeMail,
				mobileNumber
			);

			if (response) {
				if (response.status === 200) {
					let datosCerti = response.data.result;

					SignCustomWithoutdocumentS3CF(
						enqueueSnackbar,
						terminaFirma,
						datosCerti,
						true
					);
					setStatusModal(false);
					return response;
				}
			}
			setAttemps(attemps + 1);
		}
		setLoading(false);
	};
	const handleEmailVerification = async () => {
		setEnableButton(true);
		if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
			enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		let tokenClie = localStorage.getItem('JWT');
		let resultado = JSON.parse(window.atob(tokenClie.split('.')[1]));
		if (resultado.email.length > 2) {
			let email = resultado.email.split('@');
			let cantidad = email[0].length >= 6 ? 6 : email[0].length;
			let string = '';
			for (let i = 0; i < cantidad - 2; i++) string += '*';
			setOutMail(string + email[0].slice(-2) + '@' + email[1]);
			setEmail(resultado.email);

			setLoading(true);
			let dataMail = await mailOtpSendClient(enqueueSnackbar);
			setLoading(false);
			if (dataMail) {
				setSendElecSign(1);
			} else {
				setSendElecSign(2);
			}
		} else {
			enqueueSnackbar('Error al obtener el correo', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	};

	const handlemobileVerification = async () => {
		setEnableButton(true);
		if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
			enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		let tokenClie = localStorage.getItem('JWT');
		let resultado = JSON.parse(window.atob(tokenClie.split('.')[1]));
		if (resultado.email.length > 2) {
			let email = resultado.email.split('@');
			let cantidad = email[0].length >= 6 ? 6 : email[0].length;
			let string = '';
			for (let i = 0; i < cantidad - 2; i++) string += '*';
			setOutMail(string + email[0].slice(-2) + '@' + email[1]);
			const emailFirmante = resultado.email;

			setLoading(true);
			let dataMail = await mobileOtpSendClient(enqueueSnackbar, emailFirmante);
			setLoading(false);
			if (dataMail) {
				setSendElecSign(3);
			} else {
				setSendElecSign(2);
			}
		} else {
			enqueueSnackbar('Error al obtener el correo', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	};
	const mobileOtpSendClient = async (enqueueSnackbar, emailfirmante) => {
		const [identificator, phoneNumber] = mobileNumber.split('-');
		if (sendToOption === 'mobile') {
			try {
				const response = await axios.post(
					`${API_HOSTNAME_AWS}sendOtp/api/types/Send`,
					{
						celular: mobileNumber,
						TextoOTP:
							'Firmaya te ha enviado tu codigo para tu firma electronica, por favor, no lo compartas con nadie: ',
					}
				);
				if (response.status === 200) {
					return true;
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status >= 400 && error.response.status < 500) {
						enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					} else if (error.response.status === 500) {
						enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				} else {
					enqueueSnackbar(
						'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							style: { whiteSpace: 'pre-line' },
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				}
				return false;
			}
		}

		if (sendToOption === 'WhatsApp') {
			try {
				const response = await axios.post(
					`${API_HOSTNAME_AWS}sendOtp/api/whatsapp/Send`,
					{
						phoneNumber: phoneNumber,
						identificator: identificator,
						message: 'Tu codigo OTP es: ',
					}
				);
				if (response.status === 200) {
					return true;
				}
			} catch (error) {
				const data = error.response.data;
				if (error.response) {
					if (data.http === 400 && data.code === 'A065') {
						enqueueSnackbar(data.message, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
						setStatusModal(false);
						setStatusModal(true);
					} else if (
						error.response.status >= 400 &&
						error.response.status < 500
					) {
						enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					} else if (error.response.status === 500) {
						enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
				} else {
					enqueueSnackbar(
						'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							style: { whiteSpace: 'pre-line' },
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				}
				return false;
			}
		}
	};

	const handleChange = async id => {
		if (id == 4) {
			// Assuming '4' is the id for 'Firma electrónica'
			setIsElectronicSignatureSelected(true);
			setValue(id); // This seems to be your existing way to track the selected certificate type
			// You can remove or modify existing logic here that's specific to electronic signature if it's no longer needed
		} else {
			setIsElectronicSignatureSelected(false);
			// Your existing code for other id values
			setValue(id);
		}
		// if (id == 4) {
		// 	setEnableButton(true);
		// 	if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
		// 		enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
		// 			variant: 'error',
		// 			anchorOrigin: {
		// 				vertical: 'top',
		// 				horizontal: 'right',
		// 			},
		// 		});
		// 		return;
		// 	}
		// 	let tokenClie = localStorage.getItem('JWT');
		// 	let resultado = JSON.parse(window.atob(tokenClie.split('.')[1]));
		// 	if (resultado.email.length > 2) {
		// 		let email = resultado.email.split('@');
		// 		let cantidad = email[0].length >= 6 ? 6 : email[0].length;
		// 		let string = '';
		// 		if (cantidad > 2) {
		// 			for (let i = 0; i < cantidad - 2; i++) string += '*';
		// 			setOutMail(string + email[0].slice(-2) + '@' + email[1]);
		// 		} else {
		// 			string = cantidad === 1 ? '' : '*';
		// 			setOutMail(string + '@' + email[1]);
		// 		}
		// 		setLoading(true);
		// 		let dataMail = await mailOtpSendClient(enqueueSnackbar);
		// 		setLoading(false);
		// 		if (dataMail) {
		// 			setSendElecSign(1);
		// 		} else {
		// 			setSendElecSign(2);
		// 		}
		// 	} else {
		// 		enqueueSnackbar('Error al obtener el correo', {
		// 			variant: 'error',
		// 			anchorOrigin: {
		// 				vertical: 'top',
		// 				horizontal: 'right',
		// 			},
		// 		});
		// 	}
		// }
	};

	const handleClick = () => {
		window.open(URL_FIRMAYA_ASISTENTE_TOKEN);
	};

	const handleInputChange = event => {
		if (event !== '') {
			setEnableButton(false);
			setCodeMail(event);
		} else {
			setEnableButton(true);
			setCodeMail('');
		}
	};

	if (loading || loadingAction) return <Loading />;

	if (sendElecSign == 1) {
		return (
			<Modal
				open={isOpen}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro1'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => onClose(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={classes.textModalElectro}>
									Hemos enviado un código{' '}
									{sendToOption === 'WhatsApp' ? (
										<>
											al WhatsApp{' '}
											<span style={{ fontWeight: 'bold' }}>
												{mobileNumber.replace('-', ' ')}
											</span>
											,
										</>
									) : (
										<>
											a{' '}
											<span style={{ fontWeight: 'bold' }}>
												{mobileNumber
													? mobileNumber.replace('-', ' ')
													: outMail}
											</span>
											,
										</>
									)}
									<br></br>
									por favor ingrésalo a continuación
									{sendToOption !== 'WhatsApp' && (
										<>
											{' '}
											(puede haber llegado a tus{' '}
											{mobileNumber ? 'números' : 'correos'} no deseados).
										</>
									)}
								</Typography>

								<Controller
									as={
										<TextFieldVerify
											name='codeValidate'
											label={enableButton && 'Código de verificación'}
											variant='outlined'
											inputProps={{ style: { textAlign: 'center' } }}
											InputProps={{
												autoComplete: 'off',
												classes: { input: classes.input },
											}}
										/>
									}
									name='codeValidate'
									control={control}
									rules={Rules.required}
									className={classes.validateCode}
									onChange={([event]) => {
										handleInputChange(event.target.value);
									}}
								/>
								<Grid
									style={{ margin: '0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										id='buttonVerify'
										disabled={enableButton}
										style={{ width: '154px', height: '41px' }}
										onClick={handleSend}
									>
										Verificar
									</Button>
								</Grid>
								{sendToOption === 'mobile' && (
									<Typography
										className={classes.validateLink}
										onClick={() => {
											setSendElecSign(2);
										}}
									>
										¿No has recibido el código?
									</Typography>
								)}
								{sendToOption === 'WhatsApp' && (
									<Typography
										className={classes.validateLink}
										onClick={() => {
											setSendElecSign(2);
										}}
									>
										¿No has recibido el código?
									</Typography>
								)}
								{sendToOption === 'email' && (
									<Typography
										className={classes.validateLink}
										onClick={() => {
											setSendElecSign(2);
										}}
									>
										¿No has recibido el correo?
									</Typography>
								)}
								<Typography className={classes.textModalElectro}>
									Debes tener en cuenta que un certificado <br></br> electrónico
									no tiene validez jurídica.
								</Typography>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else if (sendElecSign == 2) {
		return (
			<Modal
				open={isOpen}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro2'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='space-between'
					>
						<IconButton
							edge='start'
							id='forwardCode'
							onClick={() => {
								mobileNumber ? setSendElecSign(3) : setSendElecSign(1);
								setEnableButton(true);
							}}
							className={'btn-forward-sign-modal'}
						></IconButton>
						<IconButton
							edge='start'
							aria-label='closeModal'
							onClick={() => onClose(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={'titlePopUpForwardMail'}>
									¡Lo haremos de nuevo!
								</Typography>
								<Typography className={classes.textModalElectro2}>
									Reenviaremos a{' '}
									<span style={{ fontWeight: 'bold' }}>
										{mobileNumber ? mobileNumber.replace('-', ' ') : email}
									</span>
									<br></br> un nuevo código de verificación.
								</Typography>
								<Grid
									style={{ margin: '29px 0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										onClick={() => {
											mobileNumber
												? handlemobileVerification()
												: handleEmailVerification();
										}}
										style={{
											width: '154px',
											height: '41px',
										}}
									>
										Aceptar
									</Button>
								</Grid>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else if (sendElecSign == 3) {
		return (
			<Modal
				open={isOpen}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro1'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => onClose(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={classes.textModalElectro}>
									<Typography className={classes.textModalElectro}>
										Hemos enviado un código{' '}
										{sendToOption === 'WhatsApp' ? (
											<>
												al WhatsApp{' '}
												<span style={{ fontWeight: 'bold' }}>
													{mobileNumber.replace('-', ' ')}
												</span>
												,
											</>
										) : (
											<>
												a{' '}
												<span style={{ fontWeight: 'bold' }}>
													{mobileNumber
														? mobileNumber.replace('-', ' ')
														: email}
												</span>
												,
											</>
										)}
										<br></br>
										por favor ingrésalo a continuación
										{sendToOption !== 'WhatsApp' && (
											<>
												{' '}
												(puede haber llegado a tus{' '}
												{mobileNumber ? 'números' : 'correos'} no deseados).
											</>
										)}
									</Typography>
								</Typography>
								<Controller
									as={
										<TextFieldVerify
											name='codeValidate'
											label={enableButton && 'Código de verificación'}
											variant='outlined'
											inputProps={{ style: { textAlign: 'center' } }}
											InputProps={{
												autoComplete: 'off',
												classes: { input: classes.input },
											}}
										/>
									}
									name='codeValidate'
									control={control}
									rules={Rules.required}
									className={classes.validateCode}
									onChange={([event]) => {
										handleInputChange(event.target.value);
									}}
								/>
								<Grid
									style={{ margin: '0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										id='buttonVerify'
										disabled={enableButton}
										style={{ width: '154px', height: '41px' }}
										onClick={handleSend}
									>
										Verificar
									</Button>
								</Grid>
								<Typography
									className={classes.validateLink}
									onClick={() => {
										setSendElecSign(2);
									}}
								>
									¿No has recibido el{' '}
									{mobileNumber
										? sendToOption === 'WhatsApp'
											? 'código'
											: 'código'
										: 'correo'}
									?
								</Typography>
								<Typography className={classes.textModalElectro}>
									Debes tener en cuenta que un certificado <br></br> electrónico
									no tiene validez jurídica.
								</Typography>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else if (statusModaltoken) {
		// setStatusModal(true)
		return (
			<Modal
				open={isOpen}
				onClose={() => {
					onClose(false);
					cerrarModal();
				}}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro2'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='space-between'
					>
						<IconButton
							edge='start'
							id='forwardCode'
							onClick={() => {
								cerrarModal();
							}}
							className={'btn-forward-sign-modal'}
						></IconButton>
						<IconButton
							edge='start'
							aria-label='closeModal'
							onClick={() => {
								onClose(false);
								cerrarModal();
							}}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography
									className={classes.textModalElectro2}
									style={{ margin: '0px 52px 0px ' }}
								>
									El asistente de firma no se está ejecutando. Ejecútelo e
									intente de nuevo.
									<br></br>
									<br></br>
									Si no cuenta con el asistente lo puede obtener en el botón
									descargar.
								</Typography>
								<Grid
									style={{ margin: '29px 0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										onClick={tokenRefresh}
										style={{ width: '154px', height: '41px', margin: '10px' }}
									>
										Aceptar
									</Button>

									<Button
										className={'btnGeneral18'}
										onClick={handleClick}
										style={{ width: '154px', height: '41px' }}
									>
										Descargar
									</Button>
								</Grid>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else {
		return (
			<Modal
				open={isOpen}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id={value == 3 ? 'paperToken' : value == 4 ? 'paperElectro' : ''}
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => onClose(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<Typography className={'titlePopUpCertificate'}>
						Certificado
					</Typography>
					<div className='content'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Controller
									as={
										<Select
											key={1}
											displayEmpty
											error={!!errors.tipoCertificado}
										>
											<MenuItem value='' className={'opionCustom'}>
												<Typography className='certificateText'>
													Seleccione
												</Typography>
											</MenuItem>
											{Object.keys(certificadoOptions).length &&
												certificadoOptions.map(c => (
													<MenuItem
														disabled={isP7 > 0 && c.label == 'Token'}
														onClick={() => handleChange(c.id)}
														key={c.id}
														value={c.id}
														className={'opionCustom'}
													>
														{c.label}
													</MenuItem>
												))}
										</Select>
									}
									name='tipoCertificado'
									rules={Rules.required}
									control={control}
									defaultValue=''
									className={'selectCustom'}
								/>
								{errors.tipoCertificado && (
									<FormHelperText
										style={{
											marginBottom: '-15px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.tipoCertificado}
									>
										{errors.tipoCertificado.message}
									</FormHelperText>
								)}
								{isElectronicSignatureSelected && (
									<>
										<Select
											value={sendToOption}
											onChange={e => setSendToOption(e.target.value)}
											displayEmpty
											className={'selectCustom'}
										>
											<MenuItem value='' disabled>
												Seleccione
											</MenuItem>
											<MenuItem value='email'>Correo electrónico</MenuItem>
											{services.sms&&   <MenuItem value='mobile'>SMS</MenuItem>}
											{services.whatsapp&& <MenuItem value='WhatsApp'>WhatsApp</MenuItem>}
										</Select>

										{isElectronicSignatureSelected &&
											sendToOption === 'mobile' && (
												<>
													<MuiPhoneNumber
														defaultCountry={'co'} // Código del país por defecto, 'co' para Colombia
														// value={mobileNumber}
														onChange={handleMobileNumberChange}
														className='selectCustomP'
														regions={['america', 'europe']}
														disableAreaCodes={true}
														autoFormat={false}
														localization={spanishTranslations}
														// Añade más props según necesites, por ejemplo para controlar estilos o funcionalidades
													/>

													{/* <TextField
														// label='Número de móvil'
														value={mobileNumber}
														onChange={handleMobileNumberChange}
														// error={mobileNumberError.length > 0}
														// helperText={mobileNumberError}
														inputProps={{ maxLength: 13 }} // Limita a 13 caracteres
														className={'selectCustom'} // Asegúrate de usar las mismas clases para la consistencia
														placeholder='Ingresar Número: ej:57310000000'
													/> */}
													{mobileNumberError && (
														<FormHelperText
															style={{ textAlign: 'center', width: '100%' }} // Asegúrate de tener width: '100%' para que el texto se centre correctamente
															error={true}
														>
															{mobileNumberError}
														</FormHelperText>
													)}
												</>
											)}
										{isElectronicSignatureSelected && sendToOption === 'WhatsApp' && (
												<>
													<MuiPhoneNumber
														defaultCountry={'co'} // Código del país por defecto, 'co' para Colombia
														// value={mobileNumber}
														onChange={handleMobileNumberChange}
														className='selectCustomP'
														regions={['america', 'europe']}
														disableAreaCodes={true}
														autoFormat={false}
														localization={spanishTranslations}
														// Añade más props según necesites, por ejemplo para controlar estilos o funcionalidades
													/>

													{/* <TextField
														// label='Número de móvil'
														value={mobileNumber}
														onChange={handleMobileNumberChange}
														// error={mobileNumberError.length > 0}
														// helperText={mobileNumberError}
														inputProps={{ maxLength: 13 }} // Limita a 13 caracteres
														className={'selectCustom'} // Asegúrate de usar las mismas clases para la consistencia
														placeholder='Ingresar Número: ej:57310000000'
													/> */}
													{mobileNumberError && (
														<FormHelperText
															style={{ textAlign: 'center', width: '100%' }} // Asegúrate de tener width: '100%' para que el texto se centre correctamente
															error={true}
														>
															{mobileNumberError}
														</FormHelperText>
													)}
												</>
											)}
									</>
								)}
								{value != 3 && !isElectronicSignatureSelected && (
									<Controller
										as={
											<Select key={1} displayEmpty error={!!errors.certificate}>
												<MenuItem value='' className={'opionCustom'}>
													<Typography className='certificateText'>
														ID
													</Typography>
												</MenuItem>
												{Object.keys(certificates).length &&
													certificates.map(c => (
														<MenuItem
															key={c.id}
															value={c.id}
															className={'opionCustom'}
														>
															<Typography style={{ maxWidth: '219px' }}>
																{certificateName(
																	c.identificador,
																	c.descripcion
																)}
															</Typography>
														</MenuItem>
													))}
											</Select>
										}
										name='certificate'
										rules={Rules.required}
										control={control}
										defaultValue=''
										className={'selectCustom'}
									/>
								)}
								{errors.certificate && (
									<FormHelperText
										style={{
											marginBottom: '-15px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.certificate}
									>
										{errors.certificate.message}
									</FormHelperText>
								)}
								{value != 3 && !isElectronicSignatureSelected && (
									<Controller
										as={
											<TextField
												placeholder='Contraseña'
												type='password'
												error={!!errors.password}
												FormHelperTextProps={{
													style: { margin: '0 0 0 5px', color: 'red' },
												}}
												InputProps={{ classes: { input: classes.resize } }}
											/>
										}
										name='password'
										control={control}
										rules={Rules.required}
										className={'selectCustom'}
									/>
								)}
								{errors.password && (
									<FormHelperText
										style={{
											marginBottom: '-20px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.password}
									>
										{errors.password.message}
									</FormHelperText>
								)}
							</FormControl>
							<br></br>
							<Grid
								style={{ margin: '20px 0px 0px' }}
								container
								justify='center'
							>
								<Button
									type='submit'
									className={'btnGeneral18'}
									disabled={
										isElectronicSignatureSelected
											? sendToOption === 'mobile' || sendToOption === 'WhatsApp'
												? mobileNumberError || mobileNumber.length === 0
												: null
											: false
									}
									style={{
										marginBottom:
											sendToOption === 'mobile' || sendToOption === 'WhatsApp'
												? '20px'
												: 'normal',
									}}
								>
									Aceptar
								</Button>
							</Grid>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	}
};

const mapStateToProps = state => ({
	certificates: state.sign.certificates,
	loadingAction: state.sign.loading,
});

const mapDispatchToProps = {
	getCertificates: getCerificatesByUser,
	firmar: firmarAction,
	firmarS3: firmarS3ActionCircuit,
	getUrldocuments: firmarS3ActionCircuit,
	firmarToken: firmarToken,
	getUrldocumentsToken: getUrldocumentsToken,
	mailOtpSendClient: mailOtpSendClient,
	generateCertificate: generateCertificadoAction,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ModalFirmaCliente)
);
