export const validateEmail = email => {
	// const re = /^[a-zA-ZñÑ0-9._%+-]+@[a-zA-ZñÑ0-9.-]+\.[a-zA-ZñÑ]{2,}$/;
	const re = new RegExp("^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$");
	if (!email) {
		return false;
	}
	const parts = email.split('@');
	if (parts.length !== 2) {
		return false;
	}
	const [user, domain] = parts;
	if (user.length > 64 || domain.length > 255) {
		return false;
	}
	if (!re.test(email)) {
		return false;
	}
	return true;
};

export const validateName = name => {
	const Name = name.trim();
	const caracteresValidos = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ, ]+$/;
	if (Name.length >= 3 && caracteresValidos.test(Name)) {
		return true;
	}
};
