import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';
import DropboxSaver from '../../assets/react-dropbox-saver';
import { API_DROPBOX_APP_KEY } from '../../redux/types/ApiConstants';
import { Dialog, ListItem, ListItemText, makeStyles } from '@material-ui/core';
 
const styles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		marginTop: '5px',
		marginRight: '5px',
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px 28px 20px 28px',
	},
	title: {
		margin: '0 auto 20px auto',
		textAlign: 'center',
		font: 'normal normal 800 22px/26px Muli',
		letterSpacing: '0px',
		color: '#E55200',
		opacity: 1,
	},
	btnContainer: {
		marginTop: '8px',
		width: '145px',
		height: '38px',
	},
	listContainer: {
		marginBottom: '5px',
	},
}));

export default function CustomizedDialogs(props) {
	const { open, setOpen, files, setFiles, setSelected, enqueueSnackbar } = props;

	const classes = styles();

	const handleClose = () => {
		setOpen(false);
		setSelected([]);
		setFiles([]);
	};

	const onSuccess = () => {
		for (let index = 0; index < files.length; index++) {
			enqueueSnackbar(`Archivo exportado con exito `,
				{
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
		}
		setFiles([]);
		setSelected([]);
	}

	const onError = () => {
		setSelected([]);
		setFiles([]);
		enqueueSnackbar('Hubo un error al cargar el archivo.', { 
			variant: 'error', 
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
		}, });
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<div className={classes.closeButton}>
				<IconButton
					className={'btn-close-sign-popup'}
					aria-label="Cerrar"
					onClick={handleClose}
				>
					<HighlightOffIcon
						color="primary"
						fontSize="large"
					/>
				</IconButton>
			</div>
			<div className={classes.dialogContent}>
				<Typography className={classes.title}>Almacenar en Dropbox</Typography >
				{files.map((file, index) => (
					<ListItem key={index} style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 0 5px 0', textAlign: 'center' }} className='contentZoneUploadList'>
						<ListItemText
							primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#000000' } }}
							primary={file.filename.match(/.{1,45}/g).join("\n")}
						/>
					</ListItem>
				))}
				<div className={classes.btnContainer}>
					<DropboxSaver
						appKey={API_DROPBOX_APP_KEY}
						files={files}
						success={onSuccess}
						error={onError}
					>
						<Button className={'btnGeneralTSA'}
							onClick={handleClose}
						>
							Aceptar
            			</Button>
					</DropboxSaver>
				</div>
			</div>
		</Dialog>
	);
}