import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	continue: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '8px 24px',
		background: '#E55200',
		borderRadius: '8px',
		fontFamily: 'Muli',
		fontSize: '16px',
		fontWeight: 600,
		textTransform: 'Capitalize !important',
		lineHeight: '22px',
		color: '#ffffff !important',
		boxShadow: ' 0px 2.76082px 0px rgba(0, 0, 0, 0.2)',
		transition: 'all 0.3s ease',
		width: 'auto', // por defecto
		'&:hover': {
			background: '#E55200',
			opacity: '0.8',
			boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
		},
		// Añade el estilo responsive
		'@media(max-width:960px)': {
			width: '100%', // ocupa el 100% en pantallas pequeñas
		},
	},
	select: {
		width: '177px',
		background: 'white',
		color: '#444444',
		fontWeight: 400,
		border: '1px solid #706F6F',
		borderRadius: 8,
		paddingLeft: 8,
		paddingTop: 10,
		paddingBottom: 10,
		paddingRight: 0,
		boxShadow: ' 0px 2.76082px 0px rgba(0, 0, 0, 0.2)',
		'&:focus': {
			borderRadius: 8,
			background: 'white',
		},
		'@media(max-width:960px)': {
			width: '100%',
		},
	},
	icon: {
		color: 'black',
		right: 12,
		position: 'absolute',
		userSelect: 'none',
		pointerEvents: 'none',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexDirection: 'row', // Por defecto es fila
		'@media(max-width:960px)': {
			flexDirection: 'column', // Cambia a columna en pantallas pequeñas
			alignItems: 'stretch', // Ajusta el contenido a la pantalla
		},
	},
}));



const ButtonsModeSign = ({ openModalFolder, setSign, sign }) => {
	const classes = useStyles();

	const files = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.files
	);

	useEffect(() => {
	 if(files.length == 0){
		setSign(0)
	 }
	}, [files])
	

	/*
	 *  Propiedades del menú desplegable
	 */
	const menuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	/*
	 *  Componente que se renderiza en el botón
	 */
	const iconComponent = props => {
		return (
			<ArrowDropDownIcon className={props.className + ' ' + classes.icon} />
		);
	};


	/*
	 *  Función que se ejecuta al cambiar el valor del select
	 */
	const handleChange = ({ target }) => {
		setSign(target.value);
	};

	return (
		<Grid container className={classes.gridContainer} spacing={2}>
			<Grid item>
				<FormControl style={{ width: '100%' }}>
					<Select
						disableUnderline
						classes={{ root: classes.select }}
						MenuProps={menuProps}
						IconComponent={iconComponent}
						value={sign}
						onChange={handleChange}
					>
						<MenuItem value={0}>Soy el único firmante</MenuItem>
						<MenuItem value={1}>Varios firmantes</MenuItem>
					</Select>
				</FormControl>	
			</Grid>
			<Grid item>
				<Button className={classes.continue} onClick={openModalFolder}>
					Continuar
					<KeyboardArrowRightIcon style={{ fontSize: '25px' }} />
				</Button>
			</Grid>
		</Grid>
	);
};

export default ButtonsModeSign;
