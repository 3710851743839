import axios from 'axios';
import {
  LOAD_INFO_VALIDATE_FILE,
  SET_LOADING,
} from '../types/ValidateDocumentType';
import { API_HOSTNAME } from '../types/ApiConstants';
import { SET_CHANGEPASSWORD } from '../types/ChangePasswordType';
import { toBase64 } from '../../helpers/FilesFunction';


axios.interceptors.request.use((config) => {
	const token = localStorage.getItem('JWT');
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
}, (error) => {
	Promise.reject(error);
});
 
export const ChangePasswordCertificate = (data) => async (dispatch) => {
    try {
        const response = await axios.post(`${API_HOSTNAME}/api/certificado/changePassword`, data);
        if(response.status == 200){
            dispatch({
				type: SET_CHANGEPASSWORD,
				payload: null,
			});
            return response.data
        }
    } catch (error) {
        return error
    }
}
