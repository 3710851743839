export const MODAL_ADD_FIRMANTES = 'MODAL_ADD_FIRMANTES';
export const MODAL_CONFIRM = 'MODAL_CONFIRM';
export const MODAL_DELETE = 'MODAL_DELETE';
export const MODAL_MESSAGE = 'MODAL_MESSAGE';
export const MODAL_SEND_MESSAGE = 'MODAL_SEND_MESSAGE';
export const MODAL_SIGNERS_INVITATION = 'MODAL_SIGNERS_INVITATION';
export const RADICADO_CIRCUITO = 'RADICADO_CIRCUITO';
export const NUMERO_CIRCUITO = 'NUMERO_CIRCUITO';
export const CREATE_CIRCUIT = 'CREATE_CIRCUIT';
export const FIRM_POSITIONS = 'FIRM_POSITIONS';
export const MESSAGES_PRIVATES = 'MESSAGES_PRIVATES ';
export const NAME_FOLDER = 'NAME_FOLDER';
export const CREATE_FIRMANTE = 'CREATE_FIRMANTE';
export const EDIT_FIRMANTE = 'EDIT_FIRMANTE';
export const DELETE_FIRMANTE = 'DELETE_FIRMANTE';
export const MODAL_REJECT = 'MODAL_REJECT';
export const MODAL_FINISHED = 'MODAL_FINISHED';
export const MODAL_HAVE_CERTIFICATE = 'MODAL_HAVE_CERTIFICATE';
export const MODAL_CONFIRM_REJECT = 'MODAL_CONFIRM_REJECT';
export const MODAL_SIGNATURE_REJECT = 'MODAL_SIGNATURE_REJECT';
export const MODAL_UPS = 'MODAL_UPS';
export const MODAL_MISSING_SIGNATURES = 'MODAL_MISSING_SIGNATURES';
export const MODAL_SIGN = 'MODAL_SIGN';
export const READ_ALL_DATA = 'READ_ALL_DATA';
export const CREATE_DATA = 'CREATE_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const DELETE_DATA = 'DELETE_DATA';
export const NO_DATA = 'NO_DATA';
export const ITEM = 'ITEM';
export const DELETE_CLICK_DER = 'DELETE_CLICK_DER';
export const MODAL_FIRMA_AVANZADA_CF = 'MODAL_FIRMA_AVANZADA';
export const FIRM_CLICK_DER = 'FIRM_CLICK_DER';
export const MODAL_GOOGLE_DRIVE = 'MODAL_GOOGLE_DRIVE';
export const GOOGLE_DRIVE_CLICK_DER = 'GOOGLE_DRIVE_CLICK_DER';
export const MODAL_DROPBOX = 'MODAL_DROPBOX';
export const DROPBOX_CLICK_DER = 'DROPBOX_CLICK_DER';
export const MODAL_ONE_DRIVE = 'MODAL_ONE_DRIVE';
export const ONE_DRIVE_CLICK_DER = 'ONE_DRIVE_CLICK_DER';
export const TOKEN_GENERICO = 'TOKEN';
export const FILES = 'FILES';
export const STEP_CIRCUIT = 'STEP_CIRCUIT';
export const DATA_QR = 'DATA_QR';
export const RESET_CF = 'RESET_CF';
export const IS_HOME = 'IS_HOME';
export const CLEAN_FILES = 'CLEAN_FILES';
export const INVALID_SESION = 'INVALID_SESION';
export const MINI_RESET_CF = 'MINI_RESET_CF';
export const CAMPOS_HECHOS = 'CAMPOS_HECHOS';