import React, { useState, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import tableMenuIcon from '../../assets/img/tableMenu.png';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
 
const useStyles = makeStyles(() => ({
    nameDocument: {
        font: 'normal normal bold 16px/16px Muli',
        color: '#4F4F4F',
        letterSpacing: '0px',
        fontSize: '16px',
    },
    documentInfo: {
        font: 'normal normal normal 14px/14px Muli',
        letterSpacing: '0px',
        lineHeight: '24px',
        color: '#4F4F4F'
    },
}));
 
const nameEstate = (name) => {
    return name ? name.includes('Certificado') ? name.replace('Certificado', 'Cert.') : name : null;
}

const TableValidationResponsive = (props) => {
    const classes = useStyles();
    const {
        handleClicTitular, rows
    } = props;
    const ref = useRef(null);
    const [optionsMenu, setOptionsMenu] = useState(false);
    const [serialNumber, setSerialNumber] = useState(0);
    
    const setOptions = (current, serialNumber) => {
        setOptionsMenu(current);
        setSerialNumber(serialNumber);
    }
    
    const cutNameDocument = (name) => {
		if(name != null){
		return(
			name.length > 20 ? (
				<Tooltip title={name}>
					<span>{name.substring(0, 20).concat('...')}</span>
				</Tooltip>
		) : <span>{name}</span>)}
	};

    return (
        <div ref={ref}>
        <TableContainer >
            <Table
                className={'tableSignedDocuments'}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                cellSpacing='0px'
            >
                <TableBody>
                    {rows.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell align="left" component="th" style={{ paddingTop: '18px', paddingBottom: '18px' }} scope="row" padding="none">
                                        <><Typography className={classes.documentInfo}>Serial: {cutNameDocument(row.serialNumber)}</Typography>
                                            <Typography className={classes.documentInfo}>Titular: {row.cn}</Typography>
                                            <Typography className={classes.documentInfo}>Resultado validación: {nameEstate(row.estadoValidacion)}</Typography>
                                        </>
                                    </TableCell>
                                    <TableCell key={row.id} align="right" style={{ verticalAlign: 'top',cursor: "pointer" }}>
                                        <img src={tableMenuIcon} onClick={(e) => setOptions(e.currentTarget, row)} alt='opciones' align="right" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
        <Menu
            open={Boolean(optionsMenu)}
            anchorEl={optionsMenu}
            onClose={() => setOptionsMenu(null)}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            PaperProps={{ style: { boxShadow: '0px 4px 4px #D3D3D3', marginLeft: '-11px' } }}
        >
            <MenuItem dense={true} onClick={() => { setOptionsMenu(null)
                handleClicTitular(serialNumber)}}> <Typography className={classes.nameDocument}>Detalles del certificado</Typography>
            </MenuItem>
        </Menu>
        </div>
    )
};

export default TableValidationResponsive;