import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { theme } from '../theme/FirmaYa';
import { URL_LOGIN } from '../redux/types/ApiConstants';
import Store from '../store';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AuthRoute from './AuthRoute';
import NotFound from '../components/NotFound';
import Firmar from '../views/Firmar';
import Validate from '../views/Validate';
import ConsultSignedDocument from '../views/ConsultSignedDocument/ConsultSignedDocuments';
import Decrypt from '../views/EncryptDecrypt/Decrypt';
import Encrypt from '../views/EncryptDecrypt/Encrypt';
import Descargas from '../views/Descarga/descarga';
import ValidateOTP from '../views/ValidateOTP/headerContent'
import Dashboard from '../views/Home/Dashboard';
import ModalCertificates from '../views/Home/ModalCertificates';
import NewViewFirmar from '../views/Firmar/CircuitoFirmas/NewViewFirma';
import ViewMessage from '../views/Firmar/CircuitoFirmas/ViewMessage';
import CircuitoDeFirmas from '../views/Firmar/CircuitoFirmas/CircuitoDeFirmas';
import PantallaCarga from '../views/Firmar/CircuitoFirmas/PantallaCarga';
import DetalleCircuito from '../views/Firmar/CircuitoFirmas/DetalleCircuito';
import SignerCF from '../views/Firmar/CircuitoFirmas/SignerCF';
import CadesSignature from '../views/Firmar/FirmaCades/CadesSignature';
 
const store = Store();
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key);
}


const App = () => (

	<ThemeProvider theme={responsiveFontSizes(theme)}>
		<CssBaseline />
		<SnackbarProvider maxSnack={3} ref={notistackRef}
			action={(key) => (
				<IconButton onClick={onClickDismiss(key)}>X</IconButton>
			)}>
			<Provider store={store}>
				<BrowserRouter>
					<Switch>
						<Route exact path="/" render={() => window.location = URL_LOGIN} />
						<AuthRoute exact path="/Auth/:token" />
						<PrivateRoute exact path="/home" component={Dashboard} />
						<PrivateRoute exact path="/home/firmar" component={Firmar} />
						<PrivateRoute exact path="/home/validate" component={Validate} />
						<PrivateRoute exact path="/home/ConsultSignedDocument" component={ConsultSignedDocument} />
						<PrivateRoute exact path="/home/Decrypt" component={Decrypt} />
						<PrivateRoute exact path="/home/Encrypt" component={Encrypt} />
						<PrivateRoute exact path="/home/DetalleCircuito/:id" component={DetalleCircuito}/>
						<PrivateRoute exact path="/home/certificates" component={ModalCertificates} />
						<PrivateRoute exact path="/home/FirmarCircuito" component={SignerCF}/>
						<PrivateRoute exact path="/home/CircuitoFirmas" component={CircuitoDeFirmas}/>
						<PrivateRoute exact path="/home/cades" component={CadesSignature}/>
						<PublicRoute exact path="/descargas" component={Descargas} />
						<PublicRoute exact path="/validateOTP/:code" component={ValidateOTP} />	
						<PublicRoute exact path="/circuito/:direccion" component={PantallaCarga} />
						<Route component={NotFound} />
					</Switch>
				</BrowserRouter>
			</Provider>
		</SnackbarProvider>
	</ThemeProvider>
);

export default App;
