import {
	CAMPOS_HECHOS,
	CLEAN_FILES,
	CREATE_DATA,
	CREATE_FIRMANTE,
	DATA_QR,
	DELETE_CLICK_DER,
	DELETE_DATA,
	DROPBOX_CLICK_DER,
	FILES,
	FIRM_CLICK_DER,
	FIRM_POSITIONS,
	GOOGLE_DRIVE_CLICK_DER,
	INVALID_SESION,
	IS_HOME,
	ITEM,
	LOADING,
	MESSAGES_PRIVATES,
	MINI_RESET_CF,
	MODAL_ADD_FIRMANTES,
	MODAL_CONFIRM,
	MODAL_CONFIRM_REJECT,
	MODAL_DELETE,
	MODAL_DROPBOX,
	MODAL_FINISHED,
	MODAL_FIRMA_AVANZADA_CF,
	MODAL_GOOGLE_DRIVE,
	MODAL_HAVE_CERTIFICATE,
	MODAL_MESSAGE,
	MODAL_MISSING_SIGNATURES,
	MODAL_ONE_DRIVE,
	MODAL_REJECT,
	MODAL_SEND_MESSAGE,
	MODAL_SIGN,
	MODAL_SIGNATURE_REJECT,
	MODAL_SIGNERS_INVITATION,
	MODAL_UPS,
	NAME_FOLDER,
	NO_DATA,
	NUMERO_CIRCUITO,
	ONE_DRIVE_CLICK_DER,
	RADICADO_CIRCUITO,
	READ_ALL_DATA,
	RESET_CF,
	STEP_CIRCUIT,
	TOKEN_GENERICO,
	UPDATE_DATA,
} from '../types/CircuitoFirmasTypes';

const INITIAL_STATE = {
	modalDelete: false,
	modalConfirm: false,
	modalAddFirmante: false,
	modalMessage: false,
	modalSignersInvitation: false,
	radicado: '',
	circuito: '',
	modalSendMessage: false,
	modalReject: false,
	modalConfirmReject: false,
	modalUps: false,
	modalFinished: false,
	modalHaveCertificate: false,
	modalSignatureReject: false,
	modalMissingSignatures: false,
	modalSign: false,
	modalFirmaAvanzadaCf: false,
	tokenGenerico: '',
	firmantes: [],
	carpeta: ' ',
	db: [],
	item: '',
	deleteClickDer: [],
	firmClickDer: [],
	googleDriveClickDer: [],
	oneDriveClickDer: [],
	dropboxClickDer: [],
	modalGoogleDrive: false,
	modalOneDrive: false,
	modalDropbox: false,
	stepCircuit: 0,
	dataQR: '',
	files: [],
	home: false,
	invalidSesion: false,
	camposHechos: [],   
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MODAL_ADD_FIRMANTES:
			return {
				...state,
				modalAddFirmante: action.payload,
			};
		case MODAL_CONFIRM:
			return {
				...state,
				modalConfirm: action.payload,
			};
		case MODAL_DELETE:
			return {
				...state,
				modalDelete: action.payload,
			};
		case MODAL_MESSAGE:
			return {
				...state,
				modalMessage: action.payload,
			};
		case NUMERO_CIRCUITO:
			return {
				...state,
				circuito: action.payload,
			};
		case RADICADO_CIRCUITO:
			return {
				...state,
				radicado: action.payload,
			};
		case MODAL_SEND_MESSAGE:
			return {
				...state,
				modalSendMessage: action.payload,
			};
		case MODAL_SIGNERS_INVITATION:
			return {
				...state,
				modalSignersInvitation: action.payload,
			};
		case FIRM_POSITIONS:
			return {
				...state,
				firmantes: action.payload,
			};
		case MESSAGES_PRIVATES:
			const correos = action.payload.form;
			const html = action.payload.message;
			const newMessage = state.firmantes.map(firmante => {
				if (correos.includes(firmante.Correo)) {
					return { ...firmante, mensajePriv: html };
				}
				return firmante;
			});
			return {
				...state,
				firmantes: newMessage,
			};
		case NAME_FOLDER:
			return {
				...state,
				carpeta: action.payload,
			};
		case CREATE_FIRMANTE:
			console.log(action.payload);
			return {
				...state,
				firmantes: [...state.firmantes, action.payload],
			};
		case MODAL_REJECT:
			return {
				...state,
				modalReject: action.payload,
			};
		case MODAL_CONFIRM_REJECT:
			return {
				...state,
				modalConfirmReject: action.payload,
			};
		case MODAL_UPS:
			return {
				...state,
				modalUps: action.payload,
			};
		case MODAL_FINISHED:
			return {
				...state,
				modalFinished: action.payload,
			};
		case MODAL_HAVE_CERTIFICATE:
			return {
				...state,
				modalHaveCertificate: action.payload,
			};
		case MODAL_SIGNATURE_REJECT:
			return {
				...state,
				modalSignatureReject: action.payload,
			};
		case MODAL_MISSING_SIGNATURES:
			return {
				...state,
				modalMissingSignatures: action.payload,
			};
		case MODAL_SIGN:
			return {
				...state,
				modalSign: action.payload,
			};
		case READ_ALL_DATA: {
			return {
				...state,
				db: action.payload.map(data => data),
			};
		}
		case CREATE_DATA:
			return {
				...state,
				db: [...state.db, action.payload],
			};
		case UPDATE_DATA: {
			let newData = state.db.map(el =>
				el.id === action.payload.id ? action.payload : el
			);
			return {
				...state,
				db: newData,
			};
		}
		case DELETE_DATA: {
			let newData = state.db.filter(el => el.idCircuito !== action.payload);
			return {
				...state,
				db: newData,
			};
		}
		case NO_DATA:
			return INITIAL_STATE;
		case ITEM:
			return {
				...state,
				item: action.payload,
			};
		case DELETE_CLICK_DER:
			return {
				...state,
				deleteClickDer: action.payload,
			};
		case MODAL_FIRMA_AVANZADA_CF:
			return {
				...state,
				modalFirmaAvanzadaCf: action.payload,
			};
		case FIRM_CLICK_DER:
			return {
				...state,
				firmClickDer: action.payload,
			};
		case GOOGLE_DRIVE_CLICK_DER:
			return {
				...state,
				googleDriveClickDer: action.payload,
			};
		case MODAL_GOOGLE_DRIVE:
			return {
				...state,
				modalGoogleDrive: action.payload,
			};
		case DROPBOX_CLICK_DER:
			return {
				...state,
				dropboxClickDer: action.payload,
			};
		case MODAL_DROPBOX:
			return {
				...state,
				modalDropbox: action.payload,
			};
		case ONE_DRIVE_CLICK_DER:
			return {
				...state,
				oneDriveClickDer: action.payload,
			};
		case MODAL_ONE_DRIVE:
			return {
				...state,
				modalOneDrive: action.payload,
			};
		case TOKEN_GENERICO:
			return {
				...state,
				tokenGenerico: action.payload,
			};
		case FILES:
			return {
				...state,
				files: action.payload,
			};
		case CLEAN_FILES:
			return {
				...state,
				files: [],
			};
		case STEP_CIRCUIT:
			return {
				...state,
				stepCircuit: action.payload,
			};
		case DATA_QR:
			return {
				...state,
				dataQR: action.payload,
			};
		case RESET_CF:
			return {
				...state,
				dataQR: '',
				radicado: '',
				circuito: '',
				firmantes: [],
				carpeta: ' ',
				home: false,
				files:[],
				stepCircuit: 0,
				camposHechos: [],
			};
		case CAMPOS_HECHOS:
			return {
				...state,
				camposHechos: action.payload,
			};
		case MINI_RESET_CF:
			return {
				...state,
				// dataQR: "",
				radicado: '',
				circuito: '',
				// firmantes: [],
				// carpeta: ' ',
				home: false,
				// files:[],
				stepCircuit: 0,
			};
		case IS_HOME:
			return {
				...state,
				home: action.payload,
			};
		case INVALID_SESION:
			return {
				...state,
				invalidSesion: action.payload,
			};
		default:
			return state;
	}
};
