import React, {useState} from "react";
import LinearProgress from "@mui/joy/LinearProgress";
const LineaProgreso = (props) => {
const {firmasTotales, firmasRealizadas} = props;

  return (
    <>
      <LinearProgress
        determinate
        value={(firmasRealizadas * 100) / firmasTotales}
        variant="soft"
        size="lg"
        sx={{
          width: "70%",
          marginLeft: "10px",
          backgroundColor: "#C6C6C6",
          color: "#058D78",
        }}
      />
      <p
        style={{
          fontSize: "11px",
          padding: "0px",
          margin: "0px",
          marginLeft: "10px",
          justifyContent: "left",
          textAlign: "left",
          textColor: "#4F4F4F",
        }}
      >
        {firmasRealizadas} / {firmasTotales}
      </p>
    </>
  );
};
export default LineaProgreso;