import React from 'react';
import { APL_GOOGLE_DRIVE_CLIENT_ID, API_GOOGLE_DRIVE_API_KEY, SCOPES } from '../../../redux/types/ApiConstants';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import GooglePicker from 'react-google-picker';
import { getBase64OfSelectedFileArr, UploadDocumentToDriveCF,  } from '../../../redux/actions/ConsultSignedDocumentsAction';
import drive from '../../../assets/img/googledriveIconHover.png';
import { Button, IconButton, Typography } from '@material-ui/core';

const GoogleDrive = (props) => {
    let i = 0

  return (
    <GooglePicker clientId={APL_GOOGLE_DRIVE_CLIENT_ID}
				developerKey={API_GOOGLE_DRIVE_API_KEY}
				scope={[SCOPES]}
				onChange={data => console.log('on change:', data)}
				onAuthFailed={data => console.log('on auth failed:', data)}
				multiselect={false}
				navHidden={false}
				authImmediate={false}
				mimeTypes={['application/vnd.google-apps.folder']}
				viewId={'FOLDERS'}
				createPicker={(google, oauthToken) => {
					const docsView = new google.picker.DocsView()
						.setIncludeFolders(true)
						.setMimeTypes('application/vnd.google-apps.folder')
						.setSelectFolderEnabled(true)
						.setOwnedByMe(true);
					const picker = new window.google.picker.PickerBuilder()
						.addView(docsView)
						.setLocale('es')
						.setTitle('Seleccione el folder donde desea guardar el archivo')
						.setOAuthToken(oauthToken)
						.setDeveloperKey(API_GOOGLE_DRIVE_API_KEY)
						.setCallback((response) => {
							var folderID = (response.action === "picked") ? response.docs[0].id : '';
							if (folderID !== '') {
								var rowsSelected = props.selected;
								var rows = props.rows1;
								const lista = [];
								rowsSelected.forEach((valor) => {
									lista.push(rows.find((n) => n.idCircuito === valor));
								});
                                const documentList = lista.map(el => el.documentos).flat();
								props.UploadDocumentToDriveCF(documentList, oauthToken, folderID, props.enqueueSnackbar);
								props.setSelected([]);
							}
						});
					picker.build().setVisible(true);
					props.setStatusModalGoogleDrive(false);
				}}>
				{props.iconBlack != true ? (
					<IconButton type="button" id="btnPick" className={'buttonGoogleDriveExplorerCF'} ></IconButton>
				) : (
					<p className={'buttonGoogleDriveExplorerBlackCF'} id="btnPick" type="button">Google Drive</p>
				)}
	</GooglePicker>
  )
}

const mapStateToProps = (state) => ({
	loading: state.consultSignedDocument.loading,
	consultReducer: state.consultSignedDocument,
});

const mapDispatchToProps = {
	obtenerBase64Arr: getBase64OfSelectedFileArr,
	UploadDocumentToDriveCF: UploadDocumentToDriveCF,
};

export default connect(mapStateToProps, mapDispatchToProps)((withSnackbar(GoogleDrive)));