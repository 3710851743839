const CryptoJS = require('crypto-js');

export const encriptar = (dataToEncrypt, key) => {
	//Me recibe el valor a encriptar y la llave para cifrar
	// Convierte la cadena de texto a un objeto WordArray
	let dataWordArray = CryptoJS.enc.Utf8.parse(dataToEncrypt);
	// Cifra la información utilizando AES
	let encryptedData = CryptoJS.AES.encrypt(dataWordArray, key);
	// El resultado es un objeto CipherParams
	let encryptedDataResult = encryptedData.toString();
	// console.log(encryptedDataResult);
	return encryptedDataResult;
};

export const desencriptar = (dataToDesencrypt, key) =>{
	//Solo se le debe enviar la parte cifrada, sin ninguna otra parte de la URL, y la llave para desifrar. 
	//busca todos los guiones "-" en la cadena dataToDesencrypt y los reemplaza con el carácter "+". y todos los "_" y los reemplaza con el caracter "/". No recuerdo porque se hace asi. 
	const procesar = dataToDesencrypt.replace(/[-]/g, '+').replace(/[_]/g, '/');
	//Desencripta la informacion utilizando la llave 
	let decifrado = CryptoJS.AES.decrypt(procesar, key);
	//Me da el valor resultante 
	let salida = decifrado.toString(CryptoJS.enc.Utf8);
	// console.log(salida);
}


export const encriptarForUrl = (dataToEncrypt, key) => {
	    // Convertir la cadena de texto a un objeto WordArray
		let dataWordArray = CryptoJS.enc.Utf8.parse(dataToEncrypt);
		// Cifrar la información utilizando AES
		let encryptedData = CryptoJS.AES.encrypt(dataWordArray, key);
		// El resultado es un objeto CipherParams
		let encryptedDataResult = encryptedData.toString();
		// Reemplazar caracteres especiales
		encryptedDataResult = encryptedDataResult.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
		// Codificar el resultado para que sea seguro en una URL
		let encodedEncryptedData = encodeURIComponent(encryptedDataResult);
		return encodedEncryptedData;
};