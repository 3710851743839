import React, { Component }  from 'react';
import ReactDOM from 'react-dom';

export default class ButtomPanelSuccesfull extends Component{

    constructor(props) {

       
        super(props);
        this.state = {
            filename:'',
            base64: "mainCaptcha",
            texto: ""
        }
      }

      componentDidMount() {
         
      }
    
    viewDocument = ()=>{
        document.getElementById("ifrmDocument").src="data:application/pdf;base64," + document.getElementById("hdBase64").value 
        var options = {
            height: "800px",
            width: "100%"
        };
        this.setState((state) => {
            return {
                file:false,  
            }
          });
       
    }
    downloadDocument = ()=>{
        var link=document.createElement('a');
       link.href=URL.createObjectURL(this.props.file)
       console.log("PRopiedades",this.props)
       link.download=this.props.fileName ;
       link.click();
    }
    
    
  
    render() {
        return (
            <div>
                
           
                <div  id="dvControlSucces" style={{display : 'none'}}  className="col-sm-6" >
                    <div className="col-sm-9" style={{display : 'none'}}  >
                        <input id="btnViewer" type="button" className="btnClass form-control" value="Ver documento" onClick={this.viewDocument}/>                       
                    </div>
                    <br/>    
                    <div className="col-sm-9">
                    <input id="btnDownload" type="button" className="btnClass" value="Descargar" onClick={this.downloadDocument}/>
                    </div>
                </div>
                {/*onLoadSuccess={onDocumentLoadSuccess}
                 
                <Document
                    file={URL.createObjectURL(this.state.file)}                    
                >
                 <Page pageNumber={this.state.pageNumber} />
               </Document>
               <p>Page {this.state.pageNumber} de {this.state.numPages}</p>*/}
              
            </div>

                                          
                   
    ) 
    }
}


