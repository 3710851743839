/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loading from '../components/Loading';
import { getPlainToken } from '../redux/actions/auth';

const AuthRoute = ({ computedMatch, ...rest }) => {
	const [circuito, setCircuito] = useState(null);
	const [tokenSinCircuito, setTokenSinCircuito] = useState(null);
    const regex = /[?&]circuito=(\d+)$/;
    const { token } = computedMatch.params;
    const match = token.match(regex);
	const dispatch = useDispatch();

	useEffect(() => {
        if (match && match[1]) {
            setCircuito(match[1]);
            setTokenSinCircuito(token.replace(regex, ''));
        } else {
            setTokenSinCircuito(token);
        }

    }, [token, match]);

	if (tokenSinCircuito) dispatch(getPlainToken(tokenSinCircuito, circuito));

    return (
        <Route {...rest} render={() => <Loading />} />
    );
};

export default AuthRoute;