import {
	Grid,
	Container,
	Paper,
	makeStyles,
	Button,
	Modal,
	TableSortLabel,
	TextField,
	withStyles,
	Tooltip,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ups from '../../../assets/img/ups.png';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import icoCerrar from '../../../assets/img/icono-cerrar.svg';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { API_HOSTNAME_AWS, APP_KEY, SPECIFIC_USERS } from '../../../redux/types/ApiConstants';
import Atras from '../../../assets/img/iconLeft2.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import folder from '../../../assets/img/Icon-material-folder.png';
import { useSnackbar } from 'notistack';
import Loading from '../../../components/Loading';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useSelector } from 'react-redux';
import {
	actualizarCircuito,
	circuitoFinalizado,
	notificar2,
} from '../../../redux/actions/CircuitActions';

const useStyle = makeStyles({
	menu: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		flexDirection: 'row',
	},
	modal2: {
		margin: 0,
		padding: '40px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		// justifyContent: "center",
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px',
		width: '535px',
		// height: "310px",
		'&:focus': {
			outline: 'none',
		},
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: '20px 0 0 0',
		lineHeight: '30px',
	},
	title2: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: '0',
		lineHeight: '30px',
		marginTop: '-40px',
	},
	fields: {
		width: '100%',
		height: '36px',
		border: '1px solid grey',
		borderRadius: '7px',
		boxShadow: '0 1px 3px 0 rgba(0,0,0,0.46)',
		marginTop: '8px',
		marginBottom: '27px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		marginTop: '40px',
	},
	flx: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		marginTop: '24px',
		gap: '10px',
		width: '100%',
		marginBottom: '10px',
	},
	parrafo: {
		fontSize: '16px',
		textAlign: 'center',
		margin: '24px 0 0 0',
	},
	continue: {
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		borderRadius: '10px',
		fontWeight: '800',
		border: 'none !important',
		fontWeight: 'bold !important',
		marginTop: '20px !important',
		fontSize: '16px !important',
		padding: '5px 25px ',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'@media(max-width:700px)': {
			padding: '5px 15px',
		},
	},
	modal: {
		margin: 0,
		padding: '40px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px',
		width: '434px',
		height: '336px',
		'&:focus': {
			outline: 'none',
		},
	},
	iconBack: {
		cursor: 'pointer',
		paddingRight: '10px',
	},
	nameDocuments: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		height: '22px',
		width: '100%',
	},
	root: {
		width: '100%',
		overflowX: 'auto',
	},
	table: {
		alignItems: 'left',
		textAlign: 'left',
		minWidth: 650,
	},
	tableHead: {
		background: '#FFEFD8 0% 0% no-repeat !important',
		borderRadius: '10px !important',
		opacity: 1,
		font: 'normal normal bold 11px/16px Muli',
		color: '#E55200',
		maxHeight: '16px !important',
	},
	tableCellNumber: {
		borderRadius: '50%',
		color: '#4F4F4F',
		backgroundColor: '#DEDEDE',
		width: '30px',
		height: '30px',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderColor: 'transparent',
	},
	tableCell: {
		textAlign: 'center',
	},
	tableCell2: {
		textAlign: 'center',
		backgroundColor: '#FFEFD8',
		color: '#E55200',
		margin: '0px',
		padding: '0px',
		fontWeight: 'bold',
	},
	tableCellBoton: {
		textAlign: 'center',
		width: '5px',
		marginLeft: '0',
		paddingLeft: '0',
		backgroundColor: '#FFEFD8',
		color: '#E55200',
	},
	continueNo: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
	},
	btnCerrar: {
		color: '#E55200',
		padding: '8px 24px',
		fontSize: '16px',
		cursor: 'pointer',
		border: '1px solid #E55200',
		borderRadius: '8px',
		backgroundColor: 'transparent',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
	},
	btnClose: {
		position: 'relative',
		top: '-40px',
		right: '-250px',
	},
	nameDocuments: {
		fontSize: '16px',
		margin: '0px',
		backgroundColor: 'transparent',
		width: '80%',
		border: 'none',
		fontWeight: 'bold',
	},
	estados: {
		border: '1px solid',
		margin: '0 3px',
		borderRadius: '10px',
		// color: "white",
		fontSize: '11px',
		padding: '2px 0',
		width: '80px',
		margin: 'auto',
	},
	sinEspacios: {
		margin: 0,
		padding: 0,
		maxWidth: '320px',
		overflowX: 'auto',
	},
	textField: {
		[`& fieldset`]: {
			borderRadius: '10px',
		},
		borderColor: 'black !important',
	},
});

const DetalleCircuito = () => {
	const history = useHistory();
	const classes = useStyle();
	const [firmantesTabla, setFirmantesTabla] = useState([]);
	const [fechaCreacion, setFechaCreacion] = useState('');
	const [getFila, setFila] = useState(0);
	const [folderName, setFolderName] = useState('');
	const [docs, setDocs] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const jwt = localStorage.getItem('JWT');
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [email, setEmail] = useState('');
	const ITEM_HEIGHT = 36;
	const [errorValidation, setErrorValidation] = useState('');
	const [textValidation, setTextValidation] = useState('');
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [result, setResult] = useState();
	const [isValid, setIsValid] = useState(false);
	const { id } = useParams();
	const CryptoJS = require('crypto-js');
	const key = APP_KEY;
	const cifrado = id;
	const procesar = cifrado.replace(/[-]/g, '+').replace(/[_]/g, '/');
	let decifrado = CryptoJS.AES.decrypt(procesar, key);
	let salida = decifrado.toString(CryptoJS.enc.Utf8);
	const detalleCircuito = salida.split('&')[0].split('=')[1];
	const estadoCircuito = salida.split('=')[2];
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const [posicionMenor, setPosicionMenor] = useState(1);
	const [vencido, setVencido] = useState(false)

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	}, []);

	useEffect(() => {
		//   Llamada a la api para consulta de los datos del estado del circuito con sus firmantes
		fetchData();
	}, []);

	useEffect(() => {
		if (isValid) {
			setBtnDisabled(false);
		}
		if (email.length < 1) {
			setBtnDisabled(true);
		}
		if (email.length < 1 || isValid) {
			// El correo electrónico es válido
			setErrorValidation(false);
			setTextValidation('');
		} else {
			// El correo electrónico es inválido
			setBtnDisabled(true);
			setErrorValidation(true);
			setTextValidation('Escribe un email válido');
		}
	}, [email]);
	
	async function fetchData() {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');
		let raw = JSON.stringify({
			circuito: detalleCircuito,
		});
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		try {
			setLoading(true);
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/CircuitSpec',
				requestOptions
			);
			const result = await response.json();
			if (result.responseCode === 'E91') {
				enqueueSnackbar(
					'No es posible ver el detalle del circuito ya que este usuario no corresponde al usuario creador',
					{
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				//history.push('/home');
				
				if (userInfo.userDigitalWare) {
					history.push('/home');
				} else {
					history.push('/home/CircuitoFirmas');
				}
			}
			setResult(result);
			if (result.circuito.vence === true && new Date() > new Date(result.circuito.fechaVence) || result.circuito.estado === 3 ){
				if(result.circuito.estado === 2 || result.circuito.estado === 4 || result.circuito.estado === 5 ){

				}else if (result.circuito.estado === 1 ){
					await vencerCircuito(jwt, result.circuito.id)
					setVencido(true)
				}else{
					setVencido(true)
				}
				
			 }
			let a = result.circuito.fechaCreacion;
			let b = a.split('T')[0].split('-').join('/');
			let dateArray = b.split('/');
			let newDateFormat =
				dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
			let c = result.inFirmantes.filter(
				item => item.estado != 5 && item.estado != 3
			);
			const menorPosicion = c.reduce((minPosicion, objeto) => {
				return objeto.posicion < minPosicion ? objeto.posicion : minPosicion;
			}, c[0].posicion);
			setPosicionMenor(menorPosicion);
			setLoading(false);
			setDocs(result.documentos);
			setFechaCreacion(newDateFormat);
			setFolderName(result.circuito.carpeta);
			setFirmantesTabla(c);
		} catch (error) {
			console.error('error', error);
		}
	}

	const vencerCircuito = async (token, circuito) => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');

			let raw = JSON.stringify({
				circuito: circuito,
				estadoCircuito: '3',
				firmante: "",
				estadoFirmante: '',
				correoFirmante: '',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			const respCambioEstado = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const { result } = await respCambioEstado.json();
			return result;
		} catch (error) {
			console.error('Ocurrio un error', error);
			return null;
		}
	};

	const handleClickMenu = event => {
		if(vencido){
			return
		}
		setFila(event.currentTarget.id);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const convertirHora = fecha => {
		let hora24 = fecha.substring(11, 19);
		let hora = parseInt(hora24.substring(0, 2));
		let minutos = hora24.substring(3, 5);
		let segundos = hora24.substring(6, 8);
		let ampm = hora >= 12 ? 'pm' : 'am';
		hora = hora % 12;
		hora = hora ? hora : 12; // si la hora es 0, conviértela a 12 en formato de 12 horas
		hora = hora < 10 ? '0' + hora : hora; // agregar 0 por delante en horas menores a 10
		let hora12 = hora + ':' + minutos + ':' + segundos + ' ' + ampm;
		return hora12;
	};
	const eliminarFirmante = async () => {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: detalleCircuito,
			estadoCircuito: '',
			firmante: firmantesTabla[getFila].idfirmante,
			estadoFirmante: '5',
			correoFirmante: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const result_1 = await response.json();
			if (!response.ok) {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				//Los firmantesActivos son firmantesTabla.length -1
				//filtrar los que estan en estado completado y saber la cantidad, saber tambien cuantos hay en estado leido.
				//Si los que estan en estado completado = firmantesActivos && firmantesleidos = 0,
				//quiere decir que se elimino la persona que faltaba para completar el circuito, "Completar circuito y enviar notificacion"
				//si firmantesActivos = 0 quiere ddecir que eliminamos al unico firmante que habia, "EliminarCircuito"
				//Si elimino al firmante cuya posicion sea la menor firmantesTabla[getFila].posicion, debo enviarle la notificacion de firma al siguiente firmante

				enqueueSnackbar(`Se ha eliminado el firmante correctamente!`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});

				const firmantesActivosTabla = firmantesTabla.length - 1;
				const firmantesCompletados = firmantesTabla.filter(
					item => item.estado === 2
				).length;
				const firmantesActivos = firmantesTabla.filter(
					item => item.estado === 1
				);
				const firmantesLeidos = firmantesTabla.filter(
					item => item.estado === 6
				).length;
				const posicionFirmanteEliminado = firmantesTabla[getFila].posicion;
				const posicionActivos = firmantesActivos.map(item => item.posicion);
				const minimaPosicionActivos = Math.min(...posicionActivos);
				let circuitoEliminado = false;
				// const posicionPrimerFirmanteActivo =

				//Este if verifica si se elimino un firmante y los demas estaban en completado, para en ese caso completar el circuito
				if (
					firmantesActivosTabla === firmantesCompletados &&
					firmantesLeidos === 0 &&
					firmantesActivosTabla !== 0
				) {
					actualizarCircuito(detalleCircuito, jwt, '2');
					//Notificar de que todos firmaron
					circuitoFinalizado(detalleCircuito, jwt);
				} else if (
					posicionFirmanteEliminado === minimaPosicionActivos &&
					firmantesActivos.length > 1
				) {
					notificar2(jwt, detalleCircuito, 0);
				} else if (firmantesActivosTabla === 0) {
					actualizarCircuito(detalleCircuito, jwt, '5');
					circuitoEliminado = true;
					enqueueSnackbar(`Ya no quedan firmantes, circuito eliminado`, {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}

				setTimeout(() => {
					if (circuitoEliminado) {
						history.push('/home/CircuitoFirmas');
					} else {
						window.location.reload();
					}
				}, 500);
				handleClose();
			}
		} catch (error) {
			return console.error('error', error);
		}
	};
	const handleChange = event => {
		setEmail(event.target.value);
		setIsValid(validateEmail(event.target.value));
	};

	const reenviar = async () => {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			Circuito: detalleCircuito,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/notify',
				requestOptions
			);
			const result_1 = await response.json();
			if (!response.ok) {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				throw Error(response.statusText);
			} else {
				enqueueSnackbar(`Se ha reenviado correctamente!`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
			return setLoading(false);
		} catch (error) {
			return console.error('error', error);
		}
	};

	const reenviarNoLineal = async firmante => {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			Circuito: detalleCircuito,
			firmante: firmante,
			notificacion: 0,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/notify',
				requestOptions
			);
			const result_1 = await response.json();
			if (!response.ok) {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				throw Error(response.statusText);
			} else {
				enqueueSnackbar(`Se ha reenviado correctamente!`, {
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
			return setLoading(false);
		} catch (error) {
			return console.error('error', error);
		}
	};

	const validateEmail = email => {
		const re = /^[a-zA-ZñÑ0-9._%+-]+@[a-zA-ZñÑ0-9.-]+\.[a-zA-ZñÑ]{2,}$/;
		if (!email) {
			return false;
		}
		const parts = email.split('@');
		if (parts.length !== 2) {
			return false;
		}
		const [user, domain] = parts;
		if (user.length > 64 || domain.length > 255) {
			return false;
		}
		if (!re.test(email)) {
			return false;
		}
		return true;
	};
	async function verificarEstadoObservador(idFirmante) {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');
		let raw = JSON.stringify({
			circuito: detalleCircuito,
		});
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/CircuitSpec',
				requestOptions
			);
			const result = await response.json();
			const firmante = result.inFirmantes.find(
				firmante => firmante.idfirmante === idFirmante
			);
			if (firmante && firmante.observador !== 2) {
				return true; // esObservador es diferente de 2
			}
		} catch (error) {
			console.error('Error al verificar estado del observador', error);
		}
		return false; // esObservador es 2 o hubo un error
	}

	const guardarNewCorreo = async () => {
		setLoading(true);

		const puedeEditar = await verificarEstadoObservador(
			firmantesTabla[getFila].idfirmante
		);

		if (!puedeEditar) {
			enqueueSnackbar(
				'No se puede editar, el observador ya vio el documento.',
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
			setLoading(false);
			return;
		}

		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: detalleCircuito,
			estadoCircuito: '',
			firmante: firmantesTabla[getFila].idfirmante,
			estadoFirmante: '',
			correoFirmante: email,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		const usuarioExistente = firmantesTabla.find(
			firmante => firmante.email === email
		);

		if (email == firmantesTabla[getFila].email) {
			enqueueSnackbar('Estas ingresando el mismo correo.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			setLoading(false);
		} else if (usuarioExistente) {
			enqueueSnackbar('Este correo ya existe en el circuito de firmas', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			setLoading(false);
		} else {
			try {
				const response = await fetch(
					API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
					requestOptions
				);
				const result_1 = await response.json();
				if (!response.ok) {
					if (response.status == 400) {
						enqueueSnackbar('El correo ingresado no es válido.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
						throw Error('El correo ingresado no es valido ');
					} else if (response.status == 500) {
						enqueueSnackbar(
							'Error interno en la aplicación, por favor comuníquese con un administrador o inténtelo de nuevo más tarde.',
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
						throw Error('Error interno en la aplicación');
					} else {
						enqueueSnackbar('Error al actualizar el correo.', {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
						throw Error('Error al actualizar el correo');
					}
				} else {
					enqueueSnackbar(`Se ha actualizado el correo correctamente!`, {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (error) {
				setLoading(false);
				console.error('error', error);
			}
		}
	};

	const IconClose = () => {
		return <img src={icoCerrar} />;
	};
	return (
		<Grid container spacing={0} direction='row'>
			<Container component='main' maxWidth='lg'>
				<h1 className={'title-background'}>Mis circuitos de firmas</h1>
			</Container>
			<Container component='main' maxWidth='lg'>
				<Grid
					container
					spacing={0}
					direction='row'
					justifyContent='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
					maxWidth='md'
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper elevation={0} className='paper2-upload'>
							<div className={classes.menu}>
								<img
									onClick={() => history.push('/home/CircuitoFirmas')}
									className={classes.iconBack}
									src={Atras}
								/>
								<h2 style={{ color: '#E55200' }}>Detalles </h2>
							</div>
							<div>
								<p style={{ fontSize: '9px', marginBottom: '0px' }}>
									{fechaCreacion}
								</p>
								{docs.length > 1 ? (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<div className={classes.nameDocuments}>
											<img
												src={folder}
												style={{ width: '18px', height: '16px' }}
											/>
											<input
												className={classes.nameDocuments}
												value={folderName}
												disabled
											/>
										</div>
										<ul style={{ margin: '0px 0px 30px' }}>
											{docs.map(documento => (
												<li
													key={documento.id}
													className='listDocuments'
													style={{ height: '20px' }}
												>
													{documento.nombre}
												</li>
											))}
										</ul>
									</div>
									{vencido&&
										<div>
										<h2 style={{ color: '#E42D1E', textDecoration:"underline"}}>Circuito Vencido </h2>
									</div>
									}

									</div>
									
								) : (
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div>
										{docs.map(documento => (
											<input
												style={{ marginBottom: '15px' }}
												className={classes.nameDocuments}
												value={documento.nombre}
												disabled
											/>
										))}
									</div>
									{vencido&&
										<div>
										<h2 style={{ color: 'red'}}>Circuito Vencido </h2>
									</div>
									}
									</div>
									
								)}
							</div>
							<hr color='#C1C1C1' size='1'></hr>
							<h3>Firmantes</h3>
							<div className={classes.root}>
								<Table className={classes.table} aria-label='simple table'>
									{/* Creacion cabezera de tabla */}
									<TableHead className={classes.tableHead}>
										<TableRow>
											<TableCell className={classes.tableCell2}></TableCell>
											<TableCell
												className={classes.tableCell2}
												style={{ textAlign: 'left' }}
												width={'37%'}
											>
												Firmante
											</TableCell>
											<TableCell
												className={classes.tableCell2}
												width={'3%'}
											></TableCell>
											<TableCell
												className={classes.tableCell2}
												style={{ textAlign: 'left' }}
												width={'34%'}
											>
												Última Modificación
											</TableCell>
											<TableCell
												className={classes.tableCell2}
												style={{ textAlign: 'center' }}
												width={'20%'}
											>
												Estado
											</TableCell>
											<TableCell
												className={classes.tableCellBoton}
												align='left'
											></TableCell>
										</TableRow>
									</TableHead>

									{/* Creacion cuerpo de tabla */}
									<TableBody>
										{firmantesTabla
											.sort((a, b) => a.posicion - b.posicion)
											.map((firmante, index) => (
												<>
													{firmante.estado === 4 ? (
														<>
															<TableRow
																key={firmante}
																style={{ borderBottom: 'unset' }}
															>
																<TableCell
																	padding='none'
																	style={{ borderBottom: 'none' }}
																>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			display: 'flex',
																			minWidth: '55px',
																			justifyContent: 'center',
																			alignItems: 'center',
																		}}
																	>
																		<div className={classes.tableCellNumber}>
																			<b>{index + 1}</b>
																		</div>
																	</div>
																</TableCell>
																<TableCell
																	padding='none'
																	style={{ borderBottom: 'none' }}
																>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			maxWidth: '410px',
																			overflowX: 'auto',
																		}}
																	>
																		<b>{firmante.nombre}</b>
																		<p style={{ margin: '0px' }}>
																			{firmante.email}
																		</p>
																	</div>
																</TableCell>
																<TableCell padding='none' style={{ borderBottom: 'none' }}>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			maxWidth: '30px',
																		}}
																	>
																		<div>
																			{firmante.aprobador === 0 ? (
																				<b>{}</b>
																			) : (
																				<Tooltip title='Aprobador' arrow>
																					<CheckCircleIcon
																						style={{
																							color: 'primary',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																			{firmante.observador === 1 && (
																				<Tooltip title='Observador' arrow>
																					<VisibilityIcon
																						style={{
																							color: 'primary',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																			{firmante.observador === 2 && (
																				<Tooltip title='Observado' arrow>
																					<VisibilityIcon
																						style={{
																							color: '#e55302',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																		</div>
																	</div>
																</TableCell>
																<TableCell
																	padding='none'
																	className={classes.tableCell}
																	align='right'
																	style={{ borderBottom: 'none' }}
																>
																	<div style={{ textAlign: 'left' }}>
																		{firmante.fechaActualizacion === '' ? (
																			''
																		) : (
																			<>
																				{firmante.fechaActualizacion
																					.split(' ')[0]
																					.replace(/-/g, '/')
																					.split('/')
																					.reverse()
																					.join('/')}
																				<br />
																				{convertirHora(
																					firmante.fechaActualizacion
																				)}
																			</>
																		)}
																	</div>
																</TableCell>
																<TableCell align='center' padding='none' style={{ borderBottom: 'none' }}>
																	{firmante.observador !== 0 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#e55200', // Color naranja
																				color: 'white',
																			}}
																		>
																			Observador
																		</div>
																	) : firmante.estado === 1 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#D1D1D1',
																				color: '#727272',
																			}}
																		>
																			Por firmar
																		</div>
																	) : firmante.estado === 6 ? (
																		<div
																			className={classes.estados}
																			style={{
																				borderColor: '#F5892D',
																				backgroundColor: '#F5892D',
																				color: 'white',
																			}}
																		>
																			Leído
																		</div>
																	) : firmante.estado === 4 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#DD0640',
																				color: 'white',
																			}}
																		>
																			Rechazado
																		</div>
																	) : firmante.estado === 2 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#058D78',
																				color: 'white',
																			}}
																		>
																			Completado
																		</div>
																	) : firmante.estado === 3 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: 'red',
																				color: 'white',
																			}}
																		>
																			Expirado
																		</div>
																	) : firmante.estado === 5 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: 'orange',
																				color: 'white',
																			}}
																		>
																			Eliminado
																		</div>
																	) : (
																		<p></p>
																	)}
																</TableCell>
																{/* Boton opciones de estado del Firmante */}
																{firmante.estado === 1 ||
																firmante.estado === 6 ? (
																	<TableCell
																		padding='none'
																		style={{ borderBottom: 'none' }}
																	>
																		{firmantesTabla[getFila].estado === 1 &&
																		estadoCircuito !== 4 ? (
																			<div>
																				<IconButton
																					aria-label='more'
																					id={index}
																					aria-controls={
																						open ? 'long-menu' : undefined
																					}
																					aria-expanded={
																						open ? 'true' : undefined
																					}
																					aria-haspopup='true'
																					onClick={handleClickMenu}
																				>
																					<MoreVertIcon />
																				</IconButton>
																				<Menu
																					id='long-menu'
																					MenuListProps={{
																						'aria-labelledby': 'long-button',
																					}}
																					anchorEl={anchorEl}
																					open={open}
																					onClose={handleClose}
																					PaperProps={{
																						style: {
																							maxHeight: ITEM_HEIGHT * 4.5,
																							width: '20ch',
																						},
																					}}
																				>
																					<MenuItem
																						onClick={() => {
																							setShowModal(true);
																							setAnchorEl(null);
																						}}
																					>
																						<ListItemIcon>
																							<ModeIcon fontSize='small' />
																							<ListItemText
																								sx={{ marginLeft: '20px' }}
																							>
																								Editar
																							</ListItemText>
																						</ListItemIcon>
																					</MenuItem>
																					{firmantesTabla[getFila].posicion ===
																						posicionMenor && (
																						<MenuItem
																							onClick={() => {
																								setAnchorEl(null);
																								reenviar();
																							}}
																						>
																							<ListItemIcon>
																								<ShortcutIcon fontSize='small' />
																								<ListItemText
																									sx={{ marginLeft: '20px' }}
																								>
																									Reenviar
																								</ListItemText>
																							</ListItemIcon>
																						</MenuItem>
																					)}
																					{firmantesTabla[getFila].posicion >
																						posicionMenor &&
																						(firmantesTabla[getFila - 1]
																							.estado === 2 ||
																							firmantesTabla[getFila - 1]
																								.estado === 3 ||
																							firmantesTabla[getFila - 1]
																								.estado === 4 ||
																							firmantesTabla[getFila - 1]
																								.estado === 5) && (
																							<MenuItem
																								onClick={() => {
																									setAnchorEl(null);
																									reenviar();
																								}}
																							>
																								<ListItemIcon>
																									<ShortcutIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Reenviar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																						)}
																					<MenuItem
																						onClick={() => {
																							setShowModalDelete(true);
																							setAnchorEl(null);
																						}}
																					>
																						<ListItemIcon>
																							<DeleteIcon fontSize='small' />
																							<ListItemText
																								sx={{ marginLeft: '20px' }}
																							>
																								Eliminar
																							</ListItemText>
																						</ListItemIcon>
																					</MenuItem>
																				</Menu>
																			</div>
																		) : firmantesTabla[getFila].estado === 6 &&
																		  estadoCircuito !== 4 ? (
																			<div>
																				<IconButton
																					aria-label='more'
																					id={index}
																					aria-controls={
																						open ? 'long-menu' : undefined
																					}
																					aria-expanded={
																						open ? 'true' : undefined
																					}
																					aria-haspopup='true'
																					onClick={handleClickMenu}
																				>
																					<MoreVertIcon />
																				</IconButton>
																				<Menu
																					id='long-menu'
																					MenuListProps={{
																						'aria-labelledby': 'long-button',
																					}}
																					anchorEl={anchorEl}
																					open={open}
																					onClose={handleClose}
																					PaperProps={{
																						style: {
																							maxHeight: ITEM_HEIGHT * 4.5,
																							width: '20ch',
																						},
																					}}
																				>
																					<MenuItem onClick={() => reenviar()}>
																						<ListItemIcon>
																							<ShortcutIcon fontSize='small' />
																							<ListItemText
																								sx={{ marginLeft: '20px' }}
																							>
																								Reenviar
																							</ListItemText>
																						</ListItemIcon>
																					</MenuItem>
																				</Menu>
																			</div>
																		) : (
																			<div>
																				<IconButton
																					aria-label='more'
																					id={index}
																					aria-controls={
																						open ? 'long-menu' : undefined
																					}
																					aria-expanded={
																						open ? 'true' : undefined
																					}
																					aria-haspopup='true'
																					onClick={handleClickMenu}
																				>
																					<MoreVertIcon />
																				</IconButton>
																			</div>
																		)}
																	</TableCell>
																) : (
																	<TableCell style={{ borderBottom: 'none' }} />
																)}
															</TableRow>
															<TableRow>
																<TableCell
																	padding='none'
																	style={{ borderTop: 'none' }}
																></TableCell>
																<TableCell
																	colSpan={2}
																	padding='none'
																	style={{ borderTop: 'none' }}
																>
																	<p
																		style={{
																			margin: '4px 0 0px 0',
																			padding: '0',
																		}}
																	>
																		<b>Motivo del rechazo: </b>
																	</p>
																	<p
																		style={{
																			margin: '0 0 4px 0',
																			padding: '0',
																		}}
																	>
																		{firmante.motivo}
																	</p>
																</TableCell>
																<TableCell
																	padding='none'
																	style={{ borderTop: 'none' }}
																></TableCell>
																<TableCell
																	padding='none'
																	style={{ borderTop: 'none' }}
																></TableCell>
																<TableCell
																	padding='none'
																></TableCell>
															</TableRow>
														</>
													) : result.circuito.estado === 4 || result.circuito.estado === 3 ? (
														<>
															<TableRow
																key={firmante}
																style={{ borderBottom: 'unset' }}
															>
																<TableCell padding='none'>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			display: 'flex',
																			minWidth: '55px',
																			justifyContent: 'center',
																			alignItems: 'center',
																		}}
																	>
																		<div className={classes.tableCellNumber}>
																			<b>{index + 1}</b>
																		</div>
																	</div>
																</TableCell>
																<TableCell padding='none'>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			maxWidth: '410px',
																			overflowX: 'auto',
																		}}
																	>
																		<b>{firmante.nombre}</b>
																		<p style={{ margin: '0px' }}>
																			{firmante.email}
																		</p>
																	</div>
																</TableCell>
																<TableCell padding='none'>
																	<div
																		style={{
																			margin: '0px',
																			padding: '5px 0',
																			maxWidth: '30px',
																		}}
																	>
																		<div>
																			{firmante.aprobador === 0 ? (
																				<b>{}</b>
																			) : (
																				<Tooltip title='Aprobador' arrow>
																					<CheckCircleIcon
																						style={{
																							color: 'primary',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																			{firmante.observador === 1 && (
																				<Tooltip title='Observador' arrow>
																					<VisibilityIcon
																						style={{
																							color: 'primary',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																			{firmante.observador === 2 && (
																				<Tooltip title='Observado' arrow>
																					<VisibilityIcon
																						style={{
																							color: '#e55302',
																							fontSize: '1.5rem',
																						}}
																					/>
																				</Tooltip>
																			)}
																		</div>
																	</div>
																</TableCell>
																<TableCell
																	padding='none'
																	className={classes.tableCell}
																	align='right'
																>
																	<div style={{ textAlign: 'left' }}>
																		{firmante.fechaActualizacion === '' ? (
																			''
																		) : (
																			<>
																				{firmante.fechaActualizacion
																					.split(' ')[0]
																					.replace(/-/g, '/')
																					.split('/')
																					.reverse()
																					.join('/')}
																				<br />
																				{convertirHora(
																					firmante.fechaActualizacion
																				)}
																			</>
																		)}
																	</div>
																</TableCell>
																<TableCell align='center' padding='none'>
																	{firmante.observador !== 0 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#e55200', // Color naranja
																				color: 'white',
																			}}
																		>
																			Observador
																		</div>
																	) : firmante.estado === 1 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#D1D1D1',
																				color: '#727272',
																			}}
																		>
																			Por firmar
																		</div>
																	) : firmante.estado === 6 ? (
																		<div
																			className={classes.estados}
																			style={{
																				borderColor: '#F5892D',
																				backgroundColor: '#F5892D',
																				color: 'white',
																			}}
																		>
																			Leído
																		</div>
																	) : firmante.estado === 4 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#DD0640',
																				color: 'white',
																			}}
																		>
																			Rechazado
																		</div>
																	) : firmante.estado === 2 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: '#058D78',
																				color: 'white',
																			}}
																		>
																			Completado
																		</div>
																	) : firmante.estado === 3 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: 'red',
																				color: 'white',
																			}}
																		>
																			Expirado
																		</div>
																	) : firmante.estado === 5 ? (
																		<div
																			className={classes.estados}
																			style={{
																				backgroundColor: 'orange',
																				color: 'white',
																			}}
																		>
																			Eliminado
																		</div>
																	) : (
																		<p></p>
																	)}
																</TableCell>
																{/* Boton opciones de estado del Firmante */}

																<TableCell />
															</TableRow>
														</>
													) : (
														<TableRow
															key={firmante}
															style={{ borderBottom: 'unset' }}
														>
															<TableCell padding='none'>
																<div
																	style={{
																		margin: '0px',
																		padding: '5px 0',
																		display: 'flex',
																		minWidth: '55px',
																		justifyContent: 'center',
																		alignItems: 'center',
																	}}
																>
																	<div className={classes.tableCellNumber}>
																		<b>{index + 1}</b>
																	</div>
																</div>
															</TableCell>
															<TableCell padding='none'>
																<div
																	style={{
																		margin: '0px',
																		padding: '5px 0',
																		maxWidth: '410px',
																		overflowX: 'auto',
																	}}
																>
																	<b>{firmante.nombre}</b>
																	<p style={{ margin: '0px' }}>
																		{firmante.email}
																	</p>
																</div>
															</TableCell>
															<TableCell padding='none'>
																<div
																	style={{
																		margin: '0px',
																		padding: '5px 0',
																		maxWidth: '30px',
																	}}
																>
																	<div>
																		{firmante.aprobador === 0 ? (
																			<b>{}</b>
																		) : (
																			<Tooltip title='Aprobador' arrow>
																				<CheckCircleIcon
																					style={{
																						color: 'primary',
																						fontSize: '1.5rem',
																					}}
																				/>
																			</Tooltip>
																		)}
																		{firmante.observador === 1 && (
																			<Tooltip title='Observador' arrow>
																				<VisibilityIcon
																					style={{
																						color: 'primary',
																						fontSize: '1.5rem',
																					}}
																				/>
																			</Tooltip>
																		)}
																		{firmante.observador === 2 && (
																			<Tooltip title='Observado' arrow>
																				<VisibilityIcon
																					style={{
																						color: '#e55302',
																						fontSize: '1.5rem',
																					}}
																				/>
																			</Tooltip>
																		)}
																	</div>
																</div>
															</TableCell>
															<TableCell
																padding='none'
																className={classes.tableCell}
																align='right'
															>
																<div style={{ textAlign: 'left' }}>
																	{firmante.fechaActualizacion === '' ? (
																		''
																	) : (
																		<>
																			{firmante.fechaActualizacion
																				.split(' ')[0]
																				.replace(/-/g, '/')
																				.split('/')
																				.reverse()
																				.join('/')}
																			<br />
																			{convertirHora(
																				firmante.fechaActualizacion
																			)}
																		</>
																	)}
																</div>
															</TableCell>
															<TableCell align='center' padding='none'>
																{firmante.observador !== 0 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: '#e55200', // Color naranja
																			color: 'white',
																		}}
																	>
																		Observador
																	</div>
																) : firmante.estado === 1 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: '#D1D1D1',
																			color: '#727272',
																		}}
																	>
																		Por firmar
																	</div>
																) : firmante.estado === 6 ? (
																	<div
																		className={classes.estados}
																		style={{
																			borderColor: '#F5892D',
																			backgroundColor: '#F5892D',
																			color: 'white',
																		}}
																	>
																		Leído
																	</div>
																) : firmante.estado === 4 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: '#DD0640',
																			color: 'white',
																		}}
																	>
																		Rechazado
																	</div>
																) : firmante.estado === 2 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: '#058D78',
																			color: 'white',
																		}}
																	>
																		Completado
																	</div>
																) : firmante.estado === 3 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: 'red',
																			color: 'white',
																		}}
																	>
																		Expirado
																	</div>
																) : firmante.estado === 5 ? (
																	<div
																		className={classes.estados}
																		style={{
																			backgroundColor: 'orange',
																			color: 'white',
																		}}
																	>
																		Eliminado
																	</div>
																) : (
																	<p></p>
																)}
															</TableCell>
															{/* Boton opciones de estado del Firmante */}
															{result?.circuito.estado !== 2 && (
																<>
																	{result?.circuito.tipoCircuito === 'lineal' &&
																		(firmante.estado === 1 ||
																		firmante.estado === 6 ? (
																			<TableCell padding='none'>
																				{firmantesTabla[getFila].estado === 1 &&
																				estadoCircuito !== 4 ? (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																						<Menu
																							id='long-menu'
																							MenuListProps={{
																								'aria-labelledby':
																									'long-button',
																							}}
																							anchorEl={anchorEl}
																							open={open}
																							onClose={handleClose}
																							PaperProps={{
																								style: {
																									maxHeight: ITEM_HEIGHT * 4.5,
																									width: '20ch',
																								},
																							}}
																						>
																							<MenuItem
																								onClick={() => {
																									setShowModal(true);
																									setAnchorEl(null);
																								}}
																							>
																								<ListItemIcon>
																									<ModeIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Editar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																							{firmantesTabla[getFila]
																								.posicion == posicionMenor && (
																								<MenuItem
																									onClick={() => {
																										setAnchorEl(null);
																										reenviar();
																									}}
																								>
																									<ListItemIcon>
																										<ShortcutIcon fontSize='small' />
																										<ListItemText
																											sx={{
																												marginLeft: '20px',
																											}}
																										>
																											Reenviar
																										</ListItemText>
																									</ListItemIcon>
																								</MenuItem>
																							)}
																							{firmantesTabla[getFila]
																								.posicion > posicionMenor &&
																								(firmantesTabla[getFila - 1]
																									.estado === 2 ||
																									firmantesTabla[getFila - 1]
																										.estado === 3 ||
																									firmantesTabla[getFila - 1]
																										.estado === 4 ||
																									firmantesTabla[getFila - 1]
																										.estado === 5) && (
																									<MenuItem
																										onClick={() => {
																											setAnchorEl(null);
																											reenviar();
																										}}
																									>
																										<ListItemIcon>
																											<ShortcutIcon fontSize='small' />
																											<ListItemText
																												sx={{
																													marginLeft: '20px',
																												}}
																											>
																												Reenviar
																											</ListItemText>
																										</ListItemIcon>
																									</MenuItem>
																								)}
																							<MenuItem
																								onClick={() => {
																									setShowModalDelete(true);
																									setAnchorEl(null);
																								}}
																							>
																								<ListItemIcon>
																									<DeleteIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Eliminar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																						</Menu>
																					</div>
																				) : firmantesTabla[getFila].estado ===
																						6 && estadoCircuito !== 4 ? (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																						<Menu
																							id='long-menu'
																							MenuListProps={{
																								'aria-labelledby':
																									'long-button',
																							}}
																							anchorEl={anchorEl}
																							open={open}
																							onClose={handleClose}
																							PaperProps={{
																								style: {
																									maxHeight: ITEM_HEIGHT * 4.5,
																									width: '20ch',
																								},
																							}}
																						>
																							<MenuItem
																								onClick={() => {
																									setAnchorEl(null);
																									reenviar();
																								}}
																							>
																								<ListItemIcon>
																									<ShortcutIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Reenviar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																						</Menu>
																					</div>
																				) : (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																					</div>
																				)}
																			</TableCell>
																		) : (
																			<TableCell />
																		))}
																</>
															)}
															{result?.circuito.estado !== 2 && (
																<>
																	{result?.circuito.tipoCircuito ===
																		'no lineal' &&
																		(firmante.estado === 1 ||
																		firmante.estado === 6 ? (
																			<TableCell padding='none'>
																				{firmantesTabla[getFila].estado === 1 &&
																				estadoCircuito !== 4 ? (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																						<Menu
																							id='long-menu'
																							MenuListProps={{
																								'aria-labelledby':
																									'long-button',
																							}}
																							anchorEl={anchorEl}
																							open={open}
																							onClose={handleClose}
																							PaperProps={{
																								style: {
																									maxHeight: ITEM_HEIGHT * 4.5,
																									width: '20ch',
																								},
																							}}
																						>
																							<MenuItem
																								onClick={() => {
																									setShowModal(true);
																									setAnchorEl(null);
																								}}
																							>
																								<ListItemIcon>
																									<ModeIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Editar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																							<MenuItem
																								onClick={() => {
																									setAnchorEl(null);
																									reenviarNoLineal(
																										firmantesTabla[getFila]
																											.idfirmante
																									);
																								}}
																							>
																								<ListItemIcon>
																									<ShortcutIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Reenviar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																							<MenuItem
																								onClick={() => {
																									setShowModalDelete(true);
																									setAnchorEl(null);
																								}}
																							>
																								<ListItemIcon>
																									<DeleteIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Eliminar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																						</Menu>
																					</div>
																				) : firmantesTabla[getFila].estado ===
																						6 && estadoCircuito !== 4 ? (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																						<Menu
																							id='long-menu'
																							MenuListProps={{
																								'aria-labelledby':
																									'long-button',
																							}}
																							anchorEl={anchorEl}
																							open={open}
																							onClose={handleClose}
																							PaperProps={{
																								style: {
																									maxHeight: ITEM_HEIGHT * 4.5,
																									width: '20ch',
																								},
																							}}
																						>
																							<MenuItem
																								onClick={() => {
																									setAnchorEl(null);
																									reenviarNoLineal(
																										firmantesTabla[getFila]
																											.idfirmante
																									);
																								}}
																							>
																								<ListItemIcon>
																									<ShortcutIcon fontSize='small' />
																									<ListItemText
																										sx={{ marginLeft: '20px' }}
																									>
																										Reenviar
																									</ListItemText>
																								</ListItemIcon>
																							</MenuItem>
																						</Menu>
																					</div>
																				) : (
																					<div>
																						<IconButton
																							aria-label='more'
																							id={index}
																							aria-controls={
																								open ? 'long-menu' : undefined
																							}
																							aria-expanded={
																								open ? 'true' : undefined
																							}
																							aria-haspopup='true'
																							onClick={handleClickMenu}
																						>
																							<MoreVertIcon />
																						</IconButton>
																					</div>
																				)}
																			</TableCell>
																		) : (
																			<TableCell />
																		))}
																</>
															)}
														</TableRow>
													)}
												</>
											))}
									</TableBody>
								</Table>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: '10px',
								}}
							>
								{/* <Button className={classes.continue}>Descargar Informe</Button> */}
							</div>
							{loading && <Loading />}
							{showModal && (
								<Modal
									open={true}
									onClose={handleClose}
									disableBackdropClick
									aria-labelledby='simple-modal-title'
									aria-describedby='simple-modal-description'
								>
									{loading ? (
										<Loading />
									) : (
										<Paper className={classes.modal2}>
											<IconButton
												className={classes.btnClose}
												onClick={() => {
													handleClose();
													setEmail('');
													setBtnDisabled(true);
													setErrorValidation(false);
													setTextValidation('');
													setShowModal(false);
												}}
											>
												<IconClose />
											</IconButton>
											<h2 className={classes.title2}>Editar firmante</h2>
											<p className={classes.parrafo}>
												Puedes editar únicamente el correo electrónico de tu
												firmante
											</p>
											<div className={classes.flx}>
												<button className={classes.tableCellNumber}>
													<b>{firmantesTabla[getFila].posicion}</b>
												</button>
												<b>{firmantesTabla[getFila].nombre}</b>
											</div>
											<TextField
												error={errorValidation}
												className={classes.textField}
												fullWidth
												inputProps={{
													style: {
														fontSize: '14px',
														borderRadius: '10px !important',
														padding: '12px',
													},
												}}
												label='Email'
												value={email}
												onChange={handleChange}
												helperText={textValidation}
												variant='outlined'
											/>
											<Button
												className={classes.continue}
												disabled={btnDisabled}
												style={{ height: '41px', color: 'white' }}
												onClick={() => guardarNewCorreo()}
											>
												Guardar
											</Button>
										</Paper>
									)}
								</Modal>
							)}
							{showModalDelete && (
								<Modal
									open={true}
									onClose={handleClose}
									disableBackdropClick
									aria-labelledby='simple-modal-title'
									aria-describedby='simple-modal-description'
								>
									{loading ? (
										<Loading />
									) : (
										<div className={classes.modal}>
											<img src={ups} />
											<h4 className={classes.title}> Eliminar firmante</h4>
											<p className={classes.parrafo}>
												¿Estas seguro de eliminar este firmante de manera
												permanente?
											</p>
											<div className={classes.flex}>
												<button
													onClick={() => {
														eliminarFirmante();
													}}
													className={classes.btnCerrar}
												>
													Si
												</button>
												<button
													className={classes.continueNo}
													onClick={() => {
														handleClose();
														setShowModalDelete(false);
													}}
												>
													No
												</button>
											</div>
										</div>
									)}
								</Modal>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
};

export default DetalleCircuito;
