import React, { useState, useEffect } from 'react';
import { toBase64 } from '../../helpers/FilesFunction';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Paper, Button, Container, Grid, Modal} from '@material-ui/core';
import ZoneUploadDecrypt from '../../components/ZoneUploadDecrypt';
import Loading from '../../components/Loading';
import ZoneUploadRecipient from '../../components/ZoneUploadRecipient';
import { encrypt } from '../../redux/actions/EncryptDecryptAction';
import { useDispatch } from "react-redux";
import { GET_CERTIFICATES } from '../../redux/types/EncryptDecryptTypes';
import ModalCorrect from './ModalCorrect';
 
const Encrypt = (props) => {
	const [isResponsive, setResponsive] = useState(window.innerWidth <= 600 ? false : true);
	const { encrypt } = props;
	const [data, setData] = useState({
		documentos: [],
		certificados: []
	})
	const dispatch = useDispatch();
	const [statusModalCorrect, setStatusModalCorrect] = useState(false);
	const [selectedCerts, setSelectedCerts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	window.addEventListener('resize', function (event) {
		if (this.window.innerWidth <= 600) setResponsive(false);
		else setResponsive(true);
	}, true);

	const code = async () => {
		if(files.length > 0 && selectedCerts.length > 0){
			let newdata= { ...data };
			await Promise.all(files.map(async file => {
				let documento = new Object();
				documento.base64 = (await toBase64(file)).split(',').pop();
				documento.nombre = file.name;
				newdata.documentos.push(documento);
			}));
			await Promise.all(selectedCerts.map(async cert => {
				let certificado = new Object();
				certificado.base64 = cert.base64;
				certificado.userId = cert.userId;
				newdata.certificados.push(certificado);
			}));
			setData(newdata);
			setLoading(true);
			var correct = await encrypt(enqueueSnackbar, newdata);
			setLoading(false);
			clear();
			if(correct) setStatusModalCorrect(true);
		}else if(files.length < 1) {
			enqueueSnackbar('Debe agregar por lo menos un archivo a cifrar', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}else if(selectedCerts.length < 1){
			enqueueSnackbar('Debe agregar por lo menos un destinatario', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
	}

	const clear = () => {
		setFiles([]);
		setSelectedCerts([]);
		setData({
			documentos: [],
			certificados: []
		});
		dispatch({
			type: GET_CERTIFICATES,
			payload: "",
		});
	};
	
	useEffect(() => {
		if(files.length < 1) setSelectedCerts([]);
	}, [files]);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});

	document.body.className = 'bodyMiddle';
	return (
		<>	{loading && <Loading />}
			<Grid container spacing={0} direction="row">
				<Container component="main" maxWidth="lg">
					<h1 className={'title-background'}>Cifrar archivos</h1>
				</Container>
				<Container component="main">
                    <Grid
						container
						spacing={0}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className="zone-upload-container"
						maxWidth="md"
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Paper elevation={0} id={ isResponsive && 'paper-upload-result' } className="paper-upload" >
								<>
                                    <ZoneUploadDecrypt
					                    key={1}
										isEncrypt={true}
					                    setFiles={setFiles}
					                    files={files}
					                    sizeFiles={10}
					                    className={'contentZoneUpload'}
										setLoading={setLoading}
					                    isResponsive={props.isResponsive}
				                    />
									<div id="box-botonera" style={{ marginTop: '20px', opacity: files.length < 1 ? 0.3 : 1 }}>
                                        <h1 className={'subtitleRecipient'}>Lista de destinatarios</h1>
                                        <ZoneUploadRecipient
					                        className={'contentZoneUpload'}
											selectedCerts={selectedCerts}
											setSelectedCerts={setSelectedCerts}
					                        button={files.length < 1}											
											isResponsive={props.isResponsive}
				                        />
                                        <Grid
                                            container
                                            spacing={3}
                                            direction="row"
                                            style={{paddingBottom:"15px"}}
											justify="flex-end"
                                        >
                                            <Grid item>
                                                <Button
					                        	    disableElevation
													onClick={clear}
                                                    id="clearRecipient"
					                        	    className={'btnChange'}
					                            >
					                        	    Limpiar todo
                 	                            </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
					                        	    className={'buttonRecipient'}
													id={files.length > 0 ? "buttonRecipientBlock" : ""}
													disabled={files.length < 1 || selectedCerts.length < 1}
													onClick={code}
                                                    style={{ width: '114px' }}
					                            >
					                        	    Cifrar
                 	                            </Button>
                                            </Grid>
                                        </Grid>
									</div>
								</>
							</Paper>
						</Grid>
					</Grid>
				</Container>
				<Modal
					open={statusModalCorrect}
					onClose={() => {
						setStatusModalCorrect(false);
					}}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					disableBackdropClick
				>
					<div>
						<ModalCorrect
							setStatusModalCorrect={setStatusModalCorrect}
						/>
					</div>
				</Modal>
			</Grid>
		</>
	);
};

const mapStateToProps = (state) => ({
	filesEncrypt: state.encryptDecrypt.filesEncrypt,
});

const mapDispatchToProps = {
	encrypt: encrypt,
};

export default connect(mapStateToProps, mapDispatchToProps)(Encrypt);