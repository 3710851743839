import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import imgLoader from '../assets/img/loader.gif'
 
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <img src={imgLoader} alt='loadingFirmaYa'/>
    </Backdrop>
  );
};
export default Loading;
