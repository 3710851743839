import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { URL_LICENCIAMIENTO } from '../../../redux/types/ApiConstants';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		overflow: 'hidden',
	},
	paper: {		
		padding: theme.spacing(2),
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F5AE19',
		borderRadius: '10px',
		marginLeft: '20px',
		marginRight: '20px',
	},
	title: {
		font: 'normal normal bold 24px/24px Muli',
		color: '#E55200',
		letterSpacing: '0px',
	},
	description: {
		font: 'normal normal bold 18px/19px Muli',
		color: '#474747',
		letterSpacing: '0px',
	},
	buttons: {
		background: '#F5AE19 0% 0% no-repeat padding-box',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'none',
		font: 'normal normal bold 14px/18px Muli',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textShadow: '0px 2px 0px #00000040',
		width: '95px',
		height: '26px',
	},
	buttonsResponsive: {
		background: '#F5AE19 0% 0% no-repeat padding-box',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'none',
		font: 'normal normal bold 14px/18px Muli',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textShadow: '0px 2px 0px #00000040',
		width: '95px',
		height: '32px',
	}
}));

const Plan = (props) => {
	const classes = useStyles();
	const { paquete, fecha, isResponsive } = props;

	useEffect(() => {
	}, []);

	const handleClick = () => {
		window.open(URL_LICENCIAMIENTO + '/myplan/' + localStorage.JWTD);
	}
 
	return (
		<div className={classes.root} style={ isResponsive ? { marginTop: '7px' } : { marginTop: '8px'}}>
			<Paper className={'contentPaperPlan'}>
				<Grid
					container
					spacing={2}
					direction="column"
					justify="flex-start"
					alignItems="flex-start"
				>
					<Grid item xs style={{marginBottom : 0, paddingBottom : 0}}>
						<Typography className={classes.title}>Mi plan</Typography>
					</Grid>
					{
					<Grid item xs>
						<Typography className={classes.description}>Paquete {paquete}</Typography>
						<Typography className={'validateSubtitle'}>Fecha vigencia: {fecha}</Typography>
					</Grid>}
					<Grid item xs>
						<Button
							variant="contained"
							color="primary"
							className={isResponsive ? classes.buttons : classes.buttonsResponsive }
							onClick={handleClick}
						>
							Ver Más
                </Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default Plan;