import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";
import "./OriginSignature.css";
import "./Pdf.css";
import { generateCertificadoAction } from "../../../redux/actions/SignAction";
import { getCertificates } from "../../../redux/actions/SignCunstomDocumentAction";
import { mailOtpSendClient } from "../../../redux/actions/SignAction"
import { Rules } from "../../../helpers/RHFRules";
import FormHelperText from "@material-ui/core/FormHelperText";
import { API_HOSTNAME_AWS, MAX_NUMBER_ATTEMPS_ELECTRONIC } from '../../../redux/types/ApiConstants';
import IconButton from "@material-ui/core/IconButton";
import Loading from '../../../components/Loading';
import { FormControl } from "@material-ui/core";
import { certificateName } from "../../../helpers/helpNames";
import MuiPhoneNumber from "material-ui-phone-number";
 
const certificadoOptions = [
  { id: "2", label: "Centralizado" },
  { id: "3", label: "Token" },
  { id: "4", label: "Firma electrónica" },
];

const defaultValues = {
  tipoCertificado: "",
  tsaId: "",
  tsaPassword: "",
};

const TextFieldVerify = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& label.Mui-focused': {
      left: "-45px !important",
      marginTop: "-2px",
      font: 'normal normal normal 14px/22px Muli',
      color: '#707070',
    },
    "& label": {
      width: "90%",
      marginTop: "-9px",
      font: 'normal normal normal 18px/22px Muli',
      color: '#B2B2B2'
    },
    opacity: 0.7
  },
})(TextField);

const TextFieldVerifyStyle = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    color: '#B2B2B2 !important',
    font: 'normal normal 600 18px/22px Muli'
  },
})(TextField);

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
  },
  resize: {
    paddingLeft: "4%",
    fontSize: "15px",
    letterSpacing: "0px",
    color: "black",
    opacity: "1",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: 0,
    },
  },
  validateCheck: {
    font: "normal normal 600 16px/20px Muli",
    margin: "35px 48px 14px",
  },
  validateCode: {
    font: "normal normal 600 16px/20px Muli",
    margin: "37px 70px 14px",
  },
  validateLink: {
    font: "normal normal 600 16px/20px Muli",
    color: "#E55200",
    margin: "30px 90px 22px",
    textDecoration: "underline",
    textTransform: "inherit",
    cursor: "pointer",
  },
  textModalElectro: {
    font: "normal normal 100 16px/24px Muli",
    padding: "16px 10px 0px",
    color: "#4F4F4F",
  },
  textModalElectro2: {
    font: "normal normal 100 16px/24px Muli",
    padding: "28px 10px 0px",
    color: "#4F4F4F",
  },
  input: {
    height: 5
  },
  cssLabel: {
    font: 'normal normal normal 15px/22px Muli',
    textAlign: "center !important",
    color: '#707070 !important',
  },
}));

const CredentialsSing = ({ setStatusModalSign, filesSize, terminar }) => {
	const dispatch = useDispatch();
	const idTSA = useSelector(
		({ SignCunstomDocumentReducer }) => SignCunstomDocumentReducer.idTSA
	);

	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const [s3Limit] = useState(4);
	const [sendElecSign, setSendElecSign] = useState(0);
	const [value, setValue] = useState('');
	const [enableButton, setEnableButton] = useState(true);
	const [attemps, setAttemps] = useState(0);
	const [codeMail, setCodeMail] = useState('');
	const [outMail, setOutMail] = useState('');
	const [sendToOption, setSendToOption] = useState('email');
	const [mobileNumber, setMobileNumber] = useState('');
	const [services, setServices] = useState({
        "sms": false,
        "whatsapp": false
    });

	const [mobileNumberError, setMobileNumberError] = useState('');
	const [isElectronicSignatureSelected, setIsElectronicSignatureSelected] =
		useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, errors, control } = useForm({ defaultValues });
const spanishTranslations = {
	Albania: 'Albania',
	Andorra: 'Andorra',
	Austria: 'Austria',
	Belarus: 'Bielorrusia',
	Belgium: 'Bélgica',
	'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
	Bulgaria: 'Bulgaria',
	Croatia: 'Croacia',
	Cyprus: 'Chipre',
	'Czech Republic': 'República Checa',
	Denmark: 'Dinamarca',
	Estonia: 'Estonia',
	Finland: 'Finlandia',
	France: 'Francia',
	Germany: 'Alemania',
	Greece: 'Grecia',
	Hungary: 'Hungría',
	Iceland: 'Islandia',
	Ireland: 'Irlanda',
	Italy: 'Italia',
	Latvia: 'Letonia',
	Liechtenstein: 'Liechtenstein',
	Lithuania: 'Lituania',
	Luxembourg: 'Luxemburgo',
	Malta: 'Malta',
	Moldova: 'Moldavia',
	Monaco: 'Mónaco',
	Montenegro: 'Montenegro',
	Netherlands: 'Países Bajos',
	'North Macedonia': 'Macedonia del Norte',
	Norway: 'Noruega',
	Poland: 'Polonia',
	Portugal: 'Portugal',
	Romania: 'Rumanía',
	Russia: 'Rusia',
	'San Marino': 'San Marino',
	Serbia: 'Serbia',
	Slovakia: 'Eslovaquia',
	Slovenia: 'Eslovenia',
	Spain: 'España',
	Sweden: 'Suecia',
	Switzerland: 'Suiza',
	Ukraine: 'Ucrania',
	'United Kingdom': 'Reino Unido',
	'Vatican City': 'Ciudad del Vaticano',
	Argentina: 'Argentina',
	Bolivia: 'Bolivia',
	Brazil: 'Brasil',
	Canada: 'Canadá',
	Chile: 'Chile',
	Colombia: 'Colombia',
	'Costa Rica': 'Costa Rica',
	Cuba: 'Cuba',
	'Dominican Republic': 'República Dominicana',
	Ecuador: 'Ecuador',
	'El Salvador': 'El Salvador',
	Guatemala: 'Guatemala',
	Honduras: 'Honduras',
	Mexico: 'México',
	Nicaragua: 'Nicaragua',
	Panama: 'Panamá',
	Paraguay: 'Paraguay',
	Peru: 'Perú',
	'Puerto Rico': 'Puerto Rico',
	Uruguay: 'Uruguay',
	'United States': 'Estados Unidos',
	Venezuela: 'Venezuela',
	'Antigua and Barbuda': 'Antigua y Barbuda',
	'British Virgin Islands': 'Islas Vírgenes Británicas',
	Greenland: 'Groenlandia',
	'Caribbean Netherlands': 'Caribe Neerlandés',
	'Cayman Islands': 'Islas Caimán',
	'Falkland Islands': 'Islas Malvinas',
	'Faroe Islands': 'Islas Feroe',
	'French Guiana': 'Guayana Francesa',
	Grenada: 'Granada',
	Martinique: 'Martinica',
	'Saint Barthélemy': 'San Bartolomé',
	'Saint Kitts and Nevis': 'San Cristóbal y Nieves',
	'Saint Lucia': 'Santa Lucia',
	'Saint Martin': 'San Martin',
	'Saint Pierre and Miquelon': 'San Pedro y Miquelón',
	'Saint Vincent and the Grenadines': 'San Vicente y las Granadinas',
	'Sint Maarten': 'San Martín',
	'Turks and Caicos Islands': 'Islas Turcas y Caicos',
	'U.S. Virgin Islands': 'Islas Vírgenes de los Estados Unidos',
	Turkey: 'Turquía',
	'Trinidad and Tobago': 'Trinidad y Tobago',
};



	useEffect(() => {
	
		if (!isElectronicSignatureSelected) {
			setMobileNumber('');
			setSendToOption('email');
		}
	}, [isElectronicSignatureSelected]);
	useEffect(() => {
		if (sendToOption !== 'mobile') {
			setMobileNumber('');
		}

		if (sendToOption !== 'whatsapp') {
			setMobileNumber('');
		}
	}, [sendToOption]);
	const handleMobileNumberChange = (value, countryData) => {
		const valorSinPlus = value.replace(/^\+/, '');
		const indicativo = countryData.dialCode;
		const numeroSinIndicativo = valorSinPlus.substring(indicativo.length);
		const valorFormateado = `${indicativo}-${numeroSinIndicativo}`;
		setMobileNumber(valorFormateado);

		if (!/^\d{10,13}$/.test(valorSinPlus)) {
			
			setMobileNumberError(
				sendToOption === 'mobile'
					? 'El número debe contener entre 10 y 13 caracteres numéricos.'
					: 'El número WhatsApp debe contener entre 10 y 13 caracteres numéricos.'
			);
		} else {
			setMobileNumberError('');
		}
	};


	const onSubmit = async data => {

   	if (isElectronicSignatureSelected && sendToOption === 'email') {
			await handleEmailVerification();
			// Asumiendo que no quieres continuar con el resto de la lógica de onSubmit si esto sucede.
			return;
		}

	
		if (isElectronicSignatureSelected && sendToOption === 'mobile') {
			await handleMobileVerification();
			return;
		}

			if (isElectronicSignatureSelected && sendToOption === 'whatsapp') {
				await handleWhatsappVerification();
				return;
			}

		if (value == 3) {
			terminar('Token', 'Token');
		} else if (value == 4) {
			terminar('electrónica', 'electrónica');
		} else {
			terminar(data.tsaId, data.tsaPassword);
		}
	};
	useEffect(() => {
		(async () => {
			let state = await dispatch(
				getCertificates(onNoCertificates, onFree, setServices)
			);
			if (state) setLoading(false);
		})();
	}, []);

	const onFree = async () => {
		setSendElecSign(1);
		setLoading(true);
		await handleEmailVerification();

		handleChange(4);
	};

	const handleChange = async id => {
		if (id == 4) {
			setEnableButton(true);
			setIsElectronicSignatureSelected(true); // Update state when electronic signature is selected
		
		} else {
			setIsElectronicSignatureSelected(false); // Reset state when other options are selected
		}
		setValue(id);
	};

	const handleSend = async () => {
	 if (value == 4 && codeMail !== '' && !mobileNumber) {
			setEnableButton(true);
			if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
				enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				return;
			}
			let firmaS3 = false;
			if (filesSize > s3Limit * 1024) {
				firmaS3 = true;
			}
			setLoading(true);
			let response = await dispatch(
				generateCertificadoAction(enqueueSnackbar, codeMail)
			);
			setLoading(false);
			if (response) {
				if (response.status === 200) {
					terminar(
						response.data.result.certificateId,
						response.data.result.password,
						response.data.result.codeId,
						true
					);
				}
			}
			setAttemps(attemps + 1);
		}

     if (value == 4 && codeMail !== '' && mobileNumber) {
				setEnableButton(true);
				if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
					enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					return;
				}
				let firmaS3 = false;
				if (filesSize > s3Limit * 1024) {
					firmaS3 = true;
				}
				setLoading(true);
				let response = await dispatch(
					generateCertificadoAction(enqueueSnackbar, codeMail, mobileNumber)
				);
				setLoading(false);
				if (response) {
					if (response.status === 200) {
						terminar(
							response.data.result.certificateId,
							response.data.result.password,
							response.data.result.codeId,
							true
						);
					}
				}
				setAttemps(attemps + 1);
			}
		setLoading(false);
	};

	const handleEmailVerification = async () => {
			if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
				enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				return;
			}
			let tokenClie = localStorage.getItem('JWT');
			let resultado = JSON.parse(window.atob(tokenClie.split('.')[1]));
			if (resultado.email.length > 2) {
				let email = resultado.email.split('@');
				let cantidad = email[0].length >= 6 ? 6 : email[0].length;
				let string = '';
				if (cantidad > 2) {
					for (let i = 0; i < cantidad - 2; i++) string += '*';
					setOutMail(string + email[0].slice(-2) + '@' + email[1]);
				} else {
					string = cantidad === 1 ? '*' : '**';
					setOutMail(string + '@' + email[1]);
				}
				setLoading(true);

				setSendElecSign(1);
				let dataMail = await dispatch(mailOtpSendClient(enqueueSnackbar));
				setLoading(false);
				if (dataMail) {
					setSendElecSign(1);
				} else {
					setSendElecSign(2);
				}
			} else {
				enqueueSnackbar('Error al obtener el correo', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
	};

	const handleMobileVerification = async () => {
		let myHeaders = new Headers();
    let token = localStorage.getItem('JWT');
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		const formattedMobileNumber = mobileNumber.replace(/(\d{2})(\d+)/, '$1-$2');
		let raw = JSON.stringify({
			celular: mobileNumber,
			TextoOTP:
				'Firmaya te ha enviado tu codigo para tu firma electronica, por favor, no lo compartas con nadie: ',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		setLoading(true);

		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}sendOtp/api/types/Send`,
				requestOptions
			);
			const respuesta = await response.json();
			if (respuesta.http === 200) {
				
        setLoading(false);
				
					setSendElecSign(1);
				
			
			
        return true;

			} else {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				setLoading(false);
				throw Error(respuesta.result.information);
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	};
	const handleWhatsappVerification = async () => {
	
	
		let myHeaders = new Headers();
		let token = localStorage.getItem('JWT');
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		const [identificator, phoneNumber] = mobileNumber.split('-');
			let raw = JSON.stringify({
				phoneNumber: phoneNumber,
				identificator: identificator,
				message: 'Tu codigo OTP es: ',
			});
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		setLoading(true);

		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}sendOtp/api/whatsapp/Send`,
				requestOptions
			);
			const respuesta = await response.json();
			if (respuesta.http === 200) {
				setLoading(false);

				setSendElecSign(1);

				return true;
			} else if (
				respuesta.code === 'A065' 
			) {
				enqueueSnackbar(
					respuesta.message, // Mostrar el mensaje de textoOTP
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				setLoading(false);
				setEnableButton(true);
				setIsElectronicSignatureSelected(false); // Update state when electronic signature is selected

				setSendToOption('email');
				setMobileNumber('');

				throw Error(respuesta.result.information);
			} else {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);

				setLoading(false);
				setEnableButton(true);
				setIsElectronicSignatureSelected(false); // Update state when electronic signature is selected

				setSendToOption('email');
				setMobileNumber('');

				throw Error(respuesta.result.information);
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const onNoCertificates = () => {
		handleChange(4);
	};

	const handleInputChange = event => {
		if (event !== '') {
			setEnableButton(false);
			setCodeMail(event);
		} else {
			setEnableButton(true);
			setCodeMail('');
		}
	};

	if (loading) return <Loading />;

	if (sendElecSign == 1) {
		return (
			<Paper
				id='paperElectro1'
				elevation={3}
				className='sign-paper-source-signature'
			>
				<Grid
					container
					direction='row'
					className={classes.grid}
					justify='flex-end'
				>
					<IconButton
						edge='start'
						aria-label='upload file'
						onClick={() => setStatusModalSign(false)}
						className={'btn-close-sign-modal'}
					></IconButton>
				</Grid>
				<div>
					<form>
						<FormControl autoComplete='off' noValidate className={classes.form}>
							<Typography className={classes.textModalElectro}>
								Hemos enviado un código{' '}
								{sendToOption === 'whatsapp' ? (
									<>
										al WhatsApp{' '}
										<span style={{ fontWeight: 'bold' }}>
											{mobileNumber.replace('-', ' ')}
										</span>
										,
									</>
								) : (
									<>
										a{' '}
										<span style={{ fontWeight: 'bold' }}>
											{mobileNumber ? mobileNumber.replace('-', ' ') : outMail}
										</span>
										,
									</>
								)}
								<br></br>
								por favor ingrésalo a continuación
								{sendToOption !== 'whatsapp' && (
									<>
										{' '}
										(puede haber llegado a tus{' '}
										{mobileNumber ? 'números' : 'correos'} no deseados).
									</>
								)}
							</Typography>
							<Controller
								as={
									<TextFieldVerify
										name='codeValidate'
										label={enableButton && 'Código de verificación'}
										variant='outlined'
										autoFocus={true}
										inputProps={{ style: { textAlign: 'center' } }}
										InputProps={{
											autoComplete: 'off',
											classes: { input: classes.input },
										}}
									/>
								}
								name='codeValidate'
								control={control}
								rules={Rules.required}
								className={classes.validateCode}
								onChange={([event]) => {
									handleInputChange(event.target.value);
								}}
							/>
							<Grid
								style={{ margin: '0px 0px 0px' }}
								container
								justify='center'
							>
								<Button
									className={'btnGeneral18'}
									id='buttonVerify'
									disabled={enableButton}
									style={{ width: '154px', height: '41px' }}
									onClick={handleSend}
								>
									Verificar
								</Button>
							</Grid>
							<Typography
								className={classes.validateLink}
								onClick={() => {
									setSendElecSign(2);
								}}
							>
								¿No has recibido el {mobileNumber ? 'código' : 'email'}?
							</Typography>
							<Typography className={classes.textModalElectro}>
								Debes tener en cuenta que un certificado <br></br> electrónico
								no tiene validez jurídica.
							</Typography>
						</FormControl>
					</form>
				</div>
			</Paper>
		);
	} else if (sendElecSign == 2) {
		return (
			<Paper
				id='paperElectro2'
				elevation={3}
				className='sign-paper-source-signature'
			>
				<Grid
					container
					direction='row'
					className={classes.grid}
					justify='space-between'
				>
					<IconButton
						edge='start'
						id='forwardCode'
						onClick={() => {
							setSendElecSign(1);
							setEnableButton(true);
						}}
						className={'btn-forward-sign-modal'}
					></IconButton>
					<IconButton
						edge='start'
						aria-label='closeModal'
						onClick={() => setStatusModalSign(false)}
						className={'btn-close-sign-modal'}
					></IconButton>
				</Grid>
				<div>
					<form>
						<FormControl autoComplete='off' noValidate className={classes.form}>
							<Typography className={'titlePopUpForwardMail'}>
								¡Lo haremos de nuevo!
							</Typography>
							<Typography className={classes.textModalElectro2}>
								Reenviaremos a{' '}
								<span style={{ fontWeight: 'bold' }}>
									{mobileNumber ? mobileNumber.replace('-', ' ') : outMail}
								</span>
								<br></br> un nuevo código de verificación.
							</Typography>
							<Grid
								style={{ margin: '29px 0px 0px 0px' }}
								container
								justify='center'
							>
								<Button
									className={'btnGeneral18'}
									onClick={() => {
										if (mobileNumber) {
											if (sendToOption === 'whatsapp') {
												handleWhatsappVerification();
											} else if (sendToOption === 'mobile') {
												handleMobileVerification();
											}
										} else {
											handleEmailVerification();
										}
									}}
									style={{ width: '154px', height: '41px' }}
								>
									Aceptar
								</Button>
							</Grid>
						</FormControl>
					</form>
				</div>
			</Paper>
		);
	} else {
		return (
			<Paper
				id={value == 3 ? 'paperToken' : ''}
				elevation={3}
				className='sign-paper-source-signature '
			>
				<center>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => setStatusModalSign(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div class='content'>
						<form
							className={classes.form}
							autoComplete='off'
							noValidate
							onSubmit={handleSubmit(onSubmit)}
							align='center'
						>
							<Typography color='primary' className={'titlePopUpCertificate'}>
								Certificado
							</Typography>
							<Controller
								as={
									<Select
										displayEmpty
										value={''}
										FormHelperTextProps={{
											style: { margin: '0 0 0 0px', color: 'red' },
										}}
										helperText={
											errors.tipoCertificado
												? errors.tipoCertificado.message
												: null
										}
									>
										<MenuItem value='' className={'opionCustom'}>
											<Typography className='certificateText'>
												Seleccione
											</Typography>
										</MenuItem>
										{certificadoOptions.map(option => (
											<MenuItem
												key={option.id}
												onClick={() => handleChange(option.id)}
												value={option.id}
												className={'opionCustom'}
											>
												{option.label}
											</MenuItem>
										))}
									</Select>
								}
								name='tipoCertificado'
								control={control}
								rules={Rules.required}
								className={'selectCustom'}
							/>
							{errors.tipoCertificado && (
								<FormHelperText
									style={{
										marginBottom: '-10px',
										marginLeft: '37px',
										color: 'red',
									}}
									error={!!errors.tipoCertificado}
								>
									{errors.tipoCertificado.message}
								</FormHelperText>
							)}
							{isElectronicSignatureSelected && (
								<>
									<Select
										value={sendToOption}
										onChange={e => setSendToOption(e.target.value)}
										displayEmpty
										className={'selectCustom'}
									>
										<MenuItem value='' disabled>
											Seleccione
										</MenuItem>
										<MenuItem value='email'>Correo electrónico</MenuItem>
										{services.sms&&   <MenuItem value='mobile'>SMS</MenuItem>}
										{services.whatsapp&& <MenuItem value='whatsapp'>WhatsApp</MenuItem>}
										
									</Select>

									{isElectronicSignatureSelected &&
										sendToOption === 'mobile' && (
											<>
												<MuiPhoneNumber
													defaultCountry={'co'}
													onChange={handleMobileNumberChange}
													className='selectCustomP'
													regions={['america', 'europe']}
													disableAreaCodes={true}
													autoFormat={false}
													localization={spanishTranslations}
												/>
												{mobileNumberError && (
													<FormHelperText
														style={{ textAlign: 'center', width: '100%' }}
														error={true}
													>
														{mobileNumberError}
													</FormHelperText>
												)}
											</>
										)}
									{isElectronicSignatureSelected &&
										sendToOption === 'whatsapp' && (
											<>
												<MuiPhoneNumber
													defaultCountry={'co'}
													onChange={handleMobileNumberChange}
													className='selectCustomP'
													regions={['america', 'europe']}
													disableAreaCodes={true}
													autoFormat={false}
													localization={spanishTranslations}
												/>
												{mobileNumberError && (
													<FormHelperText
														style={{ textAlign: 'center', width: '100%' }}
														error={true}
													>
														{mobileNumberError}
													</FormHelperText>
												)}
											</>
										)}
								</>
							)}
							{value != 3 && !isElectronicSignatureSelected && (
								<Controller
									as={
										<Select
											displayEmpty
											value={''}
											FormHelperTextProps={{
												style: { margin: '0 0 0 5px', color: 'red' },
											}}
											helperText={errors.tsaId ? errors.tsaId.message : null}
										>
											<MenuItem value=''>
												<Typography className='certificateText'>ID</Typography>
											</MenuItem>
											{idTSA.map(option => (
												<MenuItem
													key={option.id}
													value={option.id}
													className={'opionCustom'}
												>
													<Typography style={{ maxWidth: '219px' }}>
														{certificateName(option.identificador, option.descripcion)}
													</Typography>
												</MenuItem>
											))}
										</Select>
									}
									name='tsaId'
									control={control}
									rules={Rules.required}
									className={'selectCustom'}
								/>
							)}
							{errors.tsaId && (
								<FormHelperText
									style={{
										marginBottom: '-10px',
										marginLeft: '37px',
										color: 'red',
									}}
									error={!!errors.tsaId}
								>
									{errors.tsaId.message}
								</FormHelperText>
							)}
							{value != 3 && !isElectronicSignatureSelected && (
								<Controller
									as={
										<TextField
											placeholder='Contraseña'
											type='password'
											FormHelperTextProps={{
												style: { margin: '0 0 0 5px', color: 'red' },
											}}
											helperText={
												errors.tsaPassword ? errors.tsaPassword.message : null
											}
											InputProps={{ classes: { input: classes.resize } }}
										/>
									}
									name='tsaPassword'
									control={control}
									rules={Rules.required}
									className={'selectCustom'}
								/>
							)}
							<center>
								<Grid
									style={{ margin: '28px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										type='submit'
										className={'btnGeneral18'}
										disabled={
											isElectronicSignatureSelected
												? sendToOption === 'mobile' ||
												  sendToOption === 'whatsapp'
													? mobileNumberError || mobileNumber.length === 0
													: null
												: false
										}
										style={{
											marginBottom:
												sendToOption === 'mobile' || sendToOption === 'whatsapp'
													? '20px'
													: 'normal',
										}}
									>
										Aceptar
									</Button>
								</Grid>
							</center>
						</form>
					</div>
				</center>
			</Paper>
		);
	}
};

export default CredentialsSing;
