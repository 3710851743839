import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Rules } from '../../helpers/RHFRules';
import { decrypt } from '../../redux/actions/EncryptDecryptAction';
import { getCerificatesByUser } from '../../redux/actions/SignAction';
import Loading from '../../components/Loading';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import Modal from '@material-ui/core/Modal';
 
const useStyles = makeStyles((theme) => ({
	resize:{
		paddingLeft: '4%',
		fontSize: '15px',
		letterSpacing: '0px',
		color: 'black',
		opacity: '1',
	},
    button: {
        backgroundColor: '#F5AE19',
        marginBottom: '20px',
        marginTop: '9px',
        boxShadow: '0px 3px 0px #00000033',
        textShadow: '0px 2px 0px #00000040',
        width: '130px',
        height: '38px',
        borderRadius: '10px',
        color: '#FFFFFF',
        textShadow: '0px 2px 0px #00000040',
        font: 'normal normal bold 16px/22px Muli',
        textTransform: 'initial',
        '&:hover': {
            backgroundColor: '#F5AE19',
            boxShadow: '0px 3px 0px #00000033'
         },
		 '&:disabled': {
            backgroundColor: '#F5AE19',
            opacity: "0.15",
			color: '#FFFFFF',
         },
    },
	form: {
		width: '100%',
		marginTop: '20px'
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const ModalDecrypt = (props) => {
	const {
		onSuccess, isOpen, decrypt, onClose, files, getCertificates, certificates, loadingAction,
	} = props;

	const classes = useStyles();
	const [value, setValue] = useState(true);
	const [loading, setLoading] = useState(true);
	const [button, setButtom] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const [services, setServices] = useState({
        "sms": false,
        "whatsapp": false
    });

	useEffect(() => {
		const fetchData = async () => {
			let result = await getCertificates(enqueueSnackbar, onExit, setServices);
			if (result) {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	const {
		handleSubmit, errors, control,
	} = useForm();
	
	const extract = (description) => {
		var extract = description.split('-')[0];
		return extract.length > 2 ? description.split('-')[0] : description;
	}
	
	const change = async (event) => {
		if(event.target.value === "") setButtom(true);
		else setButtom(false);
	}

	const send = async (datos) => {
		if(datos.keySecret == undefined){
			enqueueSnackbar('No está autorizado para descifrar este archivo', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		else {
			setLoading(true);
			await decrypt(enqueueSnackbar, datos, onSuccess);
			setValue(true);
			setButtom(true);
			setLoading(false);
		};
	}

	const onSubmit = async (data) => {
		const certi = certificates.find( certi => certi.id === data.certificate );
		var sendData = {};
		var isRecipient = false;
		let key;
		let filesDecrypt = [];
		var JSZip = require("jszip");
		JSZip.loadAsync(files[0]).then(async function (zip) {
			var objects = Object.keys(zip.files);
			Promise.all(objects.map(async function (filename, index) {
				if(filename.slice(-4) == '.kek'){
					await zip.files[filename].async('string').then(function (fileData) {
						if(certi.identificador == filename.slice(0,-4)) {
							isRecipient = true;
							key = fileData;							
						}
					})
				}else {
					await zip.files[filename].async('base64').then(function (fileData) {
						let decrypt = new Object();
						decrypt.nombre = filename;
						decrypt.base64 = fileData;						
						filesDecrypt.push(decrypt);						
				  	})
				}
			})).then(() => {
				sendData.documents = filesDecrypt;
				sendData.keySecret = key;
				sendData.password = document.getElementById("password").value;
				sendData.idUser = certi.identificador;
				send(sendData);
			});			
		})
	};

	const onExit = () => {
		onClose(true);
	}

	if (loading || loadingAction) return <Loading />;

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose(false)}
			disableBackdropClick
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Paper style={{ width: '442px', minHeight: '316px'}} className="sign-paper-source-signature">
				<Grid
					container
					direction="row"
					className={classes.grid}
					justify="flex-end"
				>
					<IconButton
						edge="start"
						aria-label="upload file"
						onClick={() => onClose(false)}
						className={'btn-close-sign-modal'}
					>
					</IconButton>
				</Grid>
				<Typography style={{ paddingTop: '10px' }} className={'titlePopUpCertificate'}>Certificado</Typography>
				<div className="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormControl
							autoComplete="off"
							noValidate
							className={classes.form}
						>
							<Controller
								as={(
									<Select
										key={1}
										displayEmpty
										error={!!errors.certificate}
									>
										<MenuItem onClick={() => setValue(true)} value="" className={'opionCustom'}>
											<Typography className="certificateText">Selecciona el certificado</Typography>
										</MenuItem>
										{
											Object.keys(certificates).length
											&& certificates.map(
												(c) => (
													<MenuItem
														key={c.id}
														value={c.id}
														onClick={() => setValue(false)}
														className={'opionCustom'}
													>
														<Typography style={{maxWidth:'349px'}}>{extract(c.descripcion)} - {c.identificador}</Typography>
													</MenuItem>
												),
											)
										}
									</Select>
								)}
								name="certificate"
								rules={Rules.required}
								control={control}
								defaultValue=""
								className={'selectCustom'}
							/>
							{errors.certificate && (
								<FormHelperText style={{ marginBottom:'-17px', marginLeft: '49px', color: 'red' }} error={!!errors.certificate}>
									{errors.certificate.message}
								</FormHelperText>
							)}
							<TextField
								placeholder="Contraseña"
								onChange={change}
								id="password"
								type="password"
								className={value ? 'passwordGet': 'selectCustom'}
								error={!!errors.password}
								FormHelperTextProps={{ style: { marginLeft: '0 0 0 5px', color: 'red' } }}
								InputProps={{ classes: { input: classes.resize } }}
								disabled={value}
							/>
						</FormControl>
						<br></br>
						<Grid
							style={{ margin: '20px 0px 0px' }}
							container
							justify="center"
						>
							<Button
								type="submit"
								className={classes.button}
								disabled={button || value}
							>
								Aceptar
            				</Button>
						</Grid>
					</form>
				</div>
			</Paper>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	certificates: state.sign.certificates,
	loadingAction: state.sign.loading,
});

const mapDispatchToProps = {
	getCertificates: getCerificatesByUser,
	decrypt: decrypt
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalDecrypt));