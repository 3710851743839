import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	backdrop: {
		color: '#E55200',
	},
	textFirmando: {
		color: '#000000',
		fontFamily: 'Mulish',
	},
	div:{
		zIndex: theme.zIndex.drawer + 1},
}));

const ProgressBar = ({ value }) => {
	const [progress, setProgress] = useState(0);
	const [blink, setBlink] = useState(true);
	const theme = useTheme();
	const classes = useStyles();


	useEffect(() => {
		// Verifica si el nuevo valor es mayor que el progreso actual
		if (value > progress) {
			const increment = 1; // Cantidad por la que se incrementa la barra
			const intervalTime = 10; // Intervalo de tiempo en milisegundos

			// Calcula cuántos incrementos son necesarios para alcanzar el nuevo valor
			const incrementsNeeded = Math.ceil((value - progress) / increment);

			// Calcula el incremento en cada intervalo de tiempo
			const incrementPerInterval = increment / incrementsNeeded;

			// Establece un temporizador para actualizar gradualmente el progreso
			const timer = setInterval(() => {
				setProgress(prevProgress => prevProgress + increment);

				// Si se alcanza el nuevo valor, limpia el temporizador
				if (progress >= value) {
					clearInterval(timer);
				}
			}, intervalTime);

			// Limpia el temporizador cuando el componente se desmonta o el valor cambia antes de alcanzar el nuevo valor
			return () => clearInterval(timer);
		}
	}, [value, progress]);


	useEffect(() => {
		const interval = setInterval(() => {
			setBlink(prevBlink => !prevBlink);
		}, 500); // Cambia la velocidad de parpadeo ajustando el valor en milisegundos

		return () => {
			clearInterval(interval);
		};
	}, []);
return (
	<Box
		position='fixed'
		top='50%'
		left='50%'
		transform='translate(-50%, -50%)'
		textAlign='center'
		zIndex='1000'
		className={classes.div}
	>
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				background: 'rgba(0, 0, 0, 0.6)',
				zIndex: '999',
			}}
		></div>
		<div style={{ position: 'relative', zIndex: '1001' }}>
			<CircularProgress
				size={150}
				variant='determinate'
				value={progress}
				className={classes.backdrop}
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			/>
			<Typography
				variant='h6'
				color='orange'
				style={{
					position: 'absolute',
					top: 'calc(50%)', // Ajusta la posición según sea necesario
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: '1002',
				}}
			>{`${Math.round(progress)}%`}</Typography>
			<Typography
				variant='h5'
				className={classes.textFirmando}
				style={{
					position: 'absolute',
					top: 'calc(50% + 100px)', // Ajusta la posición según sea necesario
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: '1003',
				}}
			>
				{blink && 'Firmando...'}
			</Typography>
		</div>
	</Box>
);
};

export default ProgressBar;
