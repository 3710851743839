import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Box, Collapse, IconButton, useTheme } from '@mui/material';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { EnhancedTableHead } from '../../../components/EnhancedTableHead';
import { StyledCheckbox } from './StyledCheckbox';
import EnhancedTableToolbar from '../../ConsultSignedDocument/EnhancedTableToolbar';
import Loading from '../../../components/Loading';
import ModalDeleteFiles from '../../ConsultSignedDocument/ModalDeleteFiles';

import { orderListype } from '../../../helpers/ConsultSignedDocuments/stableSort';

import { APP_KEY, MAX_FILE_DOWNLOAD } from '../../../redux/types/ApiConstants';

const useStyles = makeStyles(theme => ({
	resignCont: {
		display: 'flex',
		justifyContent: 'start',
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'space-between',
		},
	},
	nombre: {
		font: 'normal normal normal 14px/19px Mulish;',
		lineHeight: '19px',
		color: '#000000',
		letterSpacing: '0px',
		fontWeight: '400',
		opacity: 1,
	},
	ordenar: {
		font: 'normal normal normal 13px/16px Mulish',
		textTransform: 'inherit',
		float: 'right',
		color: '#706F6F',
		letterSpacing: '0px',
		fontWeight: '500',
		opacity: 1,
		marginTop: '-47px',
		marginRight: '20px',
	},
	fila: {
		cursor: 'pointer',
		'&:hover': {
			'& span': {
				opacity: '1',
			},
		},
	},
	filaFolder: {
		cursor: 'pointer',
		'& span': {
			opacity: '1',
		},
	},
	tabs: {
		margin: '15px 25px 0',
	},
	seleccion: {
		textTransform: 'none',
		fontFamily: 'Mulish',
		fontSize: '16px',
		lineHeight: '28px',
		fontWeight: '400',
		fontStyle: 'normal',
	},
	fecha: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#575756',
		letterSpacing: '0px',
		opacity: 1,
	},
	title: {
		margin: 0,
		height: '100px',
		paddingTop: '20px',
		color: 'white',
	},
	resign: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '14px',
		color: '#E55200',
	},
	origen: {
		font: 'normal normal normal 12px/15px Mulish;',
		color: '#4F4F4F',
		letterSpacing: '0px',
		opacity: 1,
	},
	paper: {
		padding: '20px',
		marginTop: '-25px',
		borderRadius: '10px',
	},
	table: {
		borderCollapse: 'collapse',
		border: 0,
		padding: 0,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	buttonMargin: {
		margin: '2px',
	},
	image: {
		width: '25px',
		height: '25px',
	},
	visible: {
		visibility: 'visible',
	},
	oculto: {
		visibility: 'hidden',
	},
	titleEncabezado: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '12px',
		color: '#E55200',
		lineHeight: '16px',
	},
	titleEncabezado2: {
		color: '#E55200',
		fontFamily: 'Mulish',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '16px',
		paddingLeft: '18px',
	},
}));

const headCells = [
	{
		id: 'nombrereal',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Carpeta / Documento',
		width: '36%',
		style: true,
	},
	{
		id: 'descripcionOrigenFirma',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Creador',
		width: '20%',
		style: false,
	},
	{
		id: 'fechaFirma',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha creación',
		width: '15%',
		style: false,
	},
	{
		id: '',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha recepción',
		width: '29%',
		style: false,
	},
];

const DateSign = props => {
	const classes = useStyles();
	const { fechaFirma } = props;
	var date = new Date(fechaFirma);
	return (
		<Grid container spacing={2} direction='row'>
			<Grid item xs={10} style={{ marginLeft: '2px' }}>
				<Typography className={classes.fecha}>
					{date.getDate() +
						'/' +
						(date.getMonth() + 1) +
						'/' +
						date.getFullYear()}
					<br></br>
					{date
						.toLocaleString('en-US', {
							hour: 'numeric',
							minute: 'numeric',
							second: 'numeric',
							hour12: true,
						})
						.toLowerCase()}
				</Typography>
			</Grid>
		</Grid>
	);
};
const Resign = ({
	index,
	indexRow,
	isCircuit,
	isFolder = false,
	backToSignOneFile,
	selected,
}) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.resignCont}>
			{!isFolder ? (
				<>
					{window.innerWidth < 1265 ? (
						<>
							{isCircuit && indexRow !== index && (
								<Grid
									item
									xs={6}
									style={{
										display: 'contents',
									}}
								>
									<Button className='btnChange2'>Circuito de firmas</Button>
								</Grid>
							)}
							<>
								{selected.length === 0 && (
									<Grid item xs={12}>
										{indexRow === index && (
											<Grid
												container
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													color: '#E55200',
												}}
											>
												<Grid
													item
													xs={2}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Tooltip
														title='Volver a firmar'
														style={{
															width: '18px',
															height: '18px',
														}}
													>
														<EditIcon />
													</Tooltip>
												</Grid>
												<Grid
													item
													xs={10}
													onClick={e => backToSignOneFile(e, indexRow)}
													style={{
														textAlign: 'left',
													}}
												>
													{/* Este es el de hover pero en vista pequeña */}
													<Typography className={classes.resign}>
														Volver a firmar
													</Typography>
												</Grid>
											</Grid>
										)}
									</Grid>
								)}
							</>
						</>
					) : (
						<>
							<Grid item xs={6}>
								{isCircuit && (
									<Button className='btnChange2'>Circuito de firmas</Button>
								)}
							</Grid>
							{selected.length === 0 && (
								<Grid item xs={6}>
									{indexRow == index && (
										<Grid
											container
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												color: '#E55200',
											}}
										>
											<Grid
												item
												xs={2}
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Tooltip
													title='Volver a firmar'
													style={{
														width: '18px',
														height: '18px',
													}}
												>
													<EditIcon />
												</Tooltip>
											</Grid>
											<Grid
												item
												xs={10}
												onClick={e => backToSignOneFile(e, indexRow)}
											>
												{/* Este es el de hover */}
												<Typography className={classes.resign}>
													Volver a firmar
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							)}
						</>
					)}
				</>
			) : (
				<>
					{selected.length === 0 && (
						<Grid item xs={12}>
							{indexRow === index && (
								<Grid
									container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										color: '#E55200',
									}}
								>
									<Grid
										item
										xs={2}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Tooltip
											title='Volver a firmar'
											style={{
												width: '18px',
												height: '18px',
											}}
										>
											<EditIcon />
										</Tooltip>
									</Grid>
									<Grid
										item
										xs={10}
										onClick={e => {
											backToSignOneFile(e, indexRow);
										}}
									>
										<Typography className={classes.resign}>
											Volver a firmar
										</Typography>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
};

export const LastDocumentsSigned = ({
	setStatusModalAvanzado,
	setIdOneFile,
	openedRowIndex,
	setOpenedRowIndex,
	setRowsHome,
	refresh,
}) => {
	const CryptoJS = require('crypto-js');
	const dateTypeFields = ['fechaFirma'];
	const key = APP_KEY;

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const ref = useRef(null);
	const theme = useTheme();

	// const { documentSign, loading: LoadingDocument } = useSelector(
	// 	state => state.consultSignedDocument
	// );
	const { pendingSign, loading: LoadingDocument } = useSelector(
		state => state.consultSignedDocument
	);

	const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
	const [idCarpetCircuit, setIdCarpetCircuit] = useState([]);
	const [idDocsSelected, setIdDocsSelected] = useState([]);
	const [idsFolder, setIdsFolder] = useState([]);
	const [idsInFolder, setIdsInFolder] = useState([]);
	const [indexRow, setIndex] = useState(null);
	const [isAllSelected, setAllSelected] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalDeleteFiles, setModalDeleteFiles] = useState(false);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('fechaFirma');
	const [p7z, setP7z] = useState(0);
	const [rowsFilter, setRowsFilter] = useState(pendingSign);
	const [selected, setSelected] = useState([]);
	const [user, setUser] = useState();

	const filteredIds = idCarpetCircuit.filter(id => {
		const row = rowsFilter.find(row => row.id === id);
		return row && row.documentCircuito !== null;
	});



	const valorEncriptado = rowsFilter
		.map(objeto => {
			if (objeto.encriptado !== undefined && objeto.encriptado !== null) {
				return objeto.encriptado;
			}
		})
		.filter(valor => valor !== undefined)[0];

	useEffect(() => {
		setRowsFilter(
			orderListype(pendingSign, 'fechaFirma', 'desc', dateTypeFields).slice(
				0,
				5
			)
		);
		setRowsHome(
			orderListype(pendingSign, 'fechaFirma', 'desc', dateTypeFields).slice(
				0,
				5
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pendingSign]);

	useEffect(() => {
		handleIdsToDelete();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	useEffect(() => {
		const isAllCarpetSelected = filteredIds.every(id => selected.includes(id));
		if (!isAllCarpetSelected) {
			setDisabledBtnDelete(true);
		} else {
			setDisabledBtnDelete(false);
		}
	}, [selected, filteredIds, idCarpetCircuit]);

	useEffect(() => {
		if (valorEncriptado) {
			const procesar = valorEncriptado
				.replace(/[-]/g, '+')
				.replace(/[_]/g, '/');
			let decifrado = CryptoJS.AES.decrypt(procesar, key);
			let salida = decifrado.toString(CryptoJS.enc.Utf8);

			function extraerNumeroIf(string) {
				const array = string.split('&');
				const elementoIf = array.find(elemento => elemento.startsWith('if='));
				const numeroIf = elementoIf ? elementoIf.substring(3) : null;
				return numeroIf;
			}
			const string = salida;
			const numeroIf = extraerNumeroIf(string);
			setUser(numeroIf);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valorEncriptado]);

	/**
	 *  *  se encarga de manejar el evento de selección/deselección de todos los documentos de la tabla
	 * @param {*} event
	 * @param {*} ListOrder
	 */
	const handleSelectAllClick = (event, ListOrder) => {
		if (selected.length > 0) {
			setAllSelected(false);
			setSelected([]);
			setIdsFolder([]);
			setOpenedRowIndex(-1);
		} else if (isAllSelected == false || selected.length == 0) {
			const listRows = [];
			const ListsIdsFolder = [];
			const idFolderSelect = [];
			let count = p7z;
			let cont = 0;
			const prueba = ListOrder;
			ListOrder.map((row, index) => {
				if (index < 10) {
					if (row.isFolder) {
						const idsCircuito = row.documentCircuito.map(
							document => document.id
						);
						listRows.push(row);
						ListsIdsFolder.push({
							idFolder: row.id,
							idsCircuit: idsCircuito,
						});
					} else {
						listRows.push(row);
					}
					let nombre = row.nombrereal;
					let extension = nombre.split('.').pop();
					if (extension != 'pdf') {
						count += 1;
					}
				}
			});
			if (event.target !== undefined) {
				if (event.target.checked && selected.length < MAX_FILE_DOWNLOAD) {
					const newSelecteds = [];
					if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
						for (let i = 0; i < prueba.length; i++) {
							if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
								let indexObject = listRows.find(row => row.id === prueba[i].id);
								if (indexObject?.isFolder) {
									const idFolder = ListsIdsFolder.filter(
										obj => obj.idFolder === indexObject.id
									);
									const folder = idFolder.map(obj => obj.idsCircuit);
									folder[0].forEach(id => {
										if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
											newSelecteds.push(id);
										}
									});
									const includes = folder.some(arr =>
										arr.every(value => newSelecteds.includes(value))
									);
									if (includes) {
										newSelecteds.push(prueba[i].id);
										idFolderSelect.push(prueba[i].id);
										setIdsFolder(idFolderSelect);
										cont++;
									} else {
										setOpenedRowIndex(i);
									}
								} else {
									if (newSelecteds.length - cont < MAX_FILE_DOWNLOAD) {
										if (indexObject.id === prueba[i].id) {
											newSelecteds.push(prueba[i].id);
										}
										let nombre = prueba[i].nombrereal;
										let extension = nombre.split('.').pop();
										if (extension != 'pdf') {
											count += 1;
										}
									}
								}
							}
						}
					}
					setP7z(count);
					setSelected(newSelecteds);
				}
			}
			setAllSelected(true);
		} else {
			setAllSelected(false);
			setSelected([]);
		}
	};

	/**
	 * * Cambia el orden de la Tabla  según la propiedad especificada y actualiza el estado correspondiente.
	 * @param {*} event
	 * @param {*} property
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	/**
	 * * verifica si un documento está seleccionado o no en la tabla.
	 * @param {*} name
	 * @returns
	 */
	const isSelected = name => selected.indexOf(name) !== -1;

	/**
	 *  * Recibe el id de un documento y verifica si es un folder o no, si es un folder, retorna un array con los ids de los documentos que contiene, si no es un folder, retorna el id del documento.
	 * @param {*} id
	 * @param {*} files
	 * @returns
	 */

	const isIdCarpet = (id, files) => {
		let searchFiles = files.filter(file => file.id === id);
		if (searchFiles.length > 0) {
			if (searchFiles[0].documentCircuito) {
				if (idsFolder.includes(id)) {
					setIdsFolder(idsFolder.filter(ob => ob !== id));
				} else {
					setIdsFolder([...idsFolder, id]);
				}
				searchFiles = searchFiles[0].documentCircuito?.filter(
					file => file.idCircuito === id
				);
				let ids = searchFiles.map(ob => ob.id);
				ids.push(id);
				return ids;
			} else {
				return id;
			}
		} else {
			return id;
		}
	};

	/**
	 *
	 * @param {*} id
	 * @param {*} rows
	 * @param {*} selected
	 * @param {*} cont
	 * @returns
	 */
	const checkFolder = (id, rows, selected, cont) => {
		let newSelected = [];
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) {
			const arrayIds = foundMatch.documentCircuito.map(ob => ob.id);
			const result = arrayIds.every(val => selected.includes(val));
			if (result) {
				setIdsFolder([...idsFolder, foundMatch.id]);
				return newSelected.concat(selected, foundMatch.id);
			} else {
				setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));
				return selected;
			}
		}
		return selected;
	};

	/**
	 *  * busca el id de un documento en las filas de la tabla.
	 * @param {*} id
	 * @param {*} rows
	 * @returns
	 */

	const findInRows = (id, rows) => {
		const foundMatch = rows.find(
			row =>
				row.documentCircuito !== null &&
				row.documentCircuito.find(file => file.id === id)
		);

		if (foundMatch) setIdsFolder(idsFolder.filter(ob => ob !== foundMatch.id));

		return foundMatch ? foundMatch.id : null;
	};

	/**
	 * * Retorna la cantidad de documentos seleccionados, sin contar los folders que contienen documentos seleccionados.
	 * @param {*} ids
	 * @param {*} selected
	 * @param {*} folderId
	 * @param {*} rows
	 * @returns
	 */
	const skipIdFolders = (ids, selected, folderId, rows) => {
		if (typeof ids === 'number') {
			ids = [ids];
		} else {
			const foundMatch = rows.find(
				row =>
					row.documentCircuito !== null &&
					row.documentCircuito.find(file => ids.includes(file.idCircuito))
			);
			if (foundMatch) ids = ids.filter(ob => ob !== foundMatch.id);
		}
		const contselected = selected.length;
		const contids = ids.length;
		const contfolder = folderId.length;
		const cont = contselected + contids - contfolder;

		return cont;
	};

	/**
	 *  * Maneja el evento de click en un checkbox de la tabla, actualiza el estado correspondiente.
	 * @param {*} event
	 * @param {*} name
	 * @returns
	 */
	const handleClick = async (event, name) => {
		const row = event.target.closest('tr');
		const isrowselected = selected.includes(name);
		let newSelected = [];
		if (event.target.checked === true || (row.id == name && !isrowselected)) {
			const idsSelected = isIdCarpet(name, rowsFilter);
			const idsWithoutFolder = skipIdFolders(
				idsSelected,
				selected,
				idsFolder,
				rowsFilter
			);
			if (idsWithoutFolder <= MAX_FILE_DOWNLOAD) {
				newSelected = newSelected.concat(selected, idsSelected);
				newSelected = checkFolder(idsSelected, rowsFilter, newSelected);
			} else {
				enqueueSnackbar(
					`Máximo puede seleccionar ${MAX_FILE_DOWNLOAD} documentos`,
					{
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				return;
			}
			setSelected(newSelected);
		} else {
			const idsToDelete = isIdCarpet(name, rowsFilter);
			if (typeof idsToDelete === 'number') {
				newSelected = selected.filter(x => x !== idsToDelete);
				const rowFound = findInRows(name, rowsFilter);
				if (rowFound) {
					newSelected = newSelected.filter(x => x !== rowFound);
				}
			} else {
				newSelected = selected.filter(id => !idsToDelete.includes(id));
			}
			setSelected(newSelected);
		}
		let count = 0;
		// ! Arreglar map de selected
		newSelected.map(sel => {
			let file = rowsFilter.find(x => x.id === sel);
			// let nombre = file.nombrereal; // ! Revisar
			let nombre = 'nombre';
			let extension = nombre.split('.').pop();
			if (extension != 'pdf') {
				count += 1;
			}
		});
		setP7z(count);
	};

	/**
	 *  * Retorna el nombre del documento, si es mayor a 30 caracteres, se muestra un tooltip con el nombre completo y el nombre cortado con puntos suspensivos.
	 * @param {*} name
	 * @returns
	 */
	const cutName = name =>
		name.length > 30 ? (
			<Tooltip title={name}>
				<div>{name.substring(0, 30).concat('...')}</div>
			</Tooltip>
		) : (
			<div>{name}</div>
		);

		const truncarNombreCompleto = nombre => {
			if (nombre.length > 35) {
				return nombre.slice(0, 32) + '...';
			}
			return nombre;
		};

	/**
	 * * Ordena el nombre del certificado, si es un certificado de validación de OTP, se muestra primero el certificado y luego el tipo de validación.
	 * @param {*} name
	 * @returns
	 */
	const orderCertificate = name => {
		let certificate = name.split('-');
		if (certificate.length > 1) {
			if (certificate[1].includes('validateOTP'))
				return (
					<>
						Cert.{' '}
						<span style={{ textTransform: 'capitalize' }}>
							{certificate[0].toLowerCase()}
						</span>
					</>
				);
			else
				return (
					<>
						{truncarNombreCompleto(certificate[1])} <br /> Cert.{' '}
						<span style={{ textTransform: 'capitalize' }}>
							{certificate[0]}
						</span>
					</>
				);
		} else return certificate;
	};

	/**
	 * * Funcion que se encarga de abrir el modal de firma avanzada para un solo documento seleccionado
	 * @param {*} e
	 * @param {*} row
	 */
	const backToSignOneFile = (e, row) => {
		e.stopPropagation();
		setStatusModalAvanzado(true);
		setIdOneFile(row);
	};

	/**
	 *  * selecciona  los ids a eliminar
	 */
	const handleIdsToDelete = () => {
		const newDocs = [];
		const newFolders = [];
		const idInFolders = [];
		selected.forEach(id => {
			const documento = rowsFilter.find(doc => doc.id === id);
			if (documento) {
				if (documento.isCircuit) {
					if (documento.documentCircuito) {
						if (!newFolders.includes(id)) {
							newFolders.push(id);
						}
					} else {
						idInFolders.push(id);
						if (!newDocs.includes(documento.idCircuito))
							newFolders.push(documento.idCircuito);
					}
				} else {
					newDocs.push(id);
				}
			} else {
				const foundMatch = rowsFilter.find(
					row =>
						row.documentCircuito !== null &&
						row.documentCircuito.find(file => file.id === id)
				);
				if (foundMatch) {
					idInFolders.push(id);
					if (!newFolders.includes(foundMatch.id)) {
						newFolders.push(foundMatch.id);
					}
				}
			}
		});
		setIdDocsSelected(newDocs);
		setIdCarpetCircuit(newFolders);
		setIdsInFolder(idInFolders);
	};

	const ListOrder = useMemo(
		() => orderListype(rowsFilter, orderBy, order, dateTypeFields),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rowsFilter, orderBy, order]
	);

	return (
		<div className={'contentDocumentSignedHome2'}>
			<EnhancedTableToolbar
				disabledBtnDelete={disabledBtnDelete}
				handleSelectAllClick={e => handleSelectAllClick(e, ListOrder)}
				idCarpetCircuit={idCarpetCircuit}
				idDocsSelected={idDocsSelected}
				isFilterIsNull={false}
				isP7={p7z}
				numSelected={selected.length}
				refresh={refresh}
				rowCount={rowsFilter.length}
				selected={[...idDocsSelected, ...idsInFolder]}
				setModalDeleteFiles={setModalDeleteFiles}
				setOpenedRowIndex={setOpenedRowIndex}
				setSelected={setSelected}
				tabSelected={0}
			/>

			<div ref={ref} className={'containerTabSignedDocument'}>
				<TableContainer
					style={{
						minWidth: '868px',
					}}
				>
					<Table
						aria-label='enhanced table'
						aria-labelledby='tableTitle'
						cellSpacing='0px'
						className={'tableSignedDocuments'}
						size='medium'
					>
						<EnhancedTableHead
							classes={classes}
							headCellsP={headCells}
							numSelected={selected.length}
							onRequestSort={handleRequestSort}
							onSelectAllClick={handleSelectAllClick}
							order={order}
							orderBy={orderBy}
							rowCount={rowsFilter.length}
						/>
						{rowsFilter.length > 0 ? (
							<TableBody>
								{ListOrder.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;
									return !row.isFolder ? (
										<TableRow
											aria-checked={isItemSelected}
											className={classes.fila}
											hover
											id={row.id}
											key={row.id}
											onClick={event => handleClick(event, row.id)}
											onMouseEnter={() => setIndex(row.id)}
											onMouseLeave={() => setIndex(null)}
											role='checkbox'
											selected={isItemSelected}
											style={{ cursor: 'pointer' }}
											tabIndex={-1}
										>
											<TableCell padding='none'>
												<StyledCheckbox
													checked={isItemSelected}
													className={'checkButtonDocumentSigned'}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell
												align='left'
												component='th'
												padding='normal'
												scope='row'
											>
												<Grid container>
													<Grid item xs={1} />
													<Grid item xs={11}>
														<Typography className={classes.nombre}>
															{cutName(row.nombrereal)}
														</Typography>
													</Grid>
												</Grid>
											</TableCell>
											<TableCell align='start' padding='none'>
												<Typography className={classes.origen}>
													{orderCertificate(row.descripcionOrigenFirma)}
												</Typography>
											</TableCell>{' '}
											<TableCell align='start' padding='none'>
												<DateSign
													fechaFirma={row.fechaFirma}
													index={index}
													indexRow={indexRow}
												/>
											</TableCell>
											<TableCell
												align={window.innerWidth < 1265 ? 'center' : 'start'}
												padding='none'
											>
												<Resign
													backToSignOneFile={backToSignOneFile}
													index={row.id}
													indexRow={indexRow}
													isCircuit={row.isCircuit}
													selected={selected}
													theme={theme}
												/>
											</TableCell>
										</TableRow>
									) : (
										<>
											<TableRow
												hover
												id={row.id}
												onClick={event => handleClick(event, row.id)}
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
												style={{
													cursor: 'pointer',
												}}
												onMouseEnter={() => setIndex(row.id)}
												onMouseLeave={() => setIndex(null)}
												className={
													openedRowIndex === index
														? classes.filaFolder
														: classes.fila
												}
											>
												<TableCell padding='none'>
													<StyledCheckbox
														checked={isItemSelected}
														className={'checkButtonDocumentSigned'}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
												<TableCell
													align='left'
													component='th'
													id={labelId}
													scope='row'
													padding='normal'
												>
													{
														<Grid container>
															<Grid item xs={1}>
																<FolderIcon
																	style={{
																		color: '#878787',
																		width: '16px',
																		height: '16px',
																	}}
																/>
															</Grid>
															<Grid item xs={11}>
																<Typography className={classes.nombre}>
																	{cutName(row.nombrereal)}
																</Typography>
															</Grid>
														</Grid>
													}
												</TableCell>
												<TableCell align='start' padding='none'>
													<Typography className={classes.origen}>
														{orderCertificate(row.descripcionOrigenFirma)}
													</Typography>
												</TableCell>{' '}
												<TableCell align='start' padding='none'>
													<DateSign
														fechaFirma={row.fechaFirma}
														index={index}
														indexRow={indexRow}
													/>
												</TableCell>
												<TableCell align='start' padding='none'>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
														}}
													>
														<Button className='btnChange2'>
															Circuito de firmas
														</Button>
														<IconButton
															aria-label='expand row'
															size='small'
															onClick={e => {
																e.stopPropagation();
																setOpenedRowIndex(
																	openedRowIndex === index
																		? // abierto
																		  -1
																		: //cerrado
																		  index
																);
															}}
															style={{ color: '#E55200' }}
														>
															{openedRowIndex === index ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)}
														</IconButton>
													</div>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													style={{
														paddingBottom: 0,
														paddingTop: 0,
													}}
													colSpan={6}
												>
													<Collapse
														in={openedRowIndex === index}
														timeout='auto'
														unmountOnExit
													>
														<Box margin={1}>
															<Table size='small' aria-label='purchases'>
																<TableHead>
																	<TableRow
																		component={Paper}
																		style={{
																			border: 'none',
																			boxShadow: 'none',
																		}}
																	>
																		<TableCell
																			width='10%'
																			align='end'
																			padding='none'
																		/>
																		<TableCell
																			width='34%'
																			align='start'
																			className={classes.titleEncabezado}
																		>
																			Documento
																		</TableCell>
																		<TableCell
																			width='25%'
																			align='start'
																			padding='normal'
																			className={classes.titleEncabezado}
																		>
																			Certificado
																		</TableCell>
																		<TableCell
																			padding='normal'
																			width='19%'
																			align='start'
																			className={classes.titleEncabezado}
																		>
																			Fecha de firma
																		</TableCell>
																		<TableCell
																			padding='none'
																			width='15%'
																			align='start'
																			className={classes.titleEncabezado}
																		/>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{row.documentCircuito.map((row, index) => {
																		const isItemSelected = isSelected(row.id);
																		const labelId = `enhanced-table-checkbox-${index}`;
																		return (
																			<TableRow
																				hover
																				id={row.id}
																				onClick={event =>
																					handleClick(event, row.id)
																				}
																				role='checkbox'
																				aria-checked={isItemSelected}
																				tabIndex={-1}
																				key={row.id}
																				selected={isItemSelected}
																				style={{
																					cursor: 'pointer',
																				}}
																				onMouseEnter={() => setIndex(row.id)}
																				onMouseLeave={() => setIndex(null)}
																				className={classes.fila}
																			>
																				<TableCell
																					padding='checkbox'
																					style={{
																						textAlign: 'end',
																					}}
																				>
																					<StyledCheckbox
																						checked={isItemSelected}
																						className={
																							'checkButtonDocumentSigned'
																						}
																						inputProps={{
																							'aria-labelledby': labelId,
																						}}
																					/>
																				</TableCell>
																				<TableCell
																					align='start'
																					component='th'
																					id={labelId}
																					scope='row'
																					padding='normal'
																				>
																					{
																						<Typography
																							className={classes.nombre}
																						>
																							{cutName(row.nombrereal)}
																						</Typography>
																					}
																				</TableCell>
																				<TableCell
																					align='start'
																					padding='normal'
																				>
																					<Typography
																						className={classes.origen}
																					>
																						{orderCertificate(
																							row.descripcionOrigenFirma
																						)}
																					</Typography>
																				</TableCell>{' '}
																				<TableCell
																					align='start'
																					padding='normal'
																				>
																					<DateSign
																						fechaFirma={row?.fechaFirma}
																						index={index}
																						indexRow={indexRow}
																					/>
																				</TableCell>
																				<TableCell align='start' padding='none'>
																					<Resign
																						index={row.id}
																						indexRow={indexRow}
																						isCircuit={row?.isCircuit}
																						selected={selected}
																						isFolder={true}
																						backToSignOneFile={
																							backToSignOneFile
																						}
																					/>
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										</>
									);
								})}
							</TableBody>
						) : (
							<TableBody>
								<TableCell />
								<TableCell>No se encontraron Documentos</TableCell>
								<TableCell />
								<TableCell />
								<TableCell />
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</div>
			{loading || LoadingDocument ? <Loading /> : null}
			{modalDeleteFiles && (
				<ModalDeleteFiles
					idCarpetCircuit={idCarpetCircuit}
					idDocsSelected={idDocsSelected}
					idsFolder={idsFolder}
					modalDeleteFiles={modalDeleteFiles}
					refresh={refresh}
					rows={rowsFilter}
					selected={selected}
					setLoading={setLoading}
					setModalDeleteFiles={setModalDeleteFiles}
					setOpenedRowIndex={setOpenedRowIndex}
					setSelected={setSelected}
					user={user}
				/>
			)}
		</div>
	);
};
