export const truncarNombreCompleto = (nombres, apellidos="", cantidad= 35) => {
	const nombreCompleto = `${nombres} ${apellidos}`;
	if (nombreCompleto.length > cantidad) {
		return nombreCompleto.slice(0, (cantidad -3)) + '...';
	}
	return nombreCompleto;
};

export const certificateName = (identificador, description) => {
	if(identificador){
			if (description.includes(identificador)) {
				return description;
			} else {
				if (description.startsWith('-') || description.startsWith(' -')) {
					return identificador + description;
				} else {
					return identificador + ' - ' + description;
				}
			}
	} return description

};