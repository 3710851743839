import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import ModalFirmaAvanzada from '../../Firmar/FirmaAvanzada/ModalFirmaAvanzada';
import { LastDocumentsSigned } from './LastDocumentsSigned';
import { PendingDocuments } from './PendingDocuments';

const useStyles = makeStyles(theme => ({
	
	titleBackground: {
		margin: '0',
		background: 'rgba(0, 0, 0, 0.3)',
		height: '100px',
		paddingTop: '20px',
		width: '100%',
	},
	nombre: {
		font: 'normal normal normal 14px/19px Muli;',
		color: '#000000',
		letterSpacing: '0px',
		fontWeight: 'bold',
		opacity: 1,
	},
	origen: {
		font: 'normal normal normal 12px/15px Muli;',
		color: '#4F4F4F',
		letterSpacing: '0px',
		opacity: 1,
	},
	fecha: {
		font: 'normal normal normal 12px/15px Muli;',
		color: '#575756',
		letterSpacing: '0px',
		opacity: 1,
	},
	title: {
		margin: 0,
		color: 'white',
	},
	paper: {
		position: 'relative',
		padding: theme.spacing(2),
		padding: theme.spacing(4),
		marginBottom: '20px',
		marginTop: '40px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F5AE19',
		borderRadius: '10px',
		width: '98%',
	},
	table: {
		borderCollapse: 'collapse',
		border: 0,
		padding: 0,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	buttonMargin: {
		margin: '2px',
	},
	image: {
		width: '25px',
		height: '25px',
	},
	visible: {
		visibility: 'visible',
	},
	oculto: {
		visibility: 'hidden',
	},
	titleEncabezado: {
		color: '#f36a00',
		fontFamily: 'Verdana',
		fontSize: '100%',
	},
	title: {
		font: 'normal normal bold 24px/30px Muli',
		color: '#E55200',
		letterSpacing: '0px',
	},
	buttons: {
		background: '#F5AE19 0% 0% no-repeat padding-box',
		marginRight: '10px',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'none',
		font: 'normal normal bold 14px/18px Muli',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textShadow: '0px 2px 0px #00000040',
		width: '95px',
		height: '26px',
	},
}));

const DocumentsTable = props => {
	const { getListDocument } = props;
	const classes = useStyles();
	const history = useHistory();
	const [statusModalAvanzado, setStatusModalAvanzado] = useState(false);
	const [idOneFile, setIdOneFile] = useState();
	const [openedRowIndex, setOpenedRowIndex] = useState(-1);
	const [rowsHome, setRowsHome] = useState([]);


	useEffect(() => {
		document.body.className = 'bodyMiddle';
	}, []);

	const handleVer = () => {
		history.push('/home/ConsultSignedDocument');
	};

	const refresh = reInitSelect => {
		if (reInitSelect) {
			getListDocument();
		}
	};

	const onClose = type => {
		refresh(type);
		setStatusModalAvanzado(false);
	};

	return (
		<>
			<Paper elevation={0} className={'contentPaperDocuments2'}>
				<Typography className={classes.title}>
					Documentos pendientes por firmar
				</Typography>
				<br />
				<br />
				<PendingDocuments
					setStatusModalAvanzado={setStatusModalAvanzado}
					setIdOneFile={setIdOneFile}
					openedRowIndex={openedRowIndex}
					setOpenedRowIndex={setOpenedRowIndex}
					setRowsHome={setRowsHome}
					refresh={refresh}
				/>
				<Grid
					container
					direction='column'
					justify='center'
					alignItems='flex-end'
					style={{ paddingTop: '25px' }}
				>
					{/* <Grid item xs>
						<Button
							variant='contained'
							color='primary'
							className={classes.buttons}
							onClick={() => handleVer()}
						>
							Ver Más
						</Button>
					</Grid> */}
				</Grid>
			</Paper>
			{statusModalAvanzado && (
				<ModalFirmaAvanzada
					isOpen={statusModalAvanzado}
					onClose={onClose}
					files={[]}
					filesSize={0}
					style={'style'}
					rows={rowsHome}
					selected={[idOneFile]}
					setFiles={() => {}}
					refresh={refresh}
					setOpenedRowIndex={setOpenedRowIndex}
				/>
			)}
		</>
	);
};

export default DocumentsTable;
