import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { Box, Dialog } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import ButtonsModeSign from './MisDocumentos/ButtonsModeSign';
import ZoneUpload from '../../components/ZoneUpload';

import '../../assets/styles/StylesBody.css';
import ModalFirmaAvanzada from './FirmaAvanzada/ModalFirmaAvanzada';
import ModalFolder from './MisDocumentos/Modales/ModalFolder';
import { useDispatch, useSelector } from 'react-redux';
import {
	filesCf,
	firm_positions,
	name_folder,
	stepCircuit,
} from '../../redux/actions/CircuitoFirmasAction';
const FirmarSection = props => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [files, setFiles] = useState([]);
	const [isP7, setP7] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [sign, setSign] = useState(0);
	const [sizeFiles, setSizeFiles] = useState(0);
	const [statusModalAvanzado, setStatusModalAvanzado] = useState(false);
	const [folder, setFolder] = useState();
	const [setOpenedRowIndex, setSetOpenedRowIndex] = useState(-1)

	const filesCF = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.files
	);
	const signers = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.firmantes
	);
	const folders = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.carpeta
	);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});
	useEffect(() => {
		if (folders) {
			setFolder(folders);
		}
	}, [folders]);

	useEffect(() => {
		if (filesCF.length > 0) {
			setFiles(filesCF);
		} else {
			setFiles([]);
		}
	}, [filesCF]);

	useEffect(() => {
		validateP7();
		validateSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	useEffect(() => {
		if (filesCF.length < 2) dispatch(name_folder(' '));
		if (filesCF.length === 0) dispatch(firm_positions([]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filesCF]);

	const validateP7 = () => {
		let p7 = 0;
		files.map(file => {
			const fileExtension = file.name.slice(
				((file.name.lastIndexOf('.') - 1) >>> 0) + 2
			);
			if (fileExtension != 'pdf') {
				p7++;
			}
		});
		setP7(p7);
	};

	const validateSize = () => {
		let dropedSize = 0;
		for (let i = 0; i < files.length; i++) {
			let sizekiloByte = Math.round(files[i].sizeBytes / 1024);
			dropedSize += sizekiloByte;
		}
		setSizeFiles(dropedSize);
	};

	const openModalFolder = () => {
		if (sign === 0) return setStatusModalAvanzado(true);
		if (files.length > 1 && sign !== 0) {
			if (folder === ' ') {
				setOpenModal(true);
			} else {
				dispatch(stepCircuit(props.isDashboard ? 10 : 1));
				history.push("/home/firmar")
				dispatch(filesCf(files));
			}
		} else {
			props.isDashboard && history.push('/home/firmar');
			dispatch(filesCf(files));
			dispatch(stepCircuit(props.isDashboard ? 10 : 1));
		}
	};

	const onClose = type => {
		setStatusModalAvanzado(false);
		// setStatusModal(false);
		// if (refresh != undefined) refresh(type);
	};

	return (
		<>
			<Paper elevation={0} className='paper-upload'>
				<ZoneUpload
					key={1}
					setFiles={setFiles}
					files={files}
					isP7={isP7}
					isDashboard={props.isDashboard}
					sizeFiles={sizeFiles}
					className={'contentZoneUpload'}
					isResponsive={props.isResponsive}
				/>
				<Box id='box-botonera' style={{ marginTop: '40px' }}>
					{files.length > 0 && (
						<ButtonsModeSign
							openModalFolder={openModalFolder}
							sign={sign}
							setSign={setSign}
							files={files}
						/>
					)}
				</Box>
			</Paper>
			<Dialog
				open={statusModalAvanzado}
				onClose={onClose}
				aria-labelledby='responsive-dialog-title'
			>
				<ModalFirmaAvanzada
					isOpen={statusModalAvanzado}
					onClose={onClose}
					files={files}
					filesSize={sizeFiles}
					style={false}
					rows={[]}
					selected={[]}
					setFiles={setFiles}
					setOpenedRowIndex={setSetOpenedRowIndex}
				/>
			</Dialog>
			<ModalFolder
				folder={folder}
				openModal={openModal}
				setOpenModal={setOpenModal}
				files={files}
				isDashboard={props.isDashboard}
			/>
		</>
	);
};

export default withRouter(FirmarSection);
