import {
	API_HOSTNAME,
	MAX_NUMBER_ATTEMPS_ELECTRONIC,
} from '../types/ApiConstants';

export const enviarOTP = async (token, email, enqueueSnackbar) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	myHeaders.append('Content-Type', 'application/json');

	let raw = JSON.stringify({
		email: email,
		celular: '',
		aplicacion: '',
	});

	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	// setLoading(true)

	try {
		const response = await fetch(
			`${API_HOSTNAME}/api/SendEmail/SendMailOtp`,
			requestOptions
		);
		const respuesta = await response.json();
		if (respuesta.statusCode === 200) {
			return true;
		} else {
			enqueueSnackbar(
				'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con el creador del circuito.',
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
			throw Error(respuesta.result.information);
		}
	} catch (error) {
		return false;
	}
};

export const validarOTP = async (token, codeMail, enqueueSnackbar) => {
	let ip = '127.0.0.1';
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	myHeaders.append('Content-Type', 'application/json');

	let raw = JSON.stringify({
		ip,
		code: codeMail,
	});

	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	try {
		const response = await fetch(
			`${API_HOSTNAME}/api/SendEmail/ValidateCodeOtp`,
			requestOptions
		);
		const respuesta = await response.json();
		if (respuesta.httpStatus != 200) {
			if (respuesta.result.informationCode === 'RG2') {
				enqueueSnackbar(
					`${respuesta.result.information}. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (respuesta.result.informationCode === 'RE2') {
				enqueueSnackbar(
					`Error interno de la aplicación. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				enqueueSnackbar(respuesta.result.information, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
			throw Error(respuesta.result.information);
		}
		return respuesta;
	} catch (error) {
		return null;
	}
};

