import {
	LOAD_LIST_SIGNED_DOCUMENT,
	LIST_DOCUMENTS_DOWNLOAD,
	SET_LOADING,
	SET_LIST_CERTIFICATES,
	SET_PLAN_LIST,
	DOCUMENTS_SIGN,
	PENDING_SIGN,
	PENDING_OBSERVE,
} from '../types/ConsultSignedDocumentsTypes';

function createData(
	nombrereal,
	descripcionTipoFirma,
	descripcionOrigenFirma,
	fechaFirma
) {
	return {
		nombrereal,
		descripcionTipoFirma,
		descripcionOrigenFirma,
		fechaFirma,
	};
}
function formatFecha(fecha) {
	// var fecha = new Date();
	const options = { year: 'numeric', month: 'long', day: 'numeric' };

	return fecha.toLocaleDateString('es-ES', options);
}

const INITIAL_STATE = {
	listSignedDocument: [
		// createData('Cupcake.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Donut.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Eclair.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Frozen yoghurt.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Gingerbread.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Honeycomb.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Ice cream sandwich.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Jelly Bean.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('KitKat.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Lollipop.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Marshmallow.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Nougat.pdf', 'estampa', 'Token', formatFecha(new Date())),
		// createData('Oreo.pdf', 'estampa', 'Token', formatFecha(new Date()))
	],
	loading: false,
	signedDocumentsSelect: [],
	certificates: [],
	planList: [],
	documentSign: [],
	pendingSign: [],
	pendingObserve: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOAD_LIST_SIGNED_DOCUMENT:
			return {
				...state,
				listSignedDocument: action.payload,
			};
		case LIST_DOCUMENTS_DOWNLOAD:
			return {
				...state,
				signedDocumentsSelect: action.payload,
			};
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_LIST_CERTIFICATES:
			return {
				...state,
				certificates: action.payload,
			};
		case SET_PLAN_LIST:
			return {
				...state,
				planList: action.payload,
			};
		case DOCUMENTS_SIGN:
			return {
				...state,
				documentSign: action.payload,
			};
		case PENDING_SIGN:
			return {
				...state,
				pendingSign: action.payload,
			};
		case PENDING_OBSERVE:
			return {
				...state,
				pendingObserve: action.payload,
			};
		default:
			return state;
	}
};
