import { Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import check from "../../../../assets/img/checks.png";
import rechazado from "../../../../assets/img/firmaRechazada.png";
import eliminadoCF from "../../../../assets/img/eliminadoDeCF.png";
import { LANDING_PAGE_FIRMAYA } from "../../../../redux/types/ApiConstants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: "0px 3px 0px #00000033 !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "438px",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "&:focus": {
      outline: "none",
    },
    "@media(max-width:700px)": {
      width: "350px",
      height: "400px",
    },
    "@media(max-width:400px)": {
      width: "300px",
      height: "400px",
    },
  },
  flx: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    marginTop: "10px",
    paddingBottom: "10px",
  },
  img: {
    marginTop: "45px",
    marginBottom: "12px",
    "@media(max-width:700px)": {
      marginTop: "0px",
    },
  },
  continue: {
    backgroundColor: "#E55200",
    color: "white",
    textTransform: "none",
    borderRadius: "10px",
    fontWeight: "800",
    border: "none !important",
    fontWeight: "bold !important",
    marginTop: "20px",
    fontSize: "16px !important",
    padding: "5px 25px ",
    "&:hover": {
      backgroundColor: "#E55200",
    },
    "@media(max-width:700px)": {
      padding: "8px 24px",
    },
  },
}));

const MessagePantallaCarga = (props) => {
  //This modal receives as props
  // message: "It is the message that the modal will show", "its a string"
  // checks : (true or false) to display the image of the check
  // circuitoEliminado:(true or false) to display the image that the circuit was removed
  // msgBtn: "In case the button says something other than Entendido", "its a string" must either arrive false or null in case of leaving the word Entendido
  // personDeleted: (true or false) To show the image of when the person is removed from the circuit
  const {
    message,
    checks = false,
    irHome = false,
    circuitoEliminado = false,
    personaEliminada = false,
    msgBtn = "Entendido",
  } = props;
  const classes = useStyles();
  const url = LANDING_PAGE_FIRMAYA;
  const his = useHistory();

  const closeModal = () => {
    if (irHome) {
      localStorage.removeItem("URL_Circuito");
      his.push("/home");
    } else {
      window.open(url, "_self");
    }
    //return window.open(url, "_self");
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      disableBackdropClick
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modal}>
        {checks && <img src={check} className={classes.img} />}
        {circuitoEliminado && <img src={rechazado} className={classes.img} />}
        {personaEliminada && <img src={eliminadoCF} className={classes.img} />}
        <h2
          style={{
            color: "#E55200",
            fontSize: "24px",
            fontFamily: "Mulish",
            marginBottom: "0",
            padding: " 0 35px",
            fontWeight: "700",
          }}
        >
          {message}
        </h2>
        <div className={classes.flx}>
          <Button onClick={() => closeModal()} className={classes.continue}>
            {msgBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default MessagePantallaCarga;
