import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Modal, IconButton, Popover, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect } from 'react';
 
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#FFEFD8',
        font: 'font: normal normal bold 11px/16px Muli',
        letterSpacing: '0px',
        color: '#E55200',
        opacity: '1',
    },
    body: {
        fontSize: '12px',
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fff',
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: 'none',
        marginBottom: '20px',
        overflow: 'hidden !important'
    },
    typography: {
        padding: '20px',
        textAlign: 'center',
        width: '100%',
        minWidth: '300px',
    },
}));

export default function ResponsiveDialog(props) {

    const {
        onClose, signatures
    } = props;

    const classes = useStyles();
    const [isElectronic, setElectronic] = useState(false);

    const tipoDocumento = (tipo) => {
        var count = (tipo.match(/\./g) || []).length;
        if (count == tipo.length - count) return tipo;
        else return tipo + '.';
    }

    useEffect(() => {
        (async () => {
            signatures.map(signature => {
                if (signature.cn.includes('validateOTP')) setElectronic(true);
            })
        })()
    }, []);

    return (
        <div className={'over-list'}>
            <>
                <div style={{ color: '#E55200', marginLeft: '20px' }}>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="space-around"
                        alignItems="flex-start"
                    >
                        <Grid item xs={8}>
                            <h3>Lista de firmantes</h3>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton
                                className={'btn-close-popup'}
                                aria-label="Cerrar"
                                onClick={() => onClose(false)}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className="dialog-container" style={{overflowX : 'hidden'}} >
                    {
                        typeof signatures !== 'undefined' && signatures.length ? (
                            <>
                                <TableContainer component={Paper} className={classes.paper}>
                                    <Table className={'tableDocumentSigned'}>
                                        <TableHead className={'headerTableDocumentSigned'}>
                                            <TableRow>
                                                <StyledTableCell width='70%' align="center">Nombre</StyledTableCell>
                                                <StyledTableCell width='15%' align="center">Tipo documento</StyledTableCell>
                                                <StyledTableCell width='15%' align="center">No. documento</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {signatures.map((lista, index) =>
                                                <>
                                                    {!lista.cn.includes('validateOTP') &&
                                                        <StyledTableRow style={{ color: '#E55200' }} key={index}>
                                                            <StyledTableCell align="center">{lista.cn}</StyledTableCell>
                                                            <StyledTableCell align="center">{tipoDocumento(lista.tipoDocumento)} </StyledTableCell>
                                                            <StyledTableCell align="center">{lista.numeroDocumento}</StyledTableCell>
                                                            <StyledTableCell className="space-between"></StyledTableCell>
                                                        </StyledTableRow>
                                                    }
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {isElectronic &&
                                    <>
                                        {signatures.map((lista, index) =>
                                            <>
                                                {lista.cn.includes('validateOTP') &&
                                                    <div className="dialog-container">
                                                        <p>Verificar documentos: 
                                                            <a style={{ color: '#E55200' }} target="_blank" href={lista.cn}> Validador de documentos</a>.
                                                        </p>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </>
                                }
                            </>
                        )
                            :
                            (
                                <Typography className={classes.typography}>
                                    Actualmente no tiene firmantes.
                                </Typography>
                            )
                    }
                </div>
            </>
        </div>
    );
}