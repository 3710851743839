/* eslint-disable import/prefer-default-export */
export const Rules = {
  email: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      message: 'Debe ingresar una dirección de correo válida',
    },
  },
  password: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[-_@.,;+{}=()^/*&%$#!¡¿?"'|áéíóúÁÉÍÓÚ])(?!.*?[ ])/,
      message: 'Debe ingresar una contraseña con al menos un número una letra mayúscula una minúscula y un carácter especial',
    },
    minLength: {
      value: 8,
      message: 'Longitud mínima de 8 caracteres',
    },
  },
  required: {
    required: 'Campo obligatorio',
  },
};

