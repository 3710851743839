import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Recipient from '../views/EncryptDecrypt/ModalRecipient';
import dumpIcon from '../assets/img/dumpIcon.png'
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../components/Loading';
import { Modal } from '@material-ui/core';
 
const stylesDrop = makeStyles({
	dropzoneTextStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
		paddingTop:"20px", 
		paddingBottom:"20px"
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#EDEDED',
		},
	},
	listContainer: {
		width: '100%',
		marginBottom: '5px',
		display: 'flex',
		justifyContent: 'center',
	},
});

const ZoneUploadRecipient = (props) => {
	const { button, selectedCerts, setSelectedCerts } = props;
	const [isResponsive, setResponsive] = useState(window.innerWidth <= 600 ? false : true);
	const [isLoading, setLoading] = useState(false);
	const [statusModalRecipient, setStatusModalRecipient] = useState(false);
	
	window.addEventListener('resize', function (event) {
		if (this.window.innerWidth <= 600) setResponsive(false);
		else setResponsive(true);
	}, true);
	
	const handleClick = (event) => {
		setStatusModalRecipient(true);
	};

	const { enqueueSnackbar } = useSnackbar();

	const onDelete = (fileToDelete, cn) => {
		setSelectedCerts(selectedCerts.filter((selectedCerts) => selectedCerts.uuid !== fileToDelete));
		var name = cn.length > 29 ? cn.substring(0, 29) + '...' : cn;
		enqueueSnackbar(`Destinatario ${cutNameTitle(name)} eliminado.`, {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const cutNameTitle = (name) => {
		if(name != null && name.length > (isResponsive ? 70 : 10)) return (name.substring(0, (isResponsive ? 70 : 10)).concat('...'))
		else return name
	};

	const cutName = (name) => (
		name != null && name.length > (isResponsive ? 70 : 39) ? (
			<Tooltip title={name}>
				<div>{name.substring(0, (isResponsive ? 70 : 39)).concat('...')}</div>
			</Tooltip>
		) : <div>{name}</div>
	);

	const classes = stylesDrop();

	return (
		<div className="container">
			<div id="containerRecipient" className="base-style-container">
				<>
					<div className={classes.listContainer} >
						{selectedCerts.length > 0 &&
							<List dense style={{ width: '100%' }}>
								<div>
									{selectedCerts.map((element, index) => (
										<ListItem key={index} className='contentZoneUploadList'>
											<ListItemText
												primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#000000' } }}
												secondaryTypographyProps={{ style: { fontWeight: 'bold', color: '#575756' } }}
												primary={cutName(element.cn)}
												secondary={`Cert. ${element.tipo} - Identificador: ${element.userId}`}
											/>
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													aria-label="delete"
													onClick={() => onDelete(element.uuid, element.cn)}
												>
													<img src={dumpIcon} width='15px' />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
									{isLoading == true &&
										<Loading />
									}
								</div>
							</List>}
					</div>
				</>
				<div className={classes.dropzoneTextStyle}>
					<Button
						disableElevation
						className={'buttonRecipient'}
						onClick={handleClick}
						disabled={button}
						endIcon={<AddIcon style={{ marginLeft: '-2px', fontSize: 25 }} />}
					>
						Agregar destinatario
                 	</Button>
				</div>
				<Modal
					open={statusModalRecipient}
					onClose={() => {
						setStatusModalRecipient(0)
					}}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					disableBackdropClick
				>
					<div>
						<Recipient
							selectedCerts={selectedCerts}
							setSelectedCerts={setSelectedCerts}
							setStatusModalRecipient={setStatusModalRecipient}
						/>
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default ZoneUploadRecipient;