import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	makeStyles,
	Modal,
	Paper,
	Select,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../components/Loading';
import { Rules } from '../../../../helpers/RHFRules';
import MuiPhoneNumber from 'material-ui-phone-number';

import {
	modalFinished,
	modalUps,
} from '../../../../redux/actions/CircuitoFirmasAction';
import {
	API_HOSTNAME,
	API_HOSTNAME_AWS,
	API_HOSTNAME_SIGNS,
	APP_KEY,
	MAX_NUMBER_ATTEMPS_ELECTRONIC,
	PASSWORD_DIGITAL,
	USUARIO_DIGITAL,
} from '../../../../redux/types/ApiConstants';
import {
	cambiarEstadoFirmante2,
	notificar2,
	notificarCreador2,
} from '../../../../redux/actions/CircuitActions';
import { encriptar } from '../../../../redux/actions/Encriptar';
import MenuItem from '@material-ui/core/MenuItem';
import { TOKEN_CONNECTION } from '../../../../redux/types/SignTypes';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	resize: {
		paddingLeft: '4%',
		fontSize: '15px',
		letterSpacing: '0px',
		color: 'black',
		opacity: '1',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '5px 0 5px',
	},
	form: {
		width: '100%',
	},
	formElements: {
		marginBottom: '10px',
	},
	dialogAction: {
		justifyContent: 'center',
		marginBottom: '5px',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	textModalElectro: {
		font: 'normal normal 100 16px/24px Muli',
		padding: '16px 10px 0px',
		color: '#4F4F4F',
	},
	textModalElectro2: {
		font: 'normal normal 100 16px/24px Muli',
		padding: '28px 10px 0px',
		color: '#4F4F4F',
	},
	validateCheck: {
		font: 'normal normal 600 16px/20px Muli',
		margin: '35px 48px 14px',
	},
	validateCode: {
		font: 'normal normal 600 16px/20px Muli',
		margin: '37px 70px 14px',
	},
	validateLink: {
		font: 'normal normal 600 16px/20px Muli',
		color: '#E55200',
		margin: '30px 90px 22px',
		textDecoration: 'underline',
		textTransform: 'inherit',
		cursor: 'pointer',
	},
	input: {
		height: 5,
	},
	cssLabel: {
		font: 'normal normal normal 15px/22px Muli',
		color: '#707070 !important',
	},
}));

// const TextFieldVerify = withStyles({
// 	root: {
// 		'& input:valid + fieldset': {
// 			boxShadow: '0px 3px 0px #00000033 !important',
// 			border: '0.8387856483459473px solid #706F6F !important',
// 			borderRadius: '10px !important',
// 			padding: '5px !important',
// 		},
// 		'& input:valid:focus + fieldset': {
// 			boxShadow: '0px 3px 0px #00000033 !important',
// 			border: '0.8387856483459473px solid #706F6F !important',
// 			borderRadius: '10px !important',
// 			padding: '5px !important',
// 		},
// 		'& label.Mui-focused': {
// 			left: '-45px !important',
// 			marginTop: '-2px',
// 			font: 'normal normal normal 14px/22px Muli',
// 			color: '#707070',
// 		},
// 		'& label': {
// 			textAlign: 'center !important',
// 			width: '90%',
// 			marginTop: '-9px',
// 			font: 'normal normal normal 18px/22px Muli',
// 			color: '#B2B2B2',
// 		},
// 		opacity: 0.7,
// 	},
// })(TextField);

const TextFieldVerifyStyle = withStyles({
	root: {
		'& input:valid + fieldset': {
			boxShadow: '0px 3px 0px #00000033',
			border: '0.8387856483459473px solid #706F6F',
			borderRadius: '10px',
			padding: '5px !important',
		},
		'& input:valid:focus + fieldset': {
			boxShadow: '0px 3px 0px #00000033',
			border: '0.8387856483459473px solid #706F6F',
			borderRadius: '10px',
			padding: '5px !important',
		},
		color: '#B2B2B2 !important',
		font: 'normal normal 600 18px/22px Muli',
	},
})(TextField);

const defaultValues = {
	tipoCertificado: '',
	tsaId: '',
	tsaPassword: '',
};

const runTextScript = text => {
	const s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.innerHTML = text;
	document.body.appendChild(s);
	document.body.removeChild(s);
};

const TextFieldVerify = withStyles({
	root: {
		'& input:valid + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& input:valid:focus + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& label.Mui-focused': {
			left: '-45px !important',
			marginTop: '-2px',
			font: 'normal normal normal 14px/22px Muli',
			color: '#707070',
		},
		'& label': {
			width: '90%',
			marginTop: '-9px',
			font: 'normal normal normal 18px/22px Muli',
			color: '#B2B2B2',
		},
		opacity: 0.7,
	},
})(TextField);

const ModalOtpCF = ({
	onMobileNumberChange,
	sendElecSign,
	email,
	setSendElecSign,
	setStatusModalSign,
	firmante,
	circuito,
	sendData,
	ultimaPosicion,
	loading,
	services,
	setLoading,
	// setRedireccionar,
	setErrorS3,
	lineal,
	solicitarCodeOTP,
	cellphone,
	onSendOptionChange,
	option,
	vencimiento
}) => {
	//This modal receives by props
	//sendElecSign = It is a number, it can be 1 or 2. When sending the number 1 it will show the otp code verification modal. When sending the number 2 the modal will be shown to resend the otp code
	//setSendElecSign = function to update the state of the parent and change the number to show one modal or another
	//setStatusModalSign = function to open or close this modal

	const { handleSubmit, errors, control } = useForm({ defaultValues });
	const classes = useStyles();
	const dispatch = useDispatch();
	const [enableButton, setEnableButton] = useState(true);
	const [codeMail, setCodeMail] = useState('');
	const states = useSelector(state => state.CircuitoFirmasReducer);
	const token = states.tokenGenerico;
	const { enqueueSnackbar } = useSnackbar();
	const [attemps, setAttemps] = useState(0);
	const [loadingModal, setLoadingModal] = useState(false);
	//Hay una validacion y si isElectronic esta en false no envia codeId
	const isElectronic = true;
	const [errorsS3Circuito, setErrorsS3Circuito] = useState();
	let lastSigner;
	const [mobileNumberError, setMobileNumberError] = useState('');
	const [sendToOption, setSendToOption] = useState(option || 'email');
	const [mobileNumber, setMobileNumber] = useState(cellphone || '');
	const spanishTranslations = {
		Albania: 'Albania',
		Andorra: 'Andorra',
		Austria: 'Austria',
		Belarus: 'Bielorrusia',
		Belgium: 'Bélgica',
		'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
		Bulgaria: 'Bulgaria',
		Croatia: 'Croacia',
		Cyprus: 'Chipre',
		'Czech Republic': 'República Checa',
		Denmark: 'Dinamarca',
		Estonia: 'Estonia',
		Finland: 'Finlandia',
		France: 'Francia',
		Germany: 'Alemania',
		Greece: 'Grecia',
		Hungary: 'Hungría',
		Iceland: 'Islandia',
		Ireland: 'Irlanda',
		Italy: 'Italia',
		Latvia: 'Letonia',
		Liechtenstein: 'Liechtenstein',
		Lithuania: 'Lituania',
		Luxembourg: 'Luxemburgo',
		Malta: 'Malta',
		Moldova: 'Moldavia',
		Monaco: 'Mónaco',
		Montenegro: 'Montenegro',
		Netherlands: 'Países Bajos',
		'North Macedonia': 'Macedonia del Norte',
		Norway: 'Noruega',
		Poland: 'Polonia',
		Portugal: 'Portugal',
		Romania: 'Rumanía',
		Russia: 'Rusia',
		'San Marino': 'San Marino',
		Serbia: 'Serbia',
		Slovakia: 'Eslovaquia',
		Slovenia: 'Eslovenia',
		Spain: 'España',
		Sweden: 'Suecia',
		Switzerland: 'Suiza',
		Ukraine: 'Ucrania',
		'United Kingdom': 'Reino Unido',
		'Vatican City': 'Ciudad del Vaticano',
		Argentina: 'Argentina',
		Bolivia: 'Bolivia',
		Brazil: 'Brasil',
		Canada: 'Canadá',
		Chile: 'Chile',
		Colombia: 'Colombia',
		'Costa Rica': 'Costa Rica',
		Cuba: 'Cuba',
		'Dominican Republic': 'República Dominicana',
		Ecuador: 'Ecuador',
		'El Salvador': 'El Salvador',
		Guatemala: 'Guatemala',
		Honduras: 'Honduras',
		Mexico: 'México',
		Nicaragua: 'Nicaragua',
		Panama: 'Panamá',
		Paraguay: 'Paraguay',
		Peru: 'Perú',
		'Puerto Rico': 'Puerto Rico',
		Uruguay: 'Uruguay',
		'United States': 'Estados Unidos',
		Venezuela: 'Venezuela',
		'Antigua and Barbuda': 'Antigua y Barbuda',
		'British Virgin Islands': 'Islas Vírgenes Británicas',
		Greenland: 'Groenlandia',
		'Caribbean Netherlands': 'Caribe Neerlandés',
		'Cayman Islands': 'Islas Caimán',
		'Falkland Islands': 'Islas Malvinas',
		'Faroe Islands': 'Islas Feroe',
		'French Guiana': 'Guayana Francesa',
		Grenada: 'Granada',
		Martinique: 'Martinica',
		'Saint Barthélemy': 'San Bartolomé',
		'Saint Kitts and Nevis': 'San Cristóbal y Nieves',
		'Saint Lucia': 'Santa Lucia',
		'Saint Martin': 'San Martin',
		'Saint Pierre and Miquelon': 'San Pedro y Miquelón',
		'Saint Vincent and the Grenadines': 'San Vicente y las Granadinas',
		'Sint Maarten': 'San Martín',
		'Turks and Caicos Islands': 'Islas Turcas y Caicos',
		'U.S. Virgin Islands': 'Islas Vírgenes de los Estados Unidos',
		Turkey: 'Turquía',
		'Trinidad and Tobago': 'Trinidad y Tobago',
	};

	useEffect(() => {
		onSendOptionChange(sendToOption);
	}, [sendToOption]);
	useEffect(() => {
		if ( sendElecSign == 3) {
			setMobileNumber('');
		}
	}, [sendToOption]);



	const onClose = () => {
		setStatusModalSign(false);
		

	};

	const handleInputChange = event => {
		if (event !== '') {
			setEnableButton(false);
			setCodeMail(event);
		} else {
			setEnableButton(true);
			setCodeMail('');
		}
	};
	// change part of the email to ** for security
	let newEmail = email.replace(/^(.+?)(@)/, function (match, firstpart, at) {
		let last4 = firstpart.substring(firstpart.length - 3);
		let asterisks = '';
		for (let i = 0; i < firstpart.length - 3; i++) {
			asterisks += '*';
		}
		return asterisks + last4 + at;
	});
	const handleMobileNumberChange = (value, countryData) => {
		// Quita el signo + al inicio si lo hay
		const valorSinPlus = value.replace(/^\+/, '');

		// Obtiene el diaCode (indicativo) del objeto countryData
		const indicativo = countryData.dialCode;

		// Calcula el número sin el indicativo
		const numeroSinIndicativo = valorSinPlus.substring(indicativo.length);

		// Formatea el número con el guion después del indicativo
		const valorFormateado = `${indicativo}-${numeroSinIndicativo}`;

		// Establece el número de teléfono formateado
		setMobileNumber(valorFormateado);
		onMobileNumberChange(valorFormateado);

		// Validar que el número de móvil tenga entre 10 y 13 caracteres y sean todos numéricos
		// Aquí es importante validar el valor sin el + y sin el guion para contar solo los dígitos
		if (!/^\d{10,13}$/.test(valorSinPlus)) {
		
			setMobileNumberError(
				sendToOption === 'mobile'
					? 'El número debe contener entre 10 y 13 caracteres numéricos.'
					: 'El número WhatsApp debe contener entre 10 y 13 caracteres numéricos.'
			);
		} else {
			setMobileNumberError(''); // Limpiar el error si el formato es correcto
		}
	};

	//Function to change the status of the circuit.
	const cambiarEstado = async () => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');

			let raw = JSON.stringify({
				circuito: circuito,
				estadoCircuito: '',
				firmante: firmante,
				estadoFirmante: '2',
				correoFirmante: '',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			const respCambioEstado = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const { result } = await respCambioEstado.json();
			return result;
		} catch (error) {
			console.error('Ocurrio un error', error);
			return null;
		}
	};

	const vencerCircuito = async () => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + token);
			myHeaders.append('Content-Type', 'application/json');

			let raw = JSON.stringify({
				circuito: circuito,
				estadoCircuito: '3',
				firmante: firmante,
				estadoFirmante: '',
				correoFirmante: '',
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};
			const respCambioEstado = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
				requestOptions
			);
			const { result } = await respCambioEstado.json();
			return result;
		} catch (error) {
			console.error('Ocurrio un error', error);
			return null;
		}
	};

	const validarOTP = async () => {
		setLoadingModal(true);

		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		if (mobileNumber) {
			const formattedMobileNumber = mobileNumber.replace(
				/(\d{2})(\d+)/,
				'$1-$2'
			);
			let raw = JSON.stringify({
				email: email,
				firmante: firmante,
				code: codeMail,
				celular: mobileNumber,
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};

			try {
				const response = await fetch(
					`${API_HOSTNAME}/api/SendEmail/ValidateCodeOtp`,
					requestOptions
				);
				const respuesta = await response.json();
				if (respuesta.httpStatus != 200) {
					if (respuesta.result.informationCode === 'RG2') {
						enqueueSnackbar(
							`${respuesta.result.information}. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else if (respuesta.result.informationCode === 'RE2') {
						enqueueSnackbar(
							`Error interno de la aplicación. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else {
						enqueueSnackbar(respuesta.result.information, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
					setLoadingModal(false);
					//onClose();
					//setLoading(false);
					throw Error(respuesta.result.information);
				}
				return respuesta;
			} catch (error) {
				return null;
			}
		} else {
			let raw = JSON.stringify({
				email: email,
				firmante: firmante,
				code: codeMail,
				//celular
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};

			try {
				const response = await fetch(
					`${API_HOSTNAME}/api/SendEmail/ValidateCodeOtp`,
					requestOptions
				);
				const respuesta = await response.json();
				if (respuesta.httpStatus != 200) {
					if (respuesta.result.informationCode === 'RG2') {
						enqueueSnackbar(
							`${respuesta.result.information}. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else if (respuesta.result.informationCode === 'RE2') {
						enqueueSnackbar(
							`Error interno de la aplicación. Por favor intente de nuevo y si el problema persiste,\npóngase en contacto con soporte`,
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else {
						enqueueSnackbar(respuesta.result.information, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					}
					setLoadingModal(false);
					// onClose();
					// setLoading(false);
					throw Error(respuesta.result.information);
				}
				return respuesta;
			} catch (error) {
				return null;
			}
		}
	};

	const notificarCreador = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		let raw;
		if (ultimaPosicion) {
			//Notifica al creador que el circuito ha finalizado
			raw = JSON.stringify({
				Circuito: circuito,
				firmante: '',
				notificacion: 3,
				motivo: '',
			});
		} else {
			//Notifica al creador que ese firmante ya realizo su firma
			raw = JSON.stringify({
				Circuito: circuito,
				firmante: firmante,
				notificacion: 1,
				motivo: '',
			});
		}
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/notify',
				requestOptions
			);
			const result_1 = await response.json();
		} catch (error) {
			return console.error('error', error);
		}
	};
	// resends the OTP code for validation
	const reenviarOTP = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		const formattedMobileNumber = mobileNumber.replace(/(\d{2})(\d+)/, '$1-$2');
	if (sendToOption === 'WhatsApp') {
		let raw = JSON.stringify({
			phoneNumber: mobileNumber.split('-')[1], // Número sin el código del país
			identificator: mobileNumber.split('-')[0], // Código del país
			message: 'Tu código OTP es: ',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}sendOtp/api/whatsapp/Send`,
				requestOptions
			);
			const respuesta = await response.json();
			if (respuesta.http === 200) {
				return true;
			} else if (
				respuesta.code === 'A065'
			) {
				enqueueSnackbar(
					'Número de celular no valido',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				
				throw Error(respuesta.descripcionRespuesta);
			} else {
				enqueueSnackbar(
					'Ocurrió un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con el creador del circuito.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				throw Error(respuesta.result.information);
			}
		} catch (error) {
			return false;
		}
	} else if (mobileNumber !== '') {
		let raw = JSON.stringify({
			celular: mobileNumber,
			TextoOTP:
				'Firmaya te ha enviado tu codigo para tu firma electronica, por favor, no lo compartas con nadie ',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		// setLoading(true)

		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}sendOtp/api/types/Send`,
				requestOptions
			);
			const respuesta = await response.json();
			if (respuesta.http === 200) {
				return true;
			} else {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con el creador del circuito.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				throw Error(respuesta.result.information);
			}
		} catch (error) {
			return false;
		}
	} else {
		let raw = JSON.stringify({
			email: email,
			celular: '',
			aplicacion: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		// setLoading(true)

		try {
			const response = await fetch(
				`${API_HOSTNAME}/api/SendEmail/SendMailOtp`,
				requestOptions
			);
			const respuesta = await response.json();
			if (respuesta.statusCode === 200) {
				return true;
			} else {
				enqueueSnackbar(
					'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con el creador del circuito.',
					{
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
				throw Error(respuesta.result.information);
			}
		} catch (error) {
			return false;
		}
	}
	};
	// receives the validation of the otp code, if it is not ok, it will close the modal
	const ejecutar = async () => {
		setLoadingModal(true);
		const envioDeOtp = await reenviarOTP();
		setLoadingModal(false);
		if (envioDeOtp) {
			setSendElecSign(1);
			setStatusModalSign(true);
		} else {
			setStatusModalSign(false);
		}
	};

	const terminaFirma = redirect => {
		if (redirect) {
			onClose();
		}
	};

	function _base64ToArrayBuffer(base64) {
		var binary_string = window.atob(base64);
		var len = binary_string.length;
		var bytes = new Uint8Array(len);
		for (var i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}

	
	const saveDocsDigital = async ( estructura, TokenCF ) => {
		try {
			let myHeaders = new Headers();
			myHeaders.append('Authorization', 'Bearer ' + TokenCF);
			myHeaders.append('Content-Type', 'application/json');
	
			const raw = JSON.stringify(estructura);
	
			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow',
			};

			const response = await fetch(API_HOSTNAME_AWS + 'expdoc', requestOptions);
			
			const result = await response.json();
			
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const S3Circuito = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		let filesPut = [];
		await Promise.all(
			sendData.documents.map(f => {
				filesPut.push({ nombreReal: f.fileName, nombre: f.nombre });
			})
		);
		let raw = JSON.stringify({
			application: 'FirmaYaWeb',
			repository: '1',
			ltv: false,
			estampa: false,
			firmaelectronica: false,
			documentos: filesPut,
			ruta: sendData.ruta,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		try {
			const response = await fetch(
				`${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/generateurls3Circuito`,
				requestOptions
			);
			const result_1 = await response.json();
			if (!response.ok) {
				setErrorsS3Circuito(result_1.descripcionRespuesta);
				throw new Error(result_1.httpStatus);
			}
			return result_1;
		} catch (error) {
			if (error >= 400 && error < 500) {
				if (error == 401) {
					// + error.response.data.descripcionRespuesta
					enqueueSnackbar('No tiene disponibilidad para firmar', {
						variant: 'warning',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				} else {
					enqueueSnackbar(errorsS3Circuito, {
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
				}
			} else {
				enqueueSnackbar(
					'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
					{
						variant: 'error',
						style: { whiteSpace: 'pre-line' },
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			}
			terminaFirma(false);
			return false;
		}
	};
	const advancedSignRequest = async toSend => {
	
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: toSend,
			redirect: 'follow',
		};
		let errorAdvancedS3;
		try {
			const response = await fetch(
				`${API_HOSTNAME_SIGNS}api/sign/advancedsignatures3`,
				requestOptions
			);
			const responsePut = await response.json();
			errorAdvancedS3 = responsePut;
			
			if (responsePut.httpStatus == 200) {
				const {codeResponse: codigoRespuesta} = responsePut
				if (codigoRespuesta == 'RS1') {
					enqueueSnackbar('Firma completa', {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					});
					await cambiarEstado();
					await cambiarEstadoFirmante2(circuito, firmante, 2, token);
					const result_1 = await estadoCircuito();
				lastSigner = result_1.inFirmantes
					.filter(firmante => firmante.esObservador === 0)
					.every(firmante => firmante.estado === 2 || firmante.estado === 5);
					if (result_1.circuito.documentoURL && lastSigner) {
						const estructura = {
							radicado: result_1.circuito.radicado,
							circuitCode: result_1.circuito.id,
							urlDocument: result_1.documentos.map(doc=>doc.urlGet),
							user: USUARIO_DIGITAL,
							password: PASSWORD_DIGITAL,
							urlSave: result_1.circuito.urlSave,
						};
						saveDocsDigital(estructura, token)
					}
					if (lineal) {
						if (!lastSigner) {
							await notificar2(token, circuito, 0);
						}
					}
					//Aqui es donde se envia el lastSigner
					await notificarCreador2(lastSigner, circuito, firmante, token);
					setLoadingModal(false);
					dispatch(modalFinished(true));
					terminaFirma(true);
					onClose();
					return;
				} else {
					responsePut.documentos.forEach(element => {
						enqueueSnackbar(element.mensaje, {
							variant: 'warning',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
					});
					terminaFirma(false);
					return;
				}
			} else if (responsePut.codigoRespuesta === 'E2') {
				setTimeout(() => {
					advancedSignRequest(toSend);
				}, 3000);
			} else {
				throw Error(responsePut.httpStatus);
			}
		} catch (error) {
			if (error == 'Error: 401') {
				if (
					errorAdvancedS3.descripcionRespuesta.includes(
						'privilegio de firma digital centralizada'
					)
				) {
					notificar2(token, circuito, 4);
				}
				setLoadingModal(false);
				dispatch(modalUps(true));
				onClose();
			} else if (error == 'Error: 400') {
				setLoadingModal(false);
				dispatch(modalUps(true));
				onClose();
			} else {
				dispatch(modalUps(true));
				onClose();
				setLoadingModal(false);
			}
		}
	};

	const estadoCircuito = async () => {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');
		let raw = JSON.stringify({
			circuito: circuito,
			firmante: firmante,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		try {
			const response = await fetch(
				API_HOSTNAME_AWS + 'webCircuit/api/Signatory',
				requestOptions
			);
			const result_1 = await response.json();			
			if (result_1.httpStatus == 200) {
				return result_1;
			} else {
				if (result_1.responseCode == 'S02') {
					setErrorS3('Ocurrio un error por favor intente de nuevo');
					throw Error(result_1.httpStatus);
				}
			}
		} catch (error) {
			return error;
		}
	};

	const SignCustomWithoutdocumentS3CF = async (
		enqueueSnackbar,
		terminaFirma
	) => {
		const result_1 = await estadoCircuito();
		if (result_1.circuito.estado == 4) {
			// setRedireccionar(true);
			setErrorS3('No se puede firmar porque el Circuito ha sido rechazado');
			dispatch(modalUps(true));
			setLoading(false);
			onClose();
		} else if (result_1.circuito.estado == 5) {
			setErrorS3('No se puede firmar porque el Circuito ha sido eliminado');
			dispatch(modalUps(true));
			//		setLoading(false);
			onClose();
		} else if (result_1.circuito.estado == 3) {
			setErrorS3('No se puede firmar porque el Circuito ha expirado');
			dispatch(modalUps(true));
			//	setLoading(false);
			onClose();
		} else {
			const response = await S3Circuito();
			if (response?.httpStatus === 200) {
				const listFiles = [];
				const listSign = [];
				await Promise.all(
					sendData.documents.map(async f => {
						listFiles.push({
							uuid: f.uuid,
							nombreReal: f.fileName,
							nombre: f.nombre,
							base64: f.base64,
						});
					})
				);
				await Promise.all(
					response.documentos.map(async file => {
						var bytes = null;
						listFiles.map(f => {
							if (file.nombreReal == f.nombreReal) {
								bytes = _base64ToArrayBuffer(f.base64);
							}
						});
						if (bytes != null) {
							await Promise.all(
								sendData.documents.map(async f => {
									if (file.nombreReal == f.fileName) {
										listSign.push({
											uuid: f.uuid,
											fileName: file.nombre,
											realFileName: file.nombreReal,
											urlGet: file.urlGet,
											urlPut: file.urlPut,
											typeSignature: f.typeSignature,
											location: file.location,
											listSigns: f.listSigns,
										});
									}
								})
							);
						}
					})
				);
				const toSend = JSON.stringify({
					signerClientId: sendData.signerClientId,
					key: encriptar(sendData.key, APP_KEY),
					firmaelectronica: sendData.firmaelectronica,
					codeId: sendData.codeId,
					documents: listSign,
					idCircuit: circuito,
					firmanteCircuito: firmante,
				});

				advancedSignRequest(toSend);
			} else {
				setLoadingModal(false);
			}
		}
	};
	const onSubmit = async data => {
		if (sendToOption === 'email') {
			setMobileNumber('');
			onMobileNumberChange('');
			solicitarCodeOTP();
			setStatusModalSign(false);
			// await handleEmailVerification();
			// Asumiendo que no quieres continuar con el resto de la lógica de onSubmit si esto sucede.
			return;
		}
		if (sendToOption === 'mobile') {
			solicitarCodeOTP();
			setStatusModalSign(false);
			// await handlemobileVerification();
			// Incluye aquí la lógica para manejar el número de móvil
			// Por ejemplo, podrías querer enviar este número a un backend o utilizarlo para la verificación
			return;
		}
		if (sendToOption === 'WhatsApp') {
			solicitarCodeOTP();
			setStatusModalSign(false);
			// await handlemobileVerification();
			// Incluye aquí la lógica para manejar el número de móvil
			// Por ejemplo, podrías querer enviar este número a un backend o utilizarlo para la verificación
			return;
		}
	};
	const cerrarModal = () => {
		dispatch({ type: TOKEN_CONNECTION, payload: false });
	};

	const signApply = async (signerClientId, key, codeId, isElectronic) => {
		if (signerClientId == 'Token' && key == 'Token') {
			sendData.key = null;
			sendData.signerClientId = null;
			sendData.documents.map(data => {
				data.typeSignature.map((signature, index) => {
					signature.id = index;
				});
			});
			setStatusModalSign(false);
		} else {
			sendData.signerClientId = signerClientId;
			sendData.key = key;
			sendData.firmaelectronica = isElectronic ? true : null;
			sendData.codeId = isElectronic ? codeId : null;
			sendData.documents.map(data => {
				data.typeSignature.map((signature, index) => {
					signature.id = index;
					// if (signature.signatureType == 'manuscrita') {
					// 	if (window.innerWidth <= 1253) {
					// 		signature.posXY.pageHeight =
					// 			document.getElementsByClassName('pdf-canvas')[0].scrollHeight;
					// 		signature.posXY.pageWidth =
					// 			document.getElementsByClassName('pdf-canvas')[0].scrollWidth;
					// 	}
					// }
				});
			});
			setStatusModalSign(false);
			SignCustomWithoutdocumentS3CF(enqueueSnackbar, terminaFirma);
		}
	};

	// it verifies the attempt of times that the user has inserted the OTP code, once the limit of times is exceeded an alert appears and the button is disabled, it also verifies the OTP code and if it is valid, it changes the status of the circuit to signed, shows the modal saying that it has been signed successfully, and closes
	const firmar = async () => {
		setEnableButton(true);
		if (vencimiento.vence && new Date() > new Date(vencimiento.fechaVence)) {
			setErrorS3('El circuito se ha vencido, por favor comuniquese con el creador del circuito');
			await vencerCircuito()
			dispatch(modalUps(true));
			onClose();
		}
		if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
			enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		if (codeMail !== '') {
			setEnableButton(true);
			let response = await validarOTP();
			if (response) {
				if (response.httpStatus === 200) {
					setLoadingModal(false);

					signApply(
						response.result.certificateId,
						response.result.password,
						response.result.codeId,
						isElectronic
					);
				}
			}
			setAttemps(attemps + 1);
		}
	};

	if (loadingModal) return <Loading />;

	if (sendElecSign == 1) {
		return (
			<Modal
				disableBackdropClick
				open={true}
				onClose={onClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro1'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justifyContent='space-between'
					>
						<IconButton
							edge='start'
							id='forwardCode'
							className={'btn-forward-sign-modal'}
							onClick={() => {
								setLoading(false);
								setSendElecSign(3);
								setSendToOption('email');
								setMobileNumber('');
								setEnableButton(true);
								onMobileNumberChange('');
								onSendOptionChange('email');
							}}
						></IconButton>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => {
								setLoading(false);
								setStatusModalSign(false);
								setSendToOption('email');
								setMobileNumber('');
								onMobileNumberChange('');
								onSendOptionChange('email');
							}}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={classes.textModalElectro}>
									Hemos enviado un código{' '}
									{sendToOption === 'WhatsApp'
										? `al WhatsApp `
										: sendToOption === 'mobile'
										? `al `
										: `a `}
									<span style={{ fontWeight: 'bold' }}>
										{sendToOption === 'email'
											? newEmail
											: mobileNumber.replace('-', ' ')}
									</span>
									, <br></br>
									por favor ingrésalo a continuación
									{sendToOption !== 'WhatsApp' && (
										<>
											{' '}
											(puede haber llegado a tus{' '}
											{sendToOption === 'email' ? 'correos' : 'números'} no
											deseados).
										</>
									)}
								</Typography>

								<Controller
									as={
										<TextFieldVerify
											name='codeValidate'
											label={enableButton && 'Código de verificación'}
											variant='outlined'
											autoFocus={true}
											inputProps={{ style: { textAlign: 'center' } }}
											InputProps={{
												autoComplete: 'off',
												classes: { input: classes.input },
											}}
										/>
									}
									name='codeValidate'
									control={control}
									rules={Rules.required}
									className={classes.validateCode}
									onChange={([event]) => {
										handleInputChange(event.target.value);
									}}
								/>
								<Grid
									style={{ margin: '0px 0px 0px' }}
									container
									justifyContent='center'
								>
									<Button
										className={'btnGeneral18'}
										id='buttonVerify'
										disabled={enableButton}
										style={{ width: '154px', height: '41px' }}
										onClick={() => firmar()}
									>
										Verificar
									</Button>
								</Grid>
								<Typography
									className={classes.validateLink}
									onClick={() => {
										setSendElecSign(2);
										setStatusModalSign(true);
									}}
								>
									¿No has recibido el{' '}
									{sendToOption === 'WhatsApp'
										? 'código'
										: sendToOption === 'mobile'
										? 'código'
										: 'correo'}
									?
								</Typography>
								<Typography className={classes.textModalElectro}>
									Debes tener en cuenta que un certificado <br></br> electrónico
									no tiene validez jurídica.
								</Typography>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else if (sendElecSign == 2) {
		return (
			<Modal
				disableBackdropClick
				open={true}
				onClose={onClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id='paperElectro2'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justifyContent='space-between'
					>
						<IconButton
							edge='start'
							id='forwardCode'
							onClick={() => {
								setSendElecSign(1);
								setEnableButton(true);
							}}
							className={'btn-forward-sign-modal'}
						></IconButton>
						<IconButton
							edge='start'
							aria-label='closeModal'
							onClick={() => {
								setStatusModalSign(false);
								setLoading(false);
									setSendToOption('email');
									setMobileNumber('');
									onMobileNumberChange('');
									onSendOptionChange('email');
							}}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={'titlePopUpForwardMail'}>
									¡Lo haremos de nuevo!
								</Typography>
								<Typography className={classes.textModalElectro2}>
									Reenviaremos a{' '}
									<span style={{ fontWeight: 'bold' }}>
										{mobileNumber ? mobileNumber.replace('-', ' ') : newEmail}
									</span>
									<br></br> un nuevo código de verificación.
								</Typography>
								<Grid
									style={{ margin: '29px 0px 0px 0px' }}
									container
									justifyContent='center'
								>
									<Button
										className={'btnGeneral18'}
										onClick={() => {
											ejecutar();
										}}
										style={{ width: '154px', height: '41px' }}
									>
										Aceptar
									</Button>
								</Grid>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	} else if (sendElecSign == 3) {
		return (
			<Modal
				open={true}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id={'paperElectro'}
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => 
								
								{
									setLoading(false);
									setStatusModalSign(false);
									setSendToOption('email');
									setMobileNumber('');
									onMobileNumberChange('');
									onSendOptionChange('email');
									onClose(false);
								}
							}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<Typography className={'titlePopUpCertificate'}>
						Certificado
					</Typography>
					<div className='content'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Controller
									as={
										<Select
											key={1}
											displayEmpty
											error={!!errors.tipoCertificado}
										>
											<MenuItem value='' className={'opionCustom'}>
												<Typography className='certificateText'>
													Firma electrónica
												</Typography>
											</MenuItem>
										</Select>
									}
									name='tipoCertificado'
									// rules={Rules.required}
									control={control}
									defaultValue='firma'
									className={'selectCustom'}
								/>

								<Select
									value={sendToOption}
									onChange={e => setSendToOption(e.target.value)}
									displayEmpty
									className={'selectCustom'}
								>
									<MenuItem value='' disabled>
										Seleccione
									</MenuItem>
									<MenuItem value='email'>Correo electrónico</MenuItem>
									{services.sms&&   <MenuItem value='mobile'>SMS</MenuItem>}
									{services.whatsapp&& <MenuItem value='WhatsApp'>WhatsApp</MenuItem>}
								</Select>

								{sendToOption === 'mobile' && (
									<>
										<MuiPhoneNumber
											defaultCountry={'co'}
											onChange={handleMobileNumberChange}
											className='selectCustomP'
											placeholder='ej:310000000'
											regions={['america', 'europe']}
											disableAreaCodes={true}
											autoFormat={false}
											localization={spanishTranslations}
										/>
										{mobileNumberError && (
											<FormHelperText
												style={{ textAlign: 'center', width: '100%' }}
												error={true}
											>
												{mobileNumberError}
											</FormHelperText>
										)}
									</>
								)}
								{sendToOption === 'WhatsApp' && (
									<>
										<MuiPhoneNumber
											defaultCountry={'co'}
											onChange={handleMobileNumberChange}
											className='selectCustomP'
											placeholder='ej:310000000'
											regions={['america', 'europe']}
											disableAreaCodes={true}
											autoFormat={false}
											localization={spanishTranslations}
										/>
										{mobileNumberError && (
											<FormHelperText
												style={{ textAlign: 'center', width: '100%' }}
												error={true}
											>
												{mobileNumberError}
											</FormHelperText>
										)}
									</>
								)}
								<br></br>
								<Grid
									style={{ margin: '20px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										type='submit'
										className={'btnGeneral18'}
										disabled={
											sendToOption === 'mobile' || sendToOption === 'WhatsApp'
												? mobileNumberError || mobileNumber.length === 0
												: false
										}
										style={{
											marginBottom:
												sendToOption === 'mobile' || sendToOption === 'WhatsApp'
													? '20px'
													: 'normal',
										}}
									>
										Aceptar
									</Button>
								</Grid>
							</FormControl>
						</form>
					</div>
				</Paper>
			</Modal>
		);
	}
};

export default ModalOtpCF;
