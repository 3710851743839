import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { kbToMb } from '../helpers/FilesFunction';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import iconSheet from '../assets/img/IconSheet.png';
import iconPc from '../assets/img/IconPc.png';
import dumpIcon from '../assets/img/dumpIcon.png';
import GoogleDriveExplorer from '../views/ConsultSignedDocument/GetFileGoogleDrive';
import OneDriveExplorer from '../views/ConsultSignedDocument/GetFileOneDrive';
import DropboxExplorer from '../views/ConsultSignedDocument/GetFileDropbox';
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../components/Loading';
import {
	ACCEPT_FILES_VALIDATE_CADES,
	MAX_MEGAS_FILE_SIZE_CADES,
} from '../redux/types/ApiConstants';

const activeStyle = {
	borderColor: '#E55200',
	fontSize: 22,
	color: '#E55200',
};

const acceptStyle = {
	borderColor: '#00e676',
	backgroundColor: '#F0F0F0',
	border: 'solid',
	backgroundImage:
		'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
};

const rejectStyle = {
	borderColor: '#00e676',
	backgroundColor: '#F0F0F0',
	border: 'solid',
	backgroundImage:
		'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
};

const stylesDrop = makeStyles({
	dropzoneTextStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#EDEDED',
		},
	},
	buttons: {
		color: '#fff',
		textShadow: '0 1px 1px #ccc',
		textTransform: 'none',
		width: 150,
	},
	listContainer: {
		width: '100%',
		marginBottom: '5px',
		display: 'flex',
		justifyContent: 'center',
	},
	loader: {
		width: '100%',
		margin: '0 auto',
	},
});

const ZoneUploadCades = props => {
	const { accept, multiple, files, setFiles, maxSize, sizeFiles } = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [isResponsive, setResponsive] = useState(
		window.innerWidth <= 600 ? false : true
	);
	const [isLoading, setLoading] = useState(false);
	const [statusModalDropbox, setStatusModalDropbox] = useState(false);
	const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(true);
	const [services, setServices] = useState({
		sms: false,
		whatsapp: false,
	});

	window.addEventListener(
		'resize',
		function (event) {
			if (this.window.innerWidth <= 600) setResponsive(false);
			else setResponsive(true);
		},
		true
	);

	const handleClose = () => {
		setLoading(false);
		setStatusModalCloudSelect(false);
	};
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setStatusModalCloudSelect(true);
	};

	const openZoneDrop = event => {
		document.getElementById('dFZoneUpload').click();
		handleClose();
	};

	const { enqueueSnackbar } = useSnackbar();

	const onDrop = (dropedFiles, rejectedFiles) => {
		if (rejectedFiles.length > 1) {
			enqueueSnackbar(`Solo se permite firmar un archivo`, {
				style: { whiteSpace: 'pre-line' },
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			return;
		}
		if (rejectedFiles.length) {
			rejectedFiles.forEach(file => {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				var extension = '.' + file.name.split('.').pop();
				var isAccept = accept.find(element => element == extension);
				if (!isAccept) {
					enqueueSnackbar(
						`El archivo ${cutNameTitle(
							file.name
						)} no es permitido, los tipos de archivos permitidos son: ${accept
							.filter(e => e.length < 5)
							.toString()
							.replaceAll(',', ' ')}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					return;
				}
				if (fileSizeMb > maxSizeMb) {
					handleClose();
					enqueueSnackbar(
						`El tamaño del archivo ${cutNameTitle(
							file.name
						)} supera el máximo permitido.\nTamaño limite de ${kbToMb(
							maxSize
						)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
					return;
				}
			});
		}
		if (dropedFiles.length === 0) return;
		setFiles(dropedFiles);
		enqueueSnackbar('Archivos agregados exitosamente.', {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const onDelete = fileToDelete => {
		setFiles(files.filter(file => file.name !== fileToDelete));
		var name =
			fileToDelete.length > 29
				? fileToDelete.substring(0, 29) + '...'
				: fileToDelete;
		enqueueSnackbar(`Archivo ${cutNameTitle(name)} eliminado.`, {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const cutNameTitle = name => {
		if (name != null && name.length > (isResponsive ? 70 : 10))
			return name.substring(0, isResponsive ? 70 : 10).concat('...');
		else return name;
	};

	const cutName = name =>
		name != null && name.length > (isResponsive ? 70 : 39) ? (
			<Tooltip title={name}>
				<div>{name.substring(0, isResponsive ? 70 : 39).concat('...')}</div>
			</Tooltip>
		) : (
			<div>{name}</div>
		);

	const closeMenuFileSource = () => {
		setStatusModalCloudSelect(false);
	};

	const classes = stylesDrop();
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept,
		multiple,
		maxSize,
		onDrop,
		noClick: true,
		noKeyboard: true,
	});
	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragAccept, isDragReject]
	);
	const StyledMenu = withStyles({
		paper: {
			border: '1.25px solid #9D9D9C',
			width: '182px',
		},
	})(props => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		/>
	));

	return (
		<div className='container'>
			{isLoading == true && <Loading />}

			<div {...getRootProps({ style })} className='validate-style-container'>
				<input
					type='file'
					{...getInputProps()}
					id='dFZoneUpload'
					onClick={event => {
						event.target.value = null;
					}}
				/>
				<>
					<div className={classes.listContainer}>
						{files.length > 0 && (
							<List dense style={{ width: '100%' }}>
								<div>
									{files.map((element, index) => (
										<ListItem key={index} className='contentZoneUploadList'>
											<ListItemText
												primaryTypographyProps={{
													style: { fontWeight: 'bold', color: '#000000' },
												}}
												secondaryTypographyProps={{
													style: { fontWeight: 'bold', color: '#575756' },
												}}
												primary={cutName(element.name)}
												secondary={`${Math.round(element.size / 1024)} KB`}
											/>
											<ListItemSecondaryAction>
												<IconButton
													edge='end'
													aria-label='delete'
													onClick={() => onDelete(element.name)}
												>
													<img src={dumpIcon} width='15px' />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</div>
							</List>
						)}
					</div>
				</>
				{isDragActive ? (
					<p>Suelte los archivos</p>
				) : (
					<div className={classes.dropzoneTextStyle}>			
								<>
									<img style={{ paddingBottom: '6px' }} src={iconSheet} />
									<div className='textZoneUpload'>
										Arrastra o carga aquí el archivo para firmar
									</div>
								</>
						{files.length < 1 && (
							<Button
								disableElevation
								className={'btnGeneralNew'}
								style={{
									marginBottom: files.length > 0 ? '20px' : '',
									display: '',
								}}
								onClick={handleClick}
								endIcon={<ArrowDropDownIcon />}
							>
								Cargar archivo
							</Button>
						)}

						<StyledMenu
							className={'menuSourceFile'}
							id='simple-menu'
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl) && statusModalCloudSelect}
							onClose={handleClose}
						>
							<MenuItem dense={true} onClick={openZoneDrop}>
								<img src={iconPc} width='25px' className='buttonPC' /> Mi
								ordenador
							</MenuItem>
							<MenuItem dense={true} onClick={closeMenuFileSource}>
								{
									<GoogleDriveExplorer
										disableBackdropClick
										setStatusModalCloudSelect={setStatusModalCloudSelect}
										files={files}
										setFiles={setFiles}
										setLoading={setLoading}
										sizeFiles={sizeFiles}
										isCades={true}
									></GoogleDriveExplorer>
								}
							</MenuItem>
							<MenuItem dense={true}>
								<OneDriveExplorer
									setStatusModalCloudSelect={setStatusModalCloudSelect}
									files={files}
									setFiles={setFiles}
									setLoading={setLoading}
									sizeFiles={sizeFiles}
									isCades={true}
								></OneDriveExplorer>
							</MenuItem>
							<MenuItem dense={true} onClick={closeMenuFileSource}>
								<DropboxExplorer
									open={statusModalDropbox}
									onClose={() => setStatusModalDropbox(false)}
									setStatusModalDropbox={setStatusModalDropbox}
									setStatusModalCloudSelect={setStatusModalCloudSelect}
									files={files}
									setFiles={setFiles}
									setLoading={setLoading}
									sizeFiles={sizeFiles}
									isCades={true}
								></DropboxExplorer>
							</MenuItem>
						</StyledMenu>
					</div>
				)}
			</div>
		</div>
	);
};

export default ZoneUploadCades;
