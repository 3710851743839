/*
 *@param {String} str = Capitalizar el texto
 *@return {String} = (la primera letra del texto en mayuscula)
 */
 export const capitalize = (str = '') => {
	return str.charAt(0).toUpperCase() + str.slice(1);
  };
  

/*
 *@param {String} str = El texto que se va a recortar
 *@param {number} Length = la cantidad de caracteres antes de los ...
 *@return {String} = El texto truncado agregando un elipsis(....) si es necesario
 */
 export const truncateText = (str, length) => {
	return str && str.length > length ? str.substring(0, length - 3) + '...' : str;
  };  
  

export const preventSpaceWhenEmpty = (inputValue, e) =>
	inputValue.trim() === '' && e.keyCode === 32 && e.preventDefault();

export const capitalizeWords = str =>
	str
		.toLowerCase()
		.split(' ')
		.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
		.join(' ');
