import { GET_CERTIFICATES, IS_UNIQUE_ELECTRONIC, LOADING, LIST_SIGNS, POP, LIST_MANUSCRITA } from '../types/SignTypes';

const INITIAL_STATE = {
  certificates: {},
  isUniqueElectronic: false,
  loading: false,
  listSigns: [],
  pop: 0,
  listManuscrita: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POP:
      return {
        ...state,
        pop: action.payload,
      };
    case IS_UNIQUE_ELECTRONIC:
      return {
        ...state,
        isUniqueElectronic: action.payload,
      };
    case GET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LIST_SIGNS:
      return {
        ...state,
        listSigns: action.payload,
      };
    case LIST_MANUSCRITA:
      return {
        ...state,
        listManuscrita: action.payload,
      };
    default:
      return state;
  }
};