 
export const  Capitalize= (str)=>{

    var data = str.split(" ");
    var response ='';
    for(let i=0;i<data.length;i++)
    {
        response += data[i].charAt(0).toUpperCase() + data[i].slice(1).toLowerCase() +' ';
    }
    return response.substring(0,response.length-1);
}