import { Button, makeStyles, Modal, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { dataQr, firm_positions, modalConfirm, stepCircuit } from '../../../../redux/actions/CircuitoFirmasAction';
import { guardarEnIndexedDB } from '../../../../redux/actions/indexedDB';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: '0px 3px 0px #00000033 !important',
        padding: "15px 30px 38px 34px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: '10px !important',
        maxWidth: "530px",
        fontSize: "16px",
        "&:focus": {
            outline: "none"
        }
    },
    color: {
        color: "#E55200",
    },
    title: {
        color: "#E55200",
        textAlign: "center",
        fontSize: "24px"
    },
    pCenter: {
        textAlign: "center",
        margin: "10px 0 25px",
        padding: "5px 0"
    },
    flx: {
        display: "flex",
        gap: "25px",
        alignItems: "center",
        justifyContent: "center"
    },
    btnBack: {
        color: "#E55200",
        textTransform: "none",
        border: "1px solid #E55200",
        fontWeight: "bold",
        fontSize: "16px",
        padding: "4px 20px",
        borderRadius: "10px"
    },
    continue: {
        backgroundColor: "#E55200",
        color: "white",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        padding: "4px 20px",
        borderRadius: "10px",
        '&:hover': {
            backgroundColor: "#db4f04",
        },
    }
}))

const ModalReload = ({ handleConfirm, hadleCancel }) => {
    const classes = useStyles();
    const [modal, setModal] = useState(true);
    const dispatch = useDispatch();

    const closeModal = () => {
        setModal(false)
        dispatch(modalConfirm(false))
    }


    return (
        <Modal
            open={modal}
            onClose={closeModal}
            disableBackdropClick
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Paper className={classes.modal}>
                <h2 className={classes.title}>¡Ten en cuenta!</h2>
                <p>Al recargar es posible que los cambios se eliminen</p>
                <p style={{ padding: "5px 0" }}>Ellos podrán incluir su firma en cualquier lugar.</p>
                <p className={classes.pCenter}><b>¿Deseas continuar?</b></p>
                <div className={classes.flx}>
                    <Button variant='outlined' className={classes.btnBack} onClick={hadleCancel}>Regresar</Button>
                    <Button
                        className={classes.continue}
                        onClick={handleConfirm}
                        // value="/home/Message"
                        // component={Link}
                        // to="/home/Message"
                    >Si</Button>
                </div>
            </Paper>
        </Modal>
    )
}

export default ModalReload