import axios from 'axios';
import { API_HOSTNAME_SIGNS } from '../types/ApiConstants';
import { GET_CERTIFICATES } from '../types/EncryptDecryptTypes';
import { saveAs } from 'file-saver';

axios.interceptors.request.use((config) => {
	const token = localStorage.getItem('JWT');
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
}, (error) => {
	Promise.reject(error);
});
 
export const getCerificatesByUser = (enqueueSnackbar, data, setNotFound) => async (dispatch) => {
	try {
		const response = await axios.post(`${API_HOSTNAME_SIGNS}api/code/consult`, data);
        console.log(response);
		let newDate = new Date();
		let valid = [];
		if (response.status === 200) {
			await Promise.all(response.data.certificates.map((e) => {
				let newDate2 = new Date(e.validTo);
				if(newDate.getTime() < newDate2.getTime() && e.userId.substring(0,1) != "R") valid.push(e);
			}))
			dispatch({
				type: GET_CERTIFICATES,
				payload: valid,
			});
		}
		setNotFound(valid.length < 1);
		return true;
	} catch (error) {
		if (error.response) {
			if (error.response.status === 400) {
				enqueueSnackbar(error.response.data.message, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 401) {
				enqueueSnackbar('Acceso no permitido', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 500) {
				enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		} else {
			enqueueSnackbar(
				'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
				{
					variant: 'error',
					style: { whiteSpace: 'pre-line' },
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
		}
		return false;
	}
};

export const encrypt = (enqueueSnackbar, data) => async (dispatch) => {
	try {
		const response = await axios.post(`${API_HOSTNAME_SIGNS}api/code/encrypt`, data);
		console.log(response);
		var JSZip = require("jszip");
        var zip = new JSZip();
		if (response.status === 200) {
			response.data.claves.map(key => {zip.file(key.userId + '.kek', key.base64)});
			response.data.documentos.map(docu => {zip.file(docu.nombre, docu.base64.split(',').pop(), { base64: true });});
			zip.generateAsync({ type: "blob" }).then(function (content) {
            	saveAs(content, "mis_documentos_Firmaya.zip");
        	});
		}
		return true;
	} catch (error) {
		if (error.response) {
			if (error.response.status === 400) {
				enqueueSnackbar(error.response.data.message, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 401) {
				console.log(error);
				enqueueSnackbar('Acceso no permitido', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 500) {
				enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		} else {
			enqueueSnackbar(
				'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
				{
					variant: 'error',
					style: { whiteSpace: 'pre-line' },
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
		}
		return false;
	}
};

export const decrypt = (enqueueSnackbar, data, onSuccess) => async (dispatch) => {
	try {
		const response = await axios.post(`${API_HOSTNAME_SIGNS}api/code/decrypt`, data);
		var JSZip = require("jszip");
        var zip = new JSZip();
		if (response.status === 200) {
			response.data.documentos.map(docu => {zip.file(docu.nombre.substring(10), docu.base64.split(',').pop(), { base64: true });});
			zip.generateAsync({ type: "blob" }).then(function (content) {
            	saveAs(content, "mis_documentos_descifrado_Firmaya.zip");
        	});
			onSuccess();
		}
		return true;
	} catch (error) {
		if (error.response) {
			if (error.response.status === 400) {
				enqueueSnackbar(error.response.data.mensaje, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 401) {
				enqueueSnackbar('Acceso no permitido', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			} else if (error.response.status === 500) {
				enqueueSnackbar('Ocurrio un error procesando su solicitud.', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
			}
		} else {
			enqueueSnackbar(
				'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
				{
					variant: 'error',
					style: { whiteSpace: 'pre-line' },
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
		}
		return false;
	}
};