import {
	Grid,
	makeStyles,
	Modal,
	withStyles,
	LinearProgress,
	Button,
	Tooltip,
	useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import close from '../../../../assets/img/close-sign.png';
import { ToggleButtonGroup } from '@material-ui/lab';
import {
	firm_positions,
	messages_privates,
	modalMessage,
} from '../../../../redux/actions/CircuitoFirmasAction';
import { useDispatch, useSelector } from 'react-redux';
import {
	EditorState,
	convertToRaw,
	convertFromHTML,
	ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import bold from '../../../../assets/img/bold.png';
import italic from '../../../../assets/img/italic.png';
import underline from '../../../../assets/img/underline.png';
import center from '../../../../assets/img/centerAlign.png';
import right from '../../../../assets/img/rightAlign.png';
import color from '../../../../assets/img/colors.png';
import unordered from '../../../../assets/img/unorderedList.png';
import ordered from '../../../../assets/img/orderedList.png';
import indent from '../../../../assets/img/textInside.png';
import outdent from '../../../../assets/img/textOutside.png';
import left from '../../../../assets/img/LeftAlign.png';
import { truncarCorreo, truncarNombreCompleto } from '../../../../helpers/helpNames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTheme } from 'styled-components';


const useStyle = makeStyles({
	radio: {
		borderRadius: '5px',
	},
	modal: {
		position: 'absolute',
		backgroundColor: 'white',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '5.72px',
		width: '922px',
		height: '560px',
		maxWidth: '1050px',
		'&:focus': {
			outline: 'none',
		},
		'@media(max-width:1100px)': {
			width: '800px',
		},
		'@media(max-width:960px)': {
			width: '600px',
			overflowY: 'scroll',
		},
		'@media(max-width:960px)': {
			height: '95%',
			width: '95%',
			overflowY: 'scroll',
		},
		'@media(max-width:600px)': {
			width: '90%',
			overflowY: 'scroll',
			height: '95%',
		},
	},
	contain: {
		margin: '0 30px 30px',
	},
	containp: {
		'@media(max-width:600px)': {
			display: 'none',
		},
	},
	close: {
		justifyContent: 'flex-end',
		marginTop: '-25px',
		marginRight: '12px',
		float: 'right',
		cursor: 'pointer',
		'@media(max-width:960px)': {
			display: 'none !important',
		},
	},
	flx: {
		display: 'flex',

		alignItems: 'center',
		gap: '32px',
		marginTop: '15px',
		'@media(max-width:960px)': {
			justifyContent: 'center',
		},
		'@media(max-width:700px)': {
			width: '90%',
			flexDirection: 'column',
			marginLeft: '5%',
		},
		marginBottom: '5%',
	},
	firstSection: {
		backgroundColor: '#F7F7F7',
		padding: '5px 0px 0px 4%',
		height: '560px',
		borderRadius: '5px',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
		'@media(max-width:960px)': {
			padding: '5px 0px 0px 25px',
		},
		'@media(max-width:960px)': {
			maxHeight: '200px',
			minWidth: '100%',
		},
	},
	secondSection: {
		// overflowY: "auto",
		maxHeight: '550px',
		'@media(max-width:960px)': {
			minWidth: '100%',
			maxHeight: '100%',
		},
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
	},
	size: {
		fontSize: '15px',
		marginTop: '-10px',
		fontFamily: 'Mulish',
		paddingRight: '25px',
	},
	small: {
		marginTop: '-15px',
		fontSize: '14px',
		fontFamily: 'Mulish',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	form: {
		padding: '0',
		overflowY: 'auto',
		height: '400px',
		width: '87%',
		'@media(max-width:960px)': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	padding: {
		height: '28px',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		borderRadius: '10px',
		border: 'none !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 25px',
		'&:hover': {
			backgroundColor: '#E55200',
		},
		'@media(max-width:600px)': {
			padding: '8px 24px',
			fontSize: '1rem !important',
			width: '90%',
		},
	},
	add: {
		backgroundColor: 'white',
		color: '#E55200',
		textTransform: 'none',
		borderRadius: '10px',
		fontWeight: 'bold',
		border: '1px solid #E55200 !important',
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		padding: '5px 25px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'@media(max-width:600px)': {
			padding: '8px 24px',
			fontSize: '1rem !important',
			width: '90%',
		},
	},
});
const StyledToggleButtonGroup = withStyles(theme => ({
	grouped: {
		border: 'none',
		padding: '2px',
		'&:not(:first-child)': {
			borderRadius: theme.shape.borderRadius,
		},
		'&:first-child': {
			borderRadius: theme.shape.borderRadius,
		},
	},
}))(ToggleButtonGroup);

const BorderLinearProgress = withStyles(theme => ({
	root: {
		height: 5,
		width: '100%',
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		margin: '0',
	},
}))(LinearProgress);
const ModalPrivateMessage = ({
	firmantes,
	setModificarDatos,
	modificarDatos,
}) => {
	const dispatch = useDispatch();
	const classes = useStyle();
	const [modal, setModal] = useState(true);
	const lonMax = 1000;
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const texto = editorState.getCurrentContent().getPlainText();
	const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
	const suma = editorState.getCurrentContent().getPlainText().length;
	const [form, setForm] = useState([]); //Guardo los correos de las personas
	const theme = useTheme();
	const isSmallScreen = useMediaQuery('(max-width:960px)');
	
	const data = {
		form: form,
		message: html,
		texto: texto,
	};

	const closeModal = () => {
		setModal(false);
		dispatch(modalMessage(false));
	};

	const getForm = e => {
		const { value, checked } = e.target;
		if (checked) {
			setForm([...form, value]);
		} else {
			setForm(form.filter(e => e !== value));
		}
	};

	const reset = () => {
		if (form.length == 0) {
			setEditorState(
				EditorState.push(editorState, ContentState.createFromText(''))
			);
		} 
	};

	const AgregarMensajePrivado = async () => {
		const correos = data.form;
		const html = data.message;
		const newMessage = firmantes.map(firmante => {
			if (correos.includes(firmante.Correo)) {
				return { ...firmante, mensajePriv: html };
			}
			return firmante;
		});
		dispatch(firm_positions(newMessage));
		setModificarDatos(!modificarDatos);
		closeModal();
	};
	return (
		<Modal
			open={modal}
			onClose={closeModal}
			disableBackdropClick
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.radio}
		>
			<div className={classes.modal}>
				<Grid
					container
					spacing={0}
					direction={isSmallScreen ? 'column' : 'row'}
				>
					<Grid
						item
						xs={4}
						sm={4}
						md={4}
						lg={4}
						className={classes.firstSection}
					>
						<h2
							style={{
								color: '#E55200',
								fontSize: '20px',
								marginTop: '31px',
								fontFamily: 'Mulish',
							}}
						>
							Firmantes{' '}
						</h2>
						<p className={classes.size}>
							Selecciona a quien quieras enviar el mensaje privado{' '}
						</p>
						<form className={classes.form}>
							{firmantes.map(value =>
								value.mensajePriv.trim().length > 0 ? (
									<label className='checkboxFirmantes' htmlFor={value.Correo}>
										<input
											className='checkboxFirmantes__input'
											type='checkbox'
											name={value.Nombre}
											checked
											id={value.Correo}
											value={value.Correo}
											onChange={e => getForm(e)}
										/>
										<div className='checkboxFirmantes__box'></div>
										<div className={classes.list}>
											<p
												style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
											>
												<b>
													{truncarNombreCompleto(
														value.Nombres,
														value.Apellidos,
														25
													)}
												</b>
											</p>
											<small className={classes.small}>
												{truncarNombreCompleto(value.Correo, ' ', 25)}
											</small>
										</div>
										{value.esAprobador === 1 && (
											<Tooltip title='Aprobador' arrow>
												<CheckCircleIcon
													style={{
														color: 'primary',
														marginLeft: '2px',
														fontSize: '1rem',
													}} // Ajusta el tamaño a 1rem
												/>
											</Tooltip>
										)}
										{value.esObservador === 1 && (
											<Tooltip title='Observador' arrow>
												<VisibilityIcon
													style={{
														color: 'primary',
														marginLeft: '2px',
														fontSize: '1rem',
													}}
												/>
											</Tooltip>
										)}
									</label>
								) : (
									<label className='checkboxFirmantes' htmlFor={value.Correo}>
										<input
											className='checkboxFirmantes__input'
											type='checkbox'
											name={value.Nombre}
											id={value.Correo}
											value={value.Correo}
											onChange={e => getForm(e)}
											onClick={() => reset()}
										/>
										<div className='checkboxFirmantes__box'></div>
										<div className={classes.list}>
											<p
												style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
											>
												<b title={`${value.Nombres} ${value.Apellidos}`}>
													{truncarNombreCompleto(
														value.Nombres,
														value.Apellidos,
														25
													)}
												</b>
											</p>
											<small className={classes.small}>
												{truncarNombreCompleto(value.Correo, ' ', 25)}
											</small>
										</div>
										{value.esAprobador === 1 && (
											<Tooltip title='Aprobador' arrow>
												<CheckCircleIcon
													style={{
														color: 'primary',
														marginLeft: '2px',
														fontSize: '1rem',
													}} // Ajusta el tamaño a 1rem
												/>
											</Tooltip>
										)}
										{value.esObservador === 1 && (
											<Tooltip title='Observador' arrow>
												<VisibilityIcon
													style={{
														color: 'primary',
														marginLeft: '2px',
														fontSize: '1rem',
													}}
												/>
											</Tooltip>
										)}
									</label>
								)
							)}
						</form>
						<div className={classes.padding}></div>
					</Grid>
					<Grid item xs={isSmallScreen ? 12 : 8} sm={12} md={8} lg={8}>
						<div className={classes.secondSection}>
							<img
								src={close}
								className={classes.close}
								onClick={() => closeModal()}
							/>
							<div className={classes.contain}>
								<h2
									style={{
										color: '#E55200',
										fontFamily: 'Mulish',
										fontSize: '20px',
										letterSpacing: '0',
										marginTop: '32px',
										marginBottom: '0px',
									}}
								>
									Agregar mensaje privado
								</h2>
								<p
									style={{
										fontFamily: 'Mulish',
										fontSize: '18px',
										letterSpacing: '1',
										color: '#4F4F4F',
										marginTop: '8px',
										marginBottom: '15px',
									}}
									className={classes.containp}
								>
									<b>
										Escribe un mensaje privado a los firmantes seleccionados.
									</b>
								</p>
							</div>
							<div className={classes.contain}>
								{/* ------------------------------------------------TextEditor-------------------------------------------------------- */}
								<div style={{ overflow: 'auto' }}>
									{form.length <= 0 ? (
										<>
											<Editor
												placeholder='Escribe aquí tu mensaje'
												editorState={''}
												editorClassName='rdw-option-disabled'
												wrapperClassName='disabledToolbar'
												//estilos del menu
												toolbarStyle={{
													backgroundColor: '#EFEFEF',
													borderTopLeftRadius: '15px',
													borderTopRightRadius: '15px',
													borderTop: '2px solid gray',
													borderLeft: '2px solid gray',
													borderRight: '2px solid gray',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													gap: '2%',
													marginBottom: '0',
												}}
												//estilos del editor
												editorStyle={{
													height: '24vh',
													borderBottomLeftRadius: '15px',
													borderBottomRightRadius: '15px',
													borderLeft: '2px solid gray',
													borderRight: '2px solid gray',
													borderTop: 'none',
													padding: '15px',
													borderBottom: '2px solid gray',
													resize: 'none',
													margin: '0',
												}}
												// onEditorStateChange={(state) => setEditorState(state)}
												handleBeforeInput={val => {
													const textLength = editorState
														.getCurrentContent()
														.getPlainText().length;
													if (val && textLength >= lonMax) {
														return 'handled';
													}
													return 'no-handled';
												}}
												handlePastedText={val => {
													const textLength = editorState
														.getCurrentContent()
														.getPlainText().length;
													return val.length + textLength >= lonMax;
												}}
												//Menu personalizado, que opciones apareceran...
												toolbar={{
													options: [
														'fontSize',
														'inline',
														'textAlign',
														'colorPicker',
														'list',
													],
													fontSize: { className: 'btnsMenuMessage' },
													inline: {
														options: ['bold', 'italic', 'underline'],
														bold: { icon: bold, className: 'btnsMenuMessage' },
														italic: {
															icon: italic,
															className: 'btnsMenuMessage',
														},
														underline: {
															icon: underline,
															className: 'btnsMenuMessage',
														},
													},
													textAlign: {
														options: ['left', 'center', 'right'],
														left: { icon: left, className: 'btnsMenuMessage' },
														center: {
															icon: center,
															className: 'btnsMenuMessage',
														},
														right: {
															icon: right,
															className: 'btnsMenuMessage',
														},
													},
													colorPicker: {
														icon: color,
														className: 'btnsMenuMessage',
													},
													list: {
														unordered: {
															icon: unordered,
															className: 'btnsMenuMessage',
														},
														ordered: {
															icon: ordered,
															className: 'btnsMenuMessage',
														},
														indent: {
															icon: indent,
															className: 'btnsMenuMessage',
														},
														outdent: {
															icon: outdent,
															className: 'btnsMenuMessage',
														},
													},
												}}
											/>
											<div style={{ width: '20%', marginTop: '15px' }}>
												<BorderLinearProgress
													variant='determinate'
													value={0 / lonMax}
												/>
												<p
													style={{
														color: 'gray',
														fontSize: '11px',
														marginTop: '1px',
														minWidth: '300px',
													}}
												>
													0 / {lonMax}
												</p>
											</div>
										</>
									) : (
										<>
											<Editor
												placeholder='Escribe aquí tu mensaje'
												editorState={editorState}
												editorClassName='demo-editor'
												//estilos del menu
												toolbarStyle={{
													backgroundColor: '#EFEFEF',
													borderTopLeftRadius: '15px',
													borderTopRightRadius: '15px',
													borderTop: '2px solid gray',
													borderLeft: '2px solid gray',
													borderRight: '2px solid gray',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													gap: '2%',
													marginBottom: '0',
												}}
												//estilos del editor
												editorStyle={{
													height: '24vh',
													borderBottomLeftRadius: '15px',
													borderBottomRightRadius: '15px',
													borderLeft: '2px solid gray',
													borderRight: '2px solid gray',
													borderTop: 'none',
													padding: '15px',
													borderBottom: '2px solid gray',
													resize: 'none',
													margin: '0',
												}}
												onEditorStateChange={state => setEditorState(state)}
												handleBeforeInput={val => {
													const textLength = editorState
														.getCurrentContent()
														.getPlainText().length;
													if (val && textLength >= lonMax) {
														return 'handled';
													}
													return 'no-handled';
												}}
												handlePastedText={val => {
													const textLength = editorState
														.getCurrentContent()
														.getPlainText().length;
													return val.length + textLength >= lonMax;
												}}
												//Menu personalizado, que opciones apareceran...
												toolbar={{
													options: [
														'fontSize',
														'inline',
														'textAlign',
														'colorPicker',
														'list',
													],
													fontSize: { className: 'btnsMenuMessage' },
													inline: {
														options: ['bold', 'italic', 'underline'],
														bold: { icon: bold, className: 'btnsMenuMessage' },
														italic: {
															icon: italic,
															className: 'btnsMenuMessage',
														},
														underline: {
															icon: underline,
															className: 'btnsMenuMessage',
														},
													},
													textAlign: {
														options: ['left', 'center', 'right'],
														left: { icon: left, className: 'btnsMenuMessage' },
														center: {
															icon: center,
															className: 'btnsMenuMessage',
														},
														right: {
															icon: right,
															className: 'btnsMenuMessage',
														},
													},
													colorPicker: {
														icon: color,
														className: 'btnsMenuMessage',
													},
													list: {
														unordered: {
															icon: unordered,
															className: 'btnsMenuMessage',
														},
														ordered: {
															icon: ordered,
															className: 'btnsMenuMessage',
														},
														indent: {
															icon: indent,
															className: 'btnsMenuMessage',
														},
														outdent: {
															icon: outdent,
															className: 'btnsMenuMessage',
														},
													},
												}}
											/>
											<div style={{ width: '20%', marginTop: '15px' }}>
												<BorderLinearProgress
													variant='determinate'
													value={(suma * 100) / lonMax}
												/>
												<p
													style={{
														color: '#E55200',
														fontSize: '11px',
														marginTop: '1px',
														minWidth: '300px',
													}}
												>
													{suma} / {lonMax}
												</p>
											</div>
										</>
									)}
								</div>
								{/* ------------------------------------------------------------------------------------------------------------------------------ */}
								<div className={classes.flx}>
									<Button onClick={() => closeModal()} className={classes.add}>
										Cancelar
									</Button>
									{texto.length >= 1 ? (
										<Button
											onClick={() => AgregarMensajePrivado()}
											className={classes.continue}
										>
											Confirmar
										</Button>
									) : (
										<Button
											onClick={() => closeModal()}
											className={classes.continue}
										>
											Confirmar
										</Button>
									)}
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</Modal>
	);
};

export default ModalPrivateMessage;
