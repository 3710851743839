import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { Rules } from "../../../helpers/RHFRules";
import {
  getCerificatesByUser,
  firmarAction,
  firmarS3Action,
  reQuickSign,
  firmarToken,
  getUrldocumentsToken,
  mailOtpSendClient,
  generateCertificadoAction,
} from "../../../redux/actions/SignAction";
import Loading from "../../../components/Loading";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { MAX_NUMBER_ATTEMPS_ELECTRONIC, URL_FIRMAYA_ASISTENTE_TOKEN } from '../../../redux/types/ApiConstants';
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
import { TOKEN_CONNECTION } from "../../../redux/types/SignTypes";
import { certificateName } from "../../../helpers/helpNames";
 
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  resize: {
    paddingLeft: "4%",
    fontSize: "15px",
    letterSpacing: "0px",
    color: "black",
    opacity: "1",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "5px 0 5px",
  },
  form: {
    width: "100%",
  },
  formElements: {
    marginBottom: "10px",
  },
  dialogAction: {
    justifyContent: "center",
    marginBottom: "5px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textModalElectro: {
    font: "normal normal 100 16px/24px Muli",
    padding: "16px 0px 0px",
    color: "#4F4F4F",
  },
  textModalElectro2: {
    font: "normal normal 100 16px/24px Muli",
    padding: "28px 0px 0px",
    color: "#4F4F4F",
  },
  validateCheck: {
    font: "normal normal 600 16px/20px Muli",
    margin: "35px 48px 14px",
  },
  validateCode: {
    font: "normal normal 600 16px/20px Muli",
    margin: "37px 70px 14px",
  },
  validateLink: {
    font: "normal normal 600 16px/20px Muli",
    color: "#E55200",
    margin: "30px 90px 22px",
    textDecoration: "underline",
    textTransform: "inherit",
    cursor: "pointer",
  },
  input: {
    height: 5
  },
  cssLabel: {
    font: 'normal normal normal 15px/22px Muli',
    color: '#707070 !important',
  },
}));

const TextFieldVerify = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033 !important',
      border: '0.8387856483459473px solid #706F6F !important',
      borderRadius: '10px !important',
      padding: '5px !important',
    },
    '& label.Mui-focused': {
      left: "-45px !important",
      marginTop: "-2px",
      font: 'normal normal normal 14px/22px Muli',
      color: '#707070',
    },
    "& label": {
      textAlign: "center !important",
      width: "90%",
      marginTop: "-9px",
      font: 'normal normal normal 18px/22px Muli',
      color: '#B2B2B2',
    },
    opacity: 0.7
  },
})(TextField);

const TextFieldVerifyStyle = withStyles({
  root: {
    '& input:valid + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    '& input:valid:focus + fieldset': {
      boxShadow: '0px 3px 0px #00000033',
      border: '0.8387856483459473px solid #706F6F',
      borderRadius: '10px',
      padding: '5px !important',
    },
    color: '#B2B2B2 !important',
    font: 'normal normal 600 18px/22px Muli'
  },
})(TextField);

const ModalSign = (props) => {
  const {
    isOpen,
    onClose,
    info,
    getCertificates,
    certificates,
    firmar,
    firmarS3,
    history,
    filesSize,
    style,
    rows,
    selected,
    getUrldocuments,
    loadingAction,
    firmarToken,
    getUrldocumentsToken,
    isP7,
    mailOtpSendClient,
    generateCertificate,
    setStatusModal
  } = props;

  const certificadoOptions = [
    { id: "2", label: "Centralizado" },
    { id: "3", label: "Token" },
    { id: "4", label: "Firma electrónica" },
  ];

  const classes = useStyles();
  const [services, setServices] = useState({
    "sms": false,
    "whatsapp": false
});
  const [s3Limit] = useState(4);
  const [loading, setLoading] = useState(true);
  const [enableButton, setEnableButton] = useState(true);
  const [sendElecSign, setSendElecSign] = useState(0);
  const [value, setValue] = useState("");
  const [outMail, setOutMail] = useState("");
  const [codeMail, setCodeMail] = useState("");
  const [attemps, setAttemps] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  let tokenValidate = false;

  useEffect(() => {
    const fetchData = async () => {
      let result = await getCertificates(enqueueSnackbar, onExit, onNoCertificates, onFree, setServices);
      if (result) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const { handleSubmit, errors, control } = useForm();

  const onSubmit = (data) => {
    if (value == 3 || tokenValidate) {
      if (style) {
        const signInfo = {
          ltv: true,
          estampa: info.estampa,
        };
        let selectFiles = [];
        selected.map((sel) => {
          let file = rows.find((x) => x.id === sel);
          selectFiles.push(file);
        });
        getUrldocumentsToken(
          selectFiles,
          signInfo,
          enqueueSnackbar,
          history,
          onExit
        );
      } else {
        const signInfo = {
          ltv: true,
          estampa: info.estampa,
          documentos: info.files,
        };
        firmarToken(signInfo, enqueueSnackbar, onExit, history);
      }
    } else {
      if (style) {
        const signInfo = {
          idClienteFirmante: data.certificate,
          clave: data.password,
          ltv: info.ltv,
          estampa: info.estampa,
        };
        let selectFiles = [];
        selected.map((sel) => {
          let file = rows.find((x) => x.id === sel);
          selectFiles.push(file);
        });
        getUrldocuments(
          selectFiles,
          signInfo,
          enqueueSnackbar,
          history,
          onExit
        );
      } else {
        if (filesSize > s3Limit * 1024) {
          const firmarS3Info = {
            idClienteFirmante: data.certificate,
            clave: data.password,
            ltv: info.ltv,
            estampa: info.estampa,
            documentos: info.files,
          };
          firmarS3(firmarS3Info, enqueueSnackbar, onExit, history);
        } else {
          const firmarInfo = {
            idClienteFirmante: data.certificate,
            clave: data.password,
            ltv: info.ltv,
            estampa: info.estampa,
            documentos: info.files,
          };
          firmar(firmarInfo, enqueueSnackbar, onExit, history);
        }
      }
    }
  };

  const onExit = () => {
    onClose(true);
  };
  const onFree = () => {
    setLoading(true);
    setSendElecSign(1);
    handleChange(4);
    
  };
  
  const onNoCertificates = () => {
    handleChange(4);
  }

  const tokenConnection = useSelector(({SignCunstomDocumentReducer})=>SignCunstomDocumentReducer.tokenConnection);
  const dispatch = useDispatch();
  const tokenRefresh = ()=>{
    dispatch({ type: TOKEN_CONNECTION, payload: false });
    tokenValidate = true;
    onSubmit();
    tokenValidate = false;
  };
  const cerrarModal= () =>{
    dispatch({ type: TOKEN_CONNECTION, payload: false });
  };

  const handleSend = async () => {
    if (value == 4 && codeMail !== "") {
      setEnableButton(true);
      if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
        enqueueSnackbar("Superó el límite de intentos, intente más tarde", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      let firmaS3 = false;
      if (filesSize > s3Limit * 1024) {
        firmaS3 = true;
      }
      setLoading(true);
      let response = await generateCertificate(enqueueSnackbar, codeMail);
      setLoading(false);
      if (response) {
        if (response.status === 200) {
          setStatusModal(false);
          const firmarInfo = {
            idClienteFirmante: response.data.result.certificateId,
            clave: response.data.result.password,
            ltv: info.ltv,
            estampa: info.estampa,
            firmaelectronica: true,
            codeId:response.data.result.codeId,
            documentos: info.files,
          };
          if(style){
            const signInfo = {
              idClienteFirmante: response.data.result.certificateId,
              clave: response.data.result.password,
              ltv: info.ltv,
              estampa: info.estampa,
              firmaelectronica: true,
              codeId:response.data.result.codeId,
              documentos: info.files,
            };
            let selectFiles = [];
            selected.map((sel) => {
              let file = rows.find((x) => x.id === sel);
              selectFiles.push(file);
            });
            getUrldocuments(
              selectFiles,
              signInfo,
              enqueueSnackbar,
              history,
              onExit
            );
          }
          else if (firmaS3) {
            firmarS3(firmarInfo, enqueueSnackbar, onExit, history);
          } else {
            firmar(firmarInfo, enqueueSnackbar, onExit, history);
          }
          return response;
        }
      }
      setAttemps(attemps + 1);
    }
  };

  const handleChange = async (id) => {
    if (id == 4) {
      setEnableButton(true);
      if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
        enqueueSnackbar("Superó el límite de intentos, intente más tarde", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      let tokenClie = localStorage.getItem("JWT");
      let resultado = JSON.parse(window.atob(tokenClie.split(".")[1]));
      if (resultado.email.length > 2) {
        let email = resultado.email.split("@");
        let cantidad = email[0].length >= 6 ? 6 : email[0].length;
        let string = "";
        if(cantidad > 2){
          for (let i = 0; i < cantidad - 2; i++) string += '*';
          setOutMail(string + email[0].slice(-2) + "@" + email[1]);
        }else{
          string = cantidad === 1 ? "*" : "**";
          setOutMail(string + "@" + email[1]);
        }
        setLoading(true);
        let dataMail = await mailOtpSendClient(enqueueSnackbar);
        setLoading(false);
        if (dataMail) {
          setSendElecSign(1);
        } else {
          setSendElecSign(2);
        }
      } else {
        enqueueSnackbar("Error al obtener el correo", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    setValue(id);
  };
  
  const handleClick = () => {
    window.open(URL_FIRMAYA_ASISTENTE_TOKEN);
  };

  const handleInputChange = (event) => {
    if (event !== "") {
      setEnableButton(false);
      setCodeMail(event);
    } else {
      setEnableButton(true);
      setCodeMail("");
    }
  };

  if (loading || loadingAction) return <Loading />;

  if (sendElecSign == 1) {
    return (
      <Modal
        open={isOpen}
        onClose={() => onClose(false)}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          id="paperElectro1"
          elevation={3}
          className="sign-paper-source-signature"
        >
          <Grid
            container
            direction="row"
            className={classes.grid}
            justify="flex-end"
          >
            <IconButton
              edge="start"
              aria-label="upload file"
              onClick={() => onClose(false)}
              className={"btn-close-sign-modal"}
            ></IconButton>
          </Grid>
          <div>
            <form>
              <FormControl
                autoComplete="off"
                noValidate
                className={classes.form}
              >
                <Typography className={classes.textModalElectro}>
                  Hemos enviado un código a <span style={{ fontWeight: 'bold' }}>{outMail}</span>, <br></br>por favor ingrésalo a
                  continuacion (puede haber<br></br>llegado a tu correo no deseado).
                </Typography>
                <Controller
                  as={
                    <TextFieldVerify
                      name="codeValidate"
                      label={enableButton && "Código de verificación"}
                      variant="outlined"
                      inputProps={{ style: { textAlign: 'center' } }}
                      InputProps={{ autoComplete: "off", classes: { input: classes.input } }}
                    />
                  }
                  name="codeValidate"
                  control={control}
                  rules={Rules.required}
                  className={classes.validateCode}
                  onChange={([event]) => {
                    handleInputChange(event.target.value);
                  }}
                />
                <Grid
                  style={{ margin: "0px 0px 0px" }}
                  container
                  justify="center"
                >
                  <Button
                    className={"btnGeneral18"}
                    id="buttonVerify"
                    disabled={enableButton}
                    style={{ width: '154px', height: '41px' }}
                    onClick={handleSend}
                  >
                    Verificar
                  </Button>
                </Grid>
                <Typography
                  className={classes.validateLink}
                  onClick={() => {
                    setSendElecSign(2);
                  }}
                >
                  ¿No has recibido el email?
                </Typography>
                <Typography className={classes.textModalElectro}>
                  Debes tener en cuenta que un certificado <br></br> electrónico no tiene
                  validez jurídica.
                </Typography>
              </FormControl>
            </form>
          </div>
        </Paper>
      </Modal>
    );
  } else if (sendElecSign == 2) {
    return (
      <Modal
        open={isOpen}
        onClose={() => onClose(false)}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          id="paperElectro2"
          elevation={3}
          className="sign-paper-source-signature"
        >
          <Grid
            container
            direction="row"
            className={classes.grid}
            justify="space-between"
          >
            <IconButton
              edge="start"
              id="forwardCode"
              onClick={() => {
                setSendElecSign(1);
                setEnableButton(true);
              }}
              className={"btn-forward-sign-modal"}
            ></IconButton>
            <IconButton
              edge="start"
              aria-label="closeModal"
              onClick={() => onClose(false)}
              className={"btn-close-sign-modal"}
            ></IconButton>
          </Grid>
          <div>
            <form>
              <FormControl
                autoComplete="off"
                noValidate
                className={classes.form}
              >
                <Typography className={"titlePopUpForwardMail"}>
                  ¡Lo haremos de nuevo!
                </Typography>
                <Typography className={classes.textModalElectro2}>
                  Reenviaremos a <span style={{ fontWeight: "bold" }}>{outMail}</span><br></br> un nuevo
                  código de verificación.
                </Typography>
                <Grid
                  style={{ margin: "29px 0px 0px 0px" }}
                  container
                  justify="center"
                >
                  <Button
                    className={"btnGeneral18"}
                    onClick={() => {
                      handleChange(4);
                    }}
                    style={{ width: '154px', height: '41px' }}
                  >
                    Aceptar
                  </Button>
                </Grid>
              </FormControl>
            </form>
          </div>
        </Paper>
      </Modal>
    );
  } else if (tokenConnection) {
    return (
      <Modal
        open={isOpen}
        onClose={() => {
          onClose(false);
          cerrarModal();
        }}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          id="paperElectro2"
          elevation={3}
          className="sign-paper-source-signature"
        >
          <Grid
            container
            direction="row"
            className={classes.grid}
            justify="space-between"
          >
            <IconButton
              edge="start"
              id="forwardCode"
              onClick={() => {
                cerrarModal();
              }}
              className={"btn-forward-sign-modal"}
            ></IconButton>
            <IconButton
              edge="start"
              aria-label="closeModal"
              onClick={() => {onClose(false);
                cerrarModal();
              }}
              className={"btn-close-sign-modal"}
            ></IconButton>
          </Grid>
          <div>
            <form>
              <FormControl
                autoComplete="off"
                noValidate
                className={classes.form}
              >
                <Typography className={classes.textModalElectro2} style={{ margin: "0px 52px 0px "}}>
                
                
                El asistente de firma no se está ejecutando. Ejecútelo e intente de nuevo.
                <br></br>
                <br></br>
                Si no cuenta con el asistente lo puede obtener en el botón descargar.
                
                
                </Typography>
                <Grid
                  style={{ margin: "29px 0px 0px 0px"}}
                  container
                  justify="center"
                >
                  
                  <Button
                    className={"btnGeneral18"}
                    onClick={tokenRefresh}
                    style={{ width: '154px', height: '41px', margin:'10px' }}
                    
                    
                  >
                    Aceptar
                  </Button>

                  <Button
                    className={"btnGeneral18"}
                    onClick={handleClick}
                    style={{ width: '154px', height: '41px' }}
                  >
                    Descargar
                  </Button>

                </Grid>
              </FormControl>
            </form>
          </div>
        </Paper>
      </Modal>
    );
  } else {
    return (
			<Modal
				open={isOpen}
				onClose={() => onClose(false)}
				disableBackdropClick
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<Paper
					id={value == 3 ? 'paperToken' : value == 4 ? 'paperElectro' : ''}
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => onClose(false)}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<Typography className={'titlePopUpCertificate'}>
						Certificado
					</Typography>
					<div className='content'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Controller
									as={
										<Select
											key={1}
											displayEmpty
											error={!!errors.tipoCertificado}
										>
											<MenuItem value='' className={'opionCustom'}>
												<Typography className='certificateText'>
													Seleccione
												</Typography>
											</MenuItem>
											{Object.keys(certificadoOptions).length &&
												certificadoOptions.map(c => (
													<MenuItem
														disabled={isP7 > 0 && c.label == 'Token'}
														onClick={() => handleChange(c.id)}
														key={c.id}
														value={c.id}
														className={'opionCustom'}
													>
														{c.label}
													</MenuItem>
												))}
										</Select>
									}
									name='tipoCertificado'
									rules={Rules.required}
									control={control}
									defaultValue=''
									className={'selectCustom'}
								/>
								{errors.tipoCertificado && (
									<FormHelperText
										style={{
											marginBottom: '-15px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.tipoCertificado}
									>
										{errors.tipoCertificado.message}
									</FormHelperText>
								)}
								{value != 3 && (
									<Controller
										as={
											<Select key={1} displayEmpty error={!!errors.certificate}>
												<MenuItem value='' className={'opionCustom'}>
													<Typography className='certificateText'>
														ID
													</Typography>
												</MenuItem>
												{Object.keys(certificates).length &&
													certificates.map(c => (
														<MenuItem
															key={c.id}
															value={c.id}
															className={'opionCustom'}
														>
															<Typography style={{ maxWidth: '219px' }}>
																{certificateName(c.identificador, c.descripcion)}
															</Typography>
														</MenuItem>
													))}
											</Select>
										}
										name='certificate'
										rules={Rules.required}
										control={control}
										defaultValue=''
										className={'selectCustom'}
									/>
								)}
								{errors.certificate && (
									<FormHelperText
										style={{
											marginBottom: '-15px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.certificate}
									>
										{errors.certificate.message}
									</FormHelperText>
								)}
								{value != 3 && (
									<Controller
										as={
											<TextField
												placeholder='Contraseña'
												type='password'
												error={!!errors.password}
												FormHelperTextProps={{
													style: { margin: '0 0 0 5px', color: 'red' },
												}}
												InputProps={{ classes: { input: classes.resize } }}
											/>
										}
										name='password'
										control={control}
										rules={Rules.required}
										className={'selectCustom'}
									/>
								)}
								{errors.password && (
									<FormHelperText
										style={{
											marginBottom: '-20px',
											marginLeft: '37px',
											color: 'red',
										}}
										error={!!errors.password}
									>
										{errors.password.message}
									</FormHelperText>
								)}
							</FormControl>
							<br></br>
							<Grid
								style={{ margin: '20px 0px 0px' }}
								container
								justify='center'
							>
								<Button type='submit' className={'btnGeneral18'}>
									Aceptar
								</Button>
							</Grid>
						</form>
					</div>
				</Paper>
			</Modal>
		);
  }
};

const mapStateToProps = (state) => ({
  certificates: state.sign.certificates,
  loadingAction: state.sign.loading,
});

const mapDispatchToProps = {
  getCertificates: getCerificatesByUser,
  firmar: firmarAction,
  firmarS3: firmarS3Action,
  getUrldocuments: reQuickSign,
  firmarToken: firmarToken,
  getUrldocumentsToken: getUrldocumentsToken,
  mailOtpSendClient: mailOtpSendClient,
  generateCertificate: generateCertificadoAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalSign)
);
