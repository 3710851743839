import { combineReducers } from 'redux';
import validate from './ValidateDocumentReducer';
import encryptDecrypt from './EncryptDecryptReducer';
import consultSignedDocument from './ConsultSignedDocumentsReducer';
import sign from './SignReducer';
import auth from './AuthReducer';
import home from './HomeReducer';
import SignCunstomDocumentReducer from './SignCunstomDocumentReducer';
import CircuitoFirmasReducer from './CircuitoFirmasReducer';

export default combineReducers(
  {
    validate,
    encryptDecrypt,
    consultSignedDocument,
    sign,
    auth,
    home,
    SignCunstomDocumentReducer,
    CircuitoFirmasReducer,
  },
);