import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
 
const stylesDrop = makeStyles({
	dropzoneTextStyle: {
		margin: 'auto',
		textAlign: 'center',
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#EDEDED',
		},
	},
	buttons: {
		width: '124px',
		height: '27px',
		borderRadius: '7px',
		opacity: 1,
		color: '#F5AE19',
		border: '1px solid #E3AC31',
		textTransform: 'initial',
		fontWeight: 'normal',
		fontSize: '14px',
		margin: '7px',
	},
});

const ZoneUploadSign = (props) => {

	const { maxSize, loadSign, setImgLoad } = props;
	const [accept] = useState(['.jpg', '.png']);
	const [multiple] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const onDrop = async (acceptedFiles) => {
		if (acceptedFiles.length) {
			var file = acceptedFiles[0];
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async function () {
				await loadSign(reader.result);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};
		} else {
			enqueueSnackbar('No fue posible cargar la firma.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			})
		}
	};

	const classes = stylesDrop();

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		open,
	} = useDropzone({
		accept,
		multiple,
		maxSize,
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	return (
		<>
			<Box {...getRootProps()}>
				<input {...getInputProps()} />
				{
					<Box className="load-sign" id="drag-sign">
						{isDragActive ?
							<Box className='textZoneUploadSign'>Suelta la imagen aquí...</Box>
							:
							<Box>
								<Box className='textZoneUploadSign'>Arrastra la imagen de tu firma aquí <br /> ó </Box>
								<Button
									className={'btnPut'}
									onClick={open}
								>
									Cargar imagen
								</Button>
							</Box>
						}
					</Box>
				}
			</Box>
		</>
	);
};

export default ZoneUploadSign;
