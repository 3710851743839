import { API_HOSTNAME_AWS } from '../types/ApiConstants';

const token = localStorage.getItem('JWT');

// En el campo circuito solo se permiten los siguientes números: 2.Procesado, 3.Expirado, 4.Cancelado, 5.eliminado"
// En el campo estadoFirmante solo se permiten los siguientes números: 2.Procesado, 4.Cancelado, 5.eliminado, 6.Lectura"
// En el campo firmante enviamos el Id del Firmante XD
export const cambiarEstadoFirmante = async (circuito, firmante, estado) => {
	try {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + token);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: circuito,
			estadoCircuito: '',
			firmante: firmante,
			estadoFirmante: estado,
			correoFirmante: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		const respCambioEstado = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
			requestOptions
		);
		const { result } = await respCambioEstado.json();
		return result;
	} catch (error) {
		console.error('Ocurrio un error', error);
		return null;
	}
};

//Notificar al creador
export const notificarCreador = async (ultimaPosicion, circuito, firmante) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	myHeaders.append('Content-Type', 'application/json');
	let raw;
	if (ultimaPosicion) {
		//Notifica al creador que el circuito ha finalizado
		raw = JSON.stringify({
			Circuito: circuito,
			firmante: '',
			notificacion: 3,
			motivo: '',
		});
	} else {
		//Notifica al creador que ese firmante ya realizo su firma
		raw = JSON.stringify({
			Circuito: circuito,
			firmante: firmante,
			notificacion: 1,
			motivo: '',
		});
	}
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		// return console.log(result_1);
	} catch (error) {
		return console.log('error', error);
	}
};

//Notifica al siguiente firmante
export const notificar = async circuito => {
	//Notificar al siguiente firmante
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	myHeaders.append('Content-Type', 'application/json');
	let raw = JSON.stringify({
		Circuito: circuito,
		firmante: '',
		notificacion: 0,
		motivo: '',
	});
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		if (!response.ok) {
			throw new Error('error al notificar');
		}
		// else {
		// return console.log(result_1);
		// }
	} catch (error) {
		return console.log('error', error);
	}
};
export const notificar2 = async (tokenCF, circuito, code) => {
	//Notificar al siguiente firmante
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + tokenCF);
	myHeaders.append('Content-Type', 'application/json');
	let raw = JSON.stringify({
		Circuito: circuito,
		firmante: '',
		notificacion: code,
		motivo: '',
	});
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		if (!response.ok) {
			throw new Error('error al notificar');
		}
		// else {
		// return console.log(result_1);
		// }
	} catch (error) {
		return console.log('error', error);
	}
};

export const notificar3 = async (
	tokenCF,
	lisCircuito,
	firmante,
	notificacion
) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + tokenCF);
	myHeaders.append('Content-Type', 'application/json');

	let raw = JSON.stringify({
		lisCircuito: lisCircuito,
		firmante: firmante,
		notificacion: notificacion,
	});

	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};

	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		if (!response.ok) {
			throw new Error('error al notificar');
		}
	} catch (error) {
		return console.log('error', error);
	}
};

export const cambiarEstadoFirmante2 = async (
	circuito,
	firmante,
	estado,
	tokenCF
) => {
	try {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + tokenCF);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			circuito: circuito,
			estadoCircuito: '',
			firmante: firmante,
			estadoFirmante: estado.toString(),
			correoFirmante: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		const respCambioEstado = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
			requestOptions
		);
		const { result } = await respCambioEstado.json();
		return result;
	} catch (error) {
		console.error('Ocurrio un error', error);
		return null;
	}
};

export const notificarCreador2 = async (
	ultimaPosicion,
	circuito,
	firmante,
	tokenCF
) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + tokenCF);
	myHeaders.append('Content-Type', 'application/json');
	let raw;
	if (ultimaPosicion) {
		//Notifica al creador que el circuito ha finalizado
		raw = JSON.stringify({
			Circuito: circuito,
			firmante: '',
			notificacion: 3,
			motivo: '',
		});
	} else {
		//Notifica al creador que ese firmante ya realizo su firma
		raw = JSON.stringify({
			Circuito: circuito,
			firmante: firmante,
			notificacion: 1,
			motivo: '',
		});
	}
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		// return console.log(result_1);
	} catch (error) {
		return console.log('error', error);
	}
};

export const circuitoFinalizado = async (
	circuito,
	tokenCF
) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + tokenCF);
	myHeaders.append('Content-Type', 'application/json');
	let raw;
		//Notifica al creador que el circuito ha finalizado
		raw = JSON.stringify({
			Circuito: circuito,
			firmante: '',
			notificacion: 3,
			motivo: '',
		});
	
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/notify',
			requestOptions
		);
		const result_1 = await response.json();
		// return console.log(result_1);
	} catch (error) {
		return console.log('error', error);
	}
};

export const actualizarCircuito = async ( circuito, tokenCF, code ) => {
	let myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + tokenCF);
	myHeaders.append('Content-Type', 'application/json');
	let raw = JSON.stringify({
		circuito: circuito,
		estadoCircuito: code,
	});
	
	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow',
	};
	try {
		const response = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
			requestOptions
		);
		const result_1 = await response.json();
		// return console.log(result_1);
	} catch (error) {
		return console.log('error', error);
	}
};

export const cambiarEstadoFirmanteMasivo = async (
	circuitos,
	firmante,
	tokenCF
) => {
	try {
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + tokenCF);
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			listCircuito: circuitos,
			estadoCircuito: '',
			firmante: firmante,
			estadoFirmante: 2,
			correoFirmante: '',
			tipoFirma: '',
			motivo: '',
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		const respCambioEstado = await fetch(
			API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit',
			requestOptions
		);
		const { result } = await respCambioEstado.json();
		return result;
	} catch (error) {
		console.error('Ocurrio un error', error);
		return null;
	}
};