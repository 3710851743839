import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import imgDropbox from '../../assets/img/dropbox.png';
import { UploadDocumentToDropBox } from '../../redux/actions/ConsultSignedDocumentsAction';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import ConfirmDropbox from './ConfirmDropbox';

const DropBoxExplorerStyles = makeStyles((theme) => ({
  buttonDropbox: {
    backgroundImage: `url(${imgDropbox}) `,
    backgroundPosition: 'center',
    borderRadius: '5px',
    width: '28px !important',
    height: '28px !important',
  }
}));
 
const ExplorerDropbox = (props) => {

  const { selected, setSelected, rows, UploadDocumentToDropBox, iconBlack, handleClick, setStatusDropbox } = props;
  const classes = DropBoxExplorerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]); 

  const handleClickDropbox = async () => {
    const listSelect = [];
    let datosDescarga = [];
    const repetidos = {};
    selected.forEach(file => {
      const docs = rows.find(row => row.id === file);
      if (docs) {
       listSelect.push(docs);
      } else {
        const foundMatch = rows
          .filter(row => row.documentCircuito !== null)
          .flatMap(row => row.documentCircuito)
          .find(row => row.id === file);

         listSelect.push(foundMatch);
      }
    });

   const listSelectModified = listSelect.map(object =>{
    const {nombrereal}= object;
    if(repetidos[nombrereal]){
      repetidos[nombrereal]++;
      const sufijo = `_${repetidos[nombrereal]}`;
      return {...object, nombrereal: `${nombrereal}${sufijo}`};
    }else{
      repetidos[nombrereal]=1;
      return object;
    }
   })

    if(iconBlack) setStatusDropbox(false);
    await Promise.all([
      datosDescarga = await UploadDocumentToDropBox(listSelectModified, enqueueSnackbar),
      datosDescarga.length > 0 && setFiles(datosDescarga) + setOpen(true),
    ]);
  };

  if(handleClick) handleClickDropbox();

  return (
    <div>
      { iconBlack != true &&
      <IconButton
        tooltip="true"
        data-title="Guardar en Dropbox"
        className={classes.buttonDropbox}
        onClick={handleClickDropbox}
      ></IconButton>}
      {open && (
        <ConfirmDropbox
          open={open}
          setOpen={setOpen}
          files={files}
          setFiles={setFiles}
          setSelected={setSelected}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  linkDescarga: state.consultSignedDocument.link,
});

const mapDispatchToProps = {
  UploadDocumentToDropBox: UploadDocumentToDropBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerDropbox);


