import { Button, Grid, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Sign from '../../../views/Firmar/FirmaAvanzada/Sign';
 
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		marginTop: '18px',
	},
	divPaper: {
		padding: '12px'
	},
	paper: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F5AE19',
		borderRadius: '10px',
		marginLeft: '20px',
		marginRight: '20px',
	},
	title: {
		font: 'normal normal bold 24px/30px Muli',
		color: '#E55200',
		letterSpacing: '0px',
		marginTop: '20px',
		marginLeft: '23px',
	},
	buttons: {
		background: '#F5AE19 0% 0% no-repeat padding-box',
		marginTop:'16px',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'none',
		font: 'normal normal bold 14px/18px Muli',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textShadow: '0px 2px 0px #00000040',
		height: '26px',
		width: '95px',
		marginLeft: '13px',
	},
	buttonsResponsive: {
		background: '#F5AE19 0% 0% no-repeat padding-box',
		marginTop: '5px',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'none',
		font: 'normal normal bold 14px/18px Muli',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textShadow: '0px 2px 0px #00000040',
		height: '32px',
		width: '95px',
		justify: 'center',
		marginBottom: '20px'
	},
	paperSign: {
		background: '#F3F3F3 0% 0% no-repeat padding-box',
		borderRadius: '5px',
		height: '47px',
		margin: 'auto'
	},
	descriptionResponsive: {
		font: 'normal normal 600 14px/17px Muli',
		padding: '10px',
		color: '#ACACAC',
		textAlign: 'center',
		letterSpacing: '0px',
	},
	description: {
		font: 'normal normal 600 14px/17px Muli',
		marginTop:'71px',
		marginBottom:'46px',
		padding: '10px',
		color: '#ACACAC',
		textAlign: 'center',
		letterSpacing: '0px',
	},
	buttonContainer: {
		padding: theme.spacing(1),
	},
}));

const MisFirmas = (props) => {

	const classes = useStyles();
	const { listManuscrita } = props;
	const [button, setButton] = useState(0);
	const [openFirmas, setFirmas] = useState(false);
	const [showComplete] = useState(false);
	const [signPredefined] = useState(null);
	const [isResponsive, setResponsive] = useState(window.innerWidth <= 960 ? true : false )
	const [sizeWindow, setSizeWindow] = useState(window.innerWidth <= 960 ? 6 : 12);
	const [sizeList, setSizeList] = useState(window.innerWidth <= 960 ? 4 : 3);

	const handleGuardar = () => {
		setFirmas(true);
	}

	useEffect(() => {
		if(listManuscrita.length == 2) setButton(67)
		else if (listManuscrita.length == 1) setButton(140)
		else setButton(0)
	}, [listManuscrita.length]);

	window.addEventListener('resize', function(event) {
		if(this.window.innerWidth <= 960){
			setSizeWindow(6)
			setSizeList(4)
			setResponsive(true)
		}
		else { 
			setSizeWindow(12)
			setSizeList(3)
			setResponsive(false)
		}
	}, true);

	return (
		<div className={classes.root}>
			<Paper style={{ minHeight: isResponsive ? '0px' : '306px' }} className={classes.paper}>
				<Grid
					container
					spacing={2}
					direction="row"
					justify="center"					
				>
					<Grid container justify='flex-start'>
						<Typography className={classes.title}>Mis firmas</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							spacing={3}
							direction="row"
							justify="center"
						>
							{listManuscrita.length ?
								listManuscrita.slice(0, sizeList).map((firma, index) =>
									<div className={classes.divPaper}>
										<Grid key={index} item xs={sizeWindow}>
											<Paper key={index} elevation={0} name={"sign-home-" + firma.id} className={classes.paperSign} style={isResponsive ? { width: '202px' } : { width:'183px' }}>
												<div className="firma-container">
													<img className="firma-container" id={'imageSignHome-' + firma.id} src={'data:image/png;base64,' + firma.base64} />
												</div>
											</Paper>
										</Grid>
									</div>
								)
								: (
									<Grid item xs>
										<Typography className={isResponsive ? classes.descriptionResponsive : classes.description}>Aún no tienes <br/> 	firmas guardadas</Typography>
									</Grid>
								)
							}
						</Grid>
					</Grid>
					{listManuscrita.length ?
						<Grid container style={{ paddingTop: !isResponsive ? button : 0 }} className={classes.buttonContainer} justify={isResponsive ? 'center' : 'flex-start' }>
							<Button
								variant="contained"
								color="primary"
								className={isResponsive ? classes.buttonsResponsive : classes.buttons }
								onClick={() => handleGuardar()}
							>
								Ver Más
                		</Button>
						</Grid>
						:
						<Grid container justify="center" className={classes.buttonContainer}>
							<Button
								variant="contained"
								color="primary"
								style={{ whiteSpace: 'nowrap', width: '126px', height: '32px'}}
								className={classes.buttonsResponsive}
								onClick={() => handleGuardar()}
							>
								Guardar firma
                		</Button>
						</Grid>
					}
				</Grid>
			</Paper>
			{
				openFirmas && (
					<Modal
						open={openFirmas}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						onClose={() => setFirmas(false)}
						className="modal-sign"
						disableBackdropClick
					>
						<Sign
							signPredefined={signPredefined}
							home={true}
							showComplete={showComplete}
							setStateFirmas={setFirmas}
						/>
					</Modal>
				)
			}
		</div>
	);
};

export default MisFirmas;