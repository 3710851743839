import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { API_ONEDRIVE_APP_CLIENT_ID, API_ONEDRIVE_REDIRECT } from '../../../redux/types/ApiConstants';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import MicrosoftLogin from '../../../assets/MicrosoftLogin/MicrosoftLogin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogContent, Modal } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { UploadDocumentToOneDriveCF } from '../../../redux/actions/ConsultSignedDocumentsAction';
 
const useStyles = makeStyles((theme) => ({
  titleBackground: {
    margin: '0',
    background: 'rgba(0, 0, 0, 0.3)',
    height: '100px',
    paddingTop: '20px',
    width: '100%',
  },
  title: {
    margin: 0,
    color: 'white',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonMargin: {
    margin: '2px',
  },
  image: {
    width: '25px',
    height: '25px',
  },
  visible: {
    visibility: 'visible',
  },
  oculto: {
    visibility: 'hidden',
  },
  titleEncabezado: {
    color: '#f36a00',
    fontFamily: 'Verdana',
    fontSize: '100%',
  },
}));

const setVisibleModal = (setStatusModalOneDrive) => {
  setStatusModalOneDrive(false)
}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export class OneDrive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesToken: null,
      visibleLoginControl: true,
      visibleFilePicker: false,
      rowsChildFolder: [],
      parentFolder: 'root',
      breadcrumb: '',
      nameNewFolder: '',
      currentFolder: '',
      statusModalNewFolder: false,
      showDropbox: false,
    }
  }

  componentDidMount() {
    if (this.state.accesToken === null || this.state.accesToken === '') {
      this.setState((state) => {
        return {
          visibleLoginControl: false,
          visibleFilePicker: true,
        }
      });
    }
    else {
      this.setState((state) => {
        return {
          visibleLoginControl: true,
          visibleFilePicker: false,
        }
      });
    }
    this.props.setStatusModalOneDrive(true)
  }

  authHandler = (err, data) => {
    if (data) {
      this.setState((state) => {
        return {
          accesToken: data.authResponseWithAccessToken.accessToken,
          visibleLoginControl: true,
          visibleFilePicker: false,
        };
      });
      this.getChildrenList();
      this.setState({ showDropbox: true })
    } else {
      this.props.setStatusModalOneDrive(false);
      return null;
    }
  };

  uploadFile = () => {
    var oneDriveAPI = require('onedrive-api');
    const lista = [];
    this.props.selected.forEach((valor, indice, array) => {
      lista.push(this.props.rows1.find((n) => n.idCircuito === valor));
    });
    const listSelect = lista.map(el => el.documentos).flat();
    this.props.UploadDocumentToOneDriveCF(listSelect, oneDriveAPI, this.state.accesToken, this.state.parentFolder, this.props.enqueueSnackbar);
    this.props.setStatusModalOneDrive(false);
    this.props.setSelected([]);
  }

  cancelUploadFile = () => {
    this.props.setStatusModalOneDrive(false);
  }

  createNewFolder = () => {
    var oneDriveAPI = require('onedrive-api');
    var accessToken = this.state.accesToken
    oneDriveAPI.items.createFolder({
      accessToken: accessToken,
      rootItemId: this.state.parentFolder,
      name: this.state.nameNewFolder
    }).then((item) => {
      this.setState({ statusModalNewFolder: false });
      this.getChildrenList(null, this.state.parentFolder, this.state.folderName)
    })
  }

  getChildrenList = (event, parentId = 'root', folderName = '') => {
    if (parentId === 'root') {
      this.setState((state) => {
        return {
          breadcrumb: '',
        }
      });
    }
    var oneDriveAPI = require('onedrive-api');
    var accessToken = this.state.accesToken;
    oneDriveAPI.items.listChildren({
      accessToken: accessToken,
      itemId: parentId,
      shared: true,
      user: 'firmayaweb'
    }).then((childrens) => {
      this.setState((state) => {
        return {
          rowsChildFolder: childrens.value,
          parentFolder: parentId,
          currentFolder: folderName,
          breadcrumb: (this.state.breadcrumb + '/' + folderName).replace('//', '/'),

        }
      });
    })
  }

  getAuthenticationToken = () => {
    return Promise.resolve(this.state.accesToken);
  }

  handleNewFolderChange = (event) => {
    this.setState({ nameNewFolder: event.target.value });
  }

  openNewFolderModal = () => {
    this.setState({ statusModalNewFolder: true });
  }

  render() {
    const { classes } = this.props;
    //if (this.props.loading) return <Loader />;
    return (
      <div>
        {this.state.showDropbox == false ?
          (
            <div className="buttonOneDrive">
              <MicrosoftLogin
                clientId={API_ONEDRIVE_APP_CLIENT_ID}
                authCallback={this.authHandler}
                redirectUri={API_ONEDRIVE_REDIRECT}
                graphScopes={['Files.ReadWrite.All', 'Application.ReadWrite.All']}
                auto={true}
              >
                <img  />
              </MicrosoftLogin>
            </div>
          ) : (
            (
              <Dialog
                fullWidth={true}
                maxWidth='md'
                disableBackdropClick
                open={this.state.showDropbox}
                onClose={() => this.setState({ showDropbox: false })}
              >
                <DialogContent>
                <Paper elevation={3} className="onedrive-paper">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div hidden={this.state.visibleFilePicker}>
                        <Typography className={'titleModalOneDriverExplorer'} variant="h6" id="tableTitle" component="div">
                          Seleccione la carpeta donde desea guardar el archivo
                            </Typography>
                        <br />
                        <Grid className={'barcontrolOnedriveExplorer'}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={7}  >
                            <label htmlFor="ruta">Ruta</label>
                            <input id="ruta" type="text" className="breadcrumbOneDriveExplorer" value={this.state.breadcrumb} readOnly="readonly" />
                          </Grid>
                          <Grid item xs={5}  >
                            <Button className="buttonOneDriveExplorer" onClick={this.getChildrenList}>Inicio</Button>
                            <Button className="buttonOneDriveExplorer" onClick={this.openNewFolderModal}>Nueva carpeta</Button>
                          </Grid>

                        </Grid>
                        <TableContainer component={Paper}>
                          <Table className={'tableFoldersOneDrive'} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Directorio</TableCell>
                                <TableCell align="center">fecha</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.rowsChildFolder.filter(row => row.folder != null).map((row) => (
                                <TableRow key={row.name} onClick={(e) => this.getChildrenList(e, row.id, row.name)}>
                                  <TableCell align="left">{row.name}</TableCell>
                                  <TableCell align="center">{row.lastModifiedDateTime}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <br />
                        <Button className="buttonOneDriveExplorer" onClick={this.uploadFile}>Seleccionar</Button>
                        <Button className="buttonOneDriveExplorer" onClick={this.cancelUploadFile}>Cancelar</Button>
                      </div>

                    </Grid>
                  </Grid>
                  <Modal
                    open={this.state.statusModalNewFolder}
                    onClose={() => {
                      this.setState({ statusModalNewFolder: 0 });
                    }}
                  //className={classes.modalpopUpshare}
                  >
                    <div class={'newFolderModel-paper'}>
                      <br></br>
                      <br></br>
                        Nombre de la nueva carpeta
                        <br></br>
                      <br></br>
                      <input type="text" className="breadcrumbOneDriveExplorer" value={this.state.nameNewFolder} onChange={this.handleNewFolderChange} />
                      <br></br>
                      <br></br>
                      <Button className="buttonOneDriveExplorer" onClick={this.createNewFolder} >Crear carpeta</Button>
                    </div>
                  </Modal>
                </Paper>
                </DialogContent>
              </Dialog>
            )
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return {
    accesToken: null,
    rows: state.consultSignedDocument.signedDocumentsSelect,
    loading: state.consultSignedDocument.loading,
  }
};

const mapDispatchToProps = {
  UploadDocumentToOneDriveCF: UploadDocumentToOneDriveCF,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withSnackbar(OneDrive)));