import React, { useMemo, useState } from 'react';
import ButtonFiles from './ButtonFiles'
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { kbToMb } from '../helpers/FilesFunction';
import iconSheet from '../assets/img/IconSheet.png';
import dumpIcon from '../assets/img/dumpIcon.png'
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../components/Loading';
 
const activeStyle = {
	borderColor: '#E55200',
	fontSize: 22,
	color: '#E55200',
};

const acceptStyle = {
	borderColor: '#00e676',
	backgroundColor: '#F0F0F0',
	border: 'solid',
	backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
};

const rejectStyle = {
	borderColor: '#00e676',
	backgroundColor: '#F0F0F0',
	border: 'solid',
	backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
};

const stylesDrop = makeStyles({
	dropzoneTextStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
	},
	extension:{
		font: 'normal normal bold 16px/22px Muli',
		color: '#E55200'	
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#EDEDED',
		},
	},
	buttons: {
		color: '#fff',
		textShadow: '0 1px 1px #ccc',
		textTransform: 'none',
		width: 150,
	},
});

const ZoneUpload = (props) => {
	const {
		isResponsive, accept, multiple, files, setFiles, maxSize,
	} = props;

	const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(true);
	const [isLoading, setLoading] = useState(false);

	const handleClose = () => {
		setStatusModalCloudSelect(false);
	};

	const { enqueueSnackbar } = useSnackbar();

	const onDrop = (dropedFiles, rejectedFiles) => {
		if (rejectedFiles.length > 1) {
			enqueueSnackbar(
				`Solo se permite verificar un archivo`,
				{
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			);
			return;
		}
		if (rejectedFiles.length) {
			rejectedFiles.forEach((file) => {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				var extension = '.' + file.name.split('.').pop();
				var isAccept = accept.find(element => element == extension);
				if (!isAccept) {
					enqueueSnackbar(
						`El archivo ${cutNameTitle(file.name)} no es permitido, los tipos de archivos permitidos son: ${accept.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					return;
				}
				if (fileSizeMb > maxSizeMb) {
					handleClose();
					enqueueSnackbar(
						`El tamaño del archivo ${cutNameTitle(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(maxSize)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					return;
				}
			});
		}
		if (dropedFiles.length === 0) return;
		setFiles(dropedFiles);
		enqueueSnackbar('Archivos agregados exitosamente.', {
			variant: 'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const onDelete = (fileToDelete) => {
		setFiles([]);
		setStatusModalCloudSelect(false);
		enqueueSnackbar(`Archivo ${cutNameTitle(fileToDelete)} eliminado.`, {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	const classes = stylesDrop();

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept,
		multiple,
		maxSize,
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	const style = useMemo(() => ({
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {}),
	}), [
		isDragActive,
		isDragAccept,
		isDragReject,
	]);

	const cutNameTitle = (name) => {
		if(name != null && name.length > (isResponsive ? 70 : 10)) return (name.substring(0, (isResponsive ? 70 : 10)).concat('...'))
		else return name
	};

	const cutName = (name) => {
		var word = window.innerWidth > 991 ? 70 : 30;
		return(
			name.length > word ? (
			<Tooltip title={name}>
				<div>{name.substring(0, word).concat('...')}</div>
			</Tooltip>
		) : <div>{name}</div>)
	};

	return (
		<div className="container">
			{isLoading == true &&
				<Loading />
			}
			{!files.length ? (
				<div {...getRootProps({ style })} className="base-validate-container">
					{isDragActive
						? <p style={{marginTop: isResponsive ? '-17px' : '40px'}}>Suelte los archivos</p>
						: (
							<div className={classes.dropzoneTextStyle}>
								<img src={iconSheet} className="icon-upload" />
								<div className='textZoneUploadLocal'>{isResponsive ? 'Arrastra o carga' : 'Carga' } aquí únicamente {!isResponsive ? '' : <br></br>} un documento con extensión <span className={classes.extension}>.p7s .p7z </span>o <span className={classes.extension}>.pdf</span></div>
								<ButtonFiles
								accept={accept} 
								multiple={multiple}
								files={files}
								setFiles={setFiles}
								maxSize={maxSize}
								setLoading={setLoading}
								/>
							</div>
						)
					}
				</div>
			) : (
				<>
					<div {...getRootProps({ style })} className="validate-style-container">
						{isDragActive
							? <p style={{marginTop: '0px'}}>Suelte los archivos</p>
							: (
								<List dense style={{ width: '100%' }}>
									<div>
										{files.map((element) => (
											<ListItem dense key={element.path} style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '0 0 0 0' }} className='contentZoneUploadList'>
												<ListItemText
													primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
													primary={cutName(element.name)}
													secondary={`Tamaño: ${(element.size * 0.001).toFixed(2)} KB`}
												/>
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={() => onDelete(element.name)}
													>
														<img src={dumpIcon} width='15px' />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										))}
									</div>
								</List>
							)
						}	
					</div>
				</>
			)
			}
		</div>
	);
};

export default ZoneUpload;
