import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import {
	TextField,
	Box
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

	inputPassword: {
		display: 'flex',
		alignItems: 'flex-end',
		borderRadius: '10px',
		boxShadow: '0px 5px 3px #00000033',
		maxWidth: '350px',
		maxHeight: '38px',
		marginBottom: '16px',
		font: 'normal normal normal 16px/22px Muli',
		position: 'relative',

		'& label': {
			padding: '0px 12px',
			color: '#B2B2B2',
			width: '100%',
		},

		'& input': {
			padding: '6px 12px',
			color: '#6D6E71',
			width: '100%',
		},
		'& p': {
			position: 'absolute',
			bottom: '-20px',
			right: '0px',
		},

		'& .MuiFormHelperText-root': {
			color: '#f5ae19',
		},
		'& .Mui-error': {
			color: '#f5ae19',
		},
		'& input::-webkit-input-placeholder': {
			color: '#6D6E71 !important',
			width: '100%',
			fontWeight: '500',
			
		},


	},
	iconContainerPassword: {
		backgroundColor: '#F5AE19',
		borderTopLeftRadius: '8px',
		borderBottomLeftRadius: '8px',
		maxHeight: '100%',
		maxWidth: '38px',
		display: 'flex',
		padding: '0.6rem',
	},

}));

function ButtonPassword(props, ref) {
	const classes = useStyles();
	return (
		<Box className={classes.inputPassword}>
			<div className={classes.iconContainerPassword}>
				<LockIcon style={{ color: '#FFFFFF', fontSize: '11px' }} />
			</div>
			<TextField style={{ width: '100%' }}
				ref={ref}
				required
				id={props.id}
				error={props.error}
				type="password"
				label={props.label}
				variant="standard"
				onChange={props.onChange}
				helperText={props.helperText}
				disabled={props.disabled}
				inputProps={{
					placeholder: props.labelPlaceholder,
					maxLength: 20,
				}}
			/>
		</Box>
	)
}

export default forwardRef(ButtonPassword)