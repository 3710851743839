import jwtDecode from 'jwt-decode';

export const getInfoToken = () => {
  const token = localStorage.getItem('JWT');
  if (!token) return undefined;
  const jwt = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (jwt.exp < currentTime) {
    console.warn('authUtils: Token removed');
    localStorage.removeItem('JWT');
    return undefined;
  }
  const privilegios = jwt.FIRMAYA_PRIVILEGE;
  
  const userInfo = { name: jwt.given_name.toLowerCase(), surname: jwt.family_name.toLowerCase(),  email:jwt.email.toLowerCase(), userDigitalWare:privilegios.includes("FIRMAYA_CIRCUIT_API"), };

  return userInfo;
};


export const isValidToken = () => {
  const token = localStorage.getItem('JWT');

  if (!token) return false;
  const jwt = jwtDecode(token);  

  const currentTime = Date.now() / 1000;//Here it validates if the time of the token is still valid, otherwise it revokes the token 
  if (jwt.exp < currentTime) {
    console.warn('authUtils: Token removed');
    localStorage.removeItem('JWT');
    return false;
  } 
  return true;
};


export const validateEstampa = () => {
  const token = localStorage.getItem('JWT');
  if (!token) return undefined;
  const jwt = jwtDecode(token);  
  return jwt.FIRMAYA_PRIVILEGE.includes("FIRMA_TSA_CREATE_TX");
};

export const getLettersAvatar = (userInfo) => `${userInfo.name.toUpperCase().charAt(0)}${userInfo.surname.toUpperCase().charAt(0)}`;
