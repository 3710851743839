import React, { Component } from 'react';
import IconoRefrescar from '../../assets/img/btnRefreshCaptcha.png';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';
 
class Captcha extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainCaptcha: "mainCaptcha",
            txtCaptcha: "txtCaptcha",
            captcha: "captcha",
            texto: "",
            hiddenCaptcha: false,
            file: props.file,
            filename: props.fileName,
            redirect: props.redirect,
        }
    }

    componentDidMount() {
        this.genCaptcha();
    }

    genCaptcha = () => {
        var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
        var i;
        var a = "";
        for (i = 0; i < 5; i++) {
            a = a + alpha[Math.floor(Math.random() * alpha.length)] + ' ';
        }
        var code = a.substring(0, a.length - 1);
        document.getElementById('mainCaptcha').value = code;
        document.getElementById('txtCaptcha').value = "";
        this.state.texto = code;
        this.CreaIMG();
    }

    ValidCaptcha = () => {
        var string1 = document.getElementById('mainCaptcha').value.split(' ').join('');
        var string2 = document.getElementById('txtCaptcha').value.split(' ').join('');
        if (string2 != '') {
            if (string1 == string2) {
                window.location = this.state.redirect;
                this.props.setmodal(false);
            }
            else {
                this.props.enqueueSnackbar("Texto invalido, intenta nuevamente", { 
                    variant: 'error',
                    anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
                });
                document.getElementById('txtCaptcha').value = "";
                this.genCaptcha('mainCaptcha');
                return false;
            }
        }
        else
        this.props.enqueueSnackbar("Por favor ingrese el texto de la imagen", { 
            variant: 'warning',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        });
    }


    CreaIMG = () => {
        var texto = this.state.texto;
        var ctxCanvas = document.getElementById('captcha').getContext('2d');
        var fontSize = "40px";
        var fontFamily = "Arial";
        var width = 220;
        var height = 100;
        //tamaño
        ctxCanvas.canvas.width = width;
        ctxCanvas.canvas.height = height;

        //color de fondo
        ctxCanvas.fillStyle = "WHITE";// "whitesmoke";
        ctxCanvas.fillRect(0, 0, width, height);
        //puntos de distorsion
        ctxCanvas.setLineDash([7, 10]);
        ctxCanvas.strokeStyle = 'white';
        ctxCanvas.lineDashOffset = 100;
        ctxCanvas.beginPath();
        var line;
        for (var i = 0; i < (width); i++) {
            line = i * 5;
            ctxCanvas.moveTo(line, 0);
            ctxCanvas.lineTo(0, line);
        }
        ctxCanvas.stroke();
        //formato texto
        ctxCanvas.direction = 'ltr';
        ctxCanvas.font = fontSize + " " + fontFamily;
        //texto posicion
        var x = (width / 9);
        var y = (height / 3) * 2;
        //color del borde del texto
        ctxCanvas.strokeStyle = "yellow";
        ctxCanvas.strokeText(texto, x, y);
        //color del texto
        ctxCanvas.fillStyle = "black";
        ctxCanvas.fillText(texto, x, y);
    }

    render() {
        return (
            <div id="dvCaptcha" hidden={this.state.hiddenCaptcha}>
                <input type="hidden" id="mainCaptcha" name="mainCaptcha" />
                <canvas id="captcha" name="captcha"> </canvas>
                <Grid
                    container
                    justify="center"
                    spacing={1}>
                    <Grid item>
                        <img src={IconoRefrescar} height="36" onClick={this.genCaptcha} title="Refrescar"></img>
                    </Grid>
                    <Grid item>
                        <TextField id="txtCaptcha" label="Escriba el texto" variant="outlined" name="txtCaptcha" />
                    </Grid>
                </Grid>
                <br />
                <Grid
                    container
                    justify="center">
                    <div>
                        <center>
                            <Button
                                className={'btnGeneral'}
                                onClick={this.ValidCaptcha}>
                                Descargar
                             </Button>
                        </center>
                    </div>
                </Grid>
            </div>
        )
    }
}

export default withSnackbar(Captcha);