import { makeStyles, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import info from "../assets/img/info.svg";
import guion from "../assets/img/guion.png";
import nube from "../assets/img/nube.svg";
import drive from "../assets/img/drive.svg";
import download from "../assets/img/download.svg";
import dropboxs from "../assets/img/dropbox.svg";
import eliminar from "../assets/img/eliminar.svg";
import firma from "../assets/img/firma.svg";
import GoogleDrive from "../views/Firmar/CircuitoFirmas/GoogleDrive";
import { useDispatch, useSelector } from "react-redux";
import { downloadFromS3CF, UploadDocumentToDropBoxCF } from "../redux/actions/ConsultSignedDocumentsAction";
import { useSnackbar } from "notistack";
import {  deleteClickDer,  dropboxClickDer,  firmClickDer,  modalDelete,  modalDropbox,  modalFirmaAvanzadaCf,  modalOneDrive,  oneDriveClickDer} from "../redux/actions/CircuitoFirmasAction";
import OneDrive from "../views/Firmar/CircuitoFirmas/OneDrive";
import { useHistory } from "react-router-dom";
 
const MenuContextual = (props) => {
  //This is the right click menu, the styles are inside the function because they receive parameters from props such as the position in X and Y from where the right click was given
  const useStyle = makeStyles({
    menuSecundario: {
      borderRadius: "5px",
      boxSizing: "border-box",
      position: "absolute",
      margin: "0",
      width: "185px",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      backgroundColor: "white",
      top: `${props.y}px`,
      left: `${props.x}px`,
    },
    iconosMenu: {
      padding: "1px",
      margin: "9px",
      backgroundColor: "transparent",
      color: "#878787",
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      filter:
        "invert(54%) sepia(5%) saturate(10%) hue-rotate(18deg) brightness(98%) contrast(93%)",
    },
    filasMenu: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#878787",
        color: "white",
        "& img": {
          filter:
            "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(209deg) brightness(110%) contrast(106%)",
        },
      },
    },
    textoMini: {
      marginLeft: "13px",
      fontSize: "10px",
    },
    menuDerecho: {
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      width: "185px",
      height: "340px",
      borderRadius: "5px",
      backgroundColor: "white",
    },
    fila: {
      cursor: "pointer",
      "&:hover": {
        "& span": {
          opacity: "1",
        },
      },
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selecteds, setSelecteds] = useState()
  const states = useSelector((state) => state);
  const data = states.CircuitoFirmasReducer.item;
  const db = states.CircuitoFirmasReducer.db;
  const { todos, deleted } = props;
  const [statusModalGoogleDrive, setStatusModalGoogleDrive] = useState(false);
  const [statusModalOneDrive, setStatusModalOneDrive] = useState(false);
  const [tokenOneDrive, setTokenOneDrive] = useState(null);


  const firmaDocument = () => {
    //Here we send the documents to the modal Firma Avanzada 
    const firmar = [];
    
    firmar.push(data[0].idCircuito);
    dispatch(firmClickDer(firmar));
    dispatch(modalFirmaAvanzadaCf(true));
  };

  const descargaDocument = () => {
    //here we download the documents sending the information of these to the downloadFromS3CF service that is the one that allows me to download
    const files = data.map((el) => el.documentos).flat();
    dispatch(downloadFromS3CF(files, enqueueSnackbar));
  };

  const eliminarDocument = () => {
    //here we delete the circuit sending the circuit information to redux for the visual part and to the delete modal for the user to confirm said deletion
    const eliminar = [];
    eliminar.push(data[0].idCircuito);
    dispatch(deleteClickDer(eliminar));
    dispatch(modalDelete(true));
  };

  //here we set the format required for the save functionality
  const guardar = [];
  guardar.push(data[0].idCircuito);

// here we send the circuit information a guardar para la funcionalidad de OneDrive
  const handleClickOneDriveCF = (e) => {
    dispatch(oneDriveClickDer(guardar))
    dispatch(modalOneDrive(true))
  };
  const history = useHistory()

  const handleClickDetalle = (e) =>{
    const pos = data[0].cifrado;
    history.push(`/home/DetalleCircuito/${pos}`)
  }

 // here we send the circuit information a guardar para la funcionalidad de Dropbox
  const handleClickDropbox = async () => {
    const lista = [];
    let datosDescarga = [];
    guardar.forEach((valor) => {
      lista.push(db.find((n) => n.idCircuito === valor));
    });
    const listSelect = lista.map(el => el.documentos).flat();
    await Promise.all([
      datosDescarga = await dispatch(UploadDocumentToDropBoxCF(listSelect, enqueueSnackbar)),
      datosDescarga.length > 0 && dispatch(dropboxClickDer(datosDescarga)),
    ]);
    dispatch(modalDropbox(true))
  };

  return (
    <div className={classes.menuSecundario}>
      <ul style={{ margin: 0, padding: 0 }}>
        {todos == true && (
          <>
            <li
              className={classes.filasMenu}
              onClick={() => descargaDocument()}
            >
              <img className={classes.iconosMenu} src={download} />
              Descargar
            </li>
            <li
              className={classes.filasMenu}
              onClick={() => eliminarDocument()}
            >
              <img className={classes.iconosMenu} src={eliminar} />
              Eliminar
            </li>
          </>
        )}
        {todos == false && (
          <>
            <li className={classes.filasMenu} onClick={handleClickDetalle}>
              <img className={classes.iconosMenu} src={info} />
              Ver detalle
            </li>
            <hr style={{ margin: "0px", color: "#878787" }}></hr>
            <li className={classes.filasMenu} onClick={() => firmaDocument()}>
              <img className={classes.iconosMenu} src={firma} />
              Firmar
            </li>
            <li
              className={classes.filasMenu}
              onClick={() => descargaDocument()}
            >
              <img className={classes.iconosMenu} src={download} />
              Descargar
            </li>
            <li
              className={classes.filasMenu}
              onClick={() => eliminarDocument()}
            >
              <img className={classes.iconosMenu} src={eliminar} />
              Eliminar
            </li>
            <hr style={{ margin: "0px", color: "#878787" }}></hr>
            <p className={classes.textoMini}>Guardar en</p>
            <li className={classes.filasMenu}>
              <img className={classes.iconosMenu} src={drive} />
              <GoogleDrive iconBlack={true}
                open={statusModalGoogleDrive}
                 disableBackdropClick
                onClose={() => setStatusModalGoogleDrive(false)}
                setStatusModalGoogleDrive={setStatusModalGoogleDrive}
                selected={guardar}
                rows1={db}
                setSelected={setSelecteds} />
            </li>
            <li className={classes.filasMenu}
              onClick={handleClickDropbox}
            ><img className={classes.iconosMenu} src={dropboxs} />
              Dropbox
            </li>
            <li className={classes.filasMenu} onClick={handleClickOneDriveCF}>
              <img className={classes.iconosMenu} src={nube}
              />
              OneDrive
            </li>
          </>
        )}
        <Modal
          open={statusModalOneDrive}
           disableBackdropClick
          onClose={() => {
            setStatusModalOneDrive(0);
          }}
          className={classes.modalpopUpshare}
        >
          <div>
            <OneDrive
              tokenOneDrive={tokenOneDrive}
              setTokenOneDrive={setTokenOneDrive}
              setStatusModalOneDrive={setStatusModalOneDrive}
              selected={guardar}
              rows1={db}
              setSelected={setSelecteds}
            />
          </div>
        </Modal>
        {deleted == true && (
          <>
            <li className={classes.filasMenu} onClick={handleClickDetalle}>
              <img className={classes.iconosMenu} src={info} />
              Ver detalle
            </li>
            <hr style={{ margin: "0px", color: "#878787" }}></hr>
            <li
              className={classes.filasMenu}
              onClick={() => descargaDocument()}
            >
              <img className={classes.iconosMenu} src={download} />
              Descargar
            </li>
            <li
              className={classes.filasMenu}
              onClick={() => eliminarDocument()}
            >
              <img className={classes.iconosMenu} src={eliminar} />
              Eliminar
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default MenuContextual;
