import React, { Component } from 'react';
import { APL_GOOGLE_DRIVE_CLIENT_ID, API_GOOGLE_DRIVE_API_KEY, SCOPES } from '../../redux/types/ApiConstants';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import GooglePicker from 'react-google-picker';
import { getBase64OfSelectedFileArr, UploadDocumentToDrive } from '../../redux/actions/ConsultSignedDocumentsAction';
import drive from '../../assets/img/googledriveIconHover.png';
import { Button, IconButton, Typography } from '@material-ui/core';

const Driveicon = () => {
	return (<img src={drive} />);
}

class ExplorerGoogleDrive extends Component {

	constructor(props) {
		super(props);
		let i = 0;
	}

	render() {
		return (
			<GooglePicker clientId={APL_GOOGLE_DRIVE_CLIENT_ID}
				developerKey={API_GOOGLE_DRIVE_API_KEY}
				scope={[SCOPES]}
				onChange={data => console.log('on change:', data)}
				onAuthFailed={data => console.log('on auth failed:', data)}
				multiselect={false}
				navHidden={false}
				authImmediate={false}
				mimeTypes={['application/vnd.google-apps.folder']}
				viewId={'FOLDERS'}
				createPicker={(google, oauthToken) => {
					const docsView = new google.picker.DocsView()
						.setIncludeFolders(true)
						.setMimeTypes('application/vnd.google-apps.folder')
						.setSelectFolderEnabled(true)
						.setOwnedByMe(true);
					const picker = new window.google.picker.PickerBuilder()
						.addView(docsView)
						.setLocale('es')
						.setTitle('Seleccione el folder donde desea guardar el archivo')
						.setOAuthToken(oauthToken)
						.setDeveloperKey(API_GOOGLE_DRIVE_API_KEY)
						.setCallback((response) => {
							var folderID = (response.action === "picked") ? response.docs[0].id : '';
							if (folderID !== '') {
								var rowsSelected = this.props.selected;
								var rows = this.props.rows1;
								const documentList = [];
								rowsSelected.forEach(file => {
									const docs = rows.find(row => row.id === file);

									if (docs) {
										documentList.push(docs);
									} else {
										const foundMatch = rows
											.filter(row => row.documentCircuito !== null)
											.flatMap(row => row.documentCircuito)
											.find(row => row.id === file);

											documentList.push(foundMatch);
									}
								});
								this.props.UploadDocumentToDrive(documentList, oauthToken, folderID, this.props.enqueueSnackbar);
								this.props.setSelected([]);
							}
						});
					picker.build().setVisible(true);
					this.props.setStatusModalGoogleDrive(false);
				}}>
				{this.props.iconBlack != true ? (
					<IconButton type="button" id="btnPick" className={'buttonGoogleDriveExplorer'} ></IconButton>
				) : (
					<Button className={'buttonGoogleDriveExplorerBlack'} id="btnPick" type="button" startIcon={<Driveicon />}>
						<Typography style={{
							marginLeft: '-2px', font: 'normal normal bold 16px/16px Muli',
							fontWeight: 'bolder',
							letterSpacing: '0px',
							paddingLeft: '8px',
							color: '#4F4F4F'
						}}>Google Drive</Typography>
					</Button>
				)}
			</GooglePicker>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.consultSignedDocument.loading,
	consultReducer: state.consultSignedDocument,
});

const mapDispatchToProps = {
	obtenerBase64Arr: getBase64OfSelectedFileArr,
	UploadDocumentToDrive: UploadDocumentToDrive,
};

export default connect(mapStateToProps, mapDispatchToProps)((withSnackbar(ExplorerGoogleDrive)));