import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFiles } from '../../../redux/actions/SignAction';
import Modal from '@material-ui/core/Modal';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Pdf from './Pdf';

const ModalFirmaAvanzada = props => {
	const {
		isOpen,
		onClose,
		filesSize,
		style,
		selected,
		rows,
		getFiles,
		history,
		setFiles,
		setOpenedRowIndex,
	} = props;
	const [loading, setLoading] = useState(true);
	const [newFiles, setNewFiles] = useState([]);
	const [size, setSize] = useState(0);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (style) {
			getData();
		} else {
			setLoading(false);
		}
	}, []);

	const getData = async () => {
		let selectFiles = [];
		selected.forEach(file => {
			const docs = rows.find(row => row.id === file);

			if (docs) {
				selectFiles.push(docs);
			} else {
				const foundMatch = rows
					.filter(row => row.documentCircuito !== null)
					.flatMap(row => row.documentCircuito)
					.find(row => row.id === file);

				selectFiles.push(foundMatch);
			}
		});

		let response = await getFiles(selectFiles, enqueueSnackbar, history);

		if (response) {
			setNewFiles(response.files);

			setSize(response.filesSize);

			setLoading(false);
		}
	};

	const onExit = type => {
		onClose(type);
	};

	if (loading) return <Loading />;

	return (
		<Modal
			disableBackdropClick
			open={isOpen}
			onClose={onExit}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
		>
			<Pdf
				files={style ? newFiles : props.files}
				onClose={onExit}
				filesSize={style ? size : filesSize}
				style={style}
				setFiles={setFiles}
				setOpenedRowIndex={setOpenedRowIndex}
			/>
		</Modal>
	);
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
	getFiles: getFiles,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ModalFirmaAvanzada)
);
