import {Grid, makeStyles, Button, Box, MenuItem, Select, IconButton, Container, Paper, Modal,} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import IconMarcaAgua from '../../../assets/img/ico_marca_agua.png';
import IconCheck from '../../../assets/img/check.png';
import IconQR from '../../../assets/img/ico_qr.png';
import IconManuscrita from '../../../assets/img/ico-manuscrita.png';
import AddIcon from '@material-ui/icons/Add';
import Atras from '../../../assets/img/iconLeft2.png';
import AddSignature from '../../../assets/img/campofirma.png';
import imgDefaultSign from '../../../assets/img/gse.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import addButton from '../../../assets/img/add-button.png';
import '.././FirmaAvanzada/Pdf.css';
import '.././FirmaAvanzada/Sign.css';
import agrandar from '../../../assets/img/AgrandarDoc.jpg';
import alejar from '../../../assets/img/AlejarDoc.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { modalAddFirmantes, modalConfirm, dataQr, firm_positions, stepCircuit, camposHechos, name_folder,} from '../../../redux/actions/CircuitoFirmasAction';
import ModalConfirm from './modales/ModalConfirm';
import ModalAddSignature from './modales/ModalAddSignature';
import { getListSignature } from '../../../helpers/SignatureUtil';
import CanvasDragResize from '../FirmaAvanzada/CanvasDragResize';
import { MAX_MEGAS_FILES_SIZE, MAX_MEGAS_FILE_SIZE} from '../../../redux/types/ApiConstants';
import { kbToMb } from '../../../helpers/FilesFunction';
import { useSnackbar } from 'notistack';
import { PDFDocument } from 'pdf-lib';
import clsx from 'clsx';
import ModalQR from '../FirmaAvanzada/ModalQR';
import CustomizedSteppers from '../../../components/Demo';
import { truncarNombreCompleto } from '../../../helpers/helpNames';

const useStyle = makeStyles(theme => ({
	centered: {
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	hidden: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	iconBack: {
		cursor: 'pointer',
		position: 'relative',
		top: '30px',
		left: '0',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '10%',
		},
	},
	paragraph: {
		fontSize: '16px',
		color: '#4F4F4F',
		margin: '16px 0 8px 0',
		fontFamily: 'Mulish',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-start',
			width: '100%',
		},
	},
	options: {
		fontSize: '14px',
		border: '1px solid #C9C9C9',
		borderRadius: '10px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		boxShadow: '1px 3px 6px -1px rgba(0,0,0,0.66)',
		width: '100%',
		maxWidth: '300px',
		height: '40px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '15px',
		'@media(max-width:600px)': {
			maxWidth: '100%',
			fontSize: '1rem',
		},
	},
	backgroundTurquoise: {
		backgroundColor: 'transparent',
		width: '25px',
		marginTop: '8px',
	},
	divTurqoise: {
		width: '40px',
		height: '40px',
		borderTopLeftRadius: '10px',
		borderBottomLeftRadius: '10px',
		alignItems: 'center',
	},
	circleSelect: {
		color: '#84FFE7',
		display: 'inline-block',
		verticalAlign: 'middle',
		width: '12px',
		height: '12px',
		borderRadius: '50%',
		margin: ' 0 13px ',
	},
	btnCampoFirma: {
		backgroundColor: '#F3F3F3',
		marginTop: '16px',
		textTransform: 'none',
		width: '100%',
		border: '1px solid #C9C9C9',
		borderRadius: '10px',
		justifyContent: 'space-between',
		fontSize: '14px',
		maxWidth: '300px',
		padding: '0',
		paddingRight: '8px',
		'@media(max-width:600px)': {
			maxWidth: '100%',
			fontSize: '1rem',
		},
	},
	btnAdd: {
		textTransform: 'none',
		color: '#E55200',
		padding: '5px 10px',
		fontSize: '16px',
		width: '100%',
		display: 'flex',
		justifyContent: 'start',
		border: 'none',
		backgroundColor: 'transparent',
		alignItems: 'center',
		fontFamily: 'Mulish',
		marginTop: '0px',
		'&:hover': {
			backgroundColor: '#F2F2F2',
		},
	},
	boxes: {
		height: '600px',
		'@media(max-width:1252px)': {
			height: '550px',
		},
	},
	parrafo: {
		margin: '0',
	},
	iconsPdf: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '0',
		cursor: 'pointer',
	},
	iconPdf: {
		margin: '0 2px 8px 2px',
		width: '32px',
		height: '20px',
		borderLeft: '2px solid #DFDFDF',
		padding: '0 6px 0 ',
	},
	icon1Pdf: {
		margin: '0 2px 8px 2px',
		width: '32px',
		height: '20px',
		padding: '0 6px 0 ',
	},
	size: {
		fontSize: '17px',
		colo: '4F4F4F',
		margin: '0 0 32px 0',
	},
	tamaño: {
		width: '15px',
		marginLeft: '3px',
	},
	contBtns: {
		display: 'flex',
		flexDirection: 'column',
		'@media(max-width:1279px)': {
			justifyContent: 'center',
		},
	},
}));

const runTextScript = text => {
	const s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.innerHTML = text;
	document.body.appendChild(s);
	document.body.removeChild(s);
};

const listColorsChoose = [];
const NewViewFirmar = () => {
	const [addedFields, setAddedFields] = useState([]);
	const classes = useStyle();
	let marcaAguaImg = IconMarcaAgua;
	let QRImg = IconQR;
	const dispatch = useDispatch();
	const states = useSelector(state => state);
	const modalConfirmStatus = states.CircuitoFirmasReducer.modalConfirm;
	const modalAddFirmantesStatus = states.CircuitoFirmasReducer.modalAddFirmante;
	const carpeta = states.CircuitoFirmasReducer.carpeta;
	const idPdfContainer = 'tttttcontainer';
	const idThumbnailsContainer = 'idThumbnailsContainer';
	const [isStamp, setStamp] = useState(false);
	const [isMarcaAgua, setMarcaAgua] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [firmas, setFirmas] = useState([]);
	const [docs, setDocs] = useState([]);
	const [isReSign, setReSign] = useState(false);
	const [numerodocumentos, setNumeroDocumentos] = useState(0);
	const [scale, setScale] = useState(0.8);
	const dataQRS = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.dataQR
	);
	const [sendData, setSendData] = useState({
		signerClientId: null,
		firmaelectronica: null,
		key: null,
		documents: dataQRS,
	});
	const signers = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.firmantes
	);
	const manuscrita = 'manuscrita';
	const digital = 'digital';
	const estampa = 'estampa';
	const marcaAgua = 'marcaagua';
	const qr = 'qr';
	const [currentPage, setCurrentPage] = useState(1);
	const [imageSignature, setImageSignature] = useState([]);
	const [imgSign, setImgSign] = useState(null);
	const [verManuscritaDisable, setVerManuscritaDisable] = useState(false);
	const [isQR, setQR] = useState(false);
	const [currentDoc, setCurrentDoc] = useState(0);
	const [predefinedSource, setPredefinedSource] = useState(false);
	let QRClass = 'pdf-botonera-btn_CF';
	let marcaAguaClass = 'pdf-botonera-btn_CF';
	const [firmantes, setFirmantes] = useState(signers);
	const [selectedFirmante, setSelectedFirmante] = useState(0);
	const [disabledBtnsPdf, setDisabledBtnsPdf] = useState(false);
	const [verQR, setVerQR] = useState(false);
	const [typeImg, setTypeImg] = useState(manuscrita);
	const [zoomCount, setZoomCount] = useState(0);
	const [signFields, setSignFields] = useState([]);
	const [modalflag, setModalflag] = useState(false);
	const [configQR, setConfigQR] = useState({
		title: '',
		url: false,
		tPaginas: false,
	});
	const [nombFirm, setNombFirm] = useState([]);
	const filesCF = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.files
	);
	const camposAnteriores = states.CircuitoFirmasReducer.camposHechos;
	const CryptoJS = require('crypto-js');
	const firmantesRef = useRef(firmantes);
	const combinedClasses = clsx(classes.centered, classes.contBtns);

	if (isQR) {
		QRImg = IconCheck;
		QRClass = 'pdf-botonera-btn_CF-active';
	}

	if (isMarcaAgua) {
		marcaAguaImg = IconCheck;
		marcaAguaClass = 'pdf-botonera-btn_CF-active';
	}

	useEffect(() => {
		firmantesRef.current = firmantes;
	}, [firmantes]);

	useEffect(() => {
		if (firmantes.length > 0) {
			// Buscar el primer firmante que no sea observador y tenga Manuscrita en true
			const primerFirmanteManuscrita = firmantes.find(
				firmante => !firmante.esObservador && firmante.Manuscrita
			);

			if (primerFirmanteManuscrita) {
				setSelectedFirmante(primerFirmanteManuscrita.Posicion);
			} else {
				// Si no hay firmantes con Manuscrita en true, seleccionar el primer firmante que no sea observador
				const primerFirmanteNoObservador = firmantes.find(
					firmante => !firmante.esObservador
				);
				if (primerFirmanteNoObservador) {
					setSelectedFirmante(primerFirmanteNoObservador.Posicion);
				}
			}
		}
	}, [firmantes, modalAddFirmantesStatus]);

	useEffect(() => {
		if (firmantes.length > 0) {
			const firmantesPendientes = firmantes
				.filter(
					firmante =>
						!firmante.esObservador &&
						firmante.firmaDocumentos.length === 0 &&
						firmante.Manuscrita
				)
				.map(firmante => `${firmante.Nombres} ${firmante.Apellidos}`);
			setNombFirm(firmantesPendientes);
		}
	}, [firmantes]);

	useEffect(() => {
		if (camposAnteriores.length > 0) {
			setSignFields(camposAnteriores);
			renderCampos(camposAnteriores);
		}
		if (dataQRS && dataQRS.length > 0) {
			dataQRS.forEach(dataQR => {
				if (dataQR.typeSignature && dataQR.typeSignature.length > 0) {
					dataQR.typeSignature.forEach(signature => {
						if (signature.signatureType === 'marcaagua') {
							setMarcaAgua(true);
						}
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		setDocs(filesCF);
		setFirmantes(signers);
	}, [signers]);

	useEffect(() => {
		verifyFields();
	}, [firmantes]);

	useEffect(() => {
		document.body.className = 'bodyMiddle';
		let count = 0;
		let documentos = [];
		let documentosCargados = 0;
		setFirmas([]);
		runTextScript(
			"initPdfComponent('" +
				idPdfContainer +
				"','" +
				idThumbnailsContainer +
				"')"
		);
		if (docs?.length > 0) {
			docs.map(doc => {
				const reader = new FileReader();
				reader.numDoc = count;
				reader.filename = doc.name;
				reader.readAsDataURL(doc);
				reader.onload = event => {
					var document = new Object();
					document.uuid = event.target.numDoc;
					document.fileName = event.target.filename;
					document.base64 = event.target.result.replace(
						'data:' + doc.type + ';base64,',
						''
					);
					document.hash = CryptoJS.SHA256(
						CryptoJS.enc.Base64.parse(document.base64)
					).toString(CryptoJS.enc.Base64);
					if (dataQRS && dataQRS.length > 0) {
						// Encuentra el objeto correspondiente en dataQRS por base64
						const dataQR = dataQRS.find(
							data => data.base64 === document.base64
						);
						if (dataQR) {
							// Agrega typeSignature de dataQR a doc
							document.typeSignature = dataQR.typeSignature;
						} else {
							// Si no se encuentra, asigna un array vacío
							document.typeSignature = [];
						}
					} else {
						// Si dataQRS no contiene datos, asigna un array vacío
						document.typeSignature = [];
					}

					document.listSigns = getListSignature(document.base64);
					documentos.push(document);
					documentosCargados++;

					if (documentosCargados >= docs.length) {
						documentos.sort((doc1, doc2) =>
							doc1.uuid > doc2.uuid ? 1 : doc1.uuid < doc2.uuid ? -1 : 0
						);
						//Aqui es donde se me esta reiniciando el sendData------------------------------------------------------------------------------------------------------
						let newSenData = { ...sendData };
						newSenData.documents = documentos;
						if (!isReSign || (camposHechos.length > 0 && isReSign))
							setSendData(newSenData);
						runTextScript(
							'loadDocuments(' +
								scale +
								",'" +
								JSON.stringify(documentos) +
								"')"
						);
					}
					setNumeroDocumentos(documentosCargados);
				};
				count++;
			});
		}
		// updateSignatureFields(zoom);//enviar si es zoomIn o zoomOut
		setTimeout(() => {
			setDisabledBtnsPdf(false);
		}, 1500);
	}, [scale, docs]);

	useEffect(() => {
		if (firmantes && firmantes.length <= 0) {
			listColorsChoose.length = 0;
		} else {
			let totNewColor = [];
			firmantes.forEach(fir => {
				let poColById = listColorsChoose.find(fc => fc.Id === fir.Id);
				let poColByMail = listColorsChoose.find(fc => fc.Mail === fir.Correo);

				if (poColById) {
					let cUss = { Mail: fir.Correo, Color: poColById.Color, Id: fir.Id };
					totNewColor.push(cUss);
				} else if (poColByMail) {
					let cUss = { Mail: fir.Correo, Color: poColByMail.Color, Id: fir.Id };
					totNewColor.push(cUss);
				} else {
					let cUss = {
						Mail: fir.Correo,
						Color: generateRandomColors(),
						Id: fir.Id,
					};
					totNewColor.push(cUss);
				}
			});
			listColorsChoose.length = 0;
			totNewColor.forEach(tcol => {
				listColorsChoose.push(tcol);
			});
		}
		// if (firmantes && firmantes.length > 0) {
		// 	setSelectedFirmante(0);
		// }
	}, [firmantes]);

	/**
	 * Esta función verifica los campos de firma y actualiza el estado los estados pertinentes.
	 * Si el modal está activo, filtra los campos de firma según los IDs de los firmantes y los campos de firma disponibles.
	 * Además, elimina los campos de firma no filtrados del DOM. para que no queden visualmente
	 * @returns {void} No retorna nada pero si actualiza los estados de signFields y modalFlag
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const verifyFields = () => {
		if (modalflag) {
			const allIds = firmantes.flatMap(firmante =>
				firmante.firmaDocumentos.map(firma => firma.PosicionFirma.id)
			);
			// Actualizar el estado con los ids de los firmantes
			const filteredSignFields = signFields.filter(
				item => allIds.includes(item.idReSign) || item.source === 'qr'
			);
			const nonfilteredSignFields = signFields.filter(
				item => !allIds.includes(item.idReSign) && item.source !== 'qr'
			);
			// Actualizar el estado de signFields con los objetos filtrados
			setSignFields(filteredSignFields);

			if (nonfilteredSignFields.length > 0) {
				for (let i = 0; i < nonfilteredSignFields.length; i++) {
					const element = document.getElementById(
						'container-' + nonfilteredSignFields[i].idReSign
					);
					if (element) {
						try {
							element.remove();
						} catch (error) {
							console.error('error elminando', error);
						}
					} else {
						console.error(
							'elemento no encontrado:',
							'container-' + nonfilteredSignFields[i].idReSign
						);
					}
				}
			}
			setModalflag(false);
		}
	};

	/**
	 * Esta función renderiza campos de firma en el documento PDF con las ubicaciones proporcionadas.
	 * Establece el estado de 'ReSign' como verdadero para indicar que se están volviendo a dibujar los campos.
	 * Crea nuevas imágenes de firma con las ubicaciones especificadas y las agrega al estado 'imageSignature'.
	 * Además, establece el estado de 'imgSign' para cada imagen de firma.
	 * @param {Array} ubicaciones Un array de objetos que contienen información sobre las ubicaciones de los campos de firma.
	 * @returns {void} No retorna nada, pero si actualiza el estado de reSign y agrega la informacion del nuevo campo a imageSignature
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const renderCampos = ubicaciones => {
		setReSign(true);
		let newImageSignature = [];
		ubicaciones.forEach((ubicacion, index) => {
			const newSignature = {
				pageWidth: ubicacion.pageWidth,
				idPdfContainer: idPdfContainer,
				isFieldempty: true,
				isReSign: true,
				deleteSign: ubicacion.source === 'libre' && deleteSign,
				reSignWidth: ubicacion.reSignWidth,
				reSignHeigth: ubicacion.reSignHeigth,
				idReSign: ubicacion.idReSign,
				posX: ubicacion.posX,
				posY: ubicacion.posY,
				imgRoute: ubicacion.imgRoute,
				typeImg: ubicacion.typeImg,
				color: ubicacion.color,
				escala: scale,
			};
			newImageSignature.push(newSignature);
		});

		setImageSignature(newImageSignature);

		newImageSignature.forEach(signature => {
			setImgSign(signature.imgRoute);
		});
	};

	/**
	 * Esta función verifica si la propiedad 'Manuscrita' del objeto esta establecida como verdadera.
	 * @param {object} objeto El objeto a verificar.
	 * @returns {boolean} Devuelve verdadero si la propiedad 'Manuscrita' del objeto es verdadera, de lo contrario, devuelve falso.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const deshabilitar = objeto => objeto.Manuscrita === true;

	/**
	 * Este componente representa una barra de herramientas para la edición de documentos PDF, que incluye opciones de zoom.
	 * @param {object} props Las propiedades del componente.
	 * @returns {JSX.Element} Un componente que contiene botones para aumentar y disminuir el zoom en un documento PDF.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const BotoneraEditar = props => {
		/**
		 * Cambia la escala de la vista del documento PDF y realiza acciones adicionales según el modo.
		 * Si ya hay imágenes de firma, se llama a 'reSignImage' con la nueva escala y modo.
		 * De lo contrario, actualiza la escala y establece 'ReSign' como verdadero.
		 * @param {number} scale La nueva escala de zoom.
		 * @param {boolean} mode El modo de zoom (true para aumentar, false para disminuir).
		 * @returns {void} No retorna nada pero si actualiza los estados de scale, reSign, reSignImage o imageSignature segun sea el caso
		 * Documentado por: Lizeth Paola Delgadillo Robayo
		 */
		const reSign = async (scale, mode) => {
			if (props.imageSignature.length > 0) props.reSignImage(scale, mode);
			else {
				props.setScale(scale);
				props.setReSign(true);
			}
		};

		/**
		 * Esta funcion Aumenta el zoom en la vista del documento PDF y actualiza la escala.
		 * Si el recuento de zoom es menor que 3, aumenta el recuento de zoom.
		 * Si la escala actual más 0.2 supera 1.2, no realiza ninguna acción adicional.
		 * De lo contrario, calcula la nueva escala, llama a 'reSign' con la nueva escala y modo true.
		 * @returns {void} No retorna nada pero si actualiza el estado de zoomCount, disableBtnsPdf y reSign
		 * Documentado por: Lizeth Paola Delgadillo Robayo
		 */
		const zoomIn = () => {
			if (zoomCount < 3) {
				setZoomCount(zoomCount + 1);
			}
			const doc = document.querySelector('.active-sheet');
			const idDoc = doc?.getAttribute('documento');
			const page = doc?.getAttribute('data-page');
			const pdfDoc = document.getElementById(`page_${idDoc}_${page}`);
			if (!disabledBtnsPdf) {
				setDisabledBtnsPdf(true);
				let newScale = 0;
				if (props.scale + 0.2 > 1.2) {
					setDisabledBtnsPdf(false);
					return;
				} else newScale = Math.round((props.scale + 0.2) * 100) / 100;
				reSign(newScale, true);
			}
		};

		/**
		 * Esta funcion Reduce el zoom en la vista del documento PDF y actualiza la escala.
		 * Si el recuento de zoom es mayor que 0, disminuye el recuento de zoom.
		 * Si la escala actual menos 0.2 es menor que 0.7, no realiza ninguna acción adicional.
		 * De lo contrario, calcula la nueva escala, llama a 'reSign' con la nueva escala y modo false.
		 *  @returns {void} No retorna nada pero si actualiza el estado de zoomCount, disableBtnsPdf y reSign
		 * Documentado por: Lizeth Paola Delgadillo Robayo
		 */
		const zoomOut = () => {
			if (zoomCount > 0) {
				setZoomCount(zoomCount - 1);
			}
			const doc = document.querySelector('.active-sheet');
			const idDoc = doc.getAttribute('documento');
			const page = doc.getAttribute('data-page');
			const pdfDoc = document.getElementById(`page_${idDoc}_${page}`);
			if (!disabledBtnsPdf) {
				setDisabledBtnsPdf(true);
				let newScale = 0;
				if (props.scale - 0.2 < 0.7) {
					setDisabledBtnsPdf(false);
					return;
				} else newScale = Math.round((props.scale - 0.2) * 100) / 100;
				reSign(newScale, false);
			}
		};
		return (
			<Box
				className={classes.iconsPdf}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					fontFamily: '500',
					marginBottom: '1.5%',
					'@media (max-width: 600px)': {
						justifyContent: 'center',
						fontSize: '1.3rem',
					},
				}}
			>
				<img src={agrandar} className={classes.icon1Pdf} onClick={zoomIn} />
				<img src={alejar} className={classes.iconPdf} onClick={zoomOut} />
			</Box>
		);
	};

	/**
	 * Elimina un campo de firma según su ID y actualiza los estados correspondientes.
	 * @param {Event} e El evento que desencadenó la eliminación del campo de firma.
	 * @param {string} idDelete El ID del campo de firma que se va a eliminar.
	 * @returns {void} No retorna nada pero actualiza el estado de los firmantes , los campos, las posiciones y manuscritaDisable
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const deleteSign = (e, idDelete) => {
		let id = idDelete;
		document.getElementById('container-' + id).remove();
		let found = false;
		for (let i = 0; i < sendData.documents.length; i++) {
			if (sendData.documents[i].typeSignature) {
				for (let j = 0; j < sendData.documents[i].typeSignature.length; j++) {
					if (sendData.documents[i].typeSignature[j].id === idDelete) {
						found = true;
						sendData.documents[i].typeSignature.splice(j, 1);
						setQR(false);
						break;
					}
				}
			}
			if (found) {
				break;
			}
		}
		if (!found) {
			setFirmantes(prevState => {
				const firmantesActualizados = prevState.map(firmante => {
					if (firmante.firmaDocumentos) {
						const firmaDocumentosActualizados = firmante.firmaDocumentos.filter(
							documento => documento.PosicionFirma.id !== idDelete
						);
						if (firmaDocumentosActualizados.length === 0) {
							if (firmante.Manuscrita == false) {
								firmante.firmanteLibre = false;
							} else {
								firmante.firmanteLibre = true;
							}
						}
						return {
							...firmante,
							firmaDocumentos: firmaDocumentosActualizados,
						};
					}
					return firmante;
				});
				dispatch(firm_positions(firmantesActualizados));
				return firmantesActualizados;
			});
		}
		setVerManuscritaDisable(false);
		setSignFields(prevSignFields => {
			return prevSignFields.filter(field => field.idReSign !== idDelete);
		});
	};

	/**
	 * Cambia la escala de la imagen de firma y ajusta las propiedades relacionadas para permitir la reasignación.
	 * @param {number} newScale La nueva escala de la imagen de firma.
	 * @param {string} mode El modo de ajuste de escala, puede ser 'aumentar' o 'disminuir'.
	 * @returns {void} No retorna nada pero actualiza las propiedades de las imágenes de firma para la resignación.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const reSignImage = (newScale, mode) => {
		let newImageSignature = [];
		setScale(newScale);
		setReSign(true);
		signFields.forEach(firmante => {
			let datComp = document.getElementById(idPdfContainer);
			let containerWidth = datComp.clientWidth;
			let prevPageWidth = firmante.pageWidth;
			let pageWidth = mode
				? (firmante.pageWidth / scale) * (scale + 0.2)
				: (firmante.pageWidth / scale) * (scale - 0.2);

			let margValue = containerWidth - pageWidth > 0 ? false : true;
			const posYWithoutMargin = firmante.posY - (firmante.position * 2 - 1);
			const newSignature = {
				pageWidth: pageWidth,
				idPdfContainer: idPdfContainer,
				isFieldempty: true,
				isReSign: true,
				deleteSign: firmante.source === 'libre' && deleteSign,
				reSignWidth: mode
					? (firmante.reSignWidth / scale) * (scale + 0.2)
					: (firmante.reSignWidth / scale) * (scale - 0.2),
				reSignHeigth: mode
					? (firmante.reSignHeigth / scale) * (scale + 0.2)
					: (firmante.reSignHeigth / scale) * (scale - 0.2),
				idReSign: firmante.idReSign,
				posX: margValue
					? mode
						? ((firmante.posX -
								(containerWidth - prevPageWidth > 0
									? containerWidth - prevPageWidth
									: 0) /
									2) /
								scale) *
						  (scale + 0.2)
						: (firmante.posX / scale) * (scale - 0.2) -
						  (containerWidth - prevPageWidth > 0
								? containerWidth - prevPageWidth
								: 0) /
								2
					: mode
					? ((firmante.posX - (containerWidth - prevPageWidth) / 2) / scale) *
					  (mode ? scale + 0.2 : scale - 0.2)
					: (firmante.posX / scale) * (scale - 0.2) +
					  (containerWidth - pageWidth) / 2,
				posY: mode
					? (posYWithoutMargin / scale) * (scale + 0.2) +
					  (firmante.position * 2 - 1)
					: (posYWithoutMargin / scale) * (scale - 0.2) +
					  (firmante.position * 2 - 1),
				imgRoute: firmante.imgRoute,
				typeImg: firmante.typeImg,
				color: firmante.color,
				escala: mode ? scale + 0.2 : scale - 0.2,
			};
			Object.assign(firmante, newSignature);
			newImageSignature.push(newSignature);
		});
		setImageSignature(newImageSignature);

		newImageSignature.forEach(signature => {
			setImgSign(signature.imgRoute);
		});
	};

	/**
	 * Maneja el evento de liberación del ratón para el campo de firma, cuando lo arrastramos y lo soltamos.
	 * @param {Object} oImg La información del campo de firma.
	 * @returns {void} No retorna nada pero actualiza los estados relacionados con el campo de firma.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const onMouseUp = oImg => {
		const firmantes = firmantesRef.current;
		let newValue = false;
		let firmanewpos = 0;
		const containerId = oImg._id;
		for (let i = 0; i < firmantes.length; i++) {
			if (firmantes[i].firmaDocumentos.length > 0) {
				for (let j = 0; j < firmantes[i].firmaDocumentos.length; j++) {
					if (
						firmantes[i].firmaDocumentos[j].PosicionFirma.id === containerId
					) {
						setSelectedFirmante(firmantes[i].Posicion);
						newValue = true;
						firmanewpos = firmantes[i].Posicion;
						break;
					}
				}
			}
		}
		let offX;
		let offY;
		let uuid;
		let centerImgX = oImg._posX + oImg._imgWidth / 2;
		let centerImgY = oImg._posY + oImg._imgHeight / 2;
		let pdfPages = document.getElementsByClassName('pdf-canvas');
		let acumData = 0;
		let listPost = [];
		for (var i = 0; i < pdfPages.length; i++) {
			let resPo = pdfPages[i].id.split('_');
			let docv = Number(resPo[1]);
			let pagv = Number(resPo[2]);
			let arrd = { d: docv, p: pagv, v: acumData }; //Identifico en donde empieza cada pg
			acumData += pdfPages[i].height;
			listPost.push(arrd);
		}
		let oPage = {
			top: null,
			left: null,
			width: null,
			height: null,
		};
		let oFirma = {
			id: oImg._id,
			escala: scale,
			signatureType: oImg._type,
			signPredefined: 0,
			addDataCert: 0,
			base64Image: null,
			tpag: configQR.tPaginas,
			url: configQR.url,
			title: configQR.title,
			posXY: {
				pageNumber: null,
				x: null,
				y: null,
				width: null,
				height: null,
				pageWidth: null,
				pageHeight: null,
			},
		};

		for (let a = 0; a < pdfPages.length; a++) {
			oPage.top = pdfPages[a].offsetTop;
			oPage.left = pdfPages[a].offsetLeft;
			if (window.innerWidth < 1253) {
				oPage.pageWidth = pdfPages[a].scrollWidth;
				oPage.pageHeight = pdfPages[a].scrollHeight;
			} else {
				oPage.pageWidth = pdfPages[a].width;
				oPage.pageHeight = pdfPages[a].height;
			}
			if (
				centerImgY > oPage.top &&
				centerImgY < oPage.top + oPage.pageHeight &&
				centerImgX > oPage.left &&
				centerImgX < oPage.left + oPage.pageWidth
			) {
				offX = pdfPages[a].offsetLeft;
				offY = pdfPages[a].offsetTop;

				uuid = pdfPages[a].getAttribute('data-doc');
				oFirma.idimg = oImg._id;
				oFirma.base64Image =
					oImg._type === qr
						? 'iVBORw0KGgoAAAANSUhEUgAAAIQAAACEAQMAAABrihHkAAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAyElEQVRIie2Uyw3DMAxDtQH335IbsKKdBO2lRcQeIxiC/A6M9UvVYz9MEu2PYEzak6DIFc+JYzTbMCb1ByJCMfFBqT5yv09c8GUfvbhPbND5ujmxqD/A6qoFRHCGLv6V6YisidISVUZQ6OOEKyD9stZtR4AB6YIBHigPZ0B8bc2WPjOdka0rgVdPR6Tv3P8RVUK2evmJYEC8IHDVmZE1kNouIl5c35UT/7B7fY/U58Tt49q7gLjwlM61mxJXvmMn+taL++SxL/YC+rrZ93htqY0AAAAASUVORK5CYII='
						: oImg._imgBase64.replace('data:image/png;base64,', '');
				oFirma.signPredefined = 0;
				oFirma.addDataCert = 0;
				oFirma.posXY.pageNumber = pdfPages[a].getAttribute('data-pag');
				oFirma.posXY.pageHeight = oPage.pageHeight;
				oFirma.posXY.pageWidth = oPage.pageWidth;
				oFirma.posXY.x = oImg._posX - offX;
				oFirma.posXY.y = oImg._posY - offY;
				oFirma.posXY.width = oImg._imgWidth;
				oFirma.posXY.height = oImg._imgHeight;
				setCurrentPage(pdfPages[a].getAttribute('data-pag'));
				oFirma.pagina = a;
				break;
			}
		}

		if (oImg._type == 'qr') {
			for (let i = 0; i < sendData.documents.length; i++) {
				const documento = sendData.documents[i];
				const firmas = documento.typeSignature;
				// Verificar si alguna firma tiene el mismo id que oFirma
				for (let j = 0; j < firmas.length; j++) {
					const firma = firmas[j];
					if (firma.id === oFirma.id) {
						// Eliminar la firma del documento actual
						firmas.splice(j, 1);
						// Como encontramos y manejamos la firma, salimos de la función
					}
				}
				// Verificar si el documento actual es el documento de destino
				if (documento.uuid === Number(uuid)) {
					// Agregar la firma al documento de destino
					documento.typeSignature.push(oFirma);
				}
			}
		} else if (oImg._type == 'png') {
			let dataFirmante = {
				idDoc: parseInt(uuid, 10) + 1,
				firmaManuscrita: oImg._type == 'png' ? true : false,
				PosicionFirma: {
					escala: scale,
					x: oImg._posX - offX,
					y: oImg._posY - offY,
					width: oImg._imgWidth,
					height: oImg._imgHeight,
					pageWidth: oFirma.posXY.pageWidth,
					pageHeight: oFirma.posXY.pageHeight,
					pagina: parseInt(oFirma.posXY.pageNumber, 10),
					id: oImg._id,
					color: addColorList(!newValue ? selectedFirmante : firmanewpos),
				},
			};
			for (let i = 0; i < firmantes.length; i++) {
				if (
					firmantes[i].Posicion ===
						(!newValue ? selectedFirmante : firmanewpos) &&
					dataFirmante.firmaManuscrita == true
				) {
					let foundMatchingId = false;
					for (let j = 0; j < firmantes[i].firmaDocumentos.length; j++) {
						if (
							firmantes[i].firmaDocumentos[j].PosicionFirma.id ===
							dataFirmante.PosicionFirma.id
						) {
							firmantes[i].firmaDocumentos[j] = dataFirmante;
							foundMatchingId = true;
							break;
						}
					}
					if (!foundMatchingId) {
						firmantes[i].firmaDocumentos.push(dataFirmante);
					}
				}
			}
		}
		// firmantes[
		// 	(!newValue ? selectedFirmante : firmanewpos) - 1
		// ].firmanteLibre = false;

		if (oImg._type !== 'qr') {
			firmantes[
				(!newValue ? selectedFirmante : firmanewpos) - 1
			].firmanteLibre = false;
		}

		if (
			oImg._posX - offX + oImg._imgWidth > oFirma.posXY.pageWidth ||
			oImg._posX - offX === 'NAN' ||
			oImg._posX - offX < 0 ||
			oImg._posY - offY + oImg._imgHeight > oFirma.posXY.pageHeight ||
			oImg._posY - offY < 0
		) {
			enqueueSnackbar(
				'La posicion del campo de firma seleccionado supera los limites de la pagina actual',
				{
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
		}
		let fProps = {
			source: oImg._type === 'qr' ? 'qr' : 'campoVacio',
			pageWidth: oFirma.posXY.pageWidth,
			idReSign: oImg._id,
			posY: oImg._posY,
			posX: oImg._posX,
			reSignWidth: oImg._imgWidth,
			reSignHeigth: oImg._imgHeight,
			imgRoute: oImg._imgBase64,
			typeImg: oImg._type === 'qr' ? 'qr' : 'png',
			idPdfContainer: idPdfContainer,
			deleteSign: deleteSign,
			color:
				oImg._type === 'qr'
					? ''
					: addColorList(!newValue ? selectedFirmante : firmanewpos),
			position: oFirma.pagina,
			escala: scale,
		};

		setSignFields(prevState => {
			let newObjetoExistente = false;
			if (prevState.length > 0) {
				newObjetoExistente = prevState.find(
					obj => obj.idReSign === fProps.idReSign
				);
			}

			if (newObjetoExistente) {
				const nuevosSignFields = prevState.map(obj =>
					obj.idReSign === fProps.idReSign ? fProps : obj
				);
				return nuevosSignFields;
			} else {
				return [...prevState, fProps];
			}
		});
	};

	/**
	 * Abre la zona de carga al hacer clic en un elemento de carga.
	 * @returns {void} No retorna nada pero abre la zona de carga para seleccionar archivos.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const openZoneDrop = () => {
		document.getElementById('Upload').click();
	};

	/**
	 * Maneja el clic en el botón de marca de agua para activar o desactivar la funcionalidad de marca de agua en los documentos.
	 * @param {boolean} state El estado de la marca de agua, `true` para activar y `false` para desactivar.
	 * @param {string} type El tipo de marca de agua, puede ser 'marcaAgua', 'manuscrita' o 'estampa'.
	 * @returns {void} No retorna nada pero actualiza el estado de la marca de agua y los documentos relacionados.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const clickMarcaAgua = (state, type) => {
		setReSign(false);
		let newSenData = { ...sendData };
		let cantidad = 0;
		if (type == marcaAgua && state === true) {
			newSenData.documents.map(doc => {
				cantidad += doc.listSigns.length;
			});
		}
		if (cantidad == 0) {
			if (type == manuscrita) {
				if (verManuscritaDisable) {
					return;
				}
				let docAct = null;
				let listMini = document.getElementsByClassName('pdf-canvas-mini');
				if (listMini) {
					docAct = Array.from(listMini)
						.find(mini => mini.classList.contains('active-sheet'))
						.getAttribute('data-documento');
				}
				setTypeImg(manuscrita);
				return;
			}
			let newFirmas = [];
			let firma;
			let oFirma = {
				signatureType: type,
				signPredefined: 0,
				addDataCert: 0,
			};
			let existe = false;
			if (state) {
				for (var a in newSenData.documents) {
					existe = false;
					for (var b in newSenData.documents[a].typeSignature) {
						firma = newSenData.documents[a].typeSignature[b];
						if (firma.signatureType == type) {
							existe = true;
						}
					}
					if (!existe) {
						newSenData.documents[a].typeSignature.push(oFirma);
					}
				}
			} else {
				for (var a in newSenData.documents) {
					newFirmas = [];
					for (var b in newSenData.documents[a].typeSignature) {
						firma = newSenData.documents[a].typeSignature[b];
						if (firma.signatureType == type) {
							continue;
						}
						newFirmas.push(firma);
					}
					newSenData.documents[a].typeSignature = newFirmas;
				}
			}
			setSendData(newSenData);
			switch (type) {
				case manuscrita:
					break;
				case estampa:
					setStamp(state);
					break;
				case marcaAgua:
					setMarcaAgua(state);
					break;
			}
		} else {
			if (type == marcaAgua) {
				enqueueSnackbar(
					'Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar marca de agua',
					{
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			}
		}
	};

	/**
	 * Aplica un código QR a un documento si no tiene firmas previas y no tiene un QR aplicado previamente.
	 * @returns {void} No retorna nada pero establece el tipo de imagen como QR y activa la visualización del QR.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const aplicarQR = () => {
		setReSign(false);
		let cantidad = 0;
		let newSenData = { ...sendData };
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}
		newSenData.documents.map(doc => {
			if (doc.uuid == docAct) {
				cantidad += doc.listSigns.length;
			}
		});
		if (cantidad == 0) {
			let existe = false;
			for (let i in newSenData.documents) {
				let doc = newSenData.documents[i];
				if (doc.uuid == docAct) {
					let tSignature = doc.typeSignature;
					for (let t in tSignature) {
						if (tSignature[t].signatureType === qr) {
							existe = true;
						}
					}
				}
			}
			if (existe === false) {
				setTypeImg(qr);
				setVerQR(true);
				setQR(true);
			} else {
				alert('Solo se permite un QR por documento');
			}
		} else {
			enqueueSnackbar(
				'Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar Qr',
				{
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
		}
	};

	/**
	 * Maneja el cambio en la selección de archivos para cargar.
	 * Verifica la extensión y el tamaño del archivo seleccionado y muestra mensajes de error si no cumple con los requisitos.
	 * Actualiza la lista de documentos seleccionados si el archivo es válido y aún no está agregado.
	 * @returns {void} No retorna nada pero actualiza el estado de la lista de documentos seleccionados y actualiza los documentos.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handlerChange = async () => {
		let archivo = document.getElementById('Upload').files;
		//archivo es el nuevo archivo que voy a agregar
		if (archivo[0] != null) {
			await getPageNumber(archivo);
			var fileSizeMb = Math.round(archivo[0].size / 1024);
			var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1024);
			archivo[0].sizeBytes = archivo[0].size;
			var extension = '.' + archivo[0].name.split('.').pop();

			if (!extension.toLowerCase().includes('.pdf')) {
				enqueueSnackbar(
					`El archivo ${archivo[0].name} no es permitido, los tipos de archivos permitidos son: .pdf`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (docs?.length == 10) {
				enqueueSnackbar(
					`La cantidad de archivos supera el máximo permitido de 10 documentos.`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				if (fileSizeMb > maxSizeMb) {
					enqueueSnackbar(
						`El tamaño del archivo ${
							archivo[0].name
						} supera el máximo permitido.\nTamaño limite de ${kbToMb(
							MAX_MEGAS_FILE_SIZE * 1000000
						)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				} else {
					let dropedSize = 0;
					docs.map(file => {
						var sizekiloByte = Math.round(file.size / 1024);
						dropedSize += sizekiloByte;
					});
					let maxFilesSizeMb = Math.round(
						(MAX_MEGAS_FILES_SIZE * 1000000) / 1000
					);
					dropedSize += fileSizeMb;
					if (dropedSize > maxFilesSizeMb) {
						enqueueSnackbar(
							`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(
								MAX_MEGAS_FILES_SIZE * 1000000
							)}`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					} else {
						let results = docs.filter(function (doc) {
							return doc.name == archivo[0].name;
						});
						let existe = results.length > 0 ? results[0] : null;
						if (existe != null) {
							enqueueSnackbar(
								`El archivo ${archivo[0].name} se encuentra actualmente agregado`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								}
							);
						} else {
							let newdoc = docs;
							newdoc.push(archivo[0]);
							setDocs(newdoc);
							document.getElementById('Upload').files = null;
							updateDocuments();
						}
					}
				}
			}
		}
	};

	/**
	 * Actualiza la lista de documentos cargados.
	 * Lee cada documento seleccionado, convierte su contenido a base64 y lo agrega a la lista de documentos.
	 * Verifica si se deben agregar firmas predefinidas como estampa o marca de agua y las agrega si corresponde.
	 * Muestra mensajes informativos si algún documento tiene firmas, evitando agregar marcas de agua en estos casos.
	 * Finalmente, actualiza el estado de los documentos y ejecuta un script para cargar los documentos en la interfaz.
	 * @returns {void} No retorna nada pero actualiza el estado de los documentos y ejecuta un script para cargarlos en la interfaz.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const updateDocuments = () => {
		let count = 0;
		let documentos = [];
		let newDocuments = [];
		let documentosCargados = 0;
		docs.map(doc => {
			if (count <= sendData.documents.length - 1) {
				documentos.unshift(sendData.documents[count]);
				documentosCargados++;
			} else {
				let typeSignarutes = [];
				if (isStamp == true) {
					typeSignarutes.push({
						signatureType: estampa,
						signPredefined: 0,
						addDataCert: 0,
					});
				}
				if (isMarcaAgua == true) {
					typeSignarutes.push({
						signatureType: marcaAgua,
						signPredefined: 0,
						addDataCert: 0,
					});
				}
				const reader = new FileReader();
				reader.numDoc = count;
				reader.filename = doc.name;
				reader.readAsDataURL(doc);
				reader.onload = event => {
					var document = new Object();
					document.uuid = event.target.numDoc;
					document.fileName = event.target.filename;
					document.base64 = event.target.result.replace(
						'data:' + doc.type + ';base64,',
						''
					);
					document.listSigns = getListSignature(document.base64);
					document.typeSignature = typeSignarutes;
					document.hash = CryptoJS.SHA256(
						CryptoJS.enc.Base64.parse(document.base64)
					).toString(CryptoJS.enc.Base64);
					documentosCargados++;
					documentos.push(document);

					if (isMarcaAgua) {
						if (document.listSigns.length > 0) {
							clickMarcaAgua(!isMarcaAgua, marcaAgua);

							enqueueSnackbar(
								`Alguno de los documentos cargados tiene una firma por lo tanto no se le puede agregar marca de agua`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'info',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								}
							);
						}
					}

					if (numerodocumentos < docs.length) {
						if (documentosCargados > numerodocumentos) {
							documentos.sort((doc1, doc2) =>
								doc1.uuid > doc2.uuid ? 1 : doc1.uuid < doc2.uuid ? -1 : 0
							);
							newDocuments.push(document);
							let newSenData = { ...sendData };
							newSenData.documents = documentos;
							setSendData(newSenData);
							runTextScript(
								'loadDocuments(' +
									scale +
									",'" +
									JSON.stringify(newDocuments) +
									"'," +
									docs.length +
									",'" +
									JSON.stringify(documentos) +
									"', true)"
							);
						}
						setNumeroDocumentos(docs.length);
					}
				};
			}
			count++;
		});
	};

	/**
	 * Lee un archivo y devuelve su contenido como un ArrayBuffer.
	 * @param {File} file El archivo que se va a leer.
	 * @returns {Promise<ArrayBuffer>} Una promesa que se resuelve con el contenido del archivo como un ArrayBuffer si se lee correctamente, de lo contrario se rechaza con el error.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const readFile = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			reader.readAsArrayBuffer(file);
		});
	};

	/**
	 * Obtiene el número de páginas de cada archivo PDF en la lista proporcionada.
	 * @param {File[]} pFiles La lista de archivos de los cuales se obtendrá el número de páginas.
	 * @returns {Promise<void>} Una promesa que se resuelve después de actualizar la propiedad `numPages` de cada archivo PDF en la lista. Si un archivo está protegido o no es un archivo PDF, se manejará apropiadamente y se mostrará una notificación al usuario.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	async function getPageNumber(pFiles) {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {
				if ('.' + pFile.name.split('.').pop() == '.pdf') {
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile);
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${pFile.name} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
	}

	/**
	 * Actualiza el estado de las características de los documentos y la interfaz de usuario en función del documento activo.
	 * @param {Event} e El evento que desencadena la actualización del documento.
	 * @returns {void} No retorna nada pero actualiza los estados relacionados con la firma, como la disponibilidad de firma manuscrita y códigos QR.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const refreshDoc = e => {
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini && listMini.length > 0) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}
		if (e && e.target && e.target.getAttribute('documento')) {
			docAct = e.target.getAttribute('documento');
		}
		if (docAct != null) {
			setVerManuscritaDisable(false);
			setQR(false);
			let doc = sendData.documents[docAct];
			if (doc.listSigns !== null && doc.listSigns?.length > 0) {
				for (var j = 0; j < doc.typeSignature.length; j++) {
					switch (doc.typeSignature[j].signatureType) {
						case manuscrita:
							setVerManuscritaDisable(true);
							break;
						case qr:
							setQR(true);
							break;
					}
				}
			}
			if (doc.typeSignature.length > 0) {
				for (var i = 0; i < doc.typeSignature.length; i++) {
					switch (doc.typeSignature[i].signatureType) {
						case digital:
							break;
						case qr:
							setQR(true);
							break;
					}
				}
			}
		}
		if (sendData.documents.length > 1) {
			if (carpeta.trim() === '') {
				dispatch(name_folder('Circuito de firmas'));
			}
		}
	};

	/**
	 * Establece la imagen de la firma en el estado y actualiza la interfaz de usuario.
	 * @param {string} imgB64 La representación en base64 de la imagen de la firma.
	 * @param {boolean} predefined Indica si la firma es predefinida o no.
	 * @returns {void} No retorna nada pero actualiza los estados de la imagen de la firma, la fuente predefinida y la disponibilidad de firma manuscrita.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const setSignImage = (imgB64, predefined) => {
		if (predefined) {
			setPredefinedSource(true);
		}
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}
		let doc = sendData.documents[docAct];
		if (doc.listSigns !== null && doc.listSigns.length > 0) {
			setVerManuscritaDisable(true);
		}
		setImageSignature([
			{
				reSignWidth: 0,
				reSignHeigth: 0,
				idReSign: '',
				posX: 100,
				posY: 100,
				imgRoute: imgB64,
				typeImg: typeImg,
			},
		]);
		setImgSign(imgB64);
	};

	/**
	 * Maneja el cambio en la selección del firmante y actualiza el estado correspondiente.
	 * @param {object} event El evento de cambio que desencadenó esta función.
	 * @returns {void} No retorna nada pero actualiza el estado del firmante seleccionado.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleChange = event => {
		setSelectedFirmante(event.target.value);
		if (event.target.value === 'Agregar') {
			setSelectedFirmante(0);
		}
	};

	/**
	 * Selecciona automáticamente el firmante activo si no se ha seleccionado ninguno.
	 * Si no se ha seleccionado ningún firmante, selecciona el primer firmante manuscrito disponible.
	 * Actualiza el estado de `selectedFirmante`.
	 * @returns {void} No retorna nada pero actualiza el estado de `selectedFirmante`.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	function selectActiveSigner() {
		if (selectedFirmante === 0) {
			let totaSign = firmantes?.filter(est => est.Manuscrita === true);
			let possSign = totaSign?.length > 0 ? totaSign[0].Posicion : 1;
			setSelectedFirmante(possSign);
		}
	}

	/**
	 * Aplica una firma a un documento.
	 * Si la firma es predefinida, establece `predefinedSource` en true.
	 * Actualiza el estado de `imageSignature` con la información de la firma.
	 * Si hay firmas existentes en el documento, deshabilita la opción de agregar una firma manuscrita.
	 * Muestra una notificación para indicar al usuario que debe ubicar el campo de firma en la posición deseada.
	 * @param {string} imgB64 - La representación en base64 de la imagen de la firma.
	 * @param {boolean} predefined - Indica si la firma es predefinida.
	 * @returns {void} No retorna nada pero actualiza los estados de `imageSignature`, `imgSign`, y `verManuscritaDisable`, y muestra una notificación.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const aplicarFirma = (imgB64, predefined) => {
		if (predefined) {
			setPredefinedSource(true);
		}
		let docAct = null;
		let listMini = document.getElementsByClassName('pdf-canvas-mini');
		if (listMini) {
			docAct = Array.from(listMini)
				.find(mini => mini.classList.contains('active-sheet'))
				.getAttribute('data-documento');
		}
		let doc = sendData.documents[docAct];
		if (doc.listSigns !== null && doc.listSigns.length > 0) {
			setVerManuscritaDisable(true);
		}
		const color = addColorList(selectedFirmante);
		setImageSignature([
			{
				reSignWidth: 150,
				reSignHeigth: 50,
				idReSign: '',
				posX: 150,
				posY: 50,
				imgRoute: AddSignature,
				typeImg: 'png',
				colorFirmante: color,
			},
		]);
		setImgSign(imgB64);

		enqueueSnackbar('Ubicar el Campo de firma en la posición deseada', {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
	};

	/**
	 * Genera un color hexadecimal aleatorio.
	 * @returns {string} Un color hexadecimal aleatorio generado.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	function generateRandomColors() {
		let simbolos, color;
		simbolos = '0123456789ABCDEF';
		color = '#';
		for (var i = 0; i < 6; i++) {
			color = color + simbolos[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	/**
	 * Busca y devuelve el color asociado al índice de firmante proporcionado. Si no se encuentra un color asociado, se devuelve el primer color de la lista de colores disponibles.
	 * @param {number} vlIndex - El índice del firmante para el cual se busca el color.
	 * @returns {string} El color asociado al índice de firmante proporcionado o el primer color de la lista de colores disponibles si no se encuentra ningún color asociado.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	function addColorList(vlIndex) {
		let encCol = '';
		let busFir = firmantes.find(bf => bf.Posicion === vlIndex);
		if (busFir) {
			let busCol = listColorsChoose.find(fc => fc.Mail === busFir.Correo);
			if (busCol) {
				encCol = busCol.Color;
			}
		} else {
			let busCol = listColorsChoose[0];
			if (busCol) {
				encCol = busCol.Color;
			}
		}
		return encCol;
	}

	/**
	 * Actualiza el estado de los campos de firma con el nuevo valor proporcionado.
	 * @param {Array} nuevoValor - El nuevo valor de los campos de firma.
	 *  @returns {viod} No retorna nada pero actualiza el estado de los campos de firma.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const actualizarSignsfields = nuevoValor => {
		setSignFields(nuevoValor);
	};

	/**
	 * Ajusta las propiedades de los campos de firma según el tamaño del contenedor.
	 * @param {Array} signFields - Los campos de firma a ajustar.
	 * @returns {Array} - Un nuevo arreglo con las propiedades ajustadas de los campos de firma.
	 * No retorna nada explícitamente, pero ajusta las propiedades de los campos de firma.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const ajustarPropiedades = signFields => {
		let datComp = document.getElementById(idPdfContainer);
		let containerWidth = datComp.clientWidth;
		// Crear un nuevo arreglo con las propiedades ajustadas
		const signFieldsScale = signFields.map(function (objeto) {
			const newPage = (objeto.pageWidth * 0.8) / objeto.escala;
			const newMarg =
				newPage > containerWidth ? 0 : (containerWidth - newPage) / 2;
			// Si la escala es diferente de 0.8, ajustar las propiedades
			if (objeto.escala !== 0.8) {
				// Crear un nuevo objeto con las propiedades ajustadas
				return {
					...objeto,
					pageWidth: newPage,
					posY: (objeto.posY * 0.8) / objeto.escala,
					posX:
						objeto.pageWidth > containerWidth
							? (objeto.posX * 0.8) / objeto.escala + newMarg
							: (objeto.posX * 0.8) / objeto.escala,
					reSignWidth: (objeto.reSignWidth * 0.8) / objeto.escala,
					reSignHeigth: (objeto.reSignHeigth * 0.8) / objeto.escala,
					escala: 0.8,
				};
			} else {
				// Si la escala ya es 0.8, devolver el objeto sin cambios
				return objeto;
			}
		});
		// Devolver el nuevo arreglo con las propiedades ajustadas
		return signFieldsScale;
	};

	/**
	 * Verifica los campos de firma agregados y realiza las acciones correspondientes.
	 * @param {Array} firmantes - Lista de firmantes.
	 * @param {Object} seendData - Datos enviados.
	 * @returns {void} - No retorna nada explícitamente, pero realiza acciones según los campos de firma agregados.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */

	const verifyAddedFields = async (firmantes, sendData) => {
		const nuevasEscalas = firmantes.map(item => {
			const firmaDocumentos = item.firmaDocumentos.map(doc => {
				const escala = doc.PosicionFirma.escala;
				if (escala !== 0.8) {
					return {
						...doc,
						PosicionFirma: {
							...doc.PosicionFirma,
							escala: 0.8,
							x: doc.PosicionFirma.x * (0.8 / escala),
							y: doc.PosicionFirma.y * (0.8 / escala),
							width: doc.PosicionFirma.width * (0.8 / escala),
							height: doc.PosicionFirma.height * (0.8 / escala),
							pageWidth: doc.PosicionFirma.pageWidth * (0.8 / escala),
							pageHeight: doc.PosicionFirma.pageHeight * (0.8 / escala),
						},
					};
				}
				return doc;
			});

			return {
				...item,
				firmaDocumentos: firmaDocumentos,
			};
		});

		let data = [];
		let showAlert = true;

		for (let i = 0; i < sendData.documents.length - 1; i++) {
			if (sendData.documents[i].typeSignature.length > 0) {
				for (let j = 0; j < sendData.documents[i].typeSignature.length; j++) {
					if (
						sendData.documents[i].typeSignature[j].signatureType !== 'marcaagua'
					) {
						if (
							sendData.documents[i].typeSignature[j].posXY.x +
								sendData.documents[i].typeSignature[j].posXY.width >
								sendData.documents[i].typeSignature[j].posXY.pageWidth ||
							sendData.documents[i].typeSignature[j].posXY.x === 'NAN' ||
							sendData.documents[i].typeSignature[j].posXY.x < 0 ||
							sendData.documents[i].typeSignature[j].posXY.y < 0 ||
							sendData.documents[i].typeSignature[j].posXY.y +
								sendData.documents[i].typeSignature[j].posXY.height >
								sendData.documents[i].typeSignature[j].posXY.pageHeight
						) {
							enqueueSnackbar(
								'La posición del campo de firma seleccionado supera los límites de la página actual',
								{
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								}
							);
							showAlert = false;
						}
					}
				}
			}
		}

		for (let i = 0; i < nuevasEscalas.length; i++) {
			const objeto = nuevasEscalas[i];
			if (objeto.Manuscrita && !objeto.esObservador) {
				if (objeto.firmaDocumentos.length > 0) {
					for (let j = 0; j < nuevasEscalas[i].firmaDocumentos.length; j++) {
						if (
							nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.x +
								nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.width >
								nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.pageWidth ||
							nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.x === 'NAN' ||
							nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.x < 0 ||
							nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.y +
								nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.height >
								nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.pageHeight ||
							nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.y < 0 ||
							!nuevasEscalas[i].firmaDocumentos[j].PosicionFirma.pageWidth
						) {
							enqueueSnackbar(
								'La posición del campo de firma seleccionado supera los límites de la página actual',
								{
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								}
							);
							showAlert = false;
						}
					}
				} else {
					data.push(objeto.Nombres + ' ' + objeto.Apellidos);
				}
			}
		}

		if (showAlert) {
			if (!isMarcaAgua) {
				(sendData.documents || []).forEach(doc => {
					doc.typeSignature = (doc.typeSignature || []).filter(
						item => item.signatureType !== 'marcaagua'
					);
				});
			}
			if (data.length > 0) {
				dispatch(firm_positions(nuevasEscalas));
				dispatch(modalConfirm(true));
				setNombFirm(data); // Actualizar nombFirm aquí
			} else {
				dispatch(firm_positions(nuevasEscalas));
				dispatch(dataQr(sendData.documents));
				dispatch(stepCircuit(3));
			}
			dispatch(camposHechos(ajustarPropiedades(signFields)));
		}
	};

	/**
	 * Regresa a la página de inicio del circuito de firmas, realizando acciones como eliminar los campos de firma tipo "marcaagua" y ajustar propiedades de los campos de firma.
	 * @returns {void} No retorna nada explícitamente, pero realiza acciones para regresar a la página de inicio del circuito de firmas.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const regresarHome = async () => {
		if (!isMarcaAgua) {
			(sendData.documents || []).forEach(doc => {
				// Usamos filter para eliminar objetos con signatureType igual a "marcaagua"
				doc.typeSignature = (doc.typeSignature || []).filter(
					item => item.signatureType !== 'marcaagua'
				);
			});
		}
		dispatch(camposHechos(ajustarPropiedades(signFields)));
		dispatch(stepCircuit(1));
		dispatch(dataQr(sendData.documents));
	};
	return (
		<Grid container spacing={1} direction='row' id='modal-pdf'>
			<Container component='main' maxWidth='lg'>
				<h1 className={'title-background'}>Firmar documentos</h1>
			</Container>
			<Container component='main' maxWidth='lg'>
				<Grid
					container
					spacing={0}
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
					maxWidth='md'
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper elevation={0} className={'addSigner'}>
							<img
								onClick={() => regresarHome()}
								className={classes.iconBack}
								src={Atras}
							/>
							<CustomizedSteppers activeStep={2} />
							<h2
								className={classes.parrafo}
								style={{
									color: '#E55200',
									fontSize: '23px',
									margin: '3px 0 0 0 ',
								}}
							>
								Incluir campos de firma
							</h2>
							<p className={classes.size}>
								<b>
									Añade al documento el campo donde el firmante debe incluir su
									firma.
								</b>
							</p>
							<Grid container spacing={5} justifyContent='center'>
								<Grid
									item
									xs={12}
									sm={6}
									md={6}
									lg={3}
									className={combinedClasses}
									// style={{ flexGrow: 0, maxWidth: '20%', flexBasis: '20%' }}
								>
									<Button
										className={marcaAguaClass}
										onClick={() => clickMarcaAgua(!isMarcaAgua, marcaAgua)}
									>
										<img
											src={marcaAguaImg}
											style={{ width: '27px', height: '27px' }}
										/>
										<span>Marca de Agua</span>
									</Button>
									<Button className={QRClass} onClick={() => aplicarQR()}>
										<img
											src={QRImg}
											style={{ width: '27px', height: '27px' }}
										/>
										<span>Codigo QR</span>
									</Button>
									<hr
										style={{
											color: '#7B7B7B',
											margin: '0',
											maxWidth: '300px',
										}}
									/>
									<p className={classes.paragraph}>Firmantes</p>
									<Select
										labelId='select-label'
										value={selectedFirmante}
										onChange={e => handleChange(e)}
										alignItems='center'
										defaultValue={selectedFirmante}
										className={classes.options}
										inputProps={{ 'aria-label': 'Without label' }}
										style={{ textOverflow: 'ellipsis' }}
									>
										{firmantes
											.filter(firmante => !firmante.esObservador) // Filtrar los observadores
											.map((firmante, index) => (
												<MenuItem
													key={index}
													value={firmante.Posicion}
													disabled={!firmante.Manuscrita}
													title={`${firmante.Nombres} ${firmante.Apellidos}`}
												>
													<div
														style={{
															backgroundColor: addColorList(firmante.Posicion),
														}}
														className={classes.circleSelect}
													></div>
													{truncarNombreCompleto(
														firmante.Nombres,
														firmante.Apellidos
													)}
												</MenuItem>
											))}
										<MenuItem
											value='Agregar'
											className={classes.btnAdd}
											onClick={() => dispatch(modalAddFirmantes(true))}
										>
											Agregar firmante{' '}
											<span style={{ fontSize: '25px', marginLeft: '8px' }}>
												+
											</span>
										</MenuItem>
									</Select>

									<Button
										className={classes.btnCampoFirma}
										disabled={
											!firmantes.some(
												firmante =>
													firmante.Manuscrita && !firmante.esObservador
											)
										} // Deshabilitar si no hay firmantes no observadores
										onClick={() => {
											aplicarFirma(imgDefaultSign, true);
										}}
									>
										<div
											className={classes.divTurqoise}
											style={{
												backgroundColor: addColorList(selectedFirmante),
											}}
										>
											<img
												className={classes.backgroundTurquoise}
												src={IconManuscrita}
											/>
										</div>
										Campo de firma <AddIcon />
									</Button>
								</Grid>
								<Grid item xs={11} md={11} lg={6}>
									<BotoneraEditar
										scale={scale}
										setScale={setScale}
										firmas={firmas}
										reSignImage={reSignImage}
										sendData={sendData}
										deleteSign={deleteSign}
										setReSign={setReSign}
										imageSignature={imageSignature}
									/>
									<Box className={classes.boxes}>
										<Grid container style={{ width: '100%', height: '100%' }}>
											{
												<div
													id={idPdfContainer}
													className='drag-container'
													style={{ width: '100%', height: '100%' }}
												>
													{imgSign
														? imageSignature.map(signature => (
																<CanvasDragResize
																	idPdfContainer={idPdfContainer}
																	currentDoc={currentDoc}
																	setCurrentDoc={setCurrentDoc}
																	imgRoute={signature.imgRoute}
																	endRender={async () => {
																		setImgSign(null);
																		setReSign(false);
																	}}
																	colorFirmante={
																		signature.typeImg === 'qr'
																			? ' '
																			: signature.color
																			? signature.color
																			: addColorList(selectedFirmante)
																	}
																	onMouseUp={onMouseUp}
																	typeImg={signature.typeImg}
																	deleteSign={deleteSign}
																	isReSign={isReSign}
																	posX={signature.posX}
																	posY={signature.posY}
																	reSignWidth={signature.reSignWidth}
																	reSignHeigth={signature.reSignHeigth}
																	idReSign={signature.idReSign}
																	predefinedSource={predefinedSource}
																	setPredefinedSource={setPredefinedSource}
																/>
														  ))
														: null}
												</div>
											}
										</Grid>
									</Box>
								</Grid>
								<Grid
									item
									sm={3}
									style={{ alignContent: 'center' }}
									className={classes.hidden}
								>
									<div
										style={{
											width: '100%',
											height: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											maxHeight: '600px',
											boxSizing: 'border-box',
										}}
									>
										<Grid item className={'fullContainer'}>
											<Grid className='pdf-miniaturas pdf-miniaturasCF'>
												<div style={{ display: 'none' }}>
													<input
														accept='application/pdf'
														multiple=''
														type='file'
														id='Upload'
														onChangeCapture={handlerChange}
													/>
												</div>
												<Grid
													container
													direction='row'
													justify='center'
													className='addDocumentToSign'
												>
													<IconButton
														edge='start'
														aria-label='upload file'
														onClick={openZoneDrop}
													>
														<img src={addButton} />
													</IconButton>
												</Grid>
												<Grid
													id={idThumbnailsContainer}
													className='pdf-miniaturas-firmas pdf-miniaturas-firmasCF'
													onClick={refreshDoc}
												></Grid>
												<Grid item xs={0}>
													<div
														style={{
															display: 'none',
															padding: '8px',
															float: 'right',
														}}
														id='dvCurrentPageNumber'
													>
														Página: {currentPage}
													</div>
												</Grid>
											</Grid>
										</Grid>
										<Button
											className={'btnContinue'}
											onClick={() => verifyAddedFields(firmantes, sendData)}
										>
											Continuar
											<ArrowForwardIosIcon className={classes.tamaño} />
										</Button>
									</div>
								</Grid>
								<button
									className={'btnContinue2'}
									onClick={() => verifyAddedFields(firmantes, sendData)}
									style={{ width: '85%', padding: '10px' }}
								>
									Continuar
									<ArrowForwardIosIcon className={classes.tamaño} />
								</button>
							</Grid>
							{modalAddFirmantesStatus == true ? (
								<ModalAddSignature
									addedFields={addedFields}
									setAddedFields={setAddedFields}
									sendData={sendData}
									signFields={signFields}
									setSignFields={actualizarSignsfields}
									setModalflag={setModalflag}
									listColorsChoose={listColorsChoose}
								/>
							) : null}
							{modalConfirmStatus && (
								<ModalConfirm
									pendingList={nombFirm}
									firmantes={firmantes}
									files={sendData.documents}
								/>
							)}
						</Paper>
					</Grid>
				</Grid>
				<Modal
					open={verQR}
					disableBackdropClick
					onClose={() => {
						setVerQR(false);
					}}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
					className='modal-sign'
				>
					<ModalQR
						setQR={setQR}
						setVerQR={setVerQR}
						setSignImage={setSignImage}
						setTypeImg={setTypeImg}
						setConfigQR={setConfigQR}
						qr={qr}
						aplicarQR={aplicarQR}
					/>
				</Modal>
			</Container>
			{selectActiveSigner()}
		</Grid>
	);
};

export default NewViewFirmar;
