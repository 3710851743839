import { Button, makeStyles, Modal } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
 
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: '0px 3px 0px #00000033 !important',
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: '10px !important',
    "&:focus": {
			outline: "none"
		}
  },
  container: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textModal: {
    font: "normal normal 100 16px/24px Muli",
    padding: "16px 0px 0px",
    color: "#4F4F4F",
  },
}))

const ModalInvalidSession = (props) => {

  const styles = useStyles();
  const [modal, setModal] = useState(true)
  const logout = () => {
    localStorage.removeItem('JWT');
    window.location.reload()
  };

  const closeModal = async () => {
      setModal(false)
    logout()
  }

  return (
    <Modal
      open={modal}
      onClose={closeModal}
      disableBackdropClick
      >
      <div className={styles.modal}>
        <div className={styles.container}>
          <p className={styles.textModal}>{props.message}</p>
        </div>
        <div className={styles.container}>
          <Button className={"btnGeneral18"} onClick={() => closeModal()} style={{ width: '154px', height: '41px' }} >Aceptar</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalInvalidSession