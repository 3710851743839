import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Paper, Grid } from '@material-ui/core';
import GoogleDriveExplorer from './GetFileGoogleDrive';
import OneDriveExplorer from './GetFileOneDrive';
import DropboxExplorer from './GetFileDropbox';
import { useState } from 'react';
 
const ModalGetFIleCloudDrive = (props) => {

    const {
        onClose, statusModalCloudSelect, setStatusModalCloudSelect, files, setFiles, sizeFiles
    } = props;
    const [hiddenGoogleDriveButton, setHiddenGoogleDriveButton] = useState(false);
    const [statusModalGoogleDrive, setStatusModalGoogleDrive] = useState(false)
    const [statusModalOneDrive, setStatusModalOneDrive] = useState(false)
    const [statusModalDropbox, setStatusModalDropbox] = useState(false)

    return (
        <Modal
            disableBackdropClick
            open={statusModalCloudSelect}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div align="center">
                <Paper elevation={3} className="contentCloudIconsModal">
                    <Grid container spacing={2} alignContent={'center'}>
                        <Grid item xs={12}>
                            <div hidden={hiddenGoogleDriveButton}>
                                <GoogleDriveExplorer
                                    files={files}
                                    open={statusModalGoogleDrive}
                                    disableBackdropClick
                                    onClose={() => setStatusModalGoogleDrive(false)}
                                    setStatusModalGoogleDrive={setStatusModalGoogleDrive}
                                    setStatusModalCloudSelect={setStatusModalCloudSelect}
                                    setFiles={setFiles}
                                    sizeFiles={sizeFiles}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <OneDriveExplorer
                                files={files}
                                open={statusModalOneDrive}
                                onClose={() => setStatusModalOneDrive(false)}
                                setStatusModalOneDrive={setStatusModalOneDrive}
                                setStatusModalCloudSelect={setStatusModalCloudSelect}
                                setHiddenGoogleDriveButton={setHiddenGoogleDriveButton}
                                setFiles={setFiles}
                                sizeFiles={sizeFiles}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <DropboxExplorer
                                files={files}
                                open={statusModalDropbox}
                                onClose={() => setStatusModalDropbox(false)}
                                setStatusModalDropbox={setStatusModalDropbox}
                                setStatusModalCloudSelect={setStatusModalCloudSelect}
                                setFiles={setFiles}
                                sizeFiles={sizeFiles}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Modal>
    )
}

export default ModalGetFIleCloudDrive