/*
 * * Funcion para ordenar los elementos de la tabla
 */
export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

/**
 *  * Funcion para ordenar los elementos de la tabla
 * @param {*} a
 * @param {*} b
 * @param {*} orderBy
 * @returns
 */
export function descendingComparator(a, b, orderBy) {
	return b[orderBy].localeCompare(a[orderBy]);
}

/**
 * * Funcion para ordenar los elementos de la tabla
 * @param {*} order
 * @param {*} orderBy
 * @returns
 *
 *
 */
export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export const orderDocuments = (documents, order = 'desc', field) => {
	return documents.sort((a, b) => {
		const dateA = new Date(a[field] || a['fechaFirma']);
		const dateB = new Date(b[field] || b['fechaFirma']);
		if (dateA.getTime() === dateB.getTime()) {
			const timeA = new Date(a[field] || a['fechaFirma']).getTime();
			const timeB = new Date(b[field] || b['fechaFirma']).getTime();
			return order === 'desc' ? timeB - timeA : timeA - timeB;
		} else {
			return order === 'desc' ? dateB - dateA : dateA - dateB;
		}
	});
};

export const orderListype = (list, orderBy, order, dateTypeFields) => {
	const isDateField =
		dateTypeFields.includes(orderBy) ||
		list.some(item => item.hasOwnProperty('fechaFirma'));
	if (isDateField) {
		return orderDocuments(list, order, orderBy);
	} else {
		return stableSort(list, getComparator(order, orderBy));
	}
};
export const orderListypeMasivo = (list, orderBy, order, dateTypeFields) => {
	

	if (dateTypeFields.includes(orderBy))
		return orderDocuments(list, order, orderBy);
	else return stableSort(list, getComparator(order, orderBy));
};
