import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import {
	filesCf,
	stepCircuit,
} from '../../../../redux/actions/CircuitoFirmasAction';

import { InputLabel, makeStyles, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { preventSpaceWhenEmpty } from '../../../../helpers/formatText';
import { name_folder } from '../../../../redux/actions/CircuitoFirmasAction';

const useStyle = makeStyles({
	error: {
		color: 'red',
	},
	closeButton: {
		position: 'absolute',
		right: '8px',
		top: '8px',
		color: '#F5AE19',
		width: '30px',
		height: '30px',
		transitions: 'all 0.3s ease',

		'&:hover': {
			cursor: 'pointer',
			color: '#E55200',
			textShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
		},
	},
	contForm: {
		width: '100%',
	},

	labelBootstrap: {
		paddingLeft: '10px',
		color: '#E55200',
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '22px',
	},
	modal: {
		margin: 0,
		padding: '40px',
		position: 'absolute',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxShadow: '0px 3px 0px #00000033 !important',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '5.72px',
		width: '530px',
		'&:focus': {
			outline: 'none',
		},
		'@media (max-width: 600px)': {
			width: '90%',
			height: 'auto',
		},
	},
	title: {
		color: '#E55200',
		fontSize: '24px',
		fontFamily: 'Mulish',
		fontWeight: '700',
		margin: 0,
		textAlign: 'center',
	},
	parrafo: {
		fontSize: '16px',
		width: 'auto',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #E55200',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#E55200',
			opacity: '0.8',
			boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
		},
	},
	continueDisable: {
		backgroundColor: '#fadccc',
		color: 'white',
		padding: '8px 24px',
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		fontWeight: '800',
		fontFamily: 'Mulish',
		border: '1px solid #fadccc',
		borderRadius: '8px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '16px',
		marginTop: '26px',
	},
	formControl: {
		width: '100%',
		minWidth: 120,
		marginBottom: '16px',
		border: '1px solid gray',
		borderRadius: '8px',
	},
	selectEmpty: {},
	motivos: {
		width: '100%',
		height: '130px',
		border: '1px solid gray',
		padding: '8px',
		borderRadius: '8px',
		fontFamily: 'mulish',
		fontSize: '16px',
		resize: 'none',
		'&:focus': {
			outline: 'none',
		},
	},
});

// * Estilos para el input
const BootstrapInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		color: '#444444',
		backgroundColor: '#FFFFFF',
		border: '1px solid #706F6F',
		boxShadow: '0px 2.76082px 0px rgba(0, 0, 0, 0.2)',
		borderRadius: '10px',
		position: 'relative',
		fontSize: '16px',
		lineHeight: '22px',
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '400',
		width: '100%',
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			boxShadow: '0px 2.76082px 0px rgba(0, 0, 0, 0.2)',
			borderColor: '#E55200',
		},
		'&:-webkit-autofill': {
			'-webkit-text-fill-color': '#000000 !important', // Color del texto
			'-webkit-box-shadow': '0 0 0px 1000px white inset !important', // Fondo del autofill
		}
	},
}))(InputBase);

const ModalFolder = ({ openModal, setOpenModal, files, isDashboard }) => {
	const classes = useStyle();
	const history = useHistory();

	const dispatch = useDispatch();

	const MIN_FOLDER_NAME_LENGTH = 5;
	const MAX_FOLDER_NAME_LENGTH = 50;

	const [error, setError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [nameFolder, setNameFolder] = useState('');

	/**
	 *  * Función que se ejecuta al escribir en el input
	 * @param {*} event
	 */
	const handleChange = ({ target }) => {
		const errorText = validateFolderName(target.value);
		setNameFolder(target.value.replace(/\s+/g, ' ').trimStart());
		setError(!!errorText);
		setErrorText(errorText || '');
	};

	/**
	 *  * Función para cerrar el modal
	 */
	const closeModal = () => {
		setOpenModal(false);
		setNameFolder('');
	};

	/**
	 * * Función para validar el nombre de la carpeta
	 * @param {*} nameFolder
	 * @returns
	 */
	const validateFolderName = nameFolder => {
		if (nameFolder === '') {
			return 'El nombre de la carpeta es requerido';
		}
		if (nameFolder.length > MAX_FOLDER_NAME_LENGTH) {
			return `El nombre de la carpeta no puede tener más de ${MAX_FOLDER_NAME_LENGTH} caracteres`;
		}
		if (nameFolder.length < MIN_FOLDER_NAME_LENGTH) {
			return `El nombre de la carpeta debe tener al menos ${MIN_FOLDER_NAME_LENGTH} caracteres`;
		}
		return null;
	};

	/**
	 * * Función para continuar con la creación de la carpeta
	 * @param {*} nameFolder
	 */
	const signContinue = (nameFolder, files) => {
		const errorText = validateFolderName(nameFolder);
		if (errorText) {
			setError(true);
			setErrorText(errorText);
			return;
		}
		dispatch(name_folder(nameFolder.replace(/\s+/g, ' ').trim()));
		dispatch(filesCf(files));
		if (isDashboard) {
			history.push('/home/firmar');
		}
		dispatch(stepCircuit(isDashboard ? 10 : 1));
	};

	return (
		<Modal open={openModal} onClose={closeModal} disableBackdropClick>
			<div className={classes.modal}>
				<HighlightOffIcon
					onClick={() => closeModal()}
					className={classes.closeButton}
				/>
				<h4 className={classes.title}>¡Tienes varios documentos!</h4>
				<p className={classes.parrafo}>
					Al parecer quieres incluir varios documentos en tu circuito de firmas,
					se creará una carpeta.
				</p>
				<FormControl error={error} className={classes.contForm}>
					<InputLabel
						shrink
						htmlFor='bootstrap-input'
						className={classes.labelBootstrap}
						focused={false}
					>
						Nombre de la carpeta
					</InputLabel>
					<BootstrapInput
						value={nameFolder}
						id='bootstrap-input'
						onChange={handleChange}
						onKeyDown={e => preventSpaceWhenEmpty(nameFolder, e)}
						inputProps={{ maxLength: 50, placeholder: 'Carpeta_dd-mm-aaaa_hh.mm' }}
					/>
				</FormControl>
				<FormHelperText className={classes.error}>{errorText}</FormHelperText>
				<div className={classes.flex}>
					<button
						onClick={() => signContinue(nameFolder, files)}
						className={classes.continue}
					>
						Crear Carpeta
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalFolder;
