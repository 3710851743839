import {
	Grid,
	Container,
	Paper,
	makeStyles,
	InputLabel,
	Box,
	Button,
	Switch,
	FormControlLabel,
	Tooltip,
	Select,
	FormControl,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
	modalMessage,
	modalSendMessage,
	name_folder,
	modal_signers_invitation,
	radicado_circuito,
	stepCircuit,
	cleanFiles,
	resetCF,
	noAction,
} from '../../../redux/actions/CircuitoFirmasAction';
import { API_HOSTNAME_AWS } from '../../../redux/types/ApiConstants';
import Atras from '../../../assets/img/iconLeft2.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import folder from '../../../assets/img/Icon-material-folder.png';
import lapiz from '../../../assets/img/Icon-material-edit.png';
import mensajePrivado from '../../../assets/img/correo.svg';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { withStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import bold from '../../../assets/img/bold.png';
import italic from '../../../assets/img/italic.png';
import underline from '../../../assets/img/underline.png';
import left from '../../../assets/img/LeftAlign.png';
import center from '../../../assets/img/centerAlign.png';
import right from '../../../assets/img/rightAlign.png';
import color from '../../../assets/img/colors.png';
import unordered from '../../../assets/img/unorderedList.png';
import ordered from '../../../assets/img/orderedList.png';
import indent from '../../../assets/img/textInside.png';
import outdent from '../../../assets/img/textOutside.png';
import Loading from '../../../components/Loading';
import ModalSignersInvitation from './modales/ModalSignersInvitation';
import ModalSendDocument from './modales/ModalSendDocument';
import ModalPrivateMessage from './modales/ModalPrivateMessage';
import { notificar2 } from '../../../redux/actions/CircuitActions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { preventSpaceWhenEmpty } from '../../../helpers/formatText';
import CustomizedSteppers from '../../../components/Demo';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { truncarNombreCompleto } from '../../../helpers/helpNames';
import { MenuItem } from 'react-contextmenu';
import { helpHttp } from '../../../helpers/helpHttp';
const useStyle = makeStyles({
	iconBack: {
		cursor: 'pointer',
		position: 'relative',
		top: '30px',
		left: '0',
		'@media(max-width:599px)': {
			marginBottom: '10%',
		},
	},
	asunto: {
		borderRadius: '10px',
		height: '32px',
		marginBottom: '15px',
		padding: '0',
		paddingLeft: '10px',
		width: '100%',
		backgroundColor: 'transparent !importanr',
		border: '1px solid gray',
		boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.25)',
		fontFamily: 'Mulish',
		'&:focus': {
			outline: 'none',
			backgroundColor: 'white',
		},
	},
	btnFinalizar: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'end',
		alignContent: 'flex-end',
		width: '100%',
		padding: '16px',
		'@media(max-width:599px)': {
			justifyContent: 'center',
		},
	},
	size: {
		fontSize: '17px',
		colo: '4F4F4F',
		margin: '3px 0 18px 0',
	},
	tamaño: {
		width: '15px',
		marginLeft: '3px',
	},
	pBlue: {
		color: '#157594',
		cursor: 'pointer',
		fontSize: '14px',
		position: 'absolute',
		right: '30px',
		top: '0px',
	},
	mainContainer: {
		position: 'relative',
	},
	nameDocuments: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		height: '22px',
		width: '100%',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		width: '100%',
		backgroundColor: 'transparent',
	},
	small: {
		fontSize: '14px',
		fontFamily: 'Mulish',
		marginLeft: '10px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		borderRadius: '10px',
		border: 'none !important',
		fontSize: '16px !important',
		padding: '2px 25px',
		'&:hover': {
			backgroundColor: '#E55200',
		},	
		'@media(max-width:599px)': {
			width: '100%',
			fontSize: '17px !important',
			padding: '8px 24px',
		},
	},
	correo: {
		'&:hover': {
			filter:
				' invert(36%) sepia(39%) saturate(5423%) hue-rotate(10deg) brightness(97%) contrast(102%)',
			cursor: 'pointer',
		},
	},
	tooltip: {
		visibility: 'hidden',
		backgroundColor: 'gray',
		width: '85px',
		maxHeight: '17px',
		fontSize: '9px',
		borderRadius: '5px',
		color: 'white',
		padding: '3px 7px',
		marginLeft: '-20px',
		'&:hover': {
			visibility: 'visible',
		},
	},
	tooltipContent: {
		visibility: 'visible',
		position: 'relative',
		left: '20px',
	},
	resumen: {
		color: '#E55200',
		fontSize: '16px',
		marginTop: '-23px',
		marginBottom: '0',
		'@media(max-width:599px)': {
			marginTop: '40px',
		},
	},
	formControl: {
		minWidth: 120,
	},
	option: {
		fontFamily: 'Mulish',
		color: '#e56b00',
		fontSize: '16px',
		padding: '2px',
		'&:hover': {
			backgroundColor: '#A9A9A9',
		},
	},
	inputRecordatorio: {
		padding: '10px',
	},
});

const ViewMessage = () => {
	const classes = useStyle();
	const [asunto, setAsunto] = useState('');
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	let active = show ? ' active2' : null;
	let active2 = show2 ? ' active2' : null;
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const dispatch = useDispatch();
	const states = useSelector(state => state);
	const modalMessageStatus = states.CircuitoFirmasReducer.modalMessage;
	const dataDocs = states.CircuitoFirmasReducer.dataQR;
	const modalSendMessageStatus = states.CircuitoFirmasReducer.modalSendMessage;
	const modalSignersInvitationStatus = states.CircuitoFirmasReducer.modalSignersInvitation;
	const carpeta = states.CircuitoFirmasReducer.carpeta;
	const [listaFirmantes, setListaFirmantes] = useState([]);
	const [circuito, setCircuito] = useState('');
	const [correo, setCorreo] = useState('');
	const [inputValue, setInputValue] = useState( states.CircuitoFirmasReducer.carpeta );
	const [editar, setEditar] = useState(false);
	const texto = editorState.getCurrentContent().getPlainText();
	const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
	const suma = editorState.getCurrentContent().getPlainText().length;
	const jwt = localStorage.getItem('JWT');
	const [loading, setLoading] = useState(false);
	const CryptoJS = require('crypto-js');
	const historys = useHistory();
	const reseteoCF = () => {
		localStorage.removeItem('irHome');
		dispatch(stepCircuit(0));
		dispatch(resetCF());
		dispatch(cleanFiles());
	};
	const { enqueueSnackbar } = useSnackbar();
	const [notification, setNotification] = useState(false);
	const [modificarDatos, setModificarDatos] = useState(false);
	const primerFirmante = correo.toLocaleLowerCase();
	const [relacion, setRelacion] = useState();
	const [lineal, setLineal] = useState(false);
	const [periocidad, setPeriocidad] = React.useState('Dia');
	const handleChangePeriodicity = event => {
		setPeriocidad(event.target.value);
	};
	const BorderLinearProgress = withStyles(theme => ({
		root: {
			height: 5,
			width: '100%',
			borderRadius: 5,
		},
		colorPrimary: {
			backgroundColor:
				theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
		},
		bar: {
			borderRadius: 5,
			margin: '0',
		},
	}))(LinearProgress);
	const lonMax = 1000;
	const URLCreate = API_HOSTNAME_AWS + 'webCircuit/api/create';
	const URLSaveDocs = `${API_HOSTNAME_AWS}documentmanager/api/DocumentManager/addList`;
	const URLDelete = API_HOSTNAME_AWS + 'webCircuit/api/UpdateCircuit';
	const userInfo = useSelector(({ auth }) => auth.userInfo);
	const dragItem = useRef();
	const dragOverItem = useRef();
	let api = helpHttp();

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	}, []);

	useEffect(() => {
		if (dataDocs.length > 1) {
			if (carpeta.trim() === '') {
				setInputValue('Circuito de firmas');
			}
		}
	}, []);

	useEffect(() => {
		const firmantesRedux = states.CircuitoFirmasReducer.firmantes;
		if (firmantesRedux) {
			verificarFirmaDocumentos(firmantesRedux);
			setCorreo(firmantesRedux[0].Correo);
			setCircuito(states.CircuitoFirmasReducer.circuito);
		}
	}, [carpeta, circuito, modificarDatos]);

	/**
	 * Esta función es una validacion extra que se hace, y verifica la informacion del circuito de firmas por cada firmante y actualiza el estado de firmanteLibre segun sea e caso.
	 * Si un firmante tiene firma manuscrita, y hay ubicaciones en documentos para firmar, se marca como no libre.
	 * Si un firmante no tiene firma manuscrita, se marca como no libre.
	 * Si un firmante utiliza firma manuscrita pero no tiene ubicaciones de firma en documentos para firmar, se marca como libre.
	 * @param {Array} firmantes - Lista de firmantes a verificar.
	 * @returns {void} No retorna nada pero actualiza el estado de listaFirmantes.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const verificarFirmaDocumentos = firmantes => {
		const nuevaListaFirmantes = firmantes.map(firmante => {
			if (firmante.Manuscrita && firmante.firmaDocumentos.length > 0) {
				return { ...firmante, firmanteLibre: false };
			} else if (!firmante.Manuscrita) {
				return { ...firmante, firmanteLibre: false };
			} else if (firmante.Manuscrita && firmante.firmaDocumentos.length === 0) {
				return { ...firmante, firmanteLibre: true };
			}
			return firmante;
		});
		setListaFirmantes(nuevaListaFirmantes);
	};

	/**
	 * Crea un arreglo de objetos con la información de los documentos del circuito de firmas,
	 * incluyendo el contenido base64, nombre, tipo de firma, hash, y cualquier información adicional como marcas de agua o códigos QR.
	 * @param {Array} dataDocs - Arreglo de objetos que contienen la información de los documentos del circuito.
	 * @returns {Array} Arreglo de objetos con la información de los documentos, formateada según los requisitos del sistema.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const documentosCF = dataDocs.map((obj, index) => {
		const { base64, fileName, typeSignature, hash } = obj;
		const adicional = typeSignature.map((sig, adicionalIndex) => {
			if (sig.signatureType === 'marcaagua') {
				return { type: sig.signatureType, id: adicionalIndex };
			} else if (sig.signatureType === 'qr') {
				const { base64Image, tpag, url, title, posXY, idimg } = sig;
				const { pageNumber, ...rest } = posXY; // desestructuramos pageNumber y guardamos el resto en la variable rest
				const newXY = { ...rest, pagina: pageNumber }; // creamos un nuevo objeto con la propiedad "pagina" en lugar de "pageNumber"
				return {
					id: adicionalIndex,
					type: sig.signatureType,
					base64Image,
					tpag,
					url,
					title,
					posXY: newXY,
					idimg,
				};
			} else {
				return {};
			}
		});
		return {
			Base64: base64,
			Nombre: fileName,
			Adicional: adicional,
			Orden: index + 1,
			hash,
			hash2: CryptoJS.SHA256(CryptoJS.enc.Base64.parse(base64)).toString( CryptoJS.enc.Base64 ),
		};
	});

	/**
	 * Actualiza el estado del asunto basado en el valor del evento.
	 * @param {Event} event - El evento de cambio que desencadena la actualización.
	 * @returns {void} No retorna nada explícitamente, pero actualiza el estado del asunto.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleChange = event => {
		setAsunto(event.target.value);
	};

	/**
	 * Maneja la presentación del formulario y realiza acciones correspondientes.
	 * @param {Event} e - El evento del formulario.
	 * @returns {void} No retorna nada explícitamente, pero ejecuta acciones correspondientes al manejar la presentación del formulario, y ejecuta la funcion de crear el circuito de firmas.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleSubmit = async e => {
		dispatch(name_folder(inputValue));
		e.preventDefault();
		crearCF();
	};

	/**
	 * Edita el nombre de la carpeta actual.
	 * Si el nuevo nombre tiene menos de 5 caracteres, restaura el valor anterior.
	 * @returns {void} No retorna nada explícitamente, pero actualiza el nombre de la carpeta y el estado de edición.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const editFolderName = () => {
		if (inputValue.length < 5) {
			setInputValue(carpeta);
		} else {
			dispatch(name_folder(inputValue.replace(/\s+/g, ' ').trim()));
			setInputValue(inputValue.replace(/\s+/g, ' ').trim());
		}
		setEditar(!editar);
	};

	/**
	 * Cambia el valor del input para el nombre de la carpeta, eliminando espacios en blanco al principio y al final.
	 * @param {Event} e - El evento del cambio que desencadena la función.
	 * @returns {void} No retorna nada explícitamente, pero actualiza el valor del input.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const changeNameFolder = e => {
		setInputValue(e.target.value.replace(/\s+/g, ' ').trimStart());
	};

	const deleteCF = async idCircuito => {
		let raw = {
			circuito: idCircuito,
			estadoCircuito: '5',
			firmante: '',
			estadoFirmante: '',
			correoFirmante: '',
		};
		let options = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: ` Bearer ${jwt}`,
			},
			body: raw,
			redirect: 'follow',
		};
		await api.post(URLDelete, options).then(res => {
			if (!res.err) {
				console.log('Eliminado');
			} else {
				console.log('No eliminado');
			}
		});
	};

	/**
	 * Crea un circuito de firmas con la información proporcionada, incluyendo documentos, firmantes, asunto y mensaje.
	 * Realiza una solicitud HTTP POST al servidor para crear el circuito.
	 * Si la creación es exitosa, muestra mensajes de confirmación y activa ventanas modales según el tipo de circuito.
	 * Si ocurre algún error durante el proceso, muestra mensajes de error y realiza acciones de manejo de errores.
	 * @returns {void} No retorna nada explícitamente, pero actualiza el estado de la aplicación y muestra mensajes al usuario.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const crearCF = async () => {
		const name_folder =
			dataDocs.length > 1
				? inputValue.trim() !== ''
					? inputValue
					: 'Circuito de firmas'
				: ' ';
		setLoading(true);
		const tieneAdicionales = documentosCF.some(doc => doc.Adicional.length > 0);
		let myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer ' + jwt);
		myHeaders.append('Content-Type', 'application/json');
		let raw = JSON.stringify({
			TipoCircuito: lineal ? 'lineal' : 'no lineal',
			Vence: false,
			Folder: name_folder,
			FechaVence: '2022-07-30 20:10:00',
			asunto: asunto,
			mensaje: html,
			Documentos: tieneAdicionales ? documentosCF : [],
			Firmantes: listaFirmantes,
			internalDoc: true,
		});
		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		try {
			const response = await fetch(URLCreate, requestOptions);
			const result_1 = await response.json();
			if (!response.ok) {
				if (response.status === 400) {
					if (result_1.responseCode === 'E117') {
						enqueueSnackbar(
							'Ocurrio un error al cargar los documentos, por favor intente de nuevo. Si el problema persiste \npóngase en contacto con su administrador del sistema. ',
							{
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
						setLoading(false);
						reseteoCF();
						historys.push('/home');
					} else {
						enqueueSnackbar(result_1.responseText, {
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						});
						setLoading(false);
					}
				} else {
					enqueueSnackbar(
						'Ocurrio un error.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
						{
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						}
					);
				}

				setLoading(false);
			} else {
				if (!tieneAdicionales) {
					const myHeaders = new Headers();
					myHeaders.append('Content-Type', 'application/json');
					let raw = JSON.stringify({
						FileExtension: '.pdf',
						Repository: '1',
						Usuario: result_1.idcreador,
						Application: 'FirmaYaWeb',
						Circuit: result_1.circuitCode,
						Documentos: documentosCF,
					});
					let options = {
						method: 'POST',
						headers: myHeaders,
						body: raw,
						redirect: 'follow',
					};
					const maxRetries = 3;
					for (let attempt = 0; attempt < maxRetries; attempt++) {
						try {
							const res = await fetch(URLSaveDocs, options);
							const res_1 = await res.json();
							if (res.ok) {
								if (lineal) {
									await notificar2(jwt, result_1.circuitCode, 0);
									if (primerFirmante === userInfo.email) {
										dispatch(modalSendMessage(true));
									} else {
										dispatch(modal_signers_invitation(true));
									}
								} else {
									await notificar2(jwt, result_1.circuitCode, 5);
									const estaEnLista = listaFirmantes.some(
										firmante => firmante.Correo === userInfo.email
									);

									if (estaEnLista) {
										dispatch(modalSendMessage(true));
									} else {
										dispatch(modal_signers_invitation(true));
									}
								}

								dispatch(radicado_circuito(result_1.radicado));
								setRelacion(result_1.relacion);
								setLoading(false);
								break; // Salir del bucle si la solicitud es exitosa
							} else {
								if (attempt === maxRetries - 1) {
									if (res_1.codigoRespuesta === 'R10') {
										const camposObligatorios = res_1.detalleRespuesta
											.map(detalle => {
												// Aqui estoy extrayendo el campo del mensaje de error
												const campo = detalle
													.split(':')[0]
													.replace('Propiedad ', ''); // Eliminar 'Propiedad '
												return campo; // Retornar el campo sin mapeo
											})
											.join(', '); // Unirlos con comas

										// Mensaje final ajustado
										enqueueSnackbar(
											`Error en la petición, los siguientes campos son obligatorios: ${camposObligatorios}.`,
											{
												variant: 'error',
												anchorOrigin: {
													vertical: 'top',
													horizontal: 'right',
												},
											}
										);
									} else{
										enqueueSnackbar(
											`Ocurrio un error al procesar su solicitud, por favor intentelo nuevamente.`,
											{
												variant: 'error',
												anchorOrigin: {
													vertical: 'top',
													horizontal: 'right',
												},
											}
										);
									}
									// Si es el último intento, maneja el error (Mostrar un snackbar y "Eliminar el circuito" y redireccionarlo a home)
									deleteCF(result_1.circuitCode);
									historys.push('/home');
									setLoading(false);
								}
							}
						} catch (error) {
							if (attempt === maxRetries - 1) {
								// Si es el último intento, maneja el error (Mostrar un snackbar y "Eliminar el circuito")
								console.error('Error en la solicitud:', error);
								console.log("Error al guardar los documentos en la base de datos")
								deleteCF(result_1.circuitCode);
								enqueueSnackbar('Ocurrio un error al guardar los documentos ', {
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								});
								setLoading(false);
							}
							// Esperar un momento  antes de volver a intentar(Esto no es obligatorio, quiza quite ese tiempo para que no consuma tiempo de firmado )
							await new Promise(resolve => setTimeout(resolve, 1000)); // Espera 1 segundo
						}
					}
				} else {
					if (lineal) {
						await notificar2(jwt, result_1.circuitCode, 0);
						if (primerFirmante === userInfo.email) {
							dispatch(modalSendMessage(true));
						} else {
							dispatch(modal_signers_invitation(true));
						}
					} else {
						await notificar2(jwt, result_1.circuitCode, 5);
						const estaEnLista = listaFirmantes.some(
							firmante => firmante.Correo === userInfo.email
						);

						if (estaEnLista) {
							dispatch(modalSendMessage(true));
						} else {
							dispatch(modal_signers_invitation(true));
						}
					}

					dispatch(radicado_circuito(result_1.radicado));
					setRelacion(result_1.relacion);
					setLoading(false);
				}
			}
		} catch (error) {
			enqueueSnackbar(
				'Error de red.\nPor favor intente de nuevo, y si el problema persiste,\npóngase en contacto con su administrador del sistema.',
				{
					variant: 'error',
					style: { whiteSpace: 'pre-line' },
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
			setLoading(false);
			reseteoCF();
			historys.push('/home');
			return console.error('error', error);
		}
	};

	/**
	 * Maneja la reordenación de los elementos en la lista de firmantes.
	 * Copia la lista de firmantes, mueve el elemento arrastrado a la posición deseada y actualiza la posición de los demás elementos.
	 * @returns {void} No retorna nada pero actualiza el estado de 'listaFirmantes' y 'correo'.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleSort = () => {
		//duplicate items
		let _listaFirmantes = [...listaFirmantes];
		//remove and save the dragged item content
		const draggedItemContent = _listaFirmantes.splice(dragItem.current, 1)[0];
		//switch the position
		_listaFirmantes.splice(dragOverItem.current, 0, draggedItemContent);
		//reset the position ref
		dragItem.current = null;
		dragOverItem.current = null;
		//update the actual array
		const posicionesFirmantes = _listaFirmantes.map((firmante, index) => {
			if (firmante.Posicion !== index + 1) {
				return { ...firmante, Posicion: index + 1 };
			}
			return firmante;
		});
		setListaFirmantes(posicionesFirmantes);
		setCorreo(_listaFirmantes[0].Correo);
	};

	/**
	 * Regresa al paso anterior del circuito de firmas.
	 * Actualiza el estado para retroceder al paso 2 del circuito.
	 * @returns {void} No retorna nada pero actualiza el estado de 'stepCircuit'.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const atras = async () => {
		dispatch(stepCircuit(2));
	};

	/**
	 * Maneja el cambio en la opción de circuito lineal.
	 * Actualiza el estado de 'lineal' basado en el valor del checkbox.
	 * @param {object} event - El evento del cambio en el checkbox.
	 * @returns {void} No retorna nada pero actualiza el estado de 'lineal'.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleLineal = event => {
		setLineal(event.target.checked);
	};

	/**
	 * Maneja el cambio en la opción de notificación.
	 * Actualiza el estado de 'notification' basado en el valor del checkbox.
	 * @param {object} e - El evento del cambio en el checkbox.
	 * @returns {void} No retorna nada pero actualiza el estado de 'notification'.
	 * Documentado por: Lizeth Paola Delgadillo Robayo
	 */
	const handleNotification = e => {
		setNotification(e.target.checked);
	};

	return (
		<Grid container spacing={0} direction='row'>
			<Container component='main' maxWidth='lg'>
				<h1 className={'title-background'}>Firmar documentos</h1>
			</Container>
			<Container component='main' maxWidth='lg'>
				<Grid
					container
					spacing={0}
					direction='row'
					justify='flex-start'
					alignItems='flex-start'
					className='zone-upload-container'
					maxWidth='md'
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Paper elevation={0} className='addSigner'>
							{
								<img
									onClick={() => atras()}
									className={classes.iconBack}
									src={Atras}
									disabled={true}
								/>
							}
							<CustomizedSteppers activeStep={4} />
							<FormControlLabel
								control={
									<Switch
										checked={lineal}
										onChange={handleLineal}
										name='checkedB'
										color='primary'
									/>
								}
								label='Firmar en Orden'
							/>
							<br />
							<div style={{ display: 'flex' }}>
								<FormControlLabel
									control={
										<Switch
											checked={notification}
											onChange={handleNotification}
											name='checkedB'
											color='primary'
										/>
									}
									label='Activar Recordatorios'
									title='Notifica periodicamente a los firmantes de este circuito que aun no han realizado su firma.'
								/>
								<div style={{ opacity: notification ? 1 : 0 }}>
									<FormControl
										variant='outlined'
										className={classes.formControl}
									>
										<InputLabel>Recordatorio</InputLabel>
										<Select
											value={periocidad}
											onChange={handleChangePeriodicity}
											label='Recordatorio'
											disabled={!notification}
											inputProps={{ className: classes.inputRecordatorio }}
										>
											<MenuItem className={classes.option} value={'Dia'}>
												Diario
											</MenuItem>
											<MenuItem className={classes.option} value={'Semana'}>
												Semanal
											</MenuItem>
											<MenuItem className={classes.option} value={'Quincena'}>
												Quincenal
											</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div>
							<form onSubmit={handleSubmit}>
								<h2
									style={{
										color: '#E55200',
										fontSize: '20px',
										margin: '3px 0 0 0 ',
									}}
								>
									Configura tu mensaje
								</h2>
								<h4 className={classes.size}>
									Escribe un mensaje a los firmantes.
								</h4>
								<Grid container spacing={4}>
									<Grid item xs={12} sm={8} className={classes.mainContainer}>
										<InputLabel
											style={{ fontSize: '10px', color: '#4F4F4F' }}
											htmlFor='component-outlined'
										>
											Asunto *
										</InputLabel>
										<input
											id='component-outlined'
											value={asunto}
											required
											onChange={handleChange}
											label='Name'
											fullWidth
											placeholder='Escribe el asunto'
											className={classes.asunto}
										/>
										<InputLabel
											style={{
												fontSize: '10px',
												paddingBottom: '10px',
												color: '#4F4F4F',
											}}
											htmlFor='component-outlined'
										>
											Mensaje (opcional)
										</InputLabel>
										<div>
											{/* ------------------------------------------------------------------------------------------------------------------------------------ */}
											<div style={{ overflow: 'auto' }}>
												<Editor
													editorState={editorState}
													editorClassName='demo-editor'
													toolbarStyle={{
														backgroundColor: '#EFEFEF',
														borderTopLeftRadius: '15px',
														borderTopRightRadius: '15px',
														borderTop: '2px solid gray',
														borderLeft: '2px solid gray',
														borderRight: '2px solid gray',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														gap: '2%',
														marginBottom: '0',
													}}
													//estilos del editor
													editorStyle={{
														height: '24vh',
														borderBottomLeftRadius: '15px',
														borderBottomRightRadius: '15px',
														borderLeft: '2px solid gray',
														borderRight: '2px solid gray',
														borderTop: 'none',
														padding: '15px',
														borderBottom: '2px solid gray',
														resize: 'none',
														margin: '0',
													}}
													onEditorStateChange={state => setEditorState(state)}
													handleBeforeInput={val => {
														const textLength = editorState
															.getCurrentContent()
															.getPlainText().length;
														if (val && textLength >= lonMax) {
															return 'handled';
														}
														return 'no-handled';
													}}
													handlePastedText={val => {
														const textLength = editorState
															.getCurrentContent()
															.getPlainText().length;
														return val.length + textLength >= lonMax;
													}}
													//Menu personalizado, que opciones apareceran...
													toolbar={{
														options: [
															'fontSize',
															'inline',
															'textAlign',
															'colorPicker',
															'list',
														],
														fontSize: { className: 'btnsMenuMessage' },
														inline: {
															options: ['bold', 'italic', 'underline'],
															bold: {
																icon: bold,
																className: 'btnsMenuMessage',
															},
															italic: {
																icon: italic,
																className: 'btnsMenuMessage',
															},
															underline: {
																icon: underline,
																className: 'btnsMenuMessage',
															},
														},
														textAlign: {
															options: ['left', 'center', 'right'],
															left: {
																icon: left,
																className: 'btnsMenuMessage',
															},
															center: {
																icon: center,
																className: 'btnsMenuMessage',
															},
															right: {
																icon: right,
																className: 'btnsMenuMessage',
															},
														},
														colorPicker: {
															icon: color,
															className: 'btnsMenuMessage',
														},
														list: {
															unordered: {
																icon: unordered,
																className: 'btnsMenuMessage',
															},
															ordered: {
																icon: ordered,
																className: 'btnsMenuMessage',
															},
															indent: {
																icon: indent,
																className: 'btnsMenuMessage',
															},
															outdent: {
																icon: outdent,
																className: 'btnsMenuMessage',
															},
														},
													}}
												/>
												<div
													style={{
														position: 'absolute',
														width: '100%',
														display: 'block',
													}}
												>
													<div style={{ width: '20%', marginTop: '15px' }}>
														<BorderLinearProgress
															variant='determinate'
															value={(suma * 100) / lonMax}
														/>
														<p
															style={{
																color: '#E55200',
																fontSize: '11px',
																marginTop: '1px',
																minWidth: '300px',
															}}
														>
															{suma} / {lonMax}
														</p>
													</div>
													<p
														onClick={() => dispatch(modalMessage(true))}
														className={classes.pBlue}
													>
														<u>Agregar un mensaje privado</u>
													</p>
												</div>
											</div>
										</div>
										{modalMessageStatus == true ? (
											<ModalPrivateMessage
												firmantes={listaFirmantes}
												modificarDatos={modificarDatos}
												setModificarDatos={setModificarDatos}
											/>
										) : null}
										{modalSendMessageStatus == true ? (
											<ModalSendDocument relacion={relacion} />
										) : null}
										{modalSignersInvitationStatus == true ? (
											<ModalSignersInvitation />
										) : null}
										{loading && <Loading />}
									</Grid>
									<Grid item xs={12} sm={4}>
										<h3 className={classes.resumen}>Resumen</h3>

										<Box>
											<button
												type='button'
												style={{ marginTop: '12px' }}
												className={`accordion2 ${active}`}
												onClick={() => setShow(!show)}
											>
												Documentos Adjuntos
											</button>
											{show ? (
												<div style={{ width: '100%', overflowX: 'auto' }}>
													{dataDocs.length > 1 ? (
														<>
															<div className={classes.nameDocuments}>
																<img
																	src={folder}
																	style={{ width: '18px', height: '16px' }}
																/>
																{editar ? (
																	<input
																		style={{
																			fontSize: '16px',
																			margin: '0px',
																			backgroundColor: 'transparent',
																			border: 'none',
																			width: '80%',
																		}}
																		minLength={5}
																		maxLength={50}
																		onKeyDown={e =>
																			preventSpaceWhenEmpty(inputValue, e)
																		}
																		value={inputValue}
																		onChange={changeNameFolder}
																		onBlur={() => editFolderName()}
																	></input>
																) : (
																	<input
																		style={{
																			fontSize: '16px',
																			margin: '0px',
																			backgroundColor: 'transparent',
																			width: '80%',
																			border: 'none',
																			fontWeight: 'bold',
																		}}
																		value={inputValue}
																		disabled
																	></input>
																)}
																<img
																	onClick={() => setEditar(!editar)}
																	src={lapiz}
																	style={{
																		width: '15px',
																		height: '15px',
																		cursor: 'pointer',
																	}}
																	alt='Editar'
																/>
															</div>
															<ul className='ulCF'>
																{dataDocs?.map(doc => (
																	<li className='listDocumentsCF'>
																		{doc.fileName}
																	</li>
																))}
															</ul>
														</>
													) : (
														<>
															<div
																style={{
																	width: '100%',
																	minWidth: '100px',
																	overflow: 'hidden',
																}}
															>
																<small
																	style={{ fontSize: '12px', color: '#4F4F4F' }}
																>
																	Archivo Adjunto:{' '}
																</small>
																<div
																	style={{
																		maxWidth: '100%',
																		overflowX: 'auto',
																		padding: '0',
																	}}
																>
																	<p
																		className={classes.parrafo}
																		style={{
																			fontWeight: '600',
																			fontSize: '12px',
																			color: '#4F4F4F',
																			width: '100%',
																			marginBottom: '25px',
																			maxHeight: '50px',
																			overflow: 'auto',
																			margin: '0',
																		}}
																	>
																		{dataDocs[0]?.fileName}
																	</p>
																</div>
															</div>
														</>
													)}
												</div>
											) : null}
											<br />

											<button
												type='button'
												className={`accordion2 ${active2}`}
												onClick={() => setShow2(!show2)}
											>
												Firmantes
											</button>
											{show2 ? (
												<div
													style={{
														display: 'flex',
														alignItems: 'flex-start',
														justifyContent: 'initial',
														flexDirection: 'column',
														width: '100%',
														overflowX: 'auto',
													}}
												>
													{listaFirmantes.map((item, index) => (
														<div
															key={index}
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																width: '100%',
																alignItems: 'start',
															}}
														>
															<div
																title={`${item.Nombres} ${item.Apellidos}`}
																key={index}
																style={{
																	display: 'flex',
																	alignItems: 'flex-start',
																	justifyContent: 'initial',
																	marginBottom: '6px',
																	marginLeft: '10px',
																	cursor: 'move',
																	alignItems: 'center',
																	width: '100%',
																}}
																draggable
																onDragStart={e => (dragItem.current = index)}
																onDragEnter={e =>
																	(dragOverItem.current = index)
																}
																onDragEnd={handleSort}
																onDragOver={e => e.preventDefault()}
															>
																<div>
																	<div
																		style={{
																			fontSize: '14px',
																			fontFamily: 'Mulish',
																			width: '24px',
																			height: '24px',
																			borderRadius: '50%',
																			backgroundColor: '#DEDEDE',
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'center',
																		}}
																	>
																		{index + 1}
																	</div>
																</div>
																<div className={classes.list}>
																	<p
																		style={{
																			margin: '0 0 0 10px',
																			fontSize: '13px',
																			overflow: 'hidden',
																			textOverflow: 'ellipsis',
																		}}
																	>
																		<b>
																			{truncarNombreCompleto(
																				item.Nombres,
																				item.Apellidos
																			)}
																			{item.esAprobador === 1 && (
																				<Tooltip title='Aprobador' arrow>
																					<CheckCircleIcon
																						style={{
																							color: 'primary',
																							marginLeft: '8px',
																							fontSize: '1rem',
																						}} // Ajusta el tamaño a 1rem
																					/>
																				</Tooltip>
																			)}
																			{item.esObservador === 1 && (
																				<Tooltip title='Observador' arrow>
																					<VisibilityIcon
																						style={{
																							color: 'secondary',
																							marginLeft: '8px',
																							fontSize: '1rem',
																						}} // Ajusta el tamaño a 1rem
																					/>
																				</Tooltip>
																			)}
																		</b>
																	</p>
																	<small className={classes.small}>
																		{item.Correo}
																	</small>
																</div>
																<div className={classes.tooltip}>
																	<div>Mensaje privado</div>
																	{item.mensajePriv.trim().length > 0 ? (
																		<span className={classes.tooltipContent}>
																			<img
																				className={classes.correo}
																				src={mensajePrivado}
																			/>
																		</span>
																	) : null}
																</div>
															</div>
														</div>
													))}
												</div>
											) : null}
										</Box>
									</Grid>
									<div className={classes.btnFinalizar}>
										<Button className={classes.continue} type='submit'>
											Finalizar{' '}
											<ArrowForwardIosIcon className={classes.tamaño} />
										</Button>
									</div>
								</Grid>
							</form>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
};

export default ViewMessage;
