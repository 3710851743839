/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_HOSTNAME } from '../types/ApiConstants';
import { LOADING, CURRENT_PATH } from '../types/HomeTypes';

export const setCurrentPathAction = (path) => async(dispatch)=>{
  dispatch({
    type : CURRENT_PATH,
    payload : path ?? false
  })
}