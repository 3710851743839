import React from 'react';

import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
	resignCont: {
		display: 'flex',
		justifyContent: 'start',
		[theme.breakpoints.up('lg')]: {
			justifyContent: 'space-between',
		},
	},
	resign: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '14px',
		color: '#E55200',
	},
}));

const ViewResign = ({ index, indexRow, isCircuit, isFolder = false ,isObserver = false}) => {
	const classes = useStyles();
	return (
		<Grid container className={classes.resignCont}>
			<Grid item xs={12}>
				{indexRow === index && (
					<Grid
						container
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							color: '#E55200',
						}}
					>
						<Grid
							item
							xs={2}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Tooltip
								title={isObserver ? 'Ver' : 'Ver y firmar'}
								style={{
									width: '18px',
									height: '18px',
								}}
							>
								{isObserver ? <VisibilityIcon /> : <EditIcon />}
							</Tooltip>
						</Grid>
						<Grid item xs={10}>
							<Typography className={classes.resign}>
								{isObserver ? 'Ver' : 'Ver y firmar'}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default ViewResign;
