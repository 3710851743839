import React, { useEffect, useState } from 'react';
import {
	Modal,
	Paper,
	Button,
	Typography,
	Select,
	MenuItem,
	Grid,
	IconButton,
	makeStyles,
	TextField,
	FormControl,
	withStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Rules } from '../../../../helpers/RHFRules';
import {
	enviarOTP,
	validarOTP,
} from '../../../../redux/actions/Firmas';
import { useDispatch, useSelector } from 'react-redux';

import { LOADING } from '../../../../redux/types/SignTypes';
import { MAX_NUMBER_ATTEMPS_ELECTRONIC } from '../../../../redux/types/ApiConstants';
import { Refresh } from '@mui/icons-material';
import { certificateName } from '../../../../helpers/helpNames';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
    height: '300px',
		backgroundColor: 'white',
		boxShadow: '0px 3px 0px #00000033 !important',
		backgroundColor: ' #FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 5px 5px #00000080',
		border: ' 0.4941565692424774px solid #E3AC31',
		borderRadius: ' 10px !important',
		padding: '15px 30px 38px 34px',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px !important',
		maxWidth: '530px',
		fontSize: '16px',
		'&:focus': {
			outline: 'none',
		},
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	title: {
		color: '#E55200',
		textAlign: 'center',
		fontSize: '24px',
	},
	form: {
		width: '100%',
	},
	selectCustom: {
		width: '100%',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
		borderRadius: '15px',
	},
	continue: {
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#db4f04',
		},
	},
	validateCode: {
		font: 'normal normal 600 16px/20px Muli',
		margin: '37px 70px 14px',
	},

	btnBack: {
		color: '#E55200',
		textTransform: 'none',
		border: '1px solid #E55200',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
		marginRight: theme.spacing(2),
	},
	// select: {
	// 	'& .MuiOutlinedInput-root': {
	// 		'& fieldset': {
	// 			borderColor: 'orange', // color del borde
	// 		},
	// 		'&:hover fieldset': {
	// 			borderColor: 'yellow', // color del borde al pasar el mouse
	// 		},
	// 		'&.Mui-focused fieldset': {
	// 			borderColor: 'green', // color del borde al enfocarse
	// 		},
	// 	},
	// },

	// Estilos para el Input
	validateLink: {
		font: 'normal normal 600 16px/20px Muli',
		color: '#E55200',
		margin: '30px 90px 22px',
		textDecoration: 'underline',
		textTransform: 'inherit',
		cursor: 'pointer',
	},
	input: {
		width: '100%',

		'& .MuiInputBase-root': {
			color: '#010101',
			'& fieldset': {
				borderColor: '#9f9f9f !important',
				borderRadius: '13px !important',
			},
			'&:hover fieldset': {
				borderColor: '#9f9f9f !important',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#9f9f9f !important',
			},
		},
	},

	// Estilos para el botón
	button: {
		backgroundColor: '#E55200', // color de fondo del botón
		color: '#FFFFFF', // color del texto del botón
		'&:hover': {
			backgroundColor: '#db4f04', // color de fondo al pasar el mouse
		},
	},
}));

const TextFieldVerify = withStyles({
	root: {
		'& input:valid + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& input:valid:focus + fieldset': {
			boxShadow: '0px 3px 0px #00000033 !important',
			border: '0.8387856483459473px solid #706F6F !important',
			borderRadius: '10px !important',
			padding: '5px !important',
		},
		'& label.Mui-focused': {
			left: '-45px !important',
			marginTop: '-2px',
			font: 'normal normal normal 14px/22px Muli',
			color: '#707070',
		},
		'& label': {
			textAlign: 'center !important',
			width: '90%',
			marginTop: '-9px',
			font: 'normal normal normal 18px/22px Muli',
			color: '#B2B2B2',
		},
		opacity: 0.7,
	},
})(TextField);

const ModalFirmaMasiva = ({
	isOpen,
	onClose,
	onConfirm,
	datacer,
	onIdSelected,
	firmaTipo,
	setFirmaTipo,
	showElectroContent,
	setShowElectroContent,
	setAttemps,
	attemps,
	setLoadingProgress,
	bandera,
	setBandera,
	setValueProgress,
	valueProgress,
}) => {
	const classes = useStyles();

	const [claveCertificado, setClaveCertificado] = useState('');
	const [pruebaInput, setPruebaInput] = useState('');
	const token = localStorage.getItem('JWT');
	const dispatch = useDispatch();
	const initialId = datacer.length >= 1 ? datacer[0].id : null;
	const [selectedId, setSelectedId] = useState('');
	const [selectedItemId, setSelectedItemId] = useState(initialId);
	const dataArray = Array.isArray(datacer) ? datacer : [datacer];
	const { enqueueSnackbar } = useSnackbar();

	const email = useSelector(({ auth }) => auth.userInfo.email);
	useEffect(() => {
		if (datacer === false) {
			setShowElectroContent(true);
			setFirmaTipo(1);
		}
		if (datacer === false && bandera === false) {
			enviarOTP(token, email, enqueueSnackbar);
		}
	}, []);

	const handleSend = async () => {
		dispatch({ type: LOADING, payload: true });
		
		setBandera(true);
		await enviarOTP(token, email, enqueueSnackbar);
		setFirmaTipo(1);
		// setLoadingProgress(false);
		dispatch({ type: LOADING, payload: false });


		setShowElectroContent(true);
	};

	const ocultarEmail = email => {
		const atSignIndex = email.indexOf('@');
		if (atSignIndex < 2) {
			return email;
		}
		const visiblePart = email.substring(atSignIndex - 2);
		return '*'.repeat(atSignIndex - 2) + visiblePart;
	};

	const handleEmail = () => {
		setFirmaTipo(2);
	};

	const handleChange = async num => {
		if (num === 1) {
			 dispatch({ type: LOADING, payload: true });

			// setLoading(true);

			setFirmaTipo(1);
			await enviarOTP(token, email, enqueueSnackbar);

			// setLoading(false);
			 dispatch({ type: LOADING, payload: false });

			setShowElectroContent(true);
		}
	};

	const handleClaveCertificadoChange = event => {
		setClaveCertificado(event.target.value);
	};

	const validacionData = async (token, pruebaInput, enqueueSnackbar) => {
		setBandera(true);
		if (!pruebaInput) {
			enqueueSnackbar('Debe ingresar el codigo OTP', {
				variant: 'warning',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		let data;
		if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
			enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
			setAttemps(0);
			setFirmaTipo(0);
			setShowElectroContent(false);
			setBandera(false);
			onClose();
			return;
		}
		if (pruebaInput !== '') {
					  setValueProgress(60);
					setLoadingProgress(true);
			data = await validarOTP(token, pruebaInput, enqueueSnackbar);
			if (data) {
				if (data.httpStatus === 200) {
					const response = await onIdSelected(
						data.result.certificateId,
						data.result.codeId,
						true,
						data.result.password
					);
					if (response) {
					  setValueProgress(100);

						onClose();
						setFirmaTipo(0);
						window.location.reload();
					} else if (!response) {
						onClose();
						setFirmaTipo(0);
					}
				}
			}else{
				setValueProgress(0)
				setLoadingProgress(false)
			}
			setAttemps(attemps + 1);
		}
	};

	//  useEffect(() => {
	// 		if (!dataArray || dataArray.length < 1) {
	// 			setShowElectroContent(true);
	// 		} else {
	// 			setShowElectroContent(false);
	// 		}
	// 	}, []);

	const handleChangeId = event => {
		setSelectedId(event.target.value); // Manejador para el cambio en el select "ID"
	};
	const { handleSubmit, errors, control } = useForm();

	const handlePruebaInputChange = event => {
		setPruebaInput(event.target.value);
	};

	const handleConfirm = async () => {
		setLoadingProgress(true)
		if (firmaTipo === 0) {
			if (!claveCertificado) {
				enqueueSnackbar('Debe ingresar la clave del certificado', {
					variant: 'warning',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});

				return;
			}
			setValueProgress(25)
			if (onConfirm) {
				onConfirm(); // Función de confirmación
			}
			if (onIdSelected) {
				onIdSelected(selectedItemId, claveCertificado, false, false); // Llama al callback con el ID seleccionado
			}
			onClose(); // Cierra el modal // Sale de la función para evitar que se cierre el modal o se llamen otros callbacks
		}

		if (firmaTipo === 1) {
			let attemps = 0;
			let data;
			if (attemps + 1 > MAX_NUMBER_ATTEMPS_ELECTRONIC) {
				enqueueSnackbar('Superó el límite de intentos, intente más tarde', {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				setAttemps(0);

				return;
			}
			if (pruebaInput !== '') {
				setValueProgress(40)
				let response = await validarOTP(token, pruebaInput, enqueueSnackbar);
				if (response) {
					if (response.httpStatus === 200) {
						await onIdSelected(
							data.result.certificateId,
							data.result.codeId,
							true,
							data.result.password
						);
						setValueProgress(100)
						onClose();
					}
				}
				attemps = attemps + 1;
			}
			setShowElectroContent(true);
		}
	};

	const handleSelectChange = event => {
		setSelectedItemId(event.target.value);
	};
	return (
		<Modal
			open={isOpen}
			onClose={() => onClose(false)}
			aria-labelledby='modal-titulo'
			aria-describedby='modal-descripcion'
		>
			{firmaTipo === 1 ? (
				<Paper
					id='paperElectro1'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='flex-end'
					>
						<IconButton
							edge='start'
							aria-label='upload file'
							onClick={() => {
								setFirmaTipo(0);
								setShowElectroContent(false);
								onClose(false);
							}}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={classes.textModalElectro}>
									Hemos enviado un código a <br></br>
									<span style={{ fontWeight: 'bold' }}>
										{ocultarEmail(email)}
									</span>
									, <br></br>
									por favor ingrésalo a continuacion (puede haber
									<br></br>llegado a tu correo no deseado).
								</Typography>
								<Controller
									as={
										<TextFieldVerify
											name='codeValidate'
											label={'Código de verificación'}
											variant='outlined'
											inputProps={{ style: { textAlign: 'center' } }}
											InputProps={{
												autoComplete: 'off',
												classes: { input: classes.input },
												onChange: handlePruebaInputChange,
											}}
										/>
									}
									name='codeValidate'
									control={control}
									rules={Rules.required}
									className={classes.validateCode}
								/>
								<Grid
									style={{ margin: '0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										id='buttonVerify'
										// disabled={enableButton}
										style={{ width: '154px', height: '41px' }}
										onClick={() =>
											validacionData(token, pruebaInput, enqueueSnackbar)
										}
									>
										Verificar
									</Button>
								</Grid>
								<Typography
									className={classes.validateLink}
									onClick={handleEmail}
								>
									¿No has recibido el email?
								</Typography>
								<Typography className={classes.textModalElectro}>
									Debes tener en cuenta que un certificado <br></br> electrónico
									no tiene validez jurídica.
								</Typography>
							</FormControl>
						</form>
					</div>
				</Paper>
			) : firmaTipo === 0 ? (
				<div>
					<Paper
						id='paperElectro1'
						elevation={3}
						className={classes.modal}
						style={{ minHeight: '352px', width: '362px' }}
					>
						<Grid
							container
							direction='row'
							className={classes.grid}
							justify='flex-end'
						>
							<IconButton
								edge='end'
								aria-label='upload file'
								onClick={() => onClose(false)}
								className={'btn-close-sign-modal'}
							></IconButton>
						</Grid>
						<Typography className={'titlePopUpCertificate'}>
							Certificado
						</Typography>
						<form className={classes.form}>
							<Select
								value={firmaTipo}
								onChange={handleChange}
								displayEmpty
								style={{ width: '100%' }}
								className={`${classes.input} selectCustom`}
							>
								<MenuItem
									value={0}
									className={'opionCustom'}
									onClick={() => handleChange(0)}
								>
									Centralizado
								</MenuItem>

								<MenuItem
									value={1}
									className={'opionCustom'}
									onClick={() => handleChange(1)}
								>
									Firma Electrónica
								</MenuItem>
							</Select>

							<Select
								value={selectedItemId}
								onChange={handleSelectChange}
								displayEmpty
								style={{ width: '100%' }}
								className={`${classes.input} selectCustom`}
							>
								{/* <MenuItem
									value={dataArray[0].descripcion}
									disabled
									className={'opionCustom'}
								>
									{dataArray[0].descripcion}
								</MenuItem> */}
								{dataArray &&
									dataArray.map(item => (
										<MenuItem
											key={item.id}
											value={item.id}
											className={'opionCustom'}
										>
											<span>
												{certificateName(item.identificador, item.descripcion)}
											</span>{' '}
											<span style={{ marginLeft: '10px' }}>({item.id})</span>
										</MenuItem>
									))}
							</Select>

							{firmaTipo === 0 && (
								<TextField
									name='password'
									placeholder='Contraseña Certificado'
									type='password'
									fullWidth
									value={claveCertificado}
									onChange={handleClaveCertificadoChange}
									margin='normal'
									padding
									FormHelperTextProps={{
										style: { margin: '0 0 0 5px', color: 'red' },
									}}
									className={`${classes.input} selectCustom`}
								/>
							)}
							<div style={{ textAlign: 'center', marginTop: '20px' }}>
								<Button
									className={'btnGeneral18'}
									onClick={handleConfirm}
									style={{ width: '154px', height: '41px' }}
								>
									Aceptar
								</Button>
							</div>
						</form>
					</Paper>
				</div>
			) : (
				<Paper
					id='paperElectro2'
					elevation={3}
					className='sign-paper-source-signature'
				>
					<Grid
						container
						direction='row'
						className={classes.grid}
						justify='space-between'
					>
						<IconButton
							edge='start'
							id='forwardCode'
							onClick={() => {
								setFirmaTipo(1);
							}}
							className={'btn-forward-sign-modal'}
						></IconButton>
						<IconButton
							edge='start'
							aria-label='closeModal'
							onClick={() => {
								setFirmaTipo(0);
								onClose(false);
							}}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<div>
						<form>
							<FormControl
								autoComplete='off'
								noValidate
								className={classes.form}
							>
								<Typography className={'titlePopUpForwardMail'}>
									¡Lo haremos de nuevo!
								</Typography>
								<Typography className={classes.textModalElectro2}>
									Reenviaremos a
									<span style={{ fontWeight: 'bold' }}>
										{ocultarEmail(email)}
									</span>
									<br></br> un nuevo código de verificación.
								</Typography>
								<Grid
									style={{ margin: '29px 0px 0px 0px' }}
									container
									justify='center'
								>
									<Button
										className={'btnGeneral18'}
										onClick={() => {
											handleSend();
										}}
										style={{ width: '154px', height: '41px' }}
									>
										Aceptar
									</Button>
								</Grid>
							</FormControl>
						</form>
					</div>
				</Paper>
			)}
		</Modal>
	);
};

export default ModalFirmaMasiva;
