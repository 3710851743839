import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';
import { Paper, Button, Container, Grid, Modal } from '@material-ui/core';
import ZoneUploadDecrypt from '../../components/ZoneUploadDecrypt';
import { getInfoValidateAction } from '../../redux/actions/ValidateDocumentAction';
import ModalDecrypt from './ModalDecrypt';
import ModalCorrect from './ModalCorrect';
 
const Decrypt = (props) => {
	const [statusModal, setStatusModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isResponsive, setResponsive] = useState(window.innerWidth <= 600 ? false : true);
	const [files, setFiles] = useState([]);
	const [statusModalCorrect, setStatusModalCorrect] = useState(false);

	const onClose = () => {
		setStatusModal(false);
	}
	
	window.addEventListener('resize', function (event) {
		if (this.window.innerWidth <= 600) setResponsive(false);
		else setResponsive(true);
	}, true);
	
	const decrypt = () => {
		setStatusModal(true);
	}

	const onSuccess = () => {
		setFiles([]);
		setStatusModalCorrect(true);
		setStatusModal(false);
	}
	
	useEffect(() => {
		document.body.className = 'bodyMiddle';
	});

	document.body.className = 'bodyMiddle';

	return (
		<>	{loading && <Loading />}
			<Grid container spacing={0} direction="row">
				<Container component="main" maxWidth="lg">
					<h1 className={'title-background'}>Descifrar archivo</h1>
				</Container>
				<Container component="main">
                    <Grid
						container
						spacing={0}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className="zone-upload-container"
						maxWidth="md"
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Paper elevation={0} id={ isResponsive && 'paper-upload-result' } className="paper-upload" >
								<>
                                    <ZoneUploadDecrypt
					                    key={1}
                                        isDecrypt={true}
					                    setFiles={setFiles}
					                    files={files}
					                    sizeFiles={10}
					                    className={'contentZoneUpload'}
										setLoading={setLoading}
					                    isResponsive={props.isResponsive}
				                    />
									<div id="box-botonera" style={{ marginTop: '28px', marginBottom:"16px" }}>
                                        <Grid
                                            container
                                            spacing={3}
                                            direction="row"
                                            justify="flex-end"
                                        >
                                            <Grid item>
                                                <Button
					                        	    className={'buttonRecipient'}
													id="buttonRecipientBlock"
													disabled={files.length < 1}
													onClick={decrypt}
                                                    style={{ width: '139px' }}
					                            >
					                        	    Descifrar
                 	                            </Button>
                                            </Grid>
                                        </Grid>
									</div>
								</>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{
				statusModal == true && (
					<ModalDecrypt
						isOpen={statusModal}
						files={files}
						setFiles={setFiles}
						onClose={onClose}
						onSuccess={onSuccess}
						setStatusModal={setStatusModal}
					/>
				)
			}
			<Modal
				open={statusModalCorrect}
				onClose={() => {
					setStatusModalCorrect(false);
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				disableBackdropClick
			>
				<div>
					<ModalCorrect isDecrypt={true} setStatusModalCorrect={setStatusModalCorrect}/>
				</div>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	validate: state.validate,
});

const mapDispatchToProps = {
	getInfoValidate: getInfoValidateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Decrypt);