import React, { useMemo, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { kbToMb } from '../helpers/FilesFunction';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import iconSheet from '../assets/img/IconSheet.png';
import iconPc from '../assets/img/IconPc.png';
import dumpIcon from '../assets/img/dumpIcon.png';
import GoogleDriveExplorer from '../views/ConsultSignedDocument/GetFileGoogleDrive';
import OneDriveExplorer from '../views/ConsultSignedDocument/GetFileOneDrive';
import DropboxExplorer from '../views/ConsultSignedDocument/GetFileDropbox';
import Tooltip from '@material-ui/core/Tooltip';
import {
	MAX_MEGAS_FILES_SIZE,
	MAX_FILE_UPLOAD,
	ACCEPT_FILES,
	MAX_MEGAS_FILE_SIZE,
} from '../redux/types/ApiConstants';
import Loading from '../components/Loading';
import { filesCf } from '../redux/actions/CircuitoFirmasAction';
import { useDispatch } from 'react-redux';

const responsiveStyle = {
	minHeight: '146px',
};

const activeStyle = {
	borderColor: '#E55200',
	fontSize: 22,
	color: '#E55200',
};

const acceptStyle = {
	borderColor: '#00e676',
	backgroundColor: '#F0F0F0',
	border: 'solid',
	backgroundImage:
		'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
};

const rejectStyle = {
	border: 'solid',
	backgroundImage:
		'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
	animation: 'progress 2s linear infinite !important',
	backgroundSize: '150% 100%',
	color: '#fc8785',
};

const stylesDrop = makeStyles({
	dropzoneTextStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#EDEDED',
		},
	},
	buttons: {
		color: '#fff',
		textShadow: '0 1px 1px #ccc',
		textTransform: 'none',
		width: 150,
	},
	listContainer: {
		width: '100%',
		marginBottom: '5px',
		display: 'flex',
		justifyContent: 'center',
	},
	loader: {
		width: '100%',
		margin: '0 auto',
	},
});

const ZoneUpload = props => {
	const { isDashboard, accept, multiple, files, setFiles, maxSize, sizeFiles } =
		props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [isResponsive, setResponsive] = useState(
		window.innerWidth <= 600 ? false : true
	);
	const [isLoading, setLoading] = useState(false);
	const [statusModalDropbox, setStatusModalDropbox] = useState(false);
	const [statusModalCloudSelect, setStatusModalCloudSelect] = useState(true);

	const dispatch = useDispatch()

	window.addEventListener(
		'resize',
		function (event) {
			if (this.window.innerWidth <= 600) setResponsive(false);
			else setResponsive(true);
		},
		true
	);

	const handleClose = () => {
		setLoading(false);
		setStatusModalCloudSelect(false);
	};
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setStatusModalCloudSelect(true);
	};

	const openZoneDrop = event => {
		document.getElementById('dFZoneUpload').click();
		handleClose();
	};

	const { enqueueSnackbar } = useSnackbar();

	const onDrop = async dropedFiles => {
		setStatusModalCloudSelect(false);
		const fileNumber = files.length + dropedFiles.length;
		for (let i = 0; i < dropedFiles.length; i++) {
			const element = dropedFiles[i];
			element.sizeBytes = element.size;
		}
		var numFiles = MAX_FILE_UPLOAD;
		if (fileNumber > numFiles) {
			enqueueSnackbar(
				`La cantidad de archivos supera el máximo permitido de ${numFiles} documentos.`,
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
			handleClose();
			dropedFiles.splice(0, dropedFiles.length);
		}
		let listValid = await isValid(dropedFiles);
		setLoading(true);
		if (listValid.length > 0) {
			let listSizeFile = await validateSizeFile(listValid);
			if (listSizeFile.length > 0) {
				let listAdd = await validateSizeFiles(listSizeFile);
				if (listAdd.length > 0) {
					let listExist = await exists(listAdd);
					if (listExist.length > 0) {
						let dropedSize = 0;
						await getPageNumber(listExist);
						handleClose();
					} else {
						handleClose();
					}
				} else {
					handleClose();
				}
			} else {
				handleClose();
			}
		} else {
			handleClose();
		}
	};

	async function validateSizeFiles(dropedFiles) {
		let dropedSize = sizeFiles;
		let maxFilesSizeMb = Math.round((MAX_MEGAS_FILES_SIZE * 1000000) / 1000);
		for (let i = 0; i < dropedFiles.length; i++) {
			let sizekiloByte = Math.round(dropedFiles[i].size / 1024);
			dropedSize += sizekiloByte;
		}
		if (dropedSize > maxFilesSizeMb) {
			enqueueSnackbar(
				`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(
					MAX_MEGAS_FILES_SIZE * 1000000
				)}`,
				{
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}
			);
			return [];
		} else {
			return dropedFiles;
		}
	}

	async function validateSizeFile(dropedFiles) {
		let newFiles = [];
		for (let i = 0; i < dropedFiles.length; i++) {
			var fileSizeMb = Math.round(dropedFiles[i].size / 1024);
			var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1000);
			if (fileSizeMb > maxSizeMb) {
				handleClose();
				enqueueSnackbar(
					`El tamaño del archivo ${cutNameTitle(
						dropedFiles[i].name
					)} supera el máximo permitido.\nTamaño limite de ${kbToMb(
						MAX_MEGAS_FILE_SIZE * 1000000
					)}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				newFiles.push(dropedFiles[i]);
			}
		}
		return newFiles;
	}

	async function isValid(dropedFiles) {
		let newFiles = [];
		dropedFiles.map(file => {
			var extension = '.' + file.name.split('.').pop();
			var isAccept = ACCEPT_FILES.find(
				element => element.toLowerCase() == extension.toLowerCase()
			);
			if (!isAccept) {
				enqueueSnackbar(
					`El archivo ${cutNameTitle(
						file.name
					)} no es permitido, los tipos de archivos permitidos son: ${ACCEPT_FILES.filter(
						e => e.length < 5
					)
						.toString()
						.replaceAll(',', ' ')}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else if (file.name.length > 200) {
				enqueueSnackbar(
					`El archivo ${cutNameTitle(file.name)} tiene un nombre muy largo`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				newFiles.push(file);
			}
		});
		return newFiles;
	}

	async function exists(dropedFiles) {
		let newFiles = [];
		dropedFiles.map(file => {
			var results = files.filter(function (doc) {
				return doc.name == file.name;
			});
			var existe = results.length > 0 ? results[0] : null;
			if (existe != null) {
				enqueueSnackbar(
					`El archivo ${cutNameTitle(
						file.name
					)} se encuentra actualmente agregado`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					}
				);
			} else {
				newFiles.push(file);
			}
		});
		return newFiles;
	}

	const onDelete = fileToDelete => {
		setFiles(files.filter(file => file.name !== fileToDelete));
		var name =
			fileToDelete.length > 29
				? fileToDelete.substring(0, 29) + '...'
				: fileToDelete;
		enqueueSnackbar(`Archivo ${cutNameTitle(name)} eliminado.`, {
			variant: 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
		});
		dispatch(filesCf(files.filter(file => file.name !== fileToDelete)))
	};

	const cutNameTitle = name => {
		if (name != null && name.length > (isResponsive ? 70 : 10))
			return name.substring(0, isResponsive ? 70 : 10).concat('...');
		else return name;
	};

	const cutName = name =>
		name != null && name.length > (isResponsive ? 70 : 39) ? (
			<Tooltip title={name}>
				<div>{name.substring(0, isResponsive ? 70 : 39).concat('...')}</div>
			</Tooltip>
		) : (
			<div>{name}</div>
		);

	const readFile = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			reader.readAsArrayBuffer(file);
		});
	};

	async function getPageNumber(pFiles) {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {
				if ('.' + pFile.name.split('.').pop() == '.pdf') {
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile);
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${cutNameTitle(
								pFile.name
							)} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							}
						);
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
		if (validFiles.length > 0) {
			enqueueSnackbar('Archivos agregados exitosamente.', {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		await setFiles([...files, ...validFiles]);
	}

	const closeMenuFileSource = () => {
		setStatusModalCloudSelect(false);
	};

	const classes = stylesDrop();
	const {
		getRootProps,
		getInputProps,
		open,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept,
		multiple,
		maxSize,
		onDrop,
		noClick: true,
		noKeyboard: true,
	});
	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragAccept, isDragReject]
	);

	const StyledMenu = withStyles({
		paper: {
			border: '1.25px solid #9D9D9C',
			width: '182px',
		},
	})(props => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		/>
	));

	return (
		<div className='container'>
			{isLoading == true && <Loading />}
			<div
				{...getRootProps({ style })}
				id={
					!isDashboard && isResponsive && files.length < 1
						? 'base-container'
						: ''
				}
				className='base-style-container'
			>
				<input
					{...getInputProps()}
					id='dFZoneUpload'
					onClick={event => {
						event.target.value = null;
					}}
				/>
				<>
					<div className={classes.listContainer}>
						{files.length > 0 && (
							<List dense style={{ width: '100%' }}>
								<div>
									{files.map((element, index) => (
										<ListItem key={index} className='contentZoneUploadList'>
											<ListItemText
												primaryTypographyProps={{
													style: { fontWeight: 'bold', color: '#000000' },
												}}
												secondaryTypographyProps={{
													style: { fontWeight: 'bold', color: '#575756' },
												}}
												primary={cutName(element.name)}
												secondary={
													element.numPages < 1
														? `Tamaño: ${Math.round(
																element.sizeBytes / 1024
														  )} KB`
														: `${element.numPages} página(s)`
												}
											/>
											<ListItemSecondaryAction>
												<IconButton
													edge='end'
													aria-label='delete'
													onClick={() => onDelete(element.name)}
												>
													<img src={dumpIcon} width='15px' />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</div>
							</List>
						)}
					</div>
				</>
				{isDragActive ? (
					<p>Suelte los archivos</p>
				) : (
					<div className={classes.dropzoneTextStyle}>
						<img
							src={iconSheet}
							className='icon-upload'
							style={{ marginTop: files.length === 0 ? '0px ' : '20px' }}
						/>
						<div style={{ marginTop: '17px' }} className='textZoneUpload'>
							Arrastre aquí un documento
							<br />ó
						</div>
						<Button
							disableElevation
							className={'btnGeneralNew'}
							onClick={handleClick}
							endIcon={<ArrowDropDownIcon />}
						>
							Cargar archivo
						</Button>
						{!isDashboard && (
							<div
								id={files.length > 0 ? 'rangeText2' : ''}
								className='rangeText'
							>
								El maximo de peso por archivo es de {MAX_MEGAS_FILE_SIZE}Mb y el
								máximo total en documentos hasta {MAX_MEGAS_FILES_SIZE}Mb;{' '}
								{ACCEPT_FILES.length > 1
									? ' los extensiones permitidas son: ' +
									  ACCEPT_FILES.toString().replaceAll(',', ' ')
									: ' la extensión permitida es ' + ACCEPT_FILES}{' '}
							</div>
						)}
						<StyledMenu
							className={'menuSourceFile'}
							id='simple-menu'
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl) && statusModalCloudSelect}
							onClose={handleClose}
						>
							<MenuItem dense={true} onClick={openZoneDrop}>
								<img src={iconPc} width='25px' className='buttonPC' /> Mi
								ordenador
							</MenuItem>
							<MenuItem dense={true} onClick={closeMenuFileSource}>
								{
									<GoogleDriveExplorer
										disableBackdropClick
										setStatusModalCloudSelect={setStatusModalCloudSelect}
										files={files}
										setFiles={setFiles}
										setLoading={setLoading}
										sizeFiles={sizeFiles}
									></GoogleDriveExplorer>
								}
							</MenuItem>
							<MenuItem dense={true}>
								<OneDriveExplorer
									setStatusModalCloudSelect={setStatusModalCloudSelect}
									files={files}
									setFiles={setFiles}
									setLoading={setLoading}
									sizeFiles={sizeFiles}
								></OneDriveExplorer>
							</MenuItem>
							<MenuItem dense={true} onClick={closeMenuFileSource}>
								<DropboxExplorer
									open={statusModalDropbox}
									onClose={() => setStatusModalDropbox(false)}
									setStatusModalDropbox={setStatusModalDropbox}
									setStatusModalCloudSelect={setStatusModalCloudSelect}
									files={files}
									setFiles={setFiles}
									setLoading={setLoading}
									sizeFiles={sizeFiles}
								></DropboxExplorer>
							</MenuItem>
						</StyledMenu>
					</div>
				)}
			</div>
		</div>
	);
};

export default ZoneUpload;
