import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Captcha from './captcha';
import PanelSucess from './buttomPanelSuccesfull';
import Preloader from './preloader';
import '../../assets/styles/styleValidateOTP.css';
import Header from './Header';
import {
	Grid,
	Button,
	makeStyles,
	Card,
	CardContent,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Collapse,
	Box,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Función para formatear la fecha y la hora
function formatDateTime(dateTimeString) {
	const options = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	};
	return new Date(dateTimeString).toLocaleString('es-ES', options);
}

const useStyles = makeStyles(theme => ({
	tableContainer: {
		maxWidth: '100%',
	},
	table: {
		minWidth: 650,
	},
	tableHeader: {
		backgroundColor: '#f5ae19',
		color: 'white',
		fontWeight: 'bold',
	},
	tableHeadera: {
		backgroundColor: '#e55200',
		color: 'white',
		fontWeight: 'bold',
	},
	tableRow: {
		'&:hover': {
			backgroundColor: '#f0f0f0',
		},
	},
	tableCell: {
		padding: '8px',
	},
	accordionContent: {
		padding: '16px',
	},
	divContentText: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: { flexDirection: 'row' },
	},
}));

const HeaderContent = props => {
	const classes = useStyles();
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [info, setInfo] = useState(null);
	const [openedUserIndex, setOpenedUserIndex] = useState(null);
	const { code } = useParams();

	const handleDetailClick = index => {
		// Si se hace clic en el mismo usuario cuyo detalle ya está abierto, ciérralo
		if (openedUserIndex === index) {
			setOpenedUserIndex(null);
		} else {
			// Cierra cualquier detalle abierto antes de abrir el nuevo detalle
			setOpenedUserIndex(index);
		}
	};

	useEffect(() => {
		// Verificar si solo hay un usuario en la información
		const isSingleUser = info && info.length === 1;

		// Establecer openedUserIndex en 0 si es un solo usuario
		if (isSingleUser) {
			setOpenedUserIndex(0);
		}
	}, [info]);

	return (
		<div>
			<Header></Header>
			<div className='mainTitle'></div>
			<div className='textTitle'>Validador de documentos</div>
			<div className='contentViewHeader'>
				<div className='contentHeadText'>
					<Grid container spacing={4} className='contentHeadTexts'>
						<Grid item xs={6}>
							<div id='contentText' className='divContentText'>
								El validador documental permite verificar la autenticidad de los
								documentos firmados electrónicamente por FirmaYa. Para realizar
								la comprobación, introduzca los datos necesarios en el cuadro de
								texto según se muestre.
							</div>
						</Grid>
						<Grid item xs={6}>
							<Captcha
								file={file}
								setFile={setFile}
								setInfo={setInfo}
								setFileName={setFileName}
								code={code}
							></Captcha>
							<Preloader></Preloader>
							<PanelSucess
								file={file}
								fileName={fileName}
								setFile={setFile}
								setFileName={setFileName}
							></PanelSucess>
						</Grid>
					</Grid>
				</div>
				<p></p>
				{info && (
					<TableContainer component={Paper} className={classes.tableContainer}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeader} />
									<TableCell className={classes.tableHeader}>Nombres</TableCell>
									<TableCell className={classes.tableHeader}>
										Apellidos
									</TableCell>
									<TableCell className={classes.tableHeader}>Correo</TableCell>
									<TableCell className={classes.tableHeader}>
										Tipo de documento
									</TableCell>
									<TableCell className={classes.tableHeader}>
										No. identificación
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{info.map((item, index) => (
									<React.Fragment key={index}>
										<TableRow className={classes.tableRow}>
											<TableCell className={classes.tableCell}>
												<IconButton
													aria-label='expand row'
													size='small'
													onClick={() => handleDetailClick(index)}
												>
													{openedUserIndex === index ? (
														<KeyboardArrowUpIcon />
													) : (
														<KeyboardArrowDownIcon />
													)}
												</IconButton>
											</TableCell>
											<TableCell className={classes.tableCell}>
												{item.nombres}
											</TableCell>
											<TableCell className={classes.tableCell}>
												{item.apellidos}
											</TableCell>
											<TableCell className={classes.tableCell}>
												{item.correo}
											</TableCell>
											<TableCell className={classes.tableCell}>
												{item.tipodocumento}
											</TableCell>
											<TableCell className={classes.tableCell}>
												{item.numeroDocumento}
											</TableCell>
										</TableRow>
										{openedUserIndex === index && (
											<TableRow>
												<TableCell colSpan={6}>
													<Collapse
														in={openedUserIndex === index}
														timeout='auto'
														unmountOnExit
													>
														<Box className={classes.accordionContent}>
															<Typography
																variant='h6'
																gutterBottom
																component='div'
															>
																Archivos
															</Typography>
															<Table size='small' aria-label='archivos'>
																<TableHead>
																	<TableRow>
																		<TableCell className={classes.tableHeadera}>
																			Nombre
																		</TableCell>
																		<TableCell className={classes.tableHeadera}>
																			Hash
																		</TableCell>
																		<TableCell className={classes.tableHeadera}>
																			Fecha
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{item.archivos.map(
																		(archivo, archivoIndex) => (
																			<TableRow key={archivoIndex}>
																				<TableCell>{archivo.nombreDocumento}</TableCell>
																				<TableCell>{archivo.hash}</TableCell>
																				<TableCell>
																					{formatDateTime(archivo.fechaFirmado)}
																				</TableCell>
																			</TableRow>
																		)
																	)}
																</TableBody>
															</Table>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										)}
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</div>
		</div>
	);
};

export default HeaderContent;
