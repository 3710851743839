import { Button, makeStyles, Modal, Paper } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import FolderIcon from '@mui/icons-material/Folder';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
	dataQr,
	firm_positions,
	modalConfirm,
	stepCircuit,
} from '../../../../redux/actions/CircuitoFirmasAction';
import { guardarEnIndexedDB } from '../../../../redux/actions/indexedDB';
import { Grid, IconButton } from '@mui/material';
import { truncarNombreCompleto } from '../../../../helpers/helpNames';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		backgroundColor: 'white',
		maxWidth: '530px',
		minWidth: '300px', // Ajusta según tus necesidades
		width: '80%', // Esto hará que el modal se ajuste al 80% del ancho de la pantalla
		backgroundColor: ' #FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 5px 5px #00000080',
		border: ' 0.4941565692424774px solid #E3AC31',
		borderRadius: ' 10px !important',
		padding: '15px 30px 38px 34px',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px !important',
		maxWidth: '530px',
		fontSize: '16px',
		'&:focus': {
			outline: 'none',
		},
		flexDirection: 'column',
	},
	color: {
		color: '#E55200',
	},
	title: {
		color: '#E55200',
		textAlign: 'center',
		fontSize: '24px',
	},
	pCenter: {
		textAlign: 'center',
		
	},
	gridContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column !important',
	},
	flx: {
		display: 'flex',
		gap: '25px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	btnBack: {
		color: '#E55200',
		textTransform: 'none',
		border: '1px solid #E55200',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
	},
	continue: {
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: '#E55200',
		color: 'white',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: '16px',
		padding: '4px 20px',
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#db4f04',
		},
	},
	closeButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},

	contentContainer: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column !important',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const ModalMasive = ({ selectedDocumentNames, onClose, onConfirm}) => {
	const classes = useStyles();
	const [modalOpen, setModalOpen] = useState(true);

	const handleClose = () => {
		setModalOpen(false);
		if (onClose) onClose();
	};

	const handleConfirm = () => {
		setModalOpen(false);
		if (onConfirm) onConfirm();
	};

	return (
		<Modal
			open={modalOpen}
			onClose={handleClose}
			disableBackdropClick
			aria-labelledby='modal-firma-masiva-title'
			aria-describedby='modal-firma-masiva-description'
		>
			<Paper className={classes.modal}>
				<Grid className={classes.gridContainer} flexDirection='column'>
					<Grid item xs={12} className={classes.closeButtonContainer}>
						<IconButton
							edge='end'
							aria-label='upload file'
							onClick={handleClose}
							className={'btn-close-sign-modal'}
						></IconButton>
					</Grid>
					<Grid item xs={12} className={classes.contentContainer}>
						<h2 className={classes.title}>
							{' '}
							<ErrorIcon style={{ color: '#e55200', marginRight: '10px' }} />
							Confirmar Firma Masiva
						</h2>
						<p className={classes.pCenter}>
							Estás a punto de firmar los siguientes circuitos:
						</p>
						<ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
							{selectedDocumentNames.map(doc => (
								<li key={doc.nombre} title={doc.nombre}>
									{doc.esCarpeta && (
										<FolderIcon
											style={{
												marginRight: '10px',
												color: '#878787',
												fontSize: '20px',
												verticalAlign: 'middle',
												marginTop: '2px',
											}}
										/>
									)}
									{truncarNombreCompleto(doc.nombre)}
								</li>
							))}
						</ul>
						<p style={{ textAlign: 'center', fontSize: '12px' }}>
							<b>
								Se firmarán todos los documentos que contenga este circuito.
							</b>
						</p>
						<br></br>
						<p className={classes.pCenter}>
							<b>¿Estás seguro de querer continuar?</b>
						</p>
						<div className={classes.flx}>
							<Button
								onClick={handleConfirm}
								style={{
									backgroundColor: '#ea5f00',
									boxShadow: '0px 3px 0px #00000033',
									borderRadius: '10px',
									opacity: 1,
									color: '#FFFFFF',
									textShadow: '0px 2px 0px #00000040',
									border: 'none',
									padding: '38px',
									paddingTop: '2px',
									paddingBottom: '2px',
									textTransform: 'initial',
									marginTop: '14px',
									fontWeight: 'bold',
									fontSize: '18px',
									width: '234px',
									height: '41px',
								}}
							>
								Aplicar Firma
							</Button>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</Modal>
	);
};

export default ModalMasive;

