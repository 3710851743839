import React, { useEffect, useState } from 'react';
import { IS_UNIQUE_ELECTRONIC } from '../redux/types/SignTypes';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setInfoToken } from '../redux/actions/auth';
import { connect } from 'react-redux';
import Home from '../views/Home';
import { isValidToken } from '../helpers/authUtils';
import { setCurrentPathAction } from '../redux/actions/HomeAction';
import LateralMenu from '../components/LateralMenu';
import { validateEstampa } from '../helpers/authUtils';
import { Grid, makeStyles } from '@material-ui/core';
import ModalInvalidSession from '../components/ModalInvalidSession';
import { invalidSesion } from '../redux/actions/CircuitoFirmasAction';
import { SPECIFIC_USERS } from '../redux/types/ApiConstants';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		justifyContent: '',
		maxWidth: '100% !important',
	},
	menuTabs: {
		position: 'relative',
		background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box',
		backgroundColor: '#FFFFFF',
		opacity: '1',
		height: '80vh',
		width: '15%',
	},
	tabPanel: {
		width: '80%',
	},
}));

const PrivateRoute = ({ component: Component, ...rest }) => {

	const userInfo = useSelector(({ auth }) => auth.userInfo);
	
	const dispatch = useDispatch();
	const [modalInvalidSesion, setModalInvalidSesion] = useState(false)
	const [userDigitalWare, setUserDigitalWare] = useState(false)
	const hasToken = localStorage.getItem('JWT');
	if (!userInfo && hasToken) dispatch(setInfoToken());
	const classes = useStyles();
	const timeout = 600000 //10 Minutos de inactividad 
	let inactivityTimer;


	useEffect(() => {
		function resetTimer() {
			clearTimeout(inactivityTimer);
			inactivityTimer = setTimeout(() => {
				setModalInvalidSesion(true)
				dispatch(invalidSesion(true))
				localStorage.removeItem('JWT')
			}, timeout);
		}

		function clearTimer() {
			clearTimeout(inactivityTimer);
		}

		resetTimer();
		window.addEventListener('mousemove', resetTimer);
		window.addEventListener('keypress', resetTimer);
		window.addEventListener('click', resetTimer);
		window.addEventListener('scroll', resetTimer);

		return () => {
			clearTimer();
			window.removeEventListener('mousemove', resetTimer);
			window.removeEventListener('keypress', resetTimer);
			window.removeEventListener('click', resetTimer);
			window.removeEventListener('scroll', resetTimer);
		};
	}, [timeout]);


	useEffect(() => {
		window.onload = function () {
			if (validateEstampa()) dispatch({ type: IS_UNIQUE_ELECTRONIC, payload: false });
			else dispatch({ type: IS_UNIQUE_ELECTRONIC, payload: true });
		};
		if (rest.path !== null && rest.path !== undefined) {
			rest.setCurrentPathAction(rest.path);
		}
	}, [rest.path]);

	useEffect(() => {
        if(userInfo){
			setUserDigitalWare(userInfo.userDigitalWare)
		}
    }, [userInfo])


	return (
		<Route
			{...rest}
			render={(props) => (
				isValidToken() ? (
					<>
						<Grid container className={classes.root} spacing={0}>
							<Home />
							<Grid item className={'menu-tabs'}>
								{<LateralMenu userDigitalWare={userDigitalWare} />}
							</Grid>
							<Grid item className={'tab-panel'}>
								{Component && <Component {...props} />}
							</Grid>
						</Grid>
						{
							modalInvalidSesion &&
							<ModalInvalidSession message="La sesión se ha cerrado por inactividad" />
						}
					</>
				) :
					<Redirect to="/" />
			)}
		/>
	);
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { setCurrentPathAction })(PrivateRoute);
