import React, { useState } from 'react';

import {
	makeStyles,
	TableHead,
	TableRow,
	TableSortLabel,
	TableCell,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},

	titleEncabezado: {
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '12px',
		color: '#E55200',
		lineHeight: '16px',
	},
	titleEncabezado2: {
		color: '#E55200',
		fontFamily: 'Mulish',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '16px',
		paddingLeft: '18px',
	},
}));

// * Encabezado de la tabla pendientes por firmar
const headCells = [
	{
		id: 'nombrereal',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Carpeta / Documento',
		width: '34%',
		style: true,
	},
	{
		id: 'creador',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Creador del circuito ',
		width: '20%',
		style: false,
	},
	{
		id: 'fechaCreacion',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha creación',
		width: '15%',
		style: false,
	},
	{
		id: 'fechaRecepcion',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: 'Fecha recepción',
		width: '15%',
		style: false,
	},
	{
		id: '',
		align: 'start',
		numeric: false,
		disablePadding: true,
		label: '',
		width: '14%',
		style: false,
	},
];

/**
 * * Encabezado de la tabla
 */
export function EnhancedTableHead(props) {
	const classes = useStyles();

	const { order, orderBy, onRequestSort, headCellsP = headCells } = props;

	const [count, setCount] = useState(0);

	const createSortHandler = property => event => {
		onRequestSort(event, property);
		setCount(count + 1);
	};

	return (
		<TableHead className={'headerTableDocumentSigned'}>
			<TableRow>
				<TableCell padding='checkbox' />
				{headCellsP.map(headCell => (
					<TableCell
						key={headCell.id}
						width={headCell.width}
						align={headCell.align}
						className={
							headCell.style
								? classes.titleEncabezado2
								: classes.titleEncabezado
						}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.id !== '' && (
							<TableSortLabel
								className={
									headCell.style
										? classes.titleEncabezado2
										: classes.titleEncabezado
								}
								active={orderBy === headCell.id && count > 0}
								direction={orderBy === headCell.id ? order : 'desc'}
								onClick={createSortHandler(headCell.id)}
							>
								<span
									className={
										headCell.style
											? classes.titleEncabezado2
											: classes.titleEncabezado
									}
								>
									{headCell.label}
								</span>
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === 'desc'
											? 'sorted descending'
											: 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
