import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Loading from '../../components/Loading';

// import  HomeCF  from './CircuitoFirmas/HomeCF';
import  {HomeCF}  from './CircuitoFirmas/HomeCF';
import AddSignersCF from './CircuitoFirmas/AddSignersCF';
import NewViewFirmar from './CircuitoFirmas/NewViewFirma';
import ViewMessage from './CircuitoFirmas/ViewMessage';

import '../../assets/styles/StylesBody.css';
import ModalReload from './CircuitoFirmas/modales/ModalReload';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanFiles, miniResetCF, resetCF } from '../../redux/actions/CircuitoFirmasAction';
// import ModalRelolad from './CircuitoFirmas/modales/ModalReload';

const stepComponents = {
	0: HomeCF,
	1: AddSignersCF,
	2: NewViewFirmar,
	3: ViewMessage,
	10: AddSignersCF,
};

const Firmar = props => {
	const { loading } = props;
	const [modalReload, setModalReload] = useState(false)
	const history = useHistory()
	const dispatch = useDispatch()
	const location = useLocation()

	const stepCircuit = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.stepCircuit
	);
	const irHome = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.home
	);
	const sesionInvalida = useSelector(
		({ CircuitoFirmasReducer }) => CircuitoFirmasReducer.invalidSesion
	);



	useEffect(() => {
		if (!irHome && stepCircuit !== 10) {
			dispatch(cleanFiles())
		}
		return () => { if (!irHome && stepCircuit !== 10) { dispatch(cleanFiles()) } }
	}, [])

	useEffect(() => {
		window.addEventListener("popstate", () => {
			dispatch(cleanFiles())
			// localStorage.removeItem('irHome')
			dispatch(resetCF())
			if (stepCircuit !== 0) {
				history.push("/home");
			}
		})
			return () => {
				window.removeEventListener('popstate', ()=>{
				})

			}
		}, [])

	useEffect(() => {
		const home = localStorage.getItem('irHome')
		document.body.className = 'bodyMiddle';
		if (home && stepCircuit === 0) {
			history.push('/home')
			localStorage.removeItem('irHome')
		}
	});

	useEffect(() => {

		return () => {
			// dispatch(resetCF())
			dispatch(miniResetCF())
		}
	}, [location.pathname])

	useEffect(() => {
		if (!sesionInvalida) {
			if (localStorage.getItem("JWT") && (stepCircuit !== 0 || stepCircuit !== 10)) {
				const beforeUnload = (e) => {
					e.preventDefault();
					e.returnValue = "";
				};
				window.addEventListener("beforeunload", beforeUnload);
				return () => {
					window.removeEventListener("beforeunload", beforeUnload);
				};
			}
		}
	}, [stepCircuit, sesionInvalida]);

	const handleConfirm = () => {
		setModalReload(false)
		window.location.reload()
	}

	const hadleCancel = () => {
		setModalReload(false)
	}


	if (loading) return <Loading />;

	const getStepComponent = step => {
		const Component = stepComponents[step] || (() => <div>Not found</div>);
		return <Component />;
	};

	return (
		<>
			<Grid container spacing={0} direction='row'>
				{getStepComponent(stepCircuit)}
			</Grid>
			{
				modalReload &&
				<ModalReload handleConfirm={handleConfirm} hadleCancel={hadleCancel} />
			}
		</>
	);
};

export default Firmar;
