import React from 'react';
import LogoFirmaYa  from '../../assets/img/logo_firmaya.png'

export const  MainHeader =()=>{
    return ( 
        <div className="BarHeader">
            <div className="content">
                <div className="col-sm-5">
                    <img height={40} src={LogoFirmaYa} />
                </div>                
            </div>
        </div>
       
    )
}
  
export default MainHeader;
 