import React from 'react';
import { PDFDocument } from 'pdf-lib';
import {
	MAX_MEGAS_ENCRYPT_SIZE,	MAX_FILE_UPLOAD_ENCRYPT, MAX_FILE_UPLOAD, ACCEPT_FILES_ENCRYPT, 
	APL_GOOGLE_DRIVE_CLIENT_ID, ACCEPT_FILES_VALIDATE, API_GOOGLE_DRIVE_API_KEY,
	GET_INFO_DOC_GOOGLE_DRIVE, SCOPES_GET, ACCEPT_FILES,
	MAX_MEGAS_FILE_SIZE, MAX_MEGAS_FILES_SIZE,
	ACCEPT_FILES_VALIDATE_CADES
} from '../../redux/types/ApiConstants';
import { connect } from 'react-redux';
import GooglePicker from 'react-google-picker';
import iconDrive from '../../assets/img/googledriveIcon.png';
import '../../assets/styles/CloudCss.css';
import { kbToMb } from '../../helpers/FilesFunction';
import { useSnackbar } from 'notistack';
 
const GetFileGoogleDrive = (props) => {

	const {
		isDecrypt, isEncrypt, setStatusModalCloudSelect, maxSize, isP7s ,files, setLoading, setFiles, sizeFiles, isCades=false
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	
	const cutName = (name) => {
		if(name != null && name.length > (window.innerWidth > 991 ? 70 : 10)) return (name.substring(0, (window.innerWidth > 991 ? 70 : 10)).concat('...'))
		else return name
	};

	const onFailded = (data) => {
		console.log('Auth failed', data);
	}

	const downloadFile = (file, oauthToken) => {
		return new Promise(function (resolve) {
			let request = new XMLHttpRequest();
			let bearerToken = 'Bearer ' + oauthToken;
			request.open('GET', GET_INFO_DOC_GOOGLE_DRIVE + file.id, false);
			request.setRequestHeader('Authorization', bearerToken);
			request.send();
			let jsonResponse = JSON.parse(request.response);
			let requestBlob = new XMLHttpRequest();
			requestBlob.open('GET', jsonResponse.downloadUrl + file.id, true);
			requestBlob.setRequestHeader('Authorization', bearerToken);
			requestBlob.responseType = 'blob';
			requestBlob.onload = function () {
				if (this.status >= 200 && this.status < 300) {
					let blobFile = requestBlob.response;
					blobFile.lastModified = file.lastEditedUtc;
					blobFile.lastModifiedDate = new Date(Date.UTC(file.lastEditedUtc));
					blobFile.name = file.name;
					blobFile.url = file.url;
					blobFile.sizeBytes = file.sizeBytes;
					blobFile.mimeType = file.mimeType;
					resolve(blobFile);
				} else {
					resolve(null);
				}
			}
			requestBlob.send();
		});
	}
	
	const readFile = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = error => reject(error);
		  reader.readAsArrayBuffer(file);
		});
	}

	async function getPageNumber (pFiles)  {
		var validFiles = [];
		for await (const pFile of pFiles) {
			if (pFile.numPages === undefined) {		
				if('.' + pFile.name.split('.').pop() == '.pdf'){
					try {
						const arrayBuffer = await readFile(pFile);
						const pdf = await PDFDocument.load(arrayBuffer);
						pFile.numPages = pdf.getPages().length;
						validFiles.push(pFile)
					} catch (error) {
						enqueueSnackbar(
							`El archivo ${cutName(pFile.name)} está protegido y no es posible hacerle cambios`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						)
					}
				} else {
					pFile.numPages = 0;
					validFiles.push(pFile);
				}
			}
		}
		if(validFiles.length > 0){
			enqueueSnackbar('Archivos agregados exitosamente.', { 
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
			});
		}
		await setFiles([...files, ...validFiles]);
	}

	const onSuccessP7s = async(data, oauthToken) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		if (data.length > 1) {
			if(isCades){
				enqueueSnackbar(
					`Solo se puede firmar un documento.`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
			} else{
				enqueueSnackbar(
					`Solo se puede verificar un documento.`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
			}
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index], oauthToken);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFileP7s(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				setFiles(listFiles);
				enqueueSnackbar('Archivos agregados exitosamente.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                });
			}
		}
	}
	
	const verificateFileP7s = (file) => {
		return new Promise(function (resolve) {
			var extension = '.' + file.name.split('.').pop();
			var isAccept = isP7s? ACCEPT_FILES_VALIDATE.find(element => element == extension) : ACCEPT_FILES_VALIDATE_CADES.find(element => element == extension);
			if (!isAccept) {
				enqueueSnackbar(
					`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${ACCEPT_FILES_VALIDATE.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				);
				resolve(null);
			} else {
				var fileSizeMb = Math.round(file.size / 1024);
				var maxSizeMb = Math.round(maxSize / 1024);
				if (fileSizeMb > maxSizeMb) {
					enqueueSnackbar(
						`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(maxSize)}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
        	                anchorOrigin: {
        	                    vertical: 'top',
        	                    horizontal: 'right',
        	                },
						},
					);
					resolve(null);
				} else {
					resolve(file);
				}
			}
		});
	}

	const verificateFile = (file) => {
		return new Promise(function (resolve) {
			let dropedSize = sizeFiles;
			var maxFilesSizeMb;
			if(isEncrypt) maxFilesSizeMb = Math.round((MAX_MEGAS_ENCRYPT_SIZE * 1000000) / 1000);
			else maxFilesSizeMb = Math.round((MAX_MEGAS_FILES_SIZE * 1000000) / 1000);
			let sizekiloByte = Math.round(file.sizeBytes / 1024);
			dropedSize += sizekiloByte;
			if (dropedSize > maxFilesSizeMb) {
				enqueueSnackbar(
					`El tamaño total de los archivos supera el máximo permitido.\nTamaño limite de ${kbToMb(isEncrypt ? MAX_MEGAS_ENCRYPT_SIZE * 1000000 : MAX_MEGAS_FILES_SIZE * 1000000)}`,
					{
						style: { whiteSpace: 'pre-line' },
						variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
					},
				);
				resolve(null);
			} else {
				var extension = '.' + file.name.split('.').pop();
				var isAccept;
				if(isEncrypt) isAccept = ACCEPT_FILES_ENCRYPT.find(element => element.toLowerCase() == extension.toLowerCase());
				else if(isDecrypt) isAccept = ['.zip'].find(element => element == extension);
				else if (isCades) isAccept = ACCEPT_FILES_VALIDATE_CADES.find(element => element.toLowerCase() == extension.toLowerCase());
				else isAccept = ACCEPT_FILES.find(element => element.toLowerCase() == extension.toLowerCase());
				if (!isAccept) {
					var exten = isEncrypt ? ACCEPT_FILES_ENCRYPT : isDecrypt ? ['.zip'] : ACCEPT_FILES;
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} no es permitido, los tipos de archivos permitidos son: ${exten.filter(e => e.length < 5).toString().replaceAll(","," ")}`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else if(file.name.length > 200){
					enqueueSnackbar(
						`El archivo ${cutName(file.name)} tiene un nombre muy largo`,
						{
							style: { whiteSpace: 'pre-line' },
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
						},
					);
					resolve(null);
				} else {
					var fileSizeMb = Math.round(file.sizeBytes / 1024);
					var maxSizeMb = Math.round((MAX_MEGAS_FILE_SIZE * 1000000) / 1000);
					if (fileSizeMb > maxSizeMb) {
						enqueueSnackbar(
							`El tamaño del archivo ${cutName(file.name)} supera el máximo permitido.\nTamaño limite de ${kbToMb(MAX_MEGAS_FILE_SIZE * 1000000)}`,
							{
								style: { whiteSpace: 'pre-line' },
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
							},
						);
						resolve(null);
					} else {
						var results = files.filter(function (doc) { return doc.name == file.name; });
						var existe = (results.length > 0) ? results[0] : null;
						if (existe != null) {
							enqueueSnackbar(
								`El archivo ${cutName(file.name)} se encuentra actualmente agregado`,
								{
									style: { whiteSpace: 'pre-line' },
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
								},
							);
							resolve(null);
						} else {
							resolve(file);
						}
					}
				}
			}
		});
	}
	const onSuccess = async (data, oauthToken) => {
		setLoading(true);
		setStatusModalCloudSelect(false);
		const fileNumber = files.length + data.length;
		var numFiles;
		if(isEncrypt) numFiles = MAX_FILE_UPLOAD_ENCRYPT;
		else if(isDecrypt) numFiles = 1;
		else numFiles = MAX_FILE_UPLOAD;
		if (fileNumber > numFiles) {
			enqueueSnackbar(`La cantidad de archivos supera el máximo permitido de ${numFiles} archivos.`, {
					style: { whiteSpace: 'pre-line' },
					variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
				},
			);
			setLoading(false);
		} else {
			let downloadFiles = [];
			for (let index = 0; index < data.length; index++) {
				const file = await downloadFile(data[index], oauthToken);
				if (file != null) {
					downloadFiles.push(file);
				}
			}
			let listFiles = [];
			for (let index = 0; index < downloadFiles.length; index++) {
				const file = await verificateFile(downloadFiles[index]);
				if (file != null) {
					listFiles.push(file);
				}
			}
			setLoading(false);
			if (listFiles.length > 0) {
				await getPageNumber(listFiles);
			}
		}
	}

	return (
		<GooglePicker
			clientId={APL_GOOGLE_DRIVE_CLIENT_ID}
			developerKey={API_GOOGLE_DRIVE_API_KEY}
			scope={[SCOPES_GET]}
			onChange={data => console.log('on change:', data)}
			onAuthFailed={data => onFailded(data)}
			multiselect={false}
			navHidden={false}
			authImmediate={false}
			viewId={'DOCS'}
			mimeTypes={['application/vnd.google-apps.file']}
			createPicker={(google, oauthToken) => {
				const googleViewId = google.picker.ViewId.DOCS;
				const docsView = new google.picker.DocsView(googleViewId)
					.setIncludeFolders(true)
					.setSelectFolderEnabled(false)
					.setOwnedByMe(true);
				const picker = new window.google.picker.PickerBuilder()
					.addView(docsView)
					.setLocale('es')
					.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
					.setTitle('Seleccione el archivo que desea importar')
					.setOAuthToken(oauthToken)
					.setDeveloperKey(API_GOOGLE_DRIVE_API_KEY)
					.setCallback((response) => {
						if (response.action === "picked") {
							if(isP7s || isCades)onSuccessP7s(response.docs, oauthToken);
							else onSuccess(response.docs, oauthToken);
						}
					});
				picker.build().setVisible(true);
				setStatusModalCloudSelect(false);
			}}
		>
			<img src={iconDrive} width='25px' className='buttonPC' /> Google Drive
		</GooglePicker>
	);
}

const mapStateToProps = (state) => {
	return {
		rows: state.consultSignedDocument.signedDocumentsSelect,
		loading: state.consultSignedDocument.loading,
	}
};

export default connect(mapStateToProps)((GetFileGoogleDrive));