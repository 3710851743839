import * as asn1js from "asn1js";
import ContentInfo from "./signature/ContentInfo";
import SignedData from "./signature/SignedData";

const rdnmap = {
	"2.5.4.6": "C",
	"2.5.4.10": "O",
	"2.5.4.11": "OU",
	"2.5.4.3": "CN",
	"2.5.4.7": "L",
	"2.5.4.8": "S",
	"2.5.4.12": "T",
	"2.5.4.42": "GN",
	"2.5.4.43": "I",
	"2.5.4.4": "SN",
	"1.2.840.113549.1.9.1": "E-mail",
	"2.5.4.5": "SERIALNUMBER",
	"2.5.4.41": "2.5.4.41"
};
 
export const getListSignature = (base64, isValidate) => {
	let firmas = [];
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	try {
		const view = new Uint8Array(bytes.buffer);
		const pdf = new window.PDFDocument(null, view, null);
		pdf.parseStartXRef();
		pdf.parse();

		const acroForm = pdf.xref.root.get("AcroForm");
		if (typeof acroForm === "undefined") {
			return firmas;
		}
		const fields = acroForm.get("Fields");
		if (fields !== null) {
			fields.forEach(field => {
				if (window.isRef(field) === false)
					return firmas;

				const sigField = pdf.xref.fetch(field);
				const sigFieldType = sigField.get("FT");
				if ((typeof sigFieldType === "undefined") || (sigFieldType.name !== "Sig"))
					return firmas;

				const v = sigField.get("V");
				const contents = v.get("Contents");

				const contentLength = contents.length;
				const contentBuffer = new ArrayBuffer(contentLength);
				const contentView = new Uint8Array(contentBuffer);

				for (let i = 0; i < contentLength; i++)
					contentView[i] = contents.charCodeAt(i);
				const asn1 = asn1js.fromBER(contentBuffer);
				const cmsContentSimp = new ContentInfo({ schema: asn1.result });
				const cmsSignedSimp = new SignedData({ schema: cmsContentSimp.content });
				let date = null;
				if(cmsSignedSimp.signerInfos[0].signedAttrs.attributes){
					for (const typeAndValue of cmsSignedSimp.signerInfos[0].signedAttrs.attributes) {
						if(typeAndValue.type == '1.2.840.113549.1.9.5'){
							date = typeAndValue.values[0];
							break;
						}
					}
				}
				if(!date) date = new Date(v.map.M.substr(2,4), v.map.M.substr(6,2) - 1, v.map.M.substr(8,2), v.map.M.substr(10,2), v.map.M.substr(12,2), v.map.M.substr(14,2));				
				let certUsuario = cmsSignedSimp.certificates[2] == null ? (cmsSignedSimp.certificates[1] == null ? cmsSignedSimp.certificates[0] : cmsSignedSimp.certificates[1]) : cmsSignedSimp.certificates[2];
				let extensions = [];
				for (let c of cmsSignedSimp.certificates) {
					extensions.push(c.extensions.length);
				}
				let numExtensions = Math.max.apply(null, extensions);
				for (let c of cmsSignedSimp.certificates){
					if(c.extensions.length == numExtensions){
						certUsuario = c;
						break;
					}
				}
				
				var uint8array = new TextEncoder("utf-8").encode(certUsuario.serialNumber.valueBlock);
				var serialDecode = new TextDecoder().decode(uint8array);
				
				let CN = '';
				let SERIALNUMBER = '';
				let ENTIDAD = '';
				let TIPODOC = '';
				let CIUDAD = '';
				let ESTADO = 'Cert. verificado correctamente';
				let VALIDOHASTA = '';
				let VALIDODESDE = '';
				let PAIS = '';
				let CORREO = '';
				let FECHAFIRMADO = new Date(date) == "Invalid Date" ? new Date(date.year, date.month - 1, date.day, date.hour, date.minute, date.second) : date;
				let SERIAL = serialDecode;
				for (const typeAndValue of certUsuario.subject.typesAndValues) {
					let typeval = rdnmap[typeAndValue.type];
					if (typeof typeval === "undefined")
						typeval = typeAndValue.type;
					VALIDODESDE = certUsuario.notBefore.value;
					VALIDOHASTA = certUsuario.notAfter.value;
					const subjval = typeAndValue.value.valueBlock.value;
					if (typeval === 'CN') {
						CN = subjval;
					}
					if (typeval === 'SERIALNUMBER') {
						SERIALNUMBER = subjval;
					}
					if (typeval === '2.5.4.41') {
						TIPODOC = subjval;
					}
					if (typeval === 'L') {
						CIUDAD = subjval;
					}
					if (typeval === 'C') {
						PAIS = subjval;
					}
					if (typeval === 'E-mail') {
						CORREO = subjval;
					}
				}
				for (const typeAndValue of certUsuario.issuer.typesAndValues) {
					let typeval = rdnmap[typeAndValue.type];
					const subjval = typeAndValue.value.valueBlock.value;
					if (typeval === 'CN') {
						ENTIDAD = subjval;
						break;
					}
				}
				if(isValidate){
					firmas.push({ "cn": CN, "email":CORREO,"serialNumber": SERIAL,
					"organizacion": ENTIDAD, "pais":PAIS,"fechaDesde": VALIDODESDE,"fechaHasta": VALIDOHASTA,
					"estadoValidacion": ESTADO, "fechaFirmado":FECHAFIRMADO, "numeroDocumento": SERIALNUMBER,"ciudad":CIUDAD})}
				else {
					firmas.push({ "cn": CN, "numeroDocumento": SERIALNUMBER, 
					"tipoDocumento": TIPODOC})
				}
			});
		}
	}
	catch (e) {
		console.error(e.stack);
	}
	return firmas;
};