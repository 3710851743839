import { Button, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ZoneUpload from '../../../components/ZoneUpload';
import {
	ACCEPT_FILES_VALIDATE_CADES,
	API_HOSTNAME_AWS,
	MAX_MEGAS_FILE_SIZE_CADES,
	MAX_MEGAS_VALIDATE,
} from '../../../redux/types/ApiConstants';
import ButtonFiles from '../../../components/ButtonFiles';
import ZoneUploadCades from '../../../components/ZoneUploadCades';
import ModalFirmaCades from './ModalFirmaCades';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getCerificatesByUser } from '../../../redux/actions/SignAction';

const CadesSignature = () => {
	const states = useSelector(state => state);
	const [files, setFiles] = useState([]);
	const StepsEnum = { LoadFile: 1, ListToValidate: 2, InfoValidated: 3 };
	const [isResponsive, setResponsive] = useState(
		window.innerWidth <= 600 ? false : true
	);
	const [isLoading, setLoading] = useState(false);

	const url = API_HOSTNAME_AWS + "signature/api/sign/cadesSeg";
	const dispatch = useDispatch();
	const dataCert = states.sign.certificates;
	const [stepActive, setStepActive] = useState(StepsEnum.LoadFile);
	const [modalFirmaCades, setModalFirmaCades] = useState(false);
	const [firmaTipo, setFirmaTipo] = useState(0);
	const [showElectroContent, setShowElectroContent] = useState(false);
	const [attemps, setAttemps] = useState(0);
	const [bandera, setBandera] = useState(false);
	const [services, setServices] = useState({
		sms: false,
		whatsapp: false,
	});

	const { enqueueSnackbar } = useSnackbar();
	const onClose = () => {
		setModalFirmaCades(false);
	};

	useEffect(() => {
		document.body.className = 'bodyMiddle';
	}, []);

	useEffect(() => {
		(async () => {
			await dispatch(
				getCerificatesByUser(enqueueSnackbar, onClose, setServices)
			);
		})();
	}, []);

	const jwt = localStorage.getItem('JWT');

	const b64Doc = files => {
		return new Promise((resolve, reject) => {
			const promises = Array.from(files).map(file => {
				return new Promise((res, rej) => {
					const reader = new FileReader();
					reader.onloadend = () => {
						const base64String = reader.result.split(',')[1];
						res({ name: file.name, base64: base64String });
					};
					reader.onerror = error => {
						rej(error);
					};
					reader.readAsDataURL(file);
				});
			});

			Promise.all(promises)
				.then(results => resolve(results))
				.catch(error => reject(error));
		});
	};

	const sign = async (id, pass, isElectronic) => {
		const base64Docs = await b64Doc(files);
		const cuerpoSolicitud = {
			identifier: id,
			password: pass,
			electonic: isElectronic,
			data: base64Docs[0].base64,
		}
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${jwt}`,
				},
				body: JSON.stringify(cuerpoSolicitud),
			});
			const data = await response.json();
			if (!response.ok) {
				enqueueSnackbar(`Error: ${data.descriptionResponse}`, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				});
				throw new Error(`Error: ${response.status}`);
			}
			return data;
		} catch (error) {
			console.error('Ocurrio un error al realizar la firma', error);
		}
		
	};

	return (
		<>
			<Grid container spacing={0} direction='row'>
				<Container component='main' maxWidth='lg'>
					<h1
						id={stepActive === StepsEnum.InfoValidated && 'titleValidate'}
						className={'title-background'}
					>
						{isResponsive ? 'Firma Cades' : 'Firma Cades'}
					</h1>
				</Container>
				<Container component='main'>
					<Grid
						container
						spacing={0}
						direction='row'
						justify='flex-start'
						alignItems='flex-start'
						className='zone-upload-container'
						maxWidth='md'
					>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Paper
								elevation={3}
								id={
									stepActive === StepsEnum.InfoValidated &&
									isResponsive &&
									'paper-upload-result'
								}
								className='paper-upload'
							>
								{stepActive !== StepsEnum.InfoValidated && (
									<>
										<ZoneUploadCades
											accept={ACCEPT_FILES_VALIDATE_CADES}
											isResponsive={isResponsive}
											setFiles={setFiles}
											files={files}
											maxFiles={1}
											multiple={false}
											maxSize={MAX_MEGAS_VALIDATE * 1000000}
										/>

										<>
												<Grid container className='gridCades' spacing={2}>
													<Grid
														item
														style={{
															visibility: Boolean(files.length)
																? 'visible'
																: 'hidden',
														}}
													>
														<ButtonFiles
															accept={ACCEPT_FILES_VALIDATE_CADES}
															modeChange={true}
															multiple={false}
															files={files}
															setFiles={setFiles}
															isCades={true}
															setLoading={setLoading}
															maxSize={MAX_MEGAS_FILE_SIZE_CADES * 1000000}
														/>
													</Grid>
														<Button
															id={'validate'}
															className={'btnFirmaCades'}
															disabled={!Boolean(files.length)}
															disableElevation
															onClick={e => {
																setModalFirmaCades(true);
															}}
														>
															Firmar
														</Button>
												</Grid>
										</>
										{modalFirmaCades && (
											<ModalFirmaCades
												isOpen={modalFirmaCades}
												onClose={onClose}
												datacer={dataCert.length >= 1 ? dataCert : false}
												firmaTipo={firmaTipo}
												setFirmaTipo={setFirmaTipo}
												showElectroContent={showElectroContent}
												setShowElectroContent={setShowElectroContent}
												attemps={attemps}
												setAttemps={setAttemps}
												bandera={bandera}
												setBandera={setBandera}
												firmar={sign}
												setFiles={setFiles}
												files={files}
											/>
										)}
									</>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</>
	);
};

export default CadesSignature;
