import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getFilesCF } from '../../../../redux/actions/SignAction';
import Modal from '@material-ui/core/Modal';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import Pdf from '../../FirmaAvanzada/Pdf'
import { modalFirmaAvanzadaCf } from '../../../../redux/actions/CircuitoFirmasAction';

const ModalFirmaAvanzadaCF = (props) => {

	const {  getFilesCF, history } = props;
	
    const setFiles=() => { }
	const [loading, setLoading] = useState(true);
	const [newFiles, setNewFiles] = useState([]);
	const [size, setSize] = useState(0);
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const states = useSelector(state => state)
	const selected = states.CircuitoFirmasReducer.firmClickDer
	const db = states.CircuitoFirmasReducer.db
	const rows= db.filter((item) => item.estadoCircuito === 2)
	const [openedRowIndex, setOpenedRowIndex] = useState(-1)

	const isOpen = true
	const onClose = () => {
        dispatch(modalFirmaAvanzadaCf(false))
    }
    const files=[]
    const filesSize=0
    const style='style'	

	useEffect(() => {
		if (style) {
			const getData = async () => {
				let lista = [];
				selected.map(sel => {
					let file = rows.find(x => x.idCircuito === sel);
					lista.push(file);
				});
				const selectFiles = lista.map(el => el.documentos).flat();
				let response = await getFilesCF(selectFiles, enqueueSnackbar, history);
				if (response) {
					setNewFiles(response.files);
					setSize(response.filesSize);
					setLoading(false);
				}
			};
			getData();
		} else {
			setLoading(false);
		}
	}, []);

	const onExit = (type) => {
		onClose(type)
	}

	if (loading) return <Loading />;

	return (
		<Modal
			disableBackdropClick
			open={isOpen}
			onClose={onExit}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Pdf
				files={style ? newFiles : props.files}
				onClose={onExit}
				filesSize={style ? size : filesSize}
				style={style}
				setFiles={setFiles}
				canAddFiles={false}
				setOpenedRowIndex={setOpenedRowIndex}
			/>
		</Modal>
	)
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
	getFilesCF: getFilesCF,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalFirmaAvanzadaCF));