import { capitalizeWords } from '../formatText';

export const createDataDocuments = (data, email) => {
	const UniqueDocuments = {};
	const circuitOneFile = data
		.filter(({ circuito }) => !circuito)
		.map(({ documentos, encriptado, ...rest }) => {
			if (documentos?.length === 1) {
				const [
					{ id, nombre, repositorio, ruta, nombrerepositorio, certificados },
				] = documentos;
				return {
					id: Number(`${id}`),
					nombrereal: nombre,
					repositorio,
					ruta,
					encriptado,
					nombrerepositorio,
					certificados,
					idCircuito: rest.idCircuito,
					fechaFirma: rest.fechaCreacion,
					estadoCircuito: rest.estadoCircuito,
					isFolder: null,
					documentCircuito: null,
					fechaCreacion: rest.fechaCreacion,
					fechaModificacion: rest.fechaModificacion,
					fechaVencimiento: rest.fechaVencimiento,
					firmado: rest.firmado,
					firmadoUsuario: rest.firmadoUsuario,
					firmasRealizadas: rest.firmasRealizadas,
					firmasTotal: rest.firmasTotal,
					radicado: rest.radicado,
					usuarioCreador: rest.usuarioCreador,
					vence: rest.vence,
				};
			}
			return { documentos, encriptado, ...rest };
		});

	const documents = circuitOneFile.map(
		({
			documentos,
			id,
			encriptado,
			idCircuito,
			nombrereal,
			carpeta,
			descripcionOrigenFirma,
			fechaCreacion,
			fechaFirma,
			estadoCircuito,
			repositorio,
			nombrerepositorio,
			ruta,
			origenFirma,
			tipoFirma,
			descripcionTipoFirma,
			consecutivo,
			peso,
			fechaModificacion,
			firmasRealizadas,
			firmasTotal,
			vence,
			fechaVencimiento,
			firmadoUsuario,
			radicado,
			usuarioCreador,
			firmado,
			certificados,
			circuito,
		}) => ({
			id: id || idCircuito,
			nombrereal: nombrereal || carpeta,
			descripcionOrigenFirma: getDescriptionTypeSignature(
				certificados,
				email,
				documentos
			),
			fechaFirma: fechaModificacion
				? fechaModificacion
				: fechaFirma ?? getSignatureDate(documentos, email),
			isCircuit: !!estadoCircuito,
			isFolder: carpeta || null,
			documentCircuito: documentos
				? createDataDocCircuito(
						documentos,
						idCircuito,
						email,
						fechaFirma,
						encriptado
				  )
				: null,
			fechaCreacion,
			repositorio,
			nombrerepositorio,
			ruta,
			origenFirma,
			tipoFirma,
			descripcionTipoFirma,
			consecutivo,
			peso,
			fechaModificacion,
			firmasRealizadas,
			firmasTotal,
			vence,
			fechaVencimiento,
			firmadoUsuario,
			radicado,
			usuarioCreador,
			firmado,
			circuito,
			idCircuito,
			encriptado,
		})
	);

	documents.forEach(doc => {
		if (UniqueDocuments.hasOwnProperty(doc.nombrerepositorio)) {
			if (doc.isCircuit) {
				UniqueDocuments[doc.id] = doc;
			}
		} else {
			UniqueDocuments[doc.nombrerepositorio] = doc;
		}
	});

	return documents;
};

const createDataDocCircuito = (data, idCircuito, email, encriptado) => {
	return data.map(
		({ id, nombre, nombrerepositorio, repositorio, ruta, certificados }) => ({
			id: Number(`${id}`),
			nombrereal: nombre,
			descripcionOrigenFirma: getDescriptionTypeSignature(certificados, email),
			fechaFirma: getSignatureDate(data, email),
			nombrerepositorio,
			repositorio,
			ruta,
			idCircuito,
		})
	);
};

const getDescriptionTypeSignature = (certificates, email, documentos) => {
	let certs = [];
	if (Array.isArray(certificates)) {
		certs = certificates;
	} else if (documentos && Array.isArray(documentos)) {
		certs = documentos.flatMap(({ certificados }) =>
			Array.isArray(certificados) ? certificados : []
		);
	}

	const certificate =
		certs.find(certificate => certificate?.emailAddress === email) || certs[0];

	if (!certificate) {
		return '';
	}

	const prop = certificate.hasOwnProperty('descripcionTipoFirma')
		? 'descripcionTipoFirma'
		: 'descripciontipofirma';
	const descripcionTipoFirma = certificate[prop] || '';

	return capitalizeWords(`${descripcionTipoFirma} - ${certificate.cn || ''}`);
};

const getSignatureDate = (documents, email) => {
	const certificates = documents.flatMap(doc => doc.certificados);
	const foundCertificate = certificates.find(
		cert => cert.emailAddress === email
	);

	return foundCertificate
		? foundCertificate.fechafirma
		: certificates[0].fechafirma;
};
