import { GET_CERTIFICATES, ENCRYPT } from '../types/EncryptDecryptTypes';

const INITIAL_STATE = {
  certificates: {},
  filesEncrypt: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case ENCRYPT:
      return {
        ...state,
        filesEncrypt: action.payload,
      };
    default:
      return state;
  }
};