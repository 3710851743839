import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/img/logo_firmaya.png';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ManUsuarioIcon from '../../assets/img/icomanual_usuario.svg';
import ManInstalacionIcon from '../../assets/img/icomanual_instalacion.svg';
import FirmaYaIcon from '../../assets/img/ico_firmaya_desktop.svg';
import GrisManUsuarioIcon from '../../assets/img/icomanual_usuario_gris.svg';
import GrisManInstalacionIcon from '../../assets/img/icomanual_instalacion_gris.svg';
import GrisFirmaYaIcon from '../../assets/img/ico_firmaya_desktop_gris.svg';
import DescargaIcon from '../../assets/img/ico_download.svg';
import GrisFirmayaDriverIcon from '../../assets/img/icofirmaya_driver.svg';
import FirmayaDriverIcon from '../../assets/img/icofirmaya_driver_gris.svg';
import { URL_MAN_USUARIO } from '../../redux/types/ApiConstants';
import { URL_MAN_INSTALACION } from '../../redux/types/ApiConstants';
import { URL_FIRMAYA_DESKTOP } from '../../redux/types/ApiConstants';
import { URL_FIRMAYA_DRIVERx64, URL_FIRMAYA_DRIVERx86 } from '../../redux/types/ApiConstants';
import Carousel, { consts } from 'react-elastic-carousel';
import styled from 'styled-components';
import Captcha from './modalCaptcha';
import { Modal } from '@material-ui/core';
 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'left',
    minHeight: 0,
    margin: 0,
    height: 70
  },
  appBar: {
    backgroundColor: '#F1F1F1',
  },
  logo: {
    marginLeft: 20,
    marginTop: 12,
    marginBottom: 12,
    width: 147,

    height: 46
  },
  divtitle: {
    width: '100%',
    height: 120,
    background: 'rgba(0, 0, 0, 0.3)',
    paddingTop: '20px',
  },
  title: {
    margin: 0,
    color: '#FFFFFF',
    opacity: 1,
    fontSize: '33px'
  },
  paper: {
    position: 'relative',
    padding: '35px',
    marginTop: '-40px',
    border: '1px solid #F5AE19',
    borderRadius: '10px'
  },
  button: {
    backgroundColor: '#0000000D',
    borderRadius: '15px !important',
    width: '154px',
    height: '132px',
    borderRadius: '10px',
  },
  label: {
    flexDirection: 'column',
    fontSize: 10,
  },
  text: {
    textAlign: 'center',
    fontSize: '12px',
    letterSpacing: 0,
  },
  item: {
    textAlign: 'center',
    marginBottom: '79.5px',
    marginTop: "78.5px"
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    //width: '95%',
  },
  min: {
    width: 50,
    height: 50,
    position: 'fixed',
    marginTop: '108px',
    marginLeft: '-25px'
  },
  a: {
    textDecoration: 'none',
    '&:hover .MuiButtonBase-root': {
      border: '1px solid #E55200',
    }
  },
  buttoncontainer: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  modal: {
    position: 'absolute',    
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: "16px 32px 24px",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const buttonoptions = [
  {
    image: GrisManUsuarioIcon,
    imagehover: ManUsuarioIcon,
    text: 'Manual usuario',
    redirect: URL_MAN_USUARIO
  },
  {
    image: GrisManInstalacionIcon,
    imagehover: ManInstalacionIcon,
    text: 'Manual instalación',
    redirect: URL_MAN_INSTALACION
  },
  {
    image: GrisFirmaYaIcon,
    imagehover: FirmaYaIcon,
    text: 'Firmaya Desktop',
    redirect: URL_FIRMAYA_DESKTOP
  },
  {
    image: GrisFirmayaDriverIcon,
    imagehover: FirmayaDriverIcon,
    text: 'Firmaya Driver x64',
    redirect: URL_FIRMAYA_DRIVERx64
  },
  {
    image: GrisFirmayaDriverIcon,
    imagehover: FirmayaDriverIcon,
    text: 'Firmaya Driver x86',
    redirect: URL_FIRMAYA_DRIVERx86
  }
]

var redirect = { ref: "" }

  const handleClick = value => () => {    
    redirect.data = value;
  };

  const Descarga = () => {
  const classes = useStyles();

  const [hoverItem, setHoverItem] = React.useState(-1);

  const [modal, setmodal] = useState(false);    

  const openCloseModal = () => {
    setmodal(!modal);
  }

  const handlefocus = (e, index) => {
    setHoverItem(index);
  }

  const ButtonR = styled.button`
    outline:none;
    background: transparent;
    width: 29px;
    height: 29px;
    border: 6px solid #707070;
    border-width: 0 6px 6px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    :disabled,
    button[disabled]{
      border: 6px solid #0000001A;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);      
      border-width: 0 6px 6px 0;
    }
  `;

  const ButtonL = styled.button`
    outline:none;
    background: transparent;
    width: 29px;
    height: 29px;
    border: 6px solid #707070;
    border-width: 0 6px 6px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); 
    :disabled,
    button[disabled]{
      border: 6px solid #0000001A;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);    
      border-width: 0 6px 6px 0;
    }
  `;

  const myArrow = ({ type, onClick, isEdge }) => {
    return (
      <div className={classes.buttoncontainer}>
        {type === consts.NEXT ? <ButtonR onClick={onClick} disabled={isEdge} /> : <ButtonL onClick={onClick} disabled={isEdge} />}
      </div>
    )
  }

  const body = (
    <div className={classes.modal}>
      <div align="center">
        <Captcha
          redirect={redirect.data}
          setmodal={setmodal}
        />
      </div>
    </div>
  )

  return (
    <>
      <Modal
        open={modal}
        onClose={openCloseModal}>
        {body}
      </Modal>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <Link to="/">
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </Toolbar>
      </AppBar>
      <div className={classes.divtitle}>
        <Container component="main" maxWidth="md">
          <h1 className={classes.title}>Descargas</h1>
        </Container>
      </div>
      <div>
        <Container component="main" maxWidth="md">
          <Paper elevation={3} className={classes.paper}>
            <Grid container className={classes.container}>
              <Carousel
                itemsToShow={3}
                renderArrow={myArrow}
                pagination={false}
              >
                {buttonoptions.map((item, index) => {
                  return (
                    <Grid key={index} item className={classes.item}>
                      <a onClick={handleClick(item.redirect)} target="_blank" className={classes.a} rel="noopener noreferrer" download onMouseOver={e => handlefocus(e, index)} onMouseOut={e => { setHoverItem(-1) }} >
                        <IconButton
                          classes={{ root: classes.button, label: classes.label }}
                          variant="raised"
                          disableRipple={true}
                          onClick={() => openCloseModal()}
                        >
                          <div>
                            {hoverItem === index ?
                              <img src={item.imagehover} /> :
                              <img src={item.image} />}
                          </div>
                          <h1 className={classes.text}>{item.text}</h1>
                        </IconButton>
                        {hoverItem === index ?
                          <img src={DescargaIcon} className={`${classes.min}`} /> :
                          ''}
                      </a>
                    </Grid>
                  )
                })}
              </Carousel>
            </Grid>
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default Descarga;