import {
	CAMPOS_HECHOS,
	CLEAN_FILES,
	CREATE_DATA,
	CREATE_FIRMANTE,
	DATA_QR,
	DELETE_CLICK_DER,
	DELETE_DATA,
	DELETE_FIRMANTE,
	DROPBOX_CLICK_DER,
	EDIT_FIRMANTE,
	FILES,
	FIRM_CLICK_DER,
	FIRM_POSITIONS,
	GOOGLE_DRIVE_CLICK_DER,
	INVALID_SESION,
	IS_HOME,
	ITEM,
	MESSAGES_PRIVATES,
	MINI_RESET_CF,
	MODAL_ADD_FIRMANTES,
	MODAL_CONFIRM,
	MODAL_CONFIRM_REJECT,
	MODAL_DELETE,
	MODAL_DROPBOX,
	MODAL_FINISHED,
	MODAL_FIRMA_AVANZADA_CF,
	MODAL_GOOGLE_DRIVE,
	MODAL_HAVE_CERTIFICATE,
	MODAL_MESSAGE,
	MODAL_MISSING_SIGNATURES,
	MODAL_ONE_DRIVE,
	MODAL_REJECT,
	MODAL_SEND_MESSAGE,
	MODAL_SIGN,
	MODAL_SIGNATURE_REJECT,
	MODAL_SIGNERS_INVITATION,
	MODAL_UPS,
	NAME_FOLDER,
	NO_DATA,
	NUMERO_CIRCUITO,
	ONE_DRIVE_CLICK_DER,
	RADICADO_CIRCUITO,
	READ_ALL_DATA,
	RESET_CF,
	STEP_CIRCUIT,
	TOKEN_GENERICO,
	UPDATE_DATA,
} from '../types/CircuitoFirmasTypes';

export const modalConfirm = estado => ({
	type: MODAL_CONFIRM,
	payload: estado,
});

export const modalDelete = estado => ({
	type: MODAL_DELETE,
	payload: estado,
});

export const modalAddFirmantes = estado => ({
	type: MODAL_ADD_FIRMANTES,
	payload: estado,
});

export const modalMessage = estado => ({
	type: MODAL_MESSAGE,
	payload: estado,
});

export const modalSendMessage = estado => ({
	type: MODAL_SEND_MESSAGE,
	payload: estado,
});

export const firm_positions = firmantes => ({
	type: FIRM_POSITIONS,
	payload: firmantes,
});

export const messages_privates = mensaje => ({
	type: MESSAGES_PRIVATES,
	payload: mensaje,
});
export const modal_signers_invitation = estado => ({
	type: MODAL_SIGNERS_INVITATION,
	payload: estado,
});

export const radicado_circuito = estado => ({
	type: RADICADO_CIRCUITO,
	payload: estado,
});
export const numero_circuito = estado => ({
	type: NUMERO_CIRCUITO,
	payload: estado,
});

export const name_folder = newNombre => ({
	type: NAME_FOLDER,
	payload: newNombre,
});

export const create_firmante = firmante => ({
	type: CREATE_FIRMANTE,
	payload: firmante,
});

export const edit_firmante = item => ({
	type: EDIT_FIRMANTE,
	payload: item,
});

export const delete_firmante = id => ({
	type: DELETE_FIRMANTE,
	payload: id,
});

export const modalReject = estado => ({
	type: MODAL_REJECT,
	payload: estado,
});

export const modalConfirmReject = estado => ({
	type: MODAL_CONFIRM_REJECT,
	payload: estado,
});

export const modalFinished = estado => ({
	type: MODAL_FINISHED,
	payload: estado,
});

export const modalHaveCertidicate = estado => ({
	type: MODAL_HAVE_CERTIFICATE,
	payload: estado,
});

export const modalSignatureReject = estado => ({
	type: MODAL_SIGNATURE_REJECT,
	payload: estado,
});

export const modalUps = estado => ({
	type: MODAL_UPS,
	payload: estado,
});

export const modalMissingSignatures = estado => ({
	type: MODAL_MISSING_SIGNATURES,
	payload: estado,
});

export const modalSign = estado => ({
	type: MODAL_SIGN,
	payload: estado,
});

export const createAction = data => ({
	type: CREATE_DATA,
	payload: data,
});

export const readAllAction = data => ({
	type: READ_ALL_DATA,
	payload: data,
});

export const updateAction = data => ({
	type: UPDATE_DATA,
	payload: data,
});

export const deleteAction = id => ({
	type: DELETE_DATA,
	payload: id,
});

export const noAction = () => ({
	type: NO_DATA,
});

export const infoItem = item => ({
	type: ITEM,
	payload: item,
});

export const deleteClickDer = estado => ({
	type: DELETE_CLICK_DER,
	payload: estado,
});

export const modalFirmaAvanzadaCf = estado => ({
	type: MODAL_FIRMA_AVANZADA_CF,
	payload: estado,
});

export const firmClickDer = estado => ({
	type: FIRM_CLICK_DER,
	payload: estado,
});

export const googleDriveClickDer = estado => ({
	type: GOOGLE_DRIVE_CLICK_DER,
	payload: estado,
});
export const modalGoogleDrive = estado => ({
	type: MODAL_GOOGLE_DRIVE,
	payload: estado,
});

export const dropboxClickDer = estado => ({
	type: DROPBOX_CLICK_DER,
	payload: estado,
});
export const modalDropbox = estado => ({
	type: MODAL_DROPBOX,
	payload: estado,
});

export const oneDriveClickDer = estado => ({
	type: ONE_DRIVE_CLICK_DER,
	payload: estado,
});
export const modalOneDrive = estado => ({
	type: MODAL_ONE_DRIVE,
	payload: estado,
});

export const tokenGenerico = token => ({
	type: TOKEN_GENERICO,
	payload: token,
});

export const filesCf = files => ({
	type: FILES,
	payload: files,
});

export const cleanFiles = () => ({
	type: CLEAN_FILES,
});

export const stepCircuit = step => ({
	type: STEP_CIRCUIT,
	payload: step,
});

export const dataQr = data => ({
	type: DATA_QR,
	payload: data,
});

export const resetCF = () => ({
	type: RESET_CF,
});

export const miniResetCF = () => ({
	type: MINI_RESET_CF,
});

export const irHome = data => ({
	type: IS_HOME,
	payload: data,
});

export const invalidSesion = estado => ({
	type: INVALID_SESION,
	payload: estado,
});

export const camposHechos = estado => ({
	type: CAMPOS_HECHOS,
	payload: estado,
});

