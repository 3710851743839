/* eslint-disable import/prefer-default-export */
import { createTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
 
export const theme = createTheme({
  palette: {
    primary: orange,
    secondary: orange,
  },
  typography: {
    fontFamily: [
      'Muli',
      'Roboto',
    ].join(','),
  },
});
